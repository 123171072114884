import React from 'react'
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper'
import { Col, Row } from 'antd'
import ReactApexChart from 'react-apexcharts';

const LoanCost = ({ assetFinanceDetails }) => {

    const LTVChart = {
        series: [assetFinanceDetails?.ltv * 100 ?? 0],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },

                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#101828',
                            fontSize: '12px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}%`;
                            },
                            color: '#101828',
                            fontSize: '23px',
                            offsetY: 0,
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#2C406F'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };

    const LTCChart = {
        series: [assetFinanceDetails?.ltc * 100 ?? 0],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },

                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#101828',
                            fontSize: '12px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}%`;
                            },
                            color: '#101828',
                            fontSize: '23px',
                            offsetY: 0,
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#FF7228'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };

    return (
        <div className='custom-card'>
            <h2 className='mb-0' style={{ fontSize: '24px' }}>Financing KPIs</h2>
            <h2 className='pt-2 pb-4' style={{ fontSize: '24px', fontWeight: 400 }}>Loan Amount: <span style={{ color: '#344D7D', fontSize: '20px', fontWeight: 500 }}>{getFormattedEnglishNumber(assetFinanceDetails?.loanAmount ?? 0)}€</span></h2>
            <Row gutter={[40, 40]} className='pb-4 align-items-center'>
                <Col xs={24} md={14}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Loan Type</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{assetFinanceDetails?.loanType ?? 'NA'}</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Cash on cash (Average)</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.cashOnCash * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Interest Rate</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.interestRate * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>IRR</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.irr * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Amortization</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.amortization * 100)}%</p>
                    </div>
                </Col>
                <Col xs={24} lg={10}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ flexDirection: 'column' }}>
                        <h3 style={{ color: '#2C406F' }}>LTV</h3>
                        <div className="dashboard-ring-chart">
                            <ReactApexChart options={LTVChart.options} series={LTVChart.series} type="radialBar" height={230} />
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={14}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Leveraged IRR</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.leveragedIRR * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>WACC</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.wacc * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Equity Multiple</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.equityMultiple * 100 ?? 0)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Cash on cash (Year 1)</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedDecimalNumber(assetFinanceDetails?.cashOnCashYear1 * 100)}%</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: '18px' }}>
                        <p className='mb-0' style={{ color: '#344D7D', fontSize: '20px' }}>Cash Profit</p>
                        <p className='mb-0' style={{ color: '#1F1F1F', fontSize: '20px', fontWeight: 500 }}>{getFormattedEnglishNumber(assetFinanceDetails?.cashProfit ?? 0)}M</p>
                    </div>
                </Col>
                <Col xs={24} lg={10}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ flexDirection: 'column' }}>
                        <h3 style={{ color: '#FF7228' }}>LTC</h3>
                        <div className="dashboard-ring-chart">
                            <ReactApexChart options={LTCChart.options} series={LTCChart.series} type="radialBar" height={230} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default LoanCost