import {Configuration, CashflowApi} from "reia-dcf-client";
import environments from "./environments";

class DCFClient {
    store;

    constructor(store) {
        this.store = store;
    }

    accessToken = () => {
        return this.store.getState().auth.user.token;
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: { Authorization: 'Bearer ' + this.accessToken() },
        };
        openapiConfig.basePath = environments.dcfApiUrl;
        return openapiConfig;
    };

    cashflowApi = () => {
        return new CashflowApi(this.configuration());
    };
}

export default DCFClient;