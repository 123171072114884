import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  // Dropdown,
  Input,
  // Menu,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BsDot } from "react-icons/bs";
import { HiUser } from "react-icons/hi";
import SearchForm from "./component/TotalTenants/SearchForm";
import Comments from "./component/AssignedTasks/Comments";
import TaskDetails from "./component/AssignedTasks/TaskDetails";
import IMAGES from "../../../assets/images";
import Pagination from "./component/AssignedTasks/Pagination";
import {
  getAssignedTaskDashboard,
  getAssignedTaskDetail,
} from "../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import { getByTypeDropdown } from "../../../app/features/Lookups/lookup.slice";

const { Panel } = Collapse;
const { Search } = Input;
const { Text } = Typography;

export default function TaskAssigned() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const { taskStatusType } = useSelector((state) => state.lookUps);

  useEffect(() => {
    const finalData = {
      type: "taskStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const { assignedTask, assignedTaskDetail, isLoading } = useSelector(
    (state) => state.corporateDashboardData
  );
  // const [filterdArray, setfilterdArray] = useState(assignedTask);

  const lastPostIndex = currentPage * 21;
  const firstPostIndex = lastPostIndex - 21;
  // (((currentPage * 21) - 21) , currentPage* 21)
  // const currentPosts = filterdArray?.slice(firstPostIndex, lastPostIndex);

  const expandIcon = ({ isActive }) =>
    isActive ? <UpCircleOutlined /> : <DownCircleOutlined />;

  const showTaskByStatus = (text) => {
    if (text !== "") {
      const statusType = taskStatusType?.filter((type) => type.text === text);
      const finalData = {
        statusTypeId:
          typeof statusType === typeof [] ? statusType.pop().lookUpId : null,
      };
      dispatch(getAssignedTaskDashboard(finalData));
    } else {
      dispatch(getAssignedTaskDashboard());
    }
  };

  const handleCardClick = (task) => {
    setActive(task.assignTaskId);
    setUser(task.userId);
  };

  const getAllComment = () => {
    const finalData = {
      assignTaskId: active,
    };
    dispatch(getAssignedTaskDetail(finalData));
  };

  useEffect(() => {
    if (active) {
      getAllComment();
    }
  }, [active]);

  useEffect(() => {
    dispatch(getAssignedTaskDashboard());
  }, [dispatch]);
  return (
    <div className="task-assigned-wrapper">
      <Row>
        <Col xs={24} sm={14} lg={16} xl={18}>
          <Row className="d-flex justify-content-between">
            <Col>
              <div className="table-footer-btn d-flex align-items-center mt-0">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-0">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center asset-btn"
                    onClick={() => showTaskByStatus("")}
                  >
                    <div
                      className="active-action"
                      // style={{
                      //     backgroundColor: active === true ? "#f2f3f5" : " ",
                      // }}
                    >
                      <div
                        className="active-action d-flex align-items-center"
                        // style={{
                        //     Color: active === true ? "#000" : "#eee",
                        // }}
                      >
                        {/* <BsFillCircleFill size={10} fill="#2978A0" /> */}
                        <h4 className="mb-0 ml-2 text-black">All</h4>
                      </div>
                    </div>
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                    onClick={() => showTaskByStatus("Completed")}
                  >
                    <div className="active-action d-flex align-items-center">
                      {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                      <h4 className="mb-0 ml-2 text-black">Completed</h4>
                    </div>
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                    onClick={() => showTaskByStatus("Pending")}
                  >
                    <div className="active-action d-flex align-items-center">
                      {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                      <h4 className="mb-0 ml-2 text-black">Pending</h4>
                    </div>
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                    onClick={() => showTaskByStatus("Rejected")}
                  >
                    <div className="active-action d-flex align-items-center">
                      {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                      <h4 className="mb-0 ml-2 text-black">Rejected</h4>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} xl={8} className="mt-3 mt-xl-0">
              <div className="d-flex searchbar-project w-100">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                <Search
                  className="mr-2"
                  placeholder="Search a Task"
                  // onClick={() => setIsModalOpen(true)}
                />
              </div>
            </Col>
          </Row>
          <div style={{ height: "640px" }}>
            <Row
              className="assign-task w-100 my-3 d-flex align-items-center w-100"
              gutter={8}
            >
              {assignedTask
                ?.slice(currentPage * 21 - 21, currentPage * 21)
                ?.map((item) => (
                  <Col
                    xl={8}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="cursor-pointer"
                    key={item.assignTaskId}
                  >
                    <Card
                      onClick={() => handleCardClick(item)}
                      className={`task-card ${
                        active === item.assignTaskId ? "active" : ""
                      }`}
                    >
                      <p
                        className={`m-0 ${
                          (item.statusType === "Pending" && "pending-status") ||
                          (item.statusType === "Completed" &&
                            "complete-status") ||
                          (item.statusType === "Rejected" && "rejected-status")
                        }`}
                      >
                        {item.statusType}
                      </p>
                      {/* <Dropdown
                      overlay={
                        <Menu
                          selectable
                          defaultSelectedKeys={[item.statusType]}
                          items={[
                            {
                              key: "Complete",
                              label: (
                                <div className="mb-0 font-weight-normal mark-complete">
                                  Complete
                                </div>
                              ),
                            },
                            {
                              key: "Pending",
                              label: (
                                <div className="mb-0 font-weight-normal mark-complete">
                                  Pending
                                </div>
                              ),
                            },
                            {
                              key: "Rejected",
                              label: (
                                <div className="mb-0 font-weight-normal mark-complete">
                                  Rejected
                                </div>
                              ),
                            },
                          ]}
                        />
                      }
                      trigger={["click"]}
                    >
                      <p
                        className={`m-0 ${
                          item.status === "Pending"
                            ? "pending-status"
                            : "complete-status"
                        }`}
                      >
                        {item.statusType}
                      </p>
                    </Dropdown> */}
                    <Row gutter={8} className="mt-3">
                      <Col flex="none" lg={5}>
                        <div
                        className={` ${
                          item.isCommentExist === true ? "dot" : ""
                        } `}
                        />
                        <img
                          className="assign-task-avatar"
                          src={item.img ? item.img : IMAGES.MEN_AVATAR}
                          alt="avatar"
                        />
                      </Col>
                      <Col flex="auto" lg={19}>
                        <div className="m-0 text-black d-flex justify-content-between align-items-center mb-1">
                          <Text
                            style={{
                              width: 110,
                            }}
                            ellipsis={{ tooltip: item.taskName }}
                          >
                            {item.taskName}
                          </Text>
                          <p className="m-0 text-grey-shade d-flex align-items-center">
                            <BsDot />
                            {moment(item.assignedDate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                        <span className="d-flex align-items-center text-grey-shade m-0">
                          <HiUser className="mr-1" />{" "}
                          <Text
                            style={{
                              width: 90,
                            }}
                            ellipsis={{ tooltip: item.userName }}
                            className="font-weight-light"
                          >
                            {item.userName}
                          </Text>
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
          <div className="d-flex justify-content-between align-items-baseline mt-2">
            <div>{`Showing ${firstPostIndex + 1} to ${
              lastPostIndex > assignedTask.length
                ? assignedTask.length
                : lastPostIndex
            } of ${assignedTask.length} entries`}</div>
            <Pagination
              totalPosts={assignedTask.length}
              cardsPerPage={21}
              setCurrentPage={setcurrentPage}
              currentPage={currentPage}
            />
          </div>
        </Col>
        <Col
          xs={24}
          sm={10}
          lg={8}
          xl={6}
          className="bg-white p-0 rounded-sm"
          style={{ height: "755px" }}
        >
          <Collapse
            expandIcon={expandIcon}
            expandIconPosition="end"
            defaultActiveKey={["1", "2"]}
          >
            <Panel header="Task Details" key="1">
              <TaskDetails selectedTask={assignedTaskDetail} />
            </Panel>
            <Panel header="Comments" key="2">
              <Comments
                selectedTaskComments={assignedTaskDetail?.comments}
                user={user}
                active={active}
                isLoaded={isLoading}
              />
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <SearchForm
        visible={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
}
