import {dcfClient,apiClient} from "../../../index";

let lastAbortController : AbortController = null;
  
const getAssetDCFResult = async (data) => {

  if (lastAbortController != null) {
    lastAbortController.abort();
  }
  
  lastAbortController = new AbortController();
  
  const response = await dcfClient.cashflowApi().dcfAssetPost(data, {signal: lastAbortController.signal});
  
  return response.data;
};

const getAssetsDCFResult = async (data) => {
  const response = await dcfClient.cashflowApi().dcfPortfolioPost(data);
  return response.data;
};

const getCalculationDetail = async (data) => {
  const response = await apiClient.calculationApi().getCalculationDetailByAsset(data.assetId);
  return response.data;
};

const updateCalculationDetail = async (data) => {
  const response = await apiClient.calculationApi().updateCalculationDetail(data.calculationDetailId, data.calculationDetail);
  return response.data;
};

const getIndexValue = async (data) => {
  const response = await apiClient.calculationApi().getIndexValue(data.indexType, data.indexDate, data.additionalParams);
  return response.data;
};

const calculation = {
  getAssetDCFResult,
  getAssetsDCFResult,
  getCalculationDetail,
  updateCalculationDetail,
  getIndexValue
};

export default calculation;
