import { Card, Col, Row, Switch } from "antd";

export default function NotificationSettings() {
  return (
    <div className="notification-setting">
      <Card className="w-100">
        <div className="d-flex justify-content-between">
          <h3 className="text-blue mb-0">Notification Settings</h3>
        </div>
        <hr />
        <Row gutter={24}>
          <Col xs={24} className="d-flex justify-content-between align-items-center my-2">
            <p className="mb-0">Lorem Ipsum is simply dummy text of the</p>
            <span>
              <Switch className="ml-3 my-auto"/>
            </span>
          </Col>
          <Col xs={24}>
          <hr className="w-100" />
          </Col>
          <Col xs={24} className="d-flex justify-content-between align-items-center my-2">
            <p className="mb-0">Lorem Ipsum is simply dummy text of the</p>
            <span>
              <Switch className="ml-3 my-auto"/>
            </span>
          </Col>
          <Col xs={24}>
          <hr className="w-100" />
          </Col>
          
          <Col xs={24} className="d-flex justify-content-between align-items-center my-2">
            <p className="mb-0">Lorem Ipsum is simply dummy text of the</p>
            <span>
              <Switch className="ml-3 my-auto"/>
            </span>
          </Col>
          <Col xs={24}>
          <hr className="w-100" />
          </Col>
          <Col xs={24} className="d-flex justify-content-between align-items-center my-2">
            <p className="mb-0">Lorem Ipsum is simply dummy text of the</p>
            <span>
              <Switch className="ml-3 my-auto"/>
            </span>
          </Col>
          <Col xs={24}>
          <hr className="w-100" />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
