/** @format */

import {Routes, Route, createBrowserRouter, createRoutesFromElements} from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute.jsx";
import WEB_PAGES from "../pages";
import PATH from "../utils/path";

function RouterConfig() {
  return createBrowserRouter(createRoutesFromElements(<Route>
      <Route
        path={PATH.ROOT}
        element={<ProtectedRoute element={<WEB_PAGES.PROJECT_LIST />} />}
      />
      <Route
        path={PATH.ADMIN_DASHBOARD}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_DASHBOARD.INDEX />} />
        }
      />
      <Route
        path={PATH.ADMIN_ACTIVITY_LOG}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_ACTIVITY_LOG.INDEX />} />
        }
      />
      <Route
        path={PATH.ADMIN_REPORTS}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_REPORTS.INDEX />} />}
      />
      <Route
        path={PATH.ADMIN_PARAMETERS}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_PARAMETERS.INDEX />} />
        }
      />
      <Route
          path={PATH.ADMIN_INDICES}
          element={
              <ProtectedRoute element={<WEB_PAGES.ADMIN_INDICES.INDEX />} />
          }
      />
      <Route
        path={PATH.USER_MANAGEMENT_LIST}
        element={
          <ProtectedRoute element={<WEB_PAGES.USER_MANAGEMENT_LIST />} />
        }
      />
      <Route
        path={PATH.USER_MANAGEMENT_PROJECT_MANAGER}
        element={
          <ProtectedRoute element={<WEB_PAGES.USER_MANAGEMENT_LIST_PROJECT_MANAGER />} />
        }
      />
      <Route
        path={PATH.USER_MANAGEMENT_USER_DETAIL}
        element={
          <ProtectedRoute element={<WEB_PAGES.USER_MANAGEMENT_USER_DETAIL />} />
        }
      />
      <Route
        path={PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL}
        element={<ProtectedRoute element={<WEB_PAGES.PROJECT_DETAILS />} />}
      />
      <Route
        path={PATH.USER_MANAGEMENT_USER_PROJECT_ASSET_DETAIL}
        element={<ProtectedRoute element={<WEB_PAGES.ASSET_DETAILS />} />}
      />
      <Route
        path={PATH.USER_MANAGEMENT_USER_DETAIL_PORTFOLIO_DASHBOARD}
        element={<ProtectedRoute element={<WEB_PAGES.PORTFOLIO_DETAILS />} />}
      />
      <Route
        path={PATH.CORPORATE_DASHBOARD}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.CORPORATE_AND_INDIVIDUAL_DASHBOARD.INDEX />}
          />
        }
      />
      <Route
        path={PATH.CORPORATE_DASHBOARD_TASKS_ASSIGNED}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.CORPORATE_AND_INDIVIDUAL_DASHBOARD.TASKS_ASSIGNED />}
          />
        }
      />
      <Route
        path={PATH.INDIVIDUAL_DASHBOARD}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.CORPORATE_AND_INDIVIDUAL_DASHBOARD.INDEX />}
          />
        }
      />
      <Route
        path={PATH.TOTAL_TENANTS}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.TOTAL_TENANTS />}
          />
        }
      />
      <Route
        path={PATH.PROJECT_LIST}
        element={<ProtectedRoute element={<WEB_PAGES.PROJECT_LIST />} />}
      />
      <Route
        path={PATH.PROJECT_DETAILS}
        element={<ProtectedRoute element={<WEB_PAGES.PROJECT_DETAILS />} />}
      />
      <Route
        path={PATH.ASSET_DETAILS}
        element={<ProtectedRoute element={<WEB_PAGES.ASSET_DETAILS />} />}
      />
      <Route
        path={PATH.PORTFOLIO_DETAILS}
        element={<ProtectedRoute element={<WEB_PAGES.PORTFOLIO_DETAILS />} />}
      />
      <Route
          path={PATH.PORTFOLIO_ASSET_DETAILS}
          element={<ProtectedRoute element={<WEB_PAGES.ASSET_DETAILS />} />}
      />
      <Route
        path={PATH.PROFILE_SETTING}
        element={<ProtectedRoute element={<WEB_PAGES.PROFILE_SETTING />} />}
      />
      <Route
        path={PATH.TASK_ASSIGNED}
        element={<ProtectedRoute element={<WEB_PAGES.TASK_ASSIGNED />} />}
      />
      <Route
        path={PATH.PROJECT_MANAGER_DASHBOARD}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.CORPORATE_AND_INDIVIDUAL_DASHBOARD.INDEX />}
          />
        }
      />
      <Route path={PATH.NOPAGE} element={<WEB_PAGES.NO_PAGE_FOUND />} />
    </Route>));
}

export default RouterConfig;
