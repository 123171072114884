import { Button, Col, Row, Tabs, Tooltip } from "antd";
import VeAcquisitionCosts from "./component/Calculation/ValuationExpert/VeAcquisitionCosts";
import VeAcquisitionCostsExit from "./component/Calculation/ValuationExpert/VeAcquisitionCostsExit";
import VeAssetValue from "./component/Calculation/ValuationExpert/VeAssetValue";
import VeAssumptionsParameter from "./component/Calculation/ValuationExpert/VeAssumptionsParameter";
import VeExitValue from "./component/Calculation/ValuationExpert/VeExitValue";
import VeFinancing from "./component/Calculation/ValuationExpert/VeFinancing";
import VeFirstCalculationTable from "./component/Calculation/ValuationExpert/VeFirstCalculationTable";
import VeHoldingPeriodAndExitCalculation from "./component/Calculation/ValuationExpert/VeHoldingPeriodAndExitCalculation";
import VeOfficeBuilding from "./component/Calculation/ValuationExpert/VeOfficeBuilding";
import VePAssetValue from "./component/Calculation/ValuationExpert/VePAssetValue";
import VePAssetBaseRentType from "./component/Calculation/ValuationExpert/VePAssetBaseRentType";
import VePAssumptionsParameter from "./component/Calculation/ValuationExpert/VePAssumptionsParameter";
import VePAcquisitionCosts from "./component/Calculation/ValuationExpert/VePAcquisitionCosts";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import { LuRefreshCw } from "react-icons/lu";
import IMAGES from "../../../../../assets/images";
import { useSelector } from "react-redux";
import VeAssetValues from "./component/Calculation/ValuationExpert/VeAssetValues";
import VeCapitalizeRate from "./component/Calculation/ValuationExpert/VeCapitalizeRate";
import { getPortfolioAssetValue } from "../../../../../utils/portfolioHelper";
import { getFormattedEnglishNumber } from "../../../../../utils/digitHelper";

function Calculation({ handleToggleDrawer, showDrawer, PortfolioSelect, resetCalculationDetail, saveCalculationDetails, calculationDetail }) {
  const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess, calculationModified, dcfResultsModified, portfolioValueMismatched, autoSaveCalc, portfolioDCFParams, rentRollAssumptions } = useSelector((state) => state.portfolio);
  const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);

  const allDataLoaded = () => {
    return portfolio != null && calculationDetailsByPortfolio != null
  }
  const isCalcLoading = () => {
    return isLoading //isAssetDCFCalcLoading || !initialCalcDone
  }
  const disableActions = !autoSaveCalc && allDataLoaded() && !isCalcLoading() && (calculationModified || portfolioValueMismatched);
  return (
    <div className="asset-details-calculation">
      <div className="calculationComponent">
        <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
          <div style={{
            display: "flex", justifyContent: "space-between", flexWrap: 'wrap',
            alignItems: "center", padding: '10px', marginBottom: '20px', background: '#fff',
            border: '1.5px solid #b0bacd', borderRadius: '8px'
          }}
          >
            <div className="d-flex align-items-center" style={{gap:'10px'}}>
              <h3 className="mb-0">Portfolio Calculations</h3>
              {!showDrawer &&
                <h4 className="mb-0"> Portfolio Value: {getFormattedEnglishNumber(assetValue)} €</h4>
              }
            </div>
            <div className="d-flex align-items-center">
              <>
                <Tooltip title="Save changes" color="#00215B" key="save-changes">
                  <div>
                    <Button
                      icon={< IMAGES.SAVE_ICON />}
                      className="save-icon-btn mr-2 cursor-pointer"
                      onClick={saveCalculationDetails}
                      disabled={!disableActions}
                    />
                  </div>
                </Tooltip>

                <Tooltip title="Reset changes" color="#00215B" key="reset-changes">
                  <div>
                    <Button
                      icon={<LuRefreshCw size={24} />}
                      className="reset-icon-btn mr-2 cursor-pointer"
                      onClick={resetCalculationDetail}
                      disabled={!disableActions}
                    />
                  </div>
                </Tooltip>
              </>
              {PortfolioSelect && <PortfolioSelect />}
              <Tooltip
                title="show portfolio detail"
                color="#00215B"
                key="#00215B"
              >
                {/* drawer-close-btn */}
                <span
                  className={`project-drawer-close-btn cursor-pointer mr-2 ml-2 ${showDrawer ? "d-none" : ""
                    }`}
                  role="button"
                  tabIndex="0" onClick={handleToggleDrawer}
                >
                  <MdOutlineKeyboardArrowLeft
                    size={25}
                    fill="#fff"

                  />
                </span>
              </Tooltip>
            </div>
          </div>
        </Sticky >
        <Row gutter={24}>
          <Col xs={12}>
            <VeAssetValues />
          </Col>
          <Col xs={12}>
            <VeCapitalizeRate />
            <VePAssetBaseRentType showDrawer={showDrawer} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <VePAcquisitionCosts showDrawer={showDrawer} />
          </Col>
          <Col xs={12}>
            <VePAcquisitionCosts terminalValue={true} showDrawer={showDrawer} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <VePAssumptionsParameter />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Calculation;
