import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userDetail from "./userDetail.service";

const initialState = {
  projectsByUser: [],
  archivedProjectsByUser: [],
  completedProjectsByUser: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getAllProjectByUserList = createAsyncThunk(
    "userDetail/getAllProjectByUserList",
    async (finalData, thunkAPI) => {
      try {
        return await userDetail.getAllProjectByUserList(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getAllArchivedByUserProjects = createAsyncThunk(
    "userDetail/getAllArchivedByUserProjects",
    async (finalData, thunkAPI) => {
      try {
        return await userDetail.getAllArchivedByUserProjects(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getAllCompletedByUserProjects = createAsyncThunk(
    "userDetail/getAllCompletedByUserProjects",
    async (finalData, thunkAPI) => {
      try {
        return await userDetail.getAllCompletedByUserProjects(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const createUserDetailProjectSlice = createSlice({
    name: "userDetail",
    initialState,
    reducers: {
      clearData: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.statusMessage = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAllProjectByUserList.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAllProjectByUserList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.projectsByUser = action.payload;
        })
        .addCase(getAllProjectByUserList.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
        .addCase(getAllArchivedByUserProjects.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAllArchivedByUserProjects.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.archivedProjectsByUser = action.payload;
        })
        .addCase(getAllArchivedByUserProjects.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
        .addCase(getAllCompletedByUserProjects.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAllCompletedByUserProjects.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.completedProjectsByUser = action.payload;
        })
        .addCase(getAllCompletedByUserProjects.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        });
    },
  });
  
  export const { clearData } = createUserDetailProjectSlice.actions;
  
  export default createUserDetailProjectSlice.reducer;