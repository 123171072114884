/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import lookUps from "./lookup.service";

const initialState = {
  fileStorage: null,
  updateVisibleColumnsdata: null,
  country: null,
  states: null,
  cities: null,
  projectTypes: [],
  termialBaseRentType: [],
  termialValueType: [],
  financingType: [],
  projectStatus: [],
  developmentStatus:[],
  riskClass: [],
  priorityClass: [],
  propertyRating: [],
  ownershipTypes: [],
  inspectionTypes: [],
  IndexationType: [],
  financingTypes: [],
  assetTypes: [],
  UseGroupType: [],
  UseType: [],
  role: [],
  RentRollTabs: [],
  taskStatusType: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const updateVisibleColumns = createAsyncThunk(
  "lookUps/updateVisibleColumns",
  async ({ finalData, moveToNext2, notifyToaster }, thunkAPI) => {
    try {
      const response = await lookUps.updateVisibleColumns(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext2) {
          moveToNext2();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCountry = createAsyncThunk(
  "lookUps/getAllCountry",
  async (finalData, thunkAPI) => {
    try {
      const i18nData = {
        catalog: "address-data",
        context: "countries"
      }
      return await lookUps.getByTypeDropdown(i18nData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllState = createAsyncThunk(
  "lookUps/getAllState",
  async (finalData, thunkAPI) => {
    try {
      const key = finalData?.key;
      const pieces = key.split(/[\s\\.]+/)
      const last = pieces[pieces.length - 1]
      const i18nData = {
        catalog: "address-data",
        context: last
      }
      return await lookUps.getByTypeDropdown(i18nData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCities = createAsyncThunk(
  "lookUps/getAllCities",
  async (finalData, thunkAPI) => {
    try {
      const countryKey = finalData?.i18nCountry?.key;
      const stateKey = finalData?.i18nState?.key;
      let pieces = countryKey.split(/[\s\\.]+/)
      const lastCountry = pieces[pieces.length - 1]
      pieces = stateKey.split(/[\s\\.]+/)
      const lastState = pieces[pieces.length - 1]
      const i18nData = {
        catalog: "address-data",
        context: lastCountry+"-"+lastState
      }
      return await lookUps.getByTypeDropdown(i18nData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getByTypeDropdown = createAsyncThunk(
  "lookUps/getByTypeDropdown",
  async ({finalData, notifyToaster}, thunkAPI) => {
    try {
        if(finalData.type)
        {
          notifyToaster("Wrong Dropdown Type: "+finalData.type, "error");    
        }
      let response = await lookUps.getByTypeDropdown(finalData);
      response = { data: response.content, context: finalData.context, catalog: finalData.catalog};
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create a Category
export const uploadBase64 = createAsyncThunk(
  "lookUps/uploadBase64",
  async (file, thunkAPI) => {
    try {
      return await lookUps.uploadBase64(file);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createLookupsSlice = createSlice({
  name: "lookUps",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVisibleColumns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVisibleColumns.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateVisibleColumnsdata = action.payload;
      })
      .addCase(updateVisibleColumns.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.country = action.payload.content;
      })
      .addCase(getAllCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllState.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.states = action.payload.content;
      })
      .addCase(getAllState.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllCities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cities = action.payload.content;
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getByTypeDropdown.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getByTypeDropdown.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state[action.payload.context] = action.payload.data;
      })
      .addCase(getByTypeDropdown.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(uploadBase64.pending, (state) => {
        state.isLoadingUpload = true;
      })
      .addCase(uploadBase64.fulfilled, (state, action) => {
        state.isLoadingUpload = false;
        state.isSuccess = true;
        state.fileStorage = action.payload;
      })
      .addCase(uploadBase64.rejected, (state, action) => {
        state.isLoadingUpload = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createLookupsSlice.actions;

export default createLookupsSlice.reducer;
