/* eslint-disable */
import {apiClient} from "../../../index";
import {Search, SearchCriteria} from "reia-rest-client";

const createPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().addPortfolio(data);
  return response.data;
};

const updatePortfolio = async (data) => {
  const response = await apiClient.portfolioApi().updatePortfolio(data.id, data);
  return response.data;
};

const getAllPortfolio = async (data) => {
  const projectId: SearchCriteria = {filterKey: "project.id", value: data.projectId, operation: "eq"}
  const search: Search = {dataOption: "all", searchCriteriaList: [projectId]}
  const response = await apiClient.portfolioApi().searchPortfolios(search, data.page-1, data.size);
  return response.data;
};

const getPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().getPortfolio(data.portfolioId);
  return response.data;
};

const getCalculationDetailsByPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().getCalcualtionDetailsByPortfolios(data.portfolioId, data.page-1, data.size);
  return response.data;
};

const updateCalculationDetailsByPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().updateCalcualtionDetailsByPortfolios(data.portfolioId, data.portfolioDetail);
  return response.data;
};

const addAssetsToPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().addAssetsToPortfolio(data.portfolioId,data.assetIds);
  return response.data;
};

const deleteAssetsFromPortfolio = async (data) => {
  const response = await apiClient.portfolioApi().deleteAssetsFromPortfolio(data.portfolioId,data.assetsIds);
  return response.data;
};

const deletePortfolio = async (data) => {
  const response = await apiClient.portfolioApi().deletePortfolios(data);
  return response.data;
};

const portfolio = {
  createPortfolio,
  updatePortfolio,
  getAllPortfolio,
  deletePortfolio,
  addAssetsToPortfolio,
  deleteAssetsFromPortfolio,
  getCalculationDetailsByPortfolio,
  updateCalculationDetailsByPortfolio,
  getPortfolio
};

export default portfolio;
