import { Button, Card, Col, Row, Form, Input } from "antd";

function MarketValue() {
  return (
    <Form>
      <div
        style={{ backgroundColor: "#fff", borderRadius: "5px" }}
        className="definitioncalculationSetting"
      >
        <div />
        <div className=" ml-auto mr-auto pt-3 MarketCalculationSetting ">
          <Card
            title={
              <div>
                <h3 className="mt-1 ">Rounding</h3>
              </div>
            }
            className="card-head "
          >
            <Row gutter={24} className="justify-content-between">
              <Col  md={10} xs={24}>
                <h5>Net Present Value</h5>
                <div className="d-flex net ">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className="mr-auto ml-auto col-media ">
                <div className="mr-3 rounding">
                  {" "}
                  <h5>Rounding</h5>
                  {/* select code */}
                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media"/>
            <Row gutter={24}>
              <Col  md={10} xs={24}>
                <div className="d-flex mt-3">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className="mr-auto ml-auto col-media    ">
                        <div className="mr-3 mt-3 rounding">
                  {/* select code */}

                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media bg-danger"/>
            <Row gutter={24}>
              <Col  md={10} xs={24}>
                <div className="d-flex mt-3">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className=" mr-auto ml-auto col-media " >           <div className="mr-3 mt-3  rounding">
                  {/* select code */}

                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media bg-danger"/>
            <Row gutter={24}>
              <Col  md={10} xs={24}>
                <div className="d-flex mt-3">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className="mr-auto ml-auto col-media ">            <div className="mr-3 mt-3  rounding">
                  {/* select code */}

                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media bg-danger"/>
            <Row gutter={24}>
              <Col  md={10} xs={24}>
                
                <div className="d-flex mt-3">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className="mr-auto ml-auto col-media ">            <div className="mr-3 mt-3  rounding">
                  {/* select code */}

                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media bg-danger"/>
            <Row gutter={24}>
              <Col  md={10} xs={24}>
                <div className="d-flex mt-3">
                  <h5 className=" mr-2 mt-2 heading-media">&gt;=</h5>
                  <Input placeholder="0" />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col  md={10} xs={24} className="mr-auto ml-auto col-media ">            <div className="mr-3 mt-3  rounding">
                  {/* select code */}

                  <Input
                    placeholder="hundred (100€)"
                    style={{ background: "#BFBFBF", color: "black" }}
                  />
                </div>
              </Col>
            </Row>
            <hr className="horizontal-bar-media bg-danger"/>
          </Card>
        </div>

        <div style={{ textAlign: "end" }} className="d-flex justify-content-end align-items-end ">
          <Button key="submit" type="default" className="btn-primary mb-3">
            Save Changes
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default MarketValue;
