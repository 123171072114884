/* eslint-disable */
import api from "../../../utils/api";
import {apiClient} from "../../../index";
import {AssetDto, Search, SearchCriteria} from "reia-rest-client";

// Create an asset for projects
const createAsset = async (asset: AssetDto) => {
  const response = await apiClient.assetsApi().addAsset(asset);
  return response.data;
};

// Get All list of projects
const getAssetsByProject = async (data) => {
  let search: Search;
  const projectId: SearchCriteria = {filterKey: "project.id", value: data.projectId, operation: "eq"}
  if(data.portfolioId)
  {
    const portfolioId: SearchCriteria = {filterKey: "portfolio.id", value: data.portfolioId, operation: "eq"}
    search = {dataOption: "all", searchCriteriaList: [projectId, portfolioId]}  
  }
  else{
    search = {dataOption: "all", searchCriteriaList: [projectId]}  
  }
  const response = await apiClient.assetsApi().searchAssets(search, data.page-1, data.size);
  return response.data;
};

const updateAsset = async (asset: AssetDto) => {
  const response = await apiClient.assetsApi().updateAsset(asset.id,asset);
  return response.data;
};

// Delete projects
const deleteAssets = async (data) => {
  const response = await apiClient.assetsApi().deleteAssets(data);
  return response.data;
};

// Get Single projects
const getSingleAssetDetail = async (data) => {
  const response = await api.get("/api/Asset/get", {
    params: data,
  });
  return response.data.data;
};

const getProjectDetailById = async (data) => {
  const response = await api.get("/api/Project/getProjectDetailById", {
    params: data,
  });
  return response.data.data;
};
const assetsDetails = {
  createAsset,
  updateAsset,
  getAssetsByProject,
  getSingleAssetDetail,
  deleteAssets,
  getProjectDetailById
};

export default assetsDetails;
