import { useState } from "react";
import { Col, Dropdown, Menu, notification, Row, Tag } from "antd";
// import { FaShareSquare } from "react-icons/fa";
import {
  MdDelete,
  MdEdit,
  MdOutlineKeyboardArrowRight,
  // MdShare,
} from "react-icons/md";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IMAGES from "../../../../../assets/images";
import DeleteUserModal from "../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdate from "../../CreateUpdate";
import { deleteProject } from "../../../../../app/features/Projects/project.slice";
import PATH from "../../../../../utils/path";
import { getAssetsByProject } from "../../../../../app/features/projectDetails/projectDetail.slice";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { RiEditLine } from "react-icons/ri";

export default function DetailDrawer(props) {
  const {
    setShowDrawer,
    showDrawer,
    onPortfolioClick,
    onAssetClick,
    projectData,
    totalAssets,
    allPortfolio
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const [addUpdateNewProjectModal, setAddUpdateNewProjectModal] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const { user } = useSelector((state) => state.auth);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const closeAddUpdateNewProjectModal = () => {
    setAddUpdateNewProjectModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDeleteProject = () => {
    closeDeleteUserModal();
    navigate(PATH.PROJECT_LIST);
  };

  const deleteProjects = () => {
    const finalData = [projectData?.id];
    dispatch(
      deleteProject({ finalData, moveToNextDeleteProject, notifyToaster })
    );
  };
  return (
    <div
      className="custom-drawer-content bg-white rounded-sm"
    // style={{
    //   position: "fixed",
    //   width: "-webkit-fill-available",
    // }}
    >
      <div
        onClick={() => setShowDrawer(!showDrawer)}
        className="sidebar-collapse-button cursor-pointer"
      >
        <MdOutlineKeyboardArrowRight size={25} fill="#fff" />
      </div>
      <div className="custom-drawer">
        {user && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            style={{
              display: "flex",
              paddingTop: "20px",
              justifyContent: "flex-end",
              paddingRight: "20px",
            }}
          >
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "1",
                      label: (
                        <div
                          className="d-flex justify-content-left align-items-center table-dropdown"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            setFlag(true);
                            setAddUpdateNewProjectModal(true);
                            setSelectedRowKeys({
                              ...projectData,
                              projectStatus: projectData.projectStatus.id,
                              projectType: projectData.projectType.id,
                            });
                          }}
                        >
                          <RiEditLine size={24} />
                          <h5 className="mb-0 ml-3">Edit</h5>
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <div
                          className="d-flex justify-content-left align-items-center table-dropdown"
                          role="button"
                          tabIndex="0"
                          onClick={() => setDeleteUserModal(true)}
                        >
                          <AiOutlineDelete
                            size={24}
                            style={{ color: "#FC0606" }}
                          />
                          <h5
                            style={{ color: "#FC0606" }}
                            className="mb-0 ml-3"
                          >
                            Delete
                          </h5>
                        </div>
                      ),
                    },
                  ]}
                />
              }
              trigger={["click"]}
            >
              <BsThreeDotsVertical size={20} />
            </Dropdown>
          </div>
        )}

        <div className="text-center" style={{ paddingTop: "20px" }}>
          <img src={IMAGES.DRAWER_ICON} alt="" />
        </div>
        <div>
          <div
            style={{ fontSize: "24px", fontWeight: 600 }}
            className="text-black  text-center pt-2 mb-1"
          >
            {projectData?.projectName || "N/A"}
          </div>
        </div>

        {/* <div
          className={`d-flex align-items-center justify-content-center mt-4 ${
            user.roleId === 2 && "drawer-manager"
          }`}
        >
          {user && (
            <>
              <MdEdit
                size={33}
                className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                onClick={() => {
                  setFlag(true);
                  setAddUpdateNewProjectModal(true);
                  setSelectedRowKeys({
                    ...projectData,
                    projectStatus: projectData.projectStatus.id,
                    projectType: projectData.projectType.id,
                  });
                }}
              />
              <MdDelete
                size={33}
                className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                onClick={() => setDeleteUserModal(true)}
              />
            </>
          )}
        </div> */}
        <Row
          gutter={24}
          className="pt-3 pb-4 cards-row"
          style={{ paddingInline: "37px" }}
        >
          <Col xs={12} className="pr-0">
            <div
              onClick={() => onAssetClick()}
              style={{
                padding: "12px",
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <img src={IMAGES.ASSETS_IMAGE} alt="" className="portfolio-img" />
              <h5
                className="mb-0"
                style={{ paddingTop: "8px", color: "#1F1F1F" }}
              >
                Assets
              </h5>
              <h2 className=" mb-0" style={{ color: "#FF7228" }}>
                {totalAssets || "0"}
              </h2>
            </div>
          </Col>
          <Col xs={12} className="pr-0">
            <div
              onClick={() => onPortfolioClick()}
              style={{
                padding: "12px",
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <img src={IMAGES.ASSETS_IMAGE} alt="" className="portfolio-img" />
              <h5
                className="mb-0"
                style={{ paddingTop: "8px", color: "#1F1F1F" }}
              >
                Portfolio&apos;s
              </h5>
              <h2 className=" mb-0" style={{ color: "#FF7228" }}>
                {allPortfolio?.content?.length || "0"}
              </h2>
            </div>
          </Col>

        </Row>
        <div
          className="project-drawer-details custom-scroll"
          style={{ height: "calc(100vh - 500px)" }}
        >
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Project ID
            </h5>

            <p className="mb-0 text-gray pt-2">{projectData?.id || "N/A"}</p>
          </div>
          <hr className="my-2" />
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Project manager
            </h5>
            <p className="mb-0 text-gray pt-2">
              {projectData?.projectManager || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Client Name
            </h5>
            <p className="mb-0 text-gray pt-2">
              {projectData?.clientName || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Project type
            </h5>
            <p className="mb-0 text-gray pt-2">
              {projectData?.projectType?.translations.en || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Project Status
            </h5>
            <p className="mb-0 text-gray pt-2">
              {projectData?.projectStatus?.translations.en || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div style={{ paddingInline: "20px" }}>
            <h5 className="mb-0" style={{ color: "#9B9B9B" }}>
              Notes
            </h5>
            <p className="mb-0 text-gray pt-2">
              {projectData?.description || "N/A"}
            </p>
          </div>
        </div>
      </div>
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Project"
        onSubmit={deleteProjects}
      />
      <CreateUpdate
        visible={addUpdateNewProjectModal}
        handleCancel={closeAddUpdateNewProjectModal}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        flag={flag}
        setFlag={setFlag}
      />
    </div>
  );
}

DetailDrawer.propTypes = {
  setShowDrawer: PropTypes.node.isRequired,
  showDrawer: PropTypes.node.isRequired,
  onAssetClick: PropTypes.node.isRequired,
  onPortfolioClick: PropTypes.node.isRequired,
  projectData: PropTypes.object.isRequired,
  totalAssets: PropTypes.number.isRequired,
};
