/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  // message,
  // Upload,
} from "antd";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../../components/Layout/Table/DataTable";
// import PATH from "../../../../../utils/path";
// import IMAGES from "../../../../../assets/images";
import {
  SearchAssetsInByTenant,
  getDashboardData,
  getTenantDetail,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import {
  createUser,
  getSingleUser,
} from "../../../../../app/features/userManagement/user.slice";
import { uploadBase64 } from "../../../../../app/features/Lookups/lookup.slice";

export default function TenantsDetailAndProfile(props) {
  const navigate = useNavigate();
  // const [form] = Form.useForm();
  const {
    visible,
    handleCancel,
    tenantsRecord,
    showRecordTenants,
    setShowRecordTenants,
    showProfileTenants,
    setShowProfileTenants,
    form,
  } = props;
  const { Search } = Input;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userManagement);
  const { fileStorage, isLoadingUpload } = useSelector(
    (state) => state.lookUps
  );
  const { tenantDetailData, isLoading } = useSelector(
    (state) => state.corporateDashboardData
  );
  const [active, setActive] = useState(true);
  // const [showRecordTenants, setShowRecordTenants] = useState(true);
  // const [showProfileTenants, setShowProfileTenants] = useState(false);
  const [images, setImages] = useState({});
  const [searchValue, setSearchValue] = useState();

  const [currentUpload, setCurrentUpload] = useState(null);
  // useEffect(() => {
  //   const finalData = {
  //     userId: tenantsRecord?.userId,
  //   };
  //   dispatch(getSingleUser(finalData));
  // }, [tenantsRecord]);

  useEffect(() => {
    const finalData = {
      tenantId: tenantsRecord?.tenantId,
    };
    const finalData2 = {
      userId: tenantsRecord?.tenantId,
    };
    dispatch(getTenantDetail(finalData));
    dispatch(getSingleUser(finalData2));
  }, [tenantsRecord, visible]);

  const onRecordTenantsDetail = () => {
    setShowRecordTenants(true);
    setShowProfileTenants(false);
    setActive(false);
    form.resetFields();
  };
  const onProfileTenantsDetail = () => {
    setShowRecordTenants(false);
    setShowProfileTenants(true);
    setActive(false);
    form.resetFields();
    const finalData = {
      userId: tenantsRecord?.tenantId,
    };
    dispatch(getSingleUser(finalData));
  };
  const columns = [
    {
      title: "Asset ID",
      dataIndex: "assetId",
      key: "assetId",
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
      key: "assetName",
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
    },
    {
      title: "Total Revenue",
      dataIndex: "revenuePerMonth",
      render: (text) => (
        <div className="d-flex align-items-center justify-content-end">
          {text}
          <div className="d-flex" style={{ margin: "0 0 0 20px" }}>
            <IoIosArrowForward />
          </div>
        </div>
      ),
    },
  ];

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader?.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      }
    });
  }

  async function base64ToUpload(file) {
    const lastIndexOfDot = file?.name.lastIndexOf(".");
    const name = file?.name.slice(0, lastIndexOfDot);
    const ext = file?.name.slice(lastIndexOfDot + 1, file?.name?.length);

    const base64 = await convertBase64(file);

    const fileData = {
      name: name,
      base64: base64.split(",")[1],
      fileExtension: ext,
    };

    return fileData;
  }

  async function onImageChange(id, e) {
    setCurrentUpload(id);

    const data = await base64ToUpload(e.target.files[0]);
    dispatch(uploadBase64(data));
  }

  useEffect(() => {
    if (!isLoadingUpload) {
      setImages((image) => {
        return { ...image, [currentUpload]: fileStorage };
      });
    }
  }, [fileStorage, setImages, currentUpload, isLoadingUpload, dispatch]);

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(
          `/projects/${path.projectId}/project-detail/${path.assetId}/asset-detail`
        );
        // console.log(record, rowIndex, path); // to avoid es lint error
      }, // click row
    };
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    setShowRecordTenants(true);
    setShowProfileTenants(false);
    handleCancel();
    form.resetFields();
    const finalData = {
      userId: tenantsRecord?.tenantId,
    };
    dispatch(getSingleUser(finalData));
    dispatch(getDashboardData());
    //dispatch(getAllTenants())
  };
  const onFinish = (data) => {
    const name = data.name.split(" ");
    const finalData = {
      userId: user?.userId,
      roleId: user?.roleId,
      firstName: name ? name[0] : null,
      lastName: name ? name[1] : null,
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: data.address,
      imageUrl: images.profileImage,
      password: "",
    };
    dispatch(createUser({ finalData, moveToNext, notifyToaster }));
  };

  const onSearch = () => {
    const finalData = {
      TenantId: tenantsRecord?.tenantId,
      // PageNo: 1,
      // Size: 1,
      Search: searchValue ? searchValue : "",
    };
    dispatch(SearchAssetsInByTenant(finalData));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Modal
      className="tenants-detail-profile"
      centered
      open={visible}
      width={650}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="text-blue mb-0">Tenant Details</h2>
        <GrClose className="cursor-pointer" onClick={handleCancel} />
      </div>
      <Col xs={24} className="my-4">
        <div>
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
              <Button
                className="btn-action d-flex justify-content-center align-items-center asset-btn"
                onClick={onRecordTenantsDetail}
              >
                <div
                  className="active-action"
                  style={{
                    backgroundColor: active === true ? "#f2f3f5" : " ",
                  }}
                >
                  <div className="active-action d-flex align-items-center">
                    {/* <BsFillCircleFill size={10} fill="#2978A0" /> */}
                    <h4 className="mb-0 ml-2 text-black">Records</h4>
                  </div>
                </div>
              </Button>
              <Divider type="vertical" />
              <Button
                className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                onClick={() => onProfileTenantsDetail()}
              >
                <div className="active-action d-flex align-items-center">
                  {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                  <h4 className="mb-0 ml-2 text-black">Profile</h4>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <hr className="w-100" />
      {showRecordTenants && (
        <>
          <Row gutter={24} className="button-search">
            <Col xs={24} className="my-3">
              <Row gutter={24}>
                <Col xs={6} className="tannet-detail-col">
                  <div className="tannet-detail-box rounded-sm">
                    <h5 className="pt-2 pl-2 font-weight-light text-black">
                      Total Assets
                    </h5>
                    <h4 className="text-black pl-2 pb-2">
                      {tenantDetailData?.counts?.[0]?.totalAssets !== null
                        ? tenantDetailData?.counts?.[0]?.totalAssets
                        : "N/A"}
                    </h4>
                  </div>
                </Col>
                <Col xs={6} className="tannet-detail-col">
                  <div className="tannet-detail-box rounded-sm">
                    <h5 className="pt-2 pl-2 font-weight-light text-black">
                      Total Revenue
                    </h5>
                    <h4 className="text-black pl-2 pb-2">
                      {tenantDetailData?.counts?.[0]?.totalRevenue !== null
                        ? `${tenantDetailData?.counts?.[0]?.totalRevenue}$`
                        : "N/A"}
                    </h4>
                  </div>
                </Col>
                <Col xs={6} className="tannet-detail-col">
                  <div className="tannet-detail-box rounded-sm">
                    <h5 className="pt-2 pl-2 font-weight-light text-black">
                      Contract Start date
                    </h5>
                    <h4 className="text-black pl-2 pb-2">
                      {tenantDetailData?.counts?.[0]?.contractStartDate !== null
                        ? moment(
                            tenantDetailData?.counts?.[0]?.contractStartDate
                          ).format("DD-MM-YYYY")
                        : "N/A"}
                    </h4>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="tannet-detail-box rounded-sm">
                    <h5 className="pt-2 pl-2 font-weight-light text-black">
                      Contract End date
                    </h5>
                    <h4 className="text-black pl-2 pb-2">
                      {tenantDetailData?.counts?.[0]?.contractEndDate !== null
                        ? moment(
                            tenantDetailData?.counts?.[0]?.contractEndDate
                          ).format("DD-MM-YYYY")
                        : "N/A"}
                    </h4>
                  </div>
                </Col>
              </Row>
            </Col>
            <hr className="w-100" />
            <Col xs={24} className="mt-1">
              <div className="d-flex searchbar-project">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                {/* <Search className="mr-2" placeholder="Search assets" /> */}
                <Search
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSearch();
                    }
                  }}
                  onChange={handleSearch}
                  className="mr-2"
                  placeholder="Search Assets Name"
                />
              </div>
            </Col>
          </Row>
          <div className="asset-expire-wrapper mt-3">
            <DataTable
              columns={columns}
              dataSource={tenantDetailData?.rentRolls}
              loading={isLoading}
              scroll={{
                y: 250,
              }}
              className="antd-table-striped"
              pagination={false}
              onRow={navigateRow}
            />
          </div>
        </>
      )}
      {showProfileTenants && (
        <Row gutter={24}>
          <Col xs={8} className="mt-4 d-flex justify-content-center">
            <div className="justify-content-center">
              <Avatar
                size={150}
                icon={
                  <img
                    src={
                      images.profileImage
                        ? images.profileImage
                        : `https://ui-avatars.com/api/?name=${`${user?.firstName} ${user?.lastName}`}&background=00215b&color=fff`
                    }
                    alt="profileImage"
                    // className="page-icon"
                  />
                }
              />
              <label
                htmlFor="upload"
                className="d-flex align-items-center upload-btn cursor-pointer p-2 justify-content-center mt-2"
              >
                {/* <Button className="d-flex align-items-center rounded-sm upload-btn" icon={<FaRegEdit className="mr-2" />}>Change</Button> */}
                <FaRegEdit size={18} className="text-blue" />
                <div className="text-blue">Change</div>
                <input
                  id="upload"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    onImageChange("profileImage", e);
                  }}
                />
              </label>
            </div>
            {/* <div className="text-center mt-3">
                            <Upload abcdProps={abcProps}>
              </Upload>
            </div> */}
          </Col>
          <Col xs={16}>
            <Form
              name="basic"
              className="user-modal"
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                remember: false,
              }}
              // onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Name"
                    name="name"
                    initialValue={
                      !user?.lastName
                        ? ""
                        : !user?.firstName
                        ? ""
                        : `${user?.firstName} ${user?.lastName}`
                    }
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    initialValue={user?.email}
                    rules={[
                      {
                        required: false,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    initialValue={user?.phoneNumber}
                    rules={[
                      {
                        required: false,
                        message: "Please input your Phone Number!",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Phone Number" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Address"
                    name="address"
                    initialValue={user?.address}
                    rules={[
                      {
                        required: false,
                        message: "Please input your address!",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>
                {/* <Col xs={24}>
                  <Form.Item
                    label="Notes"
                    name="notes"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Notes!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={2}
                      placeholder="Notes"
                      className="custom-scroll"
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} className="d-flex justify-content-end">
                  <Button className=" btn-primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
}
TenantsDetailAndProfile.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  tenantsRecord: PropTypes.node.isRequired,
  showRecordTenants: PropTypes.node.isRequired,
  setShowRecordTenants: PropTypes.node.isRequired,
  showProfileTenants: PropTypes.node.isRequired,
  setShowProfileTenants: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
};
