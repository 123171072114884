/* eslint-disable no-unneeded-ternary */
import { Card, Col, Input, Modal, Row } from "antd";
import { GrClose } from "react-icons/gr";
import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import IMAGES from "../../../../assets/images";
import DataTable from "../../../../components/Layout/Table/DataTable";
import {
  getAssetByName,
  getDashboardData,
} from "../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
// import PATH from "../../../../utils/path";

// function CustomLeftArrow() { return <BsArrowRight /> }

function AvailableForRent({ dashboardData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Search } = Input;
  const [searchValue, setSearchValue] = useState();

  const [modalOpen, setmodalOpen] = useState(false);
  const { availableAssetsForRentData, isLoading } = useSelector(
    (state) => state.corporateDashboardData
  );

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    const finalData = {
      PageNo: 1,
      Size: 1,
      Search: searchValue ? searchValue : "",
    };
    dispatch(getAssetByName(finalData));
  };

  const onSearchCancle = () => {
    setSearchValue("");
    const finalData = {
      PageNo: 1,
      Size: 1,
      Search: "",
    };
    dispatch(getAssetByName(finalData));
  };

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "assetId",
      key: "assetId",
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
      key: "assetName",
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
    },
    {
      title: "Available from",
      dataIndex: "availableFrom",
      key: "availableFrom",
      render: (text) => (
        <div className="d-flex align-items-center justify-content-end">
          {text} days
          <div className="d-flex" style={{ margin: "0 0 0 20px" }}>
            <IoIosArrowForward />
          </div>
        </div>
      ),
    },
  ];

  // const dataSource = [
  //   {
  //     id: 0,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 1,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 2,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 3,
  //     assetName: "Johar Town",
  //     assetType: "Hotel",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 4,
  //     assetName: "Johar Town",
  //     assetType: "Hotel",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 5,
  //     assetName: "Johar Town",
  //     assetType: "Commercial Park",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 6,
  //     assetName: "Johar Town",
  //     assetType: "Commercial Park",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 7,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 8,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 9,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 10,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     availableFrom: "12/12/2022",
  //   },
  //   {
  //     id: 11,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     availableFrom: "12/12/2022",
  //   },
  // ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(
          `/projects/${path.projectId}/project-detail/${path.assetId}/asset-detail`
        ); // to avoid es lint error
      }, // click row
    };
  };

  return (
    <div className="asset-available-for-rent">
      <Card className="carousel-card">
        <div className="d-flex justify-content-between pt-1">
          <h3 className="text-black mb-0">Assets Avaibale for Rent</h3>
          <div
            className="d-flex align-items-center text-blue cursor-pointer pt-1"
            role="button"
            tabIndex="0"
            onClick={() => setmodalOpen(true)}
            onKeyDown={() => setmodalOpen(true)}
          >
            <span className="mr-1">View all</span>
            <BsArrowRight size={18} />
          </div>
        </div>
        <hr />
        {dashboardData?.availableAssets?.length > 0 ? (
          <Carousel responsive={responsive} className="asset-carousel">
            {dashboardData?.availableAssets?.map((item) => (
              <Card
                className="mr-2 my-1"
                cover={
                  <Col className="p-0">
                    <span className="small-counter">{item.assetId}</span>
                    <img
                      className="asset-carousel-img border-sm"
                      src={IMAGES.RED_HOUSE}
                      alt="test"
                    />
                    <span className="card-text">{item.assetName}</span>
                  </Col>
                }
              >
                <Row>
                  <Col xs={24} className="p-2">
                    <p className="m-0">Type</p>
                    <h5 className="m-0">{item.assetType}</h5>
                    <hr className="mt-1" />
                    <p className="m-0">Available from</p>
                    <h5 className="m-0">{item.availableFrom} days</h5>
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        ): <p className="text-center pt-2">No Data</p>}
      </Card>
      <Modal
        centered
        open={modalOpen}
        onOk={() => setmodalOpen(false)}
        onCancel={() => setmodalOpen(false)}
        footer={false}
        width={650}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="text-blue mb-0">Assets Available for Rent</h2>
          <GrClose
            className="cursor-pointer"
            onClick={() => {
              setmodalOpen(false);
              onSearchCancle();
            }}
          />
        </div>
        <Row gutter={24} className="button-search">
          <Col xs={24} className="mt-1">
            <div className="d-flex searchbar-project">
              <span className="searchbar-icon">
                <FiSearch size={20} />
              </span>
              {/* <Search className="mr-2" placeholder="Search asset" /> */}
              <Search
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }}
                value={searchValue}
                onChange={handleSearch}
                className="mr-2"
                placeholder="Search Assets Name"
              />
            </div>
          </Col>
        </Row>
        {/* <Input className="rounded-sm" prefix={<BiSearch />} placeholder="Search assets" /> */}
        <div className="asset-expire-wrapper mt-3">
          <DataTable
            columns={columns}
            dataSource={availableAssetsForRentData}
            loading={isLoading}
            scroll={{
              y: 300,
            }}
            className="antd-table-striped"
            pagination={false}
            onRow={navigateRow}
          />
        </div>
      </Modal>
    </div>
  );
}

export default AvailableForRent;
AvailableForRent.propTypes = {
  dashboardData: PropTypes.node.isRequired,
};
