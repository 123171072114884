import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Switch, Tooltip } from "antd";
import CashflowDataTable from "./component/CashflowDataTable";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import { getFormattedEnglishNumber } from "../../../../../utils/digitHelper";

function CashFlows({ handleToggleDrawer, showDrawer, AssetSelect }) {
  const [showTanents, setShowTanents] = useState(false);
  const [active, setActive] = useState("monthly");
  const { assetDCFResult } = useSelector(state => state.calculation)
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;
  return (
    <div className="cashFlowsComponent">
      <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
        <div
          className="d-flex justify-content-between align-items-center button-search"
        >
          <div className="d-flex align-items-center" style={{gap:'10px'}}>
            <div className="cashflows-first-btns ml-2 ">
              <span>
                <Button
                  key="submit"
                  type="default"
                  // className="cashflows-monthly-btn"
                  className={`cashflows-monthly-btn ${active === "monthly" ? "active" : ""
                    }`}
                  onClick={() => setActive("monthly")}
                >
                  Monthly
                </Button>
              </span>
              <span>
                <Button
                  key="submit"
                  type="default"
                  // className="cashflows-yearly-btn ml-1"
                  className={`cashflows-monthly-btn ml-1 ${active === "yearly" ? "active" : ""
                    }`}
                  onClick={() => setActive("yearly")}
                >
                  Yearly
                </Button>
              </span>
              <span>
                <Switch
                  className="ml-3 my-auto"
                  onChange={() => setShowTanents(!showTanents)}
                />
              </span>
              <span className="ml-2 my-auto show-tenants">Show Tenants</span>
            </div>
            {!showDrawer &&
              <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>
            }
          </div>
          <div className="d-flex align-items-center pr-2">
            {AssetSelect && <AssetSelect />}
            <Tooltip
              title="show asset detail"
              color="#00215B"
              key="#00215B"
            >
              {/* drawer-close-btn */}
              <span
                className={`project-drawer-close-btn cursor-pointer  ${showDrawer ? "d-none" : ""
                  }`}
                role="button"
                tabIndex="0"
                style={{ marginLeft: '20px' }} onClick={handleToggleDrawer}
              >
                <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Sticky>
      <Row>
        <Col xs={24}>
          <CashflowDataTable
            showTanents={showTanents}
            active={active}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CashFlows;
