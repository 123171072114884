import api from "../../../utils/api";

// Get All list of projects
const getAllProjectByUserList = async (data) => {
    const response = await api.get(
      `/api/project/GetAllProjectsByUserId`, {
        params: data,
      }
    );
    return response.data.data;
  };

// Get All list of projects
const getAllArchivedByUserProjects = async (data) => {
    const response = await api.get("/api/project/GetAllArchivedProjectsByUserId", {
        params: data,
      });
    return response.data.data;
  };

// Get All list of projects
const getAllCompletedByUserProjects = async (data) => {
    const response = await api.get("/api/project/GetAllCompletedProjectsByUserId", {
        params: data,
      });
    return response.data.data;
  };

  const userDetail = {
    getAllProjectByUserList,
    getAllArchivedByUserProjects,
    getAllCompletedByUserProjects,
  };
  
  export default userDetail;