import DataTable from '../../../../../../../components/Layout/Table/DataTable';
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper';

const AssetDetailsTables = ({ assetAreaAndIncomeDetails }) => {
    const firstTableColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Area</p>,
            align: "left",
            children: [{
                width: "200px",
                dataIndex: "useTypeName",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Use Type</p>,
                align: "left",
                render: (text) => (text ? text : "N/A"),
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Total Area</p>,
            align: "right",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                dataIndex: "totalAreaUnits",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Let</p>,
            align: "right",
            children: [{
                dataIndex: "let",
                align: "right",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacant</p>,
            align: "right",
            children: [{
                dataIndex: "vacant",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacancy</p>,
            align: "right",
            children: [{
                dataIndex: "vacancy",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
        {
            id: 5,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "grossRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Non Recoverable Costs</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPercentage",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }, {
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsYear",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Net Operating Income</p>,
            align: "center",
            children: [{
                dataIndex: "netOperatingIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "netOperatingIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Market Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "marketRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "marketRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 9,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "right",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>years</p>,
                align: "right",
            }]
        },
        {
            id: 10,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Rental Level</p>,
            align: "right",
            children: [{
                dataIndex: "rentalLevel",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
    ];
    const SecondTableColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Unit</p>,
            align: "left",
            children: [{
                width: "200px",
                dataIndex: "useTypeName",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Use Type</p>,
                align: "left",
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Total Units</p>,
            align: "right",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "totalAreaUnits",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Let</p>,
            align: "right",
            children: [{
                dataIndex: "let",
                align: "right",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacant</p>,
            align: "right",
            children: [{
                dataIndex: "vacant",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacancy</p>,
            align: "right",
            children: [{
                dataIndex: "vacancy",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
        {
            id: 5,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "grossRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Non Recoverable Costs</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPercentage",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }, {
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsYear",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Net Operating Income</p>,
            align: "center",
            children: [{
                dataIndex: "netOperatingIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "netOperatingIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Market Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "marketRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "marketRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedEnglishNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 9,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "right",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>years</p>,
                align: "right",
            }]
        },
        {
            id: 10,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Rental Level</p>,
            align: "right",
            children: [{
                dataIndex: "rentalLevel",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {getFormattedDecimalNumber(text * 100 ?? 0)}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
    ];

    const useTypeTableData = assetAreaAndIncomeDetails?.useTypeValues ? Object.values(assetAreaAndIncomeDetails?.useTypeValues) : [];
    const areaTableData = useTypeTableData?.filter(item => item.rentalType === 'area');
    const unitTableData = useTypeTableData?.filter(item => item.rentalType === 'unit');
    const totalValues = assetAreaAndIncomeDetails?.totalValues;
    const totalAreaValues = assetAreaAndIncomeDetails?.totalRentalTypeValues?.area;
    const totalUnitValues = assetAreaAndIncomeDetails?.totalRentalTypeValues?.unit;

    if (totalAreaValues) {
        const newUseTypeTotal = {
            ...totalAreaValues,
            useTypeName: "Total Area"
        };
        areaTableData.push(newUseTypeTotal);
    }
    if (totalUnitValues) {
        const newUnitTypeTotal = {
            ...totalUnitValues,
            useTypeName: "Total Unit"
        };
        unitTableData.push(newUnitTypeTotal);
    }
    const totalSummary = []
    if (totalValues) {
        const newTotalSummaryValues = {
            ...totalValues,
            useTypeName: "Total"
        };
        totalSummary.push(newTotalSummaryValues);
    }

    return (
        <div className='custom-card'>
            <h2 style={{ fontSize: '24px', paddingBottom: '14px' }}>Area & Income Overview</h2>

            <div>
                <DataTable
                    columns={firstTableColumns.filter((item) => item.hidden === false)}
                    dataSource={totalSummary}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-header-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
            {areaTableData?.length > 0 && <div>
                <DataTable
                    columns={firstTableColumns.filter((item) => item.hidden === false)}
                    dataSource={areaTableData}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>}
            {unitTableData?.length > 0 && <div className='pt-4'>
                <DataTable
                    columns={SecondTableColumns.filter((item) => item.hidden === false)}
                    dataSource={unitTableData}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>}
        </div>
    )
}

export default AssetDetailsTables