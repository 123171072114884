import { useState } from "react";
import {
    Button,
    Col,
    Divider,
    Input,
    Modal,
    Row,
} from "antd";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { GrClose } from "react-icons/gr";
import PATH from "../../../../utils/path";
import DataTable from "../../../../components/Layout/Table/DataTable";

export default function AllAssetsModal(props) {
    const navigate = useNavigate();
    const { visible, handleCancel } = props;
    const { Search } = Input;
    const [active, setActive] = useState(true);
    const [showRecordTenants, setShowRecordTenants] = useState(true);
    const [showProfileTenants, setShowProfileTenants] = useState(false);

    const onRecordTenantsDetail = () => {
        setShowRecordTenants(true);
        setShowProfileTenants(false);
        setActive(false);
    };
    const onProfileTenantsDetail = () => {
        setShowRecordTenants(false);
        setShowProfileTenants(true);
        setActive(false);
    };
    const columns = [
        {
            title: "Asset ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Asset Name",
            dataIndex: "assetName",
            key: "assetName",
        },
        // {
        //     title: "Asset Type",
        //     dataIndex: "assetType",
        //     key: "assetType",
        // },
    ];

    const dataSource = [
        {
            id: 0,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 1,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 2,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 3,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 4,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 5,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 6,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
    ];

    const portfolioColumns = [
        {
            title: "Project ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Project Name",
            dataIndex: "assetName",
            key: "assetName",
        },
        // {
        //     title: "Portfolio Type",
        //     dataIndex: "assetType",
        //     key: "assetType",
        // },
    ];

    const portfolioDataSource = [
        {
            id: 0,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 1,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 2,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 3,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 4,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 5,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
        {
            id: 6,
            assetName: "Johar Town",
            assetType: "John",
            expirationDate: "12/12/2022",
        },
    ];

    const navigateRow = (record, rowIndex) => {
        const path = record; // to avoid es list error
        return {
            onClick: () => {
                navigate(PATH.ASSET_DETAILS);
                console.log(record, rowIndex, path); // to avoid es lint error
            }, // click row
        };
    };

    return (
        <Modal
            className="tenants-detail-profile"
            centered
            open={visible}
            width={650}
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={false}
        >
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="text-blue mb-0">Income & Cost Analysis</h2>
                <GrClose className="cursor-pointer" onClick={handleCancel} />
            </div>
            <Col xs={24} className="my-4">
                <div>
                    <div className="table-footer-btn d-flex align-items-center">
                        <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                            <Button
                                className="btn-action d-flex justify-content-center align-items-center asset-btn"
                                onClick={onRecordTenantsDetail}
                            >
                                <div
                                    className="active-action"
                                    style={{
                                        backgroundColor: active === true ? "#f2f3f5" : " ",
                                    }}
                                >
                                    <div className="active-action d-flex align-items-center">
                                        {/* <BsFillCircleFill size={10} fill="hsl(200.16806722689077, 59.203980099502495%, 39.411764705882355%)" /> */}
                                        <h4 className="mb-0 ml-2 text-black">Assets</h4>
                                    </div>
                                </div>
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                                onClick={onProfileTenantsDetail}
                            >
                                <div className="active-action d-flex align-items-center">
                                    {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                                    <h4 className="mb-0 ml-2 text-black">Project</h4>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
            <hr className="w-100" />
            {showRecordTenants && (
                <>
                    {" "}
                    <Row gutter={24} className="button-search">
                        <Col xs={24} className="mt-1">
                            <div className="d-flex searchbar-project">
                                <span className="searchbar-icon">
                                    <FiSearch size={20} />
                                </span>
                                <Search className="mr-2" placeholder="Search a project" />
                            </div>
                        </Col>
                    </Row>
                    <div className="asset-expire-wrapper mt-3">
                        <DataTable
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{
                                y: 300,
                            }}
                            className="antd-table-striped"
                            pagination={false}
                            onRow={navigateRow}
                        />
                    </div>
                </>
            )}
            {showProfileTenants && (
                <>
                    <Row gutter={24} className="button-search">
                        <Col xs={24} className="mt-1">
                            <div className="d-flex searchbar-project">
                                <span className="searchbar-icon">
                                    <FiSearch size={20} />
                                </span>
                                <Search className="mr-2" placeholder="Search a project" />
                            </div>
                        </Col>
                    </Row>
                    <div className="asset-expire-wrapper mt-3">
                        <DataTable
                            columns={portfolioColumns}
                            dataSource={portfolioDataSource}
                            scroll={{
                                y: 300,
                            }}
                            className="antd-table-striped"
                            pagination={false}
                            onRow={navigateRow}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
}
AllAssetsModal.propTypes = {
    visible: PropTypes.node.isRequired,
    handleCancel: PropTypes.node.isRequired,
};
