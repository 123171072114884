/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * RentRollControllerApi - axios parameter creator
 * @export
 */
export const RentRollControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentRoll: (rentRollDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'rentRollDto' is not null or undefined
            assertParamExists('addRentRoll', 'rentRollDto', rentRollDto);
            const localVarPath = `/api/rentrolls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(rentRollDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRoll: (rentRollId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'rentRollId' is not null or undefined
            assertParamExists('deleteRentRoll', 'rentRollId', rentRollId);
            const localVarPath = `/api/rentrolls/{rentRollId}`
                .replace(`{${"rentRollId"}}`, encodeURIComponent(String(rentRollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRolls: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteRentRolls', 'requestBody', requestBody);
            const localVarPath = `/api/rentrolls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRentRolls: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/rentrolls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentRoll: (rentRollId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'rentRollId' is not null or undefined
            assertParamExists('getRentRoll', 'rentRollId', rentRollId);
            const localVarPath = `/api/rentrolls/{rentRollId}`
                .replace(`{${"rentRollId"}}`, encodeURIComponent(String(rentRollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentRolls: (search, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchRentRolls', 'search', search);
            const localVarPath = `/api/rentrolls/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} rentRollId
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRentRoll: (rentRollId, rentRollDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'rentRollId' is not null or undefined
            assertParamExists('updateRentRoll', 'rentRollId', rentRollId);
            // verify required parameter 'rentRollDto' is not null or undefined
            assertParamExists('updateRentRoll', 'rentRollDto', rentRollDto);
            const localVarPath = `/api/rentrolls/{rentRollId}`
                .replace(`{${"rentRollId"}}`, encodeURIComponent(String(rentRollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(rentRollDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * RentRollControllerApi - functional programming interface
 * @export
 */
export const RentRollControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = RentRollControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentRoll(rentRollDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addRentRoll(rentRollDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRoll(rentRollId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteRentRoll(rentRollId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRolls(requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteRentRolls(requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRentRolls(page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllRentRolls(page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentRoll(rentRollId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRentRoll(rentRollId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentRolls(search, page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchRentRolls(search, page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} rentRollId
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRentRoll(rentRollId, rentRollDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateRentRoll(rentRollId, rentRollDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * RentRollControllerApi - factory interface
 * @export
 */
export const RentRollControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = RentRollControllerApiFp(configuration);
    return {
        /**
         *
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentRoll(rentRollDto, options) {
            return localVarFp.addRentRoll(rentRollDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRoll(rentRollId, options) {
            return localVarFp.deleteRentRoll(rentRollId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRentRolls(requestBody, options) {
            return localVarFp.deleteRentRolls(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRentRolls(page, size, sort, options) {
            return localVarFp.getAllRentRolls(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} rentRollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentRoll(rentRollId, options) {
            return localVarFp.getRentRoll(rentRollId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentRolls(search, page, size, sort, options) {
            return localVarFp.searchRentRolls(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} rentRollId
         * @param {RentRollDto} rentRollDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRentRoll(rentRollId, rentRollDto, options) {
            return localVarFp.updateRentRoll(rentRollId, rentRollDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * RentRollControllerApi - object-oriented interface
 * @export
 * @class RentRollControllerApi
 * @extends {BaseAPI}
 */
export class RentRollControllerApi extends BaseAPI {
    /**
     *
     * @param {RentRollDto} rentRollDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    addRentRoll(rentRollDto, options) {
        return RentRollControllerApiFp(this.configuration).addRentRoll(rentRollDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} rentRollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    deleteRentRoll(rentRollId, options) {
        return RentRollControllerApiFp(this.configuration).deleteRentRoll(rentRollId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    deleteRentRolls(requestBody, options) {
        return RentRollControllerApiFp(this.configuration).deleteRentRolls(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    getAllRentRolls(page, size, sort, options) {
        return RentRollControllerApiFp(this.configuration).getAllRentRolls(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} rentRollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    getRentRoll(rentRollId, options) {
        return RentRollControllerApiFp(this.configuration).getRentRoll(rentRollId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Search} search
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    searchRentRolls(search, page, size, sort, options) {
        return RentRollControllerApiFp(this.configuration).searchRentRolls(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} rentRollId
     * @param {RentRollDto} rentRollDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentRollControllerApi
     */
    updateRentRoll(rentRollId, rentRollDto, options) {
        return RentRollControllerApiFp(this.configuration).updateRentRoll(rentRollId, rentRollDto, options).then((request) => request(this.axios, this.basePath));
    }
}
