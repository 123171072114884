import { Col, Row } from "antd";
import { useState } from "react";
import IMAGES from "../../../assets/images";
import ReportsChart from "./components/ReportsChart";
import TanentsCard from "./components/TanentsCard";
import UserCard from "./components/User";

export default function Index() {


  const data = [
    {
      id: 0,
      date: new Date(2022, 10, 11),
      image: IMAGES.CHILD_IMG,
      name: "Kathryn Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Individual',
      tanents: [
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]
    },
    {
      id: 1,
      date: new Date(2022, 10, 11),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },

      ]
    },
    {
      id: 2,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Kathryn Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',

      tanents: [
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]

    },
    {
      id: 3,
      date: new Date(2022, 10, 13),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]
    },
    {
      id: 4,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]
    },
    {
      id: 5,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]
    },
    {
      id: 6,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Corporate',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
      ]
    }, {
      id: 7,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      address: "32 South Military Street Kent, OH 44240",
      status: 'Individual',
      activityCount: 35,
      tanents: [
        {
          assetName: "10 Falcon",
          totalTanents: 12,
        },
        {
          assetName: "30 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          assetName: "12 Inlet Cove Cottage",
          totalTanents: 12,
        },
        {
          assetName: "70 Spotted Sandpiper",
          totalTanents: 12,
        },
        {
          assetName: "Peppers House",
          totalTanents: 12,
        },
        {
          assetName: "24 Bungalow",
          totalTanents: 12,
        },
        {
          assetName: "Hearst Castle",
          totalTanents: 12,
        },
        {
          assetName: "29 Evergeen",
          totalTanents: 12,
        },
        {
          assetName: "Boston heights",
          totalTanents: 12,
        },
        {
          id: 8,
          date: new Date(2022, 10, 10),
          image: IMAGES.CHILD_IMG,
          name: "Alexandar Murphy",
          address: "32 South Military Street Kent, OH 44240",
          status: 'Corporate',
          activityCount: 35,
          tanents: [
            {
              assetName: "10 Falcon",
              totalTanents: 12,
            },
            {
              assetName: "30 Inlet Cove Cottage",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
            {
              assetName: "Peppers House",
              totalTanents: 12,
            },
            {
              assetName: "70 Spotted Sandpiper",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
            {
              assetName: "12 Inlet Cove Cottage",
              totalTanents: 12,
            },
            {
              assetName: "70 Spotted Sandpiper",
              totalTanents: 12,
            },
            {
              assetName: "Peppers House",
              totalTanents: 12,
            },
            {
              assetName: "24 Bungalow",
              totalTanents: 12,
            },
            {
              assetName: "Hearst Castle",
              totalTanents: 12,
            },
            {
              assetName: "29 Evergeen",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
          ]
        },
        {
          id: 10,
          date: new Date(2022, 10, 10),
          image: IMAGES.CHILD_IMG,
          name: "Alexandar Murphy",
          address: "32 South Military Street Kent, OH 44240",
          status: 'Individual',
          activityCount: 35,
          tanents: [
            {
              assetName: "10 Falcon",
              totalTanents: 12,
            },
            {
              assetName: "30 Inlet Cove Cottage",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
            {
              assetName: "Peppers House",
              totalTanents: 12,
            },
            {
              assetName: "70 Spotted Sandpiper",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
            {
              assetName: "12 Inlet Cove Cottage",
              totalTanents: 12,
            },
            {
              assetName: "70 Spotted Sandpiper",
              totalTanents: 12,
            },
            {
              assetName: "Peppers House",
              totalTanents: 12,
            },
            {
              assetName: "24 Bungalow",
              totalTanents: 12,
            },
            {
              assetName: "Hearst Castle",
              totalTanents: 12,
            },
            {
              assetName: "29 Evergeen",
              totalTanents: 12,
            },
            {
              assetName: "Boston heights",
              totalTanents: 12,
            },
          ]
        }
      ]
    }
  ]

  const [tanents, setTanents] = useState(data[0].tanents);

  return (
    <Row gutter={24} className="admin-dashboard-wrapper">
      <Col span={18} xl={18} lg={18} md={18} sm={24} xs={24}>
        <Row gutter={24}>
          <Col xs={24}>
            <ReportsChart />
          </Col>
          <Col xs={24}>
            <UserCard data={data} setTanents={setTanents} />
          </Col>
        </Row>
      </Col>
      <Col span={6} xl={6} lg={6} md={6} sm={24} xs={24}>
        <TanentsCard tanents={tanents} />
      </Col>
    </Row >
  );
}
