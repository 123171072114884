import React from 'react'
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'

const bgColor = {
    0: "#F3F8FF",
    1: "#FEF3E9",
    2: "#FFF9E7",
}
const AccquisitionCostsExitSummary = () => {

    const accquisitionCostsChart = {
        series: [23, 23, 23],
        options: {
            chart: {
                type: 'donut',
                width: 190,
                height: 190,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 190,
                    },
                },
            }],
            colors: ['#3372C4', '#ED7D31', '#FFC000'],
            legend: {
                show: false,
            },
            labels: ['Tax', 'Agent', 'Other'],
            dataLabels: {
                enabled: false,
            },
        }
    }
    const yieldProfileChart = {
        series: [{
            name: 'NIY',
            data: [
                5 ?? 0,
                5 ?? 0,
                5 ?? 0
            ]
        }, {
            name: 'GIY',
            data: [
                6 ?? 0,
                6?? 0,
                6 ?? 0
            ]
        },],
        options: {
            chart: {
                type: 'bar',
                height: 900,
                width: '100%',
                toolbar: {
                    show: false // Hide the menu icon
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    borderRadius: 10, // Set border radius to 10px
                    borderRadiusApplication: 'end', // Apply border radius to top corners only
                    dataLabels: {
                        position: 'top', // Show percentage on top of bars
                    },
                },
            },
            colors: ['#333F50', '#ED7D31'], // Set colors to match the desired orange and dark color
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val ? `${getFormattedDecimalNumber(val)}%` : 0;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Current', 'Potential', 'Market'],
            },
            yaxis: {
                labels: {
                    show: false // Remove y-axis side data label
                },
                title: {
                    text: undefined
                }
            },
            grid: {
                show: false // Remove chart background lines
            },
            legend: {
                show: false // Remove legends
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val ? `${getFormattedDecimalNumber(val)}%` : 0;
                    }
                }
            }
        },
    };

    return (
        <div className='custom-card'>
            <div className='d-flex justify-content-between align-items-center' style={{ gap: '20px', flexWrap: 'wrap' }}>
                {[{ name: 'Exit Value', value: 23452345 },
                { name: 'Capital Value', value: 48800000 },
                { name: 'Gross Value', value: 23452345 },
                ].map(((item, index) =>
                    <div style={{ background: bgColor[index], padding: '24px', borderRadius: '24px', flexGrow: 1 }}>
                        <p className='mb-0' style={{ fontSize: '24px', fontWeight: 500, color: '#0C4259' }}>{item.name}</p>
                        <p className='mb-0' style={{ fontSize: '28px', fontWeight: 600, color: '#0C4259', }}>
                            {getFormattedEnglishNumber(item.value)}€
                        </p>
                    </div>
                ))}
            </div>
            <div className='d-flex align-items-center justify-content-between' style={{ gap: '18px', flexWrap: 'wrap', paddingBottom: '5rem', paddingTop: '20px' }}>
                <div className='custom-card' style={{ flexGrow: 1 }}>
                    <p className='mb-0' style={{ fontSize: '24px', color: '#000', fontWeight: 500 }}>Acquisition Costs @Exit</p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {[{ name: 'Tax', percentage: 2, value: 3172000 },
                            { name: 'Agent', percentage: 3, value: 3172000 },
                            { name: 'Other', percentage: 6, value: 3172000 },
                            ].map((item, index) => (
                                <div key={index} style={{ paddingTop: index === 0 ? '0' : '20px' }}>
                                    <div className='d-flex justify-content-between align-items-center' style={{ gap: '2px' }}>
                                        <p className='mb-0' style={{ fontSize: '16px', color: "#000" }}>{item.name}</p>
                                        <p className='mb-0' style={{ fontSize: '16px', color: "#000" }}>{getFormattedDecimalNumber(item.percentage)}%</p>
                                    </div>
                                    <p className='mb-0' style={{ fontSize: '16px', color: '#FC0606' }}>{getFormattedEnglishNumber(item.value)} €</p>
                                </div>
                            ))}
                        </div>
                        <div className='position-relative'>
                            <ReactApexChart options={accquisitionCostsChart.options} series={accquisitionCostsChart.series} type="donut" />
                            <div className='text-center position-absolute' style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <p className='mb-0' style={{ color: '#797979', fontSize: '9px' }}>Total</p>
                                <p className='mb-0' style={{ color: '#0A0A0A', fontSize: '18px' }}>{getFormattedDecimalNumber(7.78)}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-card' style={{ flexGrow: 1 }}>
                    <p className='mb-0' style={{ fontSize: '24px', color: '#000', fontWeight: 500 }}>Yield Profile @Exit</p>
                    <div>
                        <div style={{ width: '305px' }}>
                            <ReactApexChart options={yieldProfileChart.options} series={yieldProfileChart.series} type="bar" />
                        </div>
                        <div className="d-flex justify-content-between align-items-center" style={{ paddingInline: '3rem' }}>
                            <p className="mb-0">{getFormattedDecimalNumber(12)}%</p>
                            <p className="mb-0">{getFormattedDecimalNumber(12)}%</p>
                            <p className="mb-0">{getFormattedDecimalNumber(12)}%</p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default AccquisitionCostsExitSummary
