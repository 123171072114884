import { Col, Row } from "antd";
import GroundRentsOtherPeriodicCosts from "./component/IncomeAndCost/GroundRentsOtherPeriodicCosts";
import OtherCashFlows from "./component/IncomeAndCost/OtherCashFlows";
import OneTimeExpensesRevenue from "./component/IncomeAndCost/OneTimeExpensesRevenue";


function IncomeAndCost({ handleToggleDrawer, PortfolioSelect, showDrawer }) {

    return (
        <Row gutter={24} className="incomecost-wrapper">
            <Col xs={24}>
                <GroundRentsOtherPeriodicCosts
                    showDrawer={showDrawer}
                    PortfolioSelect={PortfolioSelect}
                    handleToggleDrawer={handleToggleDrawer}
                />
            </Col>
        </Row>
    );
}

export default IncomeAndCost;
