import { Col, Row } from 'antd';
import React from 'react'
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper';
import DataTable from '../../../../../../../components/Layout/Table/DataTable';
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';

const TenantAnalysis = () => {
    const tenantShare = {
        series: [75],
        options: {
            chart: {
                height: 120,
                width: 100,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '67%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -8,
                            show: true,
                            color: '#101828',
                            fontSize: '8px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}%`;
                            },
                            color: '#101828',
                            offsetY: -6,
                            fontSize: '12px',
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#2C406F'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };

    const prolongation = {
        series: [75],
        options: {
            chart: {
                height: 120,
                width: 100,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '67%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#101828',
                            fontSize: '8px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}%`;
                            },
                            color: '#101828',
                            offsetY: -6,
                            fontSize: '12px',
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#FF7228'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };
    const topTenantsColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>No</p>,
            align: "center",
            children: [{
                dataIndex: 'key',
                width: "60px",
                title: "",
                align: "center",
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Top 5 Tenants</p>,
            align: "center",
            children: [{
                dataIndex: "name",
                width: "100px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Name</p>,
                align: "center",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Share</p>,
            align: "center",
            children: [{
                dataIndex: "share",
                align: "center",
                width: "100px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Icome %</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text) : null}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Lettable</p>,
            align: "center",
            children: [{
                dataIndex: "lettableArea",
                width: "100px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Area sq</p>,
                align: "center",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossCurrentRate",
                width: "145px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Current Rate (EUR p.a)</p>,
                align: "left",
            }, {
                dataIndex: "grossRentPerSQM",
                width: "100px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>(EUR/sqm)</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Expiry</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span>
                        {text ? text : "N/A"}
                    </span>
                ),
                width: "100px",
                dataIndex: "date",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Date</p>,
                align: "center",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "center",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ), width: "100px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Year</p>,
                align: "center",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>{`Options (Years)`}</p>,
            align: "center",
            children: [{
                dataIndex: "optionsYear1",
                render: (text) => (
                    <span>
                        {text ? text : "N/A"}
                    </span>
                ), width: "80px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>1st Year</p>,
                align: "left",
            }, {
                dataIndex: "optionsYear2",
                render: (text) => (
                    <span>
                        {text ? text : "N/A"}
                    </span>
                ), width: "80px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>2nd Year</p>,
                align: "right",
            }]
        },
    ];
    const totalTenantsChartData = {
        type: 'area',
        height: 50,
        width: 150,
        options: {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#12B76A'], // Line color
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ECFDF3'], // Fill color below the line
                    inverseColors: false,
                    opacityFrom: 0.6,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            yaxis: {
                min: 0,
                max: 100,
                labels: {
                    show: false // Remove y-axis labels
                }
            },
            xaxis: {
                labels: {
                    show: false // Remove x-axis labels
                }
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: () => 'Total Order'
                    }
                },
                marker: {
                    show: false
                }
            }
        },
        series: [
            {
                name: 'series1',
                data: [45, 66, 41, 89, 25, 44, 9, 54]
            }
        ]
    };
    const totalTenantsExcludingUnitsChartData = {
        type: 'area',
        height: 50,
        width: 150,
        options: {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#F04438'], // Line color
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#FEF3F2'], // Fill color below the line
                    inverseColors: false,
                    opacityFrom: 0.6,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            yaxis: {
                min: 0,
                max: 100,
                labels: {
                    show: false // Remove y-axis labels
                }
            },
            xaxis: {
                labels: {
                    show: false // Remove x-axis labels
                }
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: () => 'Total Order'
                    }
                },
                marker: {
                    show: false
                }
            }
        },
        series: [
            {
                name: 'series1',
                data: [45, 66, 41, 89, 25, 44, 9, 54]
            }
        ]
    };
    const topTenantsData = [
        {
            key: '1',
            name: 'Tenant A',
            share: 12.5,
            lettableArea: 1500,
            grossCurrentRate: 30000,
            grossRentPerSQM: 20,
            date: '2025-06-30',
            walt: 5,
            optionsYear1: '2026',
            optionsYear2: '2027',
        },
        {
            key: '2',
            name: 'Tenant B',
            share: 10.0,
            lettableArea: 1200,
            grossCurrentRate: 24000,
            grossRentPerSQM: 18,
            date: '2026-12-31',
            walt: 4,
            optionsYear1: '2027',
            optionsYear2: '2028',
        },
        {
            key: '3',
            name: 'Tenant C',
            share: 8.0,
            lettableArea: 1000,
            grossCurrentRate: 20000,
            grossRentPerSQM: 16,
            date: '2024-08-15',
            walt: 3,
            optionsYear1: '2025',
            optionsYear2: '2026',
        },
        {
            key: '4',
            name: 'Tenant D',
            share: 15.0,
            lettableArea: 2000,
            grossCurrentRate: 40000,
            grossRentPerSQM: 22,
            date: '2023-05-20',
            walt: 6,
            optionsYear1: '2024',
            optionsYear2: '2025',
        },
        {
            key: '5',
            name: 'Tenant E',
            share: 5.5,
            lettableArea: 800,
            grossCurrentRate: 16000,
            grossRentPerSQM: 15,
            date: '2027-11-01',
            walt: 2,
            optionsYear1: '2028',
            optionsYear2: '2029',
        },
    ];
    const totalTenantsData = {
        key: 'Total',
        name: '',
        share: 12.5,
        lettableArea: 1500,
        grossCurrentRate: 30000,
        grossRentPerSQM: 20,
        date: '2025-06-30',
        walt: 5,
        optionsYear1: '2026',
        optionsYear2: '2027',
    }
    topTenantsData.push(totalTenantsData)
    return (
        <div>
            <Row gutter={[20, 20]} style={{ paddingBottom: '20px' }}>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center'>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Total Tenants</h2>
                            <h2 style={{ fontSize: '24px' }}>25</h2>
                        </div>
                        <div>
                            {/* <Chart {...totalTenantsChartData} /> */}
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center'>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Total Tenants <span style={{ fontSize: '20px' }}>(excluding units)</span></h2>
                            <h2 style={{ fontSize: '24px' }}>15</h2>
                        </div>
                        <div>
                            {/* <Chart {...totalTenantsExcludingUnitsChartData} /> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='custom-card'>
                <h2 style={{ fontSize: '24px' }}>Top Tenants Analysis</h2>
                <DataTable
                    columns={topTenantsColumns.filter((item) => item.hidden === false)}
                    dataSource={topTenantsData ?? []}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
            <Row gutter={20} style={{ paddingTop: '20px' }}>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ paddingBottom: '32px' }}>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Top 5 Tenants Share of
                                Income</h2>
                        </div>
                        <ReactApexChart options={tenantShare.options} series={tenantShare.series} type="radialBar" height={120} width={100} />
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ paddingBottom: '32px' }}>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Share of Income with Prolongation Options</h2>
                        </div>
                        <ReactApexChart options={prolongation.options} series={prolongation.series} type="radialBar" height={120} width={100} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TenantAnalysis