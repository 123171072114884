import axios from "axios";
import environments from "./environments";
import { toast } from "react-toastify";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const api = axios.create({
  baseURL: environments.apiUrl,
  headers,
});

let store
export const injectStore = _store => {
    store = _store
}
api.interceptors.request.use(function (config) {
    const auth = store.getState().auth;
    config.headers.Authorization =  "Bearer " + auth.user.token;

    return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("Backend response: User is unauthorized")
    }

    let { message } = error.response.data;

    if (!message) {
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
    const errors = {
      errors: error.response.data.errors,
      message,
    };
    toast.error(message);

    throw errors;
  }
);

export default api;
