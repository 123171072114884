import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import ChangePassword from "./Component/ChangePassword";
import EditProfile from "./Component/EditProfile";
import NotificationSettings from "./Component/NotificationSettings";

export default function Index() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      {user?.roleId !== 2 ? (
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <EditProfile />
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={24}>
              <Col xs={24}>
                <ChangePassword />
              </Col>
              <Col xs={24}>
                <NotificationSettings />
              </Col>
            </Row>
          </Col>
          <Col xs={24} className="d-flex justify-content-end">
            <Button key="submit" type="default" className="btn-primary">
              Save
            </Button>
          </Col>
        </Row>
      ) : (
        <Row gutter={24} className="d-flex justify-content-center align-items-center">
          <Col xs={10}>
            <ChangePassword user={user} />
          </Col>
        </Row>
      )}
    </div>
  );
}
