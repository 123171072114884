/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assets from "./assets.service";

const initialState = {
  asset: null,
  createAsset: null, 
  assetsByProject: null,
  deleteAssets: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: ""
};

export const createAsset = createAsyncThunk(
  "assets/createAsset",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assets.createAsset(finalData);
      if (response.id) {
        if (notifyToaster) {
          notifyToaster("Asset created! AssetId: "+response.id, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(JSON.stringify(response), "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAsset = createAsyncThunk(
    "assets/updateAsset",
    async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
        try {
            const response = await assets.updateAsset(finalData);
            if (response.id) {
                if (notifyToaster) {
                    notifyToaster("Asset with "+response.id+" updated!", "success");
                }
                if (moveToNext) {
                    moveToNext();
                }
            } else {
                notifyToaster("Asset update failed!", "error");
            }
            return response;
        } catch (error) {
            notifyToaster(error.message, "error");
            const message =
                (error.response &&
                    error.response.detail &&
                    error.response.detail) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAssetsByProject = createAsyncThunk(
    "assets/getAssetsByProject",
    async ({finalData, notifyToaster}, thunkAPI) => {
        try {
            return await assets.getAssetsByProject(finalData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAssetByID = createAsyncThunk(
    "assets/getAssetByID",
    async ({finalData, notifyToaster}, thunkAPI) => {
        try {
            return await assets.getAssetById(finalData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteAssets = createAsyncThunk(
  "assets/deleteAsset",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response = await assets.deleteAssets(finalData);
      if (response.code === 200) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAssetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        // const createAsset = [...state.assetDetails];
        // createAsset.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.createAsset = action.payload;
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAsset = action.payload;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteAssets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAssets.fulfilled, (state, action) => {
        // const deleteMultipleAssets = [...state.assetDetails];
        // deleteMultipleAssets = state.assetDetails.filter(
        //   (item) => item.assetId !== action.payload
        // );
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteAssets = action.payload;
      })
      .addCase(deleteAssets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetByID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.asset = action.payload;
      })
      .addCase(getAssetByID.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
    .addCase(getAssetsByProject.pending, (state) => {
        state.isLoading = true;
    })
    .addCase(getAssetsByProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assetsByProject = action.payload;
    })
    .addCase(getAssetsByProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
    })
  },
});

export const { clearData } = createAssetsSlice.actions;

export default createAssetsSlice.reducer;
