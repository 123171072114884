import { Button, Col, Form, Input, Row, Select } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function CurrentRent(props) {
  const [rentValue, setRentValue] = useState({
    square: "",
    month: "",
    year: "",
  });
  const { Option } = Select;

  const { next, prev, handleCancel } = props;
  
  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem(
      "currentRent",
      JSON.stringify({ ...values, ...rentValue })
    );

    next();
  };
  const localStorageData = localStorage.getItem("currentRent");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;

  const localStorageBasicData = localStorage.getItem("basicForm");
  const BasicDataForm = localStorageBasicData ? JSON.parse(localStorageBasicData) : null;

  let areaUnit = -1;
  
  if(BasicDataForm && BasicDataForm.areaUnit)
    areaUnit = BasicDataForm.areaUnit;
  
  console.log(BasicDataForm);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleValue = (e) => {
    const squarePerMeter = e.target.value;
    setRentValue({
      square: squarePerMeter,
      month: squarePerMeter * areaUnit,
      year: squarePerMeter * areaUnit * 12,
    });
  };
  if (FormData !== null) {
    useEffect(() => {
      const squarePerMeter = FormData?.perSqM;
      setRentValue({
        square: squarePerMeter,
        month: squarePerMeter * areaUnit,
        year: squarePerMeter * areaUnit * 12,
      });
    }, []);
  }

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Current Rent
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Sq M	"
              name="perSqM"
              initialValue={FormData?.perSqM}
              rules={[
                {
                  required: true,
                  message: "Please input Per Sq M!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input
                onChange={handleValue}
                value={rentValue.square}
                type="number"
                placeholder="Per Sq M"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Month"
              // name="perMonth"
              initialValue={FormData?.month}
              rules={[
                {
                  required: false,
                  message: "Please input rent!",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input
                value={rentValue?.month}
                disabled
                placeholder="Per Month"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Year"
              // name="perYear"
              initialValue={FormData?.year}
              rules={[
                {
                  required: false,
                  message: "Please input per year!",
                },
              ]}
            >
              <Input
                text="name"
                value={rentValue?.year}
                disabled
                placeholder="Per Year"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="VAT"
              name="vat"
              initialValue={FormData?.vat}
              rules={[{ required: true, message: "Please input Vat Type!" }]}
            >
              <Select placeholder="Select" allowClear>
                <Option value="True">Yes</Option>
                <Option value="False">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CurrentRent;

CurrentRent.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
