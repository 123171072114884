import PropTypes from "prop-types";
import { Modal } from 'antd'
import { CloseOutlined } from "@ant-design/icons";

export default function ShowMapModal(props) {

    const { visible, handleCancel, setShowMap } = props;

    return (
        <Modal
            className="map-modal"
            open={visible}
            handleCancel={handleCancel}
            onCancel={handleCancel}
            width={620}
            centered
            footer={null}
        >
            <div className="d-flex justify-content-end"><CloseOutlined onClick={() => setShowMap(false)} /></div>
            <div className="mapouter mt-3">
                <div className="gmap_canvas">

                    <iframe
                        className="gmap_iframe"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        title="myFrame"
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=570&height=300&hl=en&q=University of Oxford&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    />
                    {/* <a href="https://piratebay-proxys.com/">Pirate Proxy</a> */}
                </div>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            ".mapouter{position:relative;text-align:right;width:570px;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:570px;height:300px;}.gmap_iframe {width:570px!important;height:300px!important;}",
                    }}
                />
            </div>
        </Modal>
    )
}


ShowMapModal.propTypes = {
    visible: PropTypes.node.isRequired,
    handleCancel: PropTypes.node.isRequired,
    setShowMap: PropTypes.node.isRequired,
};
