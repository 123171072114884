import {
    Configuration,
    ProjectControllerApi,
    I18nControllerApi,
    AssetControllerApi,
    RentRollControllerApi, SettingsControllerApi, CalculationDetailControllerApi, PortfolioControllerApi
} from "reia-rest-client";
import environments from "./environments";

class RestClient {
    store;

    constructor(store) {
        this.store = store;
    }

    accessToken = () => {
        return this.store.getState().auth.user.token;
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: { Authorization: 'Bearer ' + this.accessToken() },
        };
        openapiConfig.basePath = environments.apiUrl;
        return openapiConfig;
    };

    projectsApi = () => {
        return new ProjectControllerApi(this.configuration());
    };

    assetsApi = () => {
        return new AssetControllerApi(this.configuration());
    };

    i18nApi = () => {
        return new I18nControllerApi(this.configuration());
    };

    rentRollApi = () => {
        return new RentRollControllerApi(this.configuration());
    };

    settingsApi = () => {
        return new SettingsControllerApi(this.configuration());
    };

    calculationApi = () => {
        return new CalculationDetailControllerApi(this.configuration());
    };

    portfolioApi = () => {
        return new PortfolioControllerApi(this.configuration());
    };
}

export default RestClient;