import PropTypes from "prop-types";

export default function Pagination({
    totalPosts,
    cardsPerPage,
    setCurrentPage,
    currentPage,
}) {
    const pages = [];

    for (let i = 1; i <= Math.ceil(totalPosts / cardsPerPage); i += 1) {
        pages.push(i);
    }

    return (
        <div className='pagination'>
            <button
                type="button"
                onClick={() => setCurrentPage(current => current - 1)}
                disabled={currentPage === 1}
            >
                Prev
            </button>
            {pages.map((page) =>
                <button
                    type="button"
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={page === currentPage ? "active" : ""}>
                    {page}
                </button>
            )}
            <button
                type="button"
                onClick={() => setCurrentPage(current => current + 1)}
                disabled={currentPage === pages.length}
            // className={page === currentPage ? "active" : ""}
            >
                Next
            </button>
        </div>
    );
};

Pagination.propTypes = {
    totalPosts: PropTypes.node.isRequired,
    cardsPerPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.node.isRequired,
    currentPage: PropTypes.node.isRequired,
};
