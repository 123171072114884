/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetsDetails from "./projectDetail.service";

const initialState = {
  assetDetail: null,
  assetDetails: null,
  createAsset: null,
  assetsByProject: null,
  projectDetailById: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
  projectDetailFilters: null,
};

export const createAsset = createAsyncThunk(
  "projectDetail/createAsset",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.createAsset(finalData);
      if (response.id) {
        if (notifyToaster) {
          notifyToaster("Asset created! AssetId: " + response.id, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(JSON.stringify(response), "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAsset = createAsyncThunk(
  "projectDetail/updateAsset",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateAsset(finalData);
      if (response.id) {
        if (notifyToaster) {
          notifyToaster("Asset with " + response.id + " updated!", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster("Asset update failed!", "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetsByProject = createAsyncThunk(
  "projectDetail/getAssetsByProject",
  async ({ finalData, notifyToaster }, thunkAPI) => {
    try {
      return await assetsDetails.getAssetsByProject(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProjectDetailById = createAsyncThunk(
  "projectDetail/getProjectDetailById",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getProjectDetailById(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteAssets = createAsyncThunk(
  "projectDetail/deleteAsset",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.deleteAssets(finalData);
      if (response.code === 200) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProjectFilters = createAsyncThunk(
  "projectDetail/updateProjectFilters",
  async ({ key, value }, thunkAPI) => {
    return { key, value };
  }
);

export const resetProjectFilters = createAsyncThunk(
  "projectDetail/resetProjectFilters",
  async (thunkAPI) => {
    return {};
  }
);

export const createProjectSlice = createSlice({
  name: "projectDetail",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        // const createAsset = [...state.assetDetails];
        // createAsset.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.createAsset = action.payload;
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAsset = action.payload;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteAssets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAssets.fulfilled, (state, action) => {
        // const deleteMultipleAssets = [...state.assetDetails];
        // deleteMultipleAssets = state.assetDetails.filter(
        //   (item) => item.assetId !== action.payload
        // );
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteAssets = action.payload;
      })
      .addCase(deleteAssets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getProjectDetailById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectDetailById = action.payload;
      })
      .addCase(getProjectDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      // .addCase(getBasicDataList.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getBasicDataList.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.getBasicData = action.payload;
      // })
      // .addCase(getBasicDataList.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.statusMessage = action.payload;
      // })
      .addCase(getAssetsByProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetsByProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assetsByProject = action.payload;
      })
      .addCase(getAssetsByProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateProjectFilters.fulfilled, (state, action) => {
        const updatedProjectDetailFilters = {
          ...state.projectDetailFilters,
          [action.payload.key]: action.payload.value,
        };
        state.projectDetailFilters = updatedProjectDetailFilters;
      })

      .addCase(resetProjectFilters.fulfilled, (state, action) => {
        state.projectDetailFilters = {};
      });
  },
});

export const { clearData } = createProjectSlice.actions;

export default createProjectSlice.reducer;
