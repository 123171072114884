/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, Form, Input, Modal, notification, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
import ConfirmationModal from "../../../../../../components/Layout/ConfirmationModel/ConfirmationModal";
import {
  createPortfolio,
  getAllPortfolio,
  mergeAssetToPortfolio,
  updatePortfolio,
} from "../../../../../../app/features/Portfolio/portfolio.slice";
import { PortfolioDto } from "reia-rest-client";
import { InputNumber } from "antd-v5";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const [inputvalue, setInputValue] = useState("");
  const [portfolioIdValue, setPortfolioIdValue] = useState("");

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const param = useParams();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    setPortfolioIdValue(record.id);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  // useEffect(() => {
  //   dispatch(getAllPortfolio());
  // }, [dispatch]);

  const handleValue = (e) => {
    setInputValue(e.target.value);
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext2 = () => {
    const finalData = {
      projectId: param.projectId,
      portfolioId: param.portfolioId,
      page: 1,
      size: -1,
    };
    // setTimeout(() => {
    dispatch(getAllPortfolio(finalData));
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        // ...record,
        ...values,
      });
      const finalData = {
        id: portfolioIdValue,
        portfolioName: inputvalue,
        projectId: parseInt(param.projectId),
      };
      dispatch(updatePortfolio({ finalData, moveToNext2, notifyToaster }));
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          onChange={(e) => handleValue(e)}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

export default function MergeToPortfolio(props) {
  const { visible, handleCancel, selectedRowKeys, setSelectedRowKeys } = props;
  const { Search } = Input;
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  // const [count, setCount] = useState(6);
  const [selectedRowKeysMerge, setSelectedRowKeysMerge] = useState(null);
  const onSelectChange = (newSelectedRowKeysMerge) => {
    setSelectedRowKeysMerge(newSelectedRowKeysMerge);
  };
  const rowSelection = {
    selectedRowKeysMerge,
    onChange: onSelectChange,
  };
  const [confirmModal, setConfirmModal] = useState(false);
  const { allPortfolio, isLoading } = useSelector((state) => state.portfolio);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputvalue, setInputValue] = useState("");
  const [portfolioError, setPortfolioError] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedRowKeys([])
  };
  const handleCancel2 = () => {
    setIsModalOpen(false);
    setSelectedRowKeys([])
  };
  const handleValue = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    const finalData = {
      projectId: +ProjectId,
      page: 1,
      size: -1,
    };
    dispatch(getAllPortfolio(finalData));
  }, [dispatch]);

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setSelectedRowKeysMerge([]);
    handleCancel();
    setSelectedRowKeys([]);
  };
  const moveToNext2 = () => {
    setIsModalOpen(false);
    setPortfolioError(false);
    const finalData = {
      projectId: param.projectId,
      page: 1,
      size: -1,
    };
    dispatch(getAllPortfolio(finalData));
    setInputValue("");
  };
  const addPortfolio = () => {
    const finalData: PortfolioDto = {
      id: 0,
      portfolioName: inputvalue,
      projectId: parseInt(param.projectId),
    };
    if (inputvalue) {
      dispatch(createPortfolio({ finalData, moveToNext2, notifyToaster }));
    }
  };

  const handleMerge = () => {
    const finalData = {
      portfolioId: parseInt(selectedRowKeysMerge.join()),
      assetIds: selectedRowKeys,
    };

    dispatch(mergeAssetToPortfolio({ finalData, moveToNext, notifyToaster }));
    setConfirmModal(false);
  };

  const onSearch = () => {
    const finalData = {
      projectId: parseInt(param.projectId),
      search: searchValue || "",
      page: 1,
      size: -1,
    };
    dispatch(getAllPortfolio(finalData));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const mergeColumns = [
    {
      title: "ID",
      onCell: (data) => ({
        className: data.type === 1 ? "red-border" : "blue-border",
      }),
      dataIndex: "id",
    },

    {
      title: "Portfolio",
      dataIndex: "portfolioName",
      editable: true,
    },
  ];

  const [mergeDataSource, setMergeRentDataSource] = useState([]);

  useEffect(() => {
    if (allPortfolio?.content?.length > 0)
      setMergeRentDataSource(allPortfolio.content ? allPortfolio.content : []);
  }, [allPortfolio]);
  // const handleAdd = () => {
  //   const newData = {
  //     key: count,
  //     id: `${count}`,
  //     name: "Portfolio",
  //   };
  //   setMergeRentDataSource([newData, ...mergeDataSource]);
  //   setCount(count + 1);
  // };

  const handleSave = (row) => {
    const newData = [...mergeDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setMergeRentDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = mergeColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Modal
      visible={visible}
      handleCancel={handleCancel}
      onCancel={handleCancel}
      width={800}
      centered
      footer={[
        <Button onClick={handleCancel} className="btn-cancel">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="default"
          htmlType="submit"
          className="btn-primary"
          disabled={selectedRowKeysMerge?.length === 1 ? false : true}
          onClick={() => {
            setConfirmModal(true);
          }}
        >
          Merge
        </Button>,
      ]}
    >
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center"
      >
        <Col flex="none">
          <h3 className="mb-0 modal-heading">Merge</h3>
        </Col>
        <Col flex="none" className="d-flex align-items-center">
          <Button
            className="plus-add-icon add-row-btn mr-2 d-flex align-items-center"
            // onClick={handleAdd}
            onClick={showModal}
          >
            <span style={{ paddingRight: "6px" }}> Add Portfolio</span>
            <AiOutlinePlus size={17} />
          </Button>
          <Input
            size="large"
            style={{ width: "287px", height: "44px" }}
            prefix={
              <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
            onChange={handleSearch}
            className="mr-2"
            placeholder="Search a Portfolio"
          />
        </Col>
      </Row>

      <div className="mt-3 user-list">
        <DataTable
          components={components}
          columns={columns}
          dataSource={mergeDataSource ? mergeDataSource : []}
          pagination={false}
          className="antd-table-striped custom-scroll"
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.id}
          scroll={{
            y: "calc(100vh - 360px)",
          }}
        />
      </div>
      <ConfirmationModal
        visible={confirmModal}
        handleCancel={closeConfirmModal}
        confirmMessage="merge to portfolio"
        onSubmit={handleMerge}
      />
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal
        title="Add Portfolio"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel2}
        centered
        footer={[
          <Button onClick={handleCancel2} className="btn-cancel">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="default"
            htmlType="submit"
            className="btn-primary"
            onClick={() => {
              addPortfolio();
              setPortfolioError(true);
            }}
          >
            Add
          </Button>,
        ]}
      >
        <Input
          type="text"
          maxLength={50}
          value={inputvalue ? inputvalue : ""}
          placeholder="Add Portfolio"
          required
          onChange={(e) => handleValue(e)}
        />
        {!inputvalue && portfolioError && (
          <p className="text-danger">Please Add Portfolio</p>
        )}
      </Modal>
    </Modal>
  );
}
MergeToPortfolio.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  selectedRowKeys: PropTypes.node.isRequired,
  setSelectedRowKeys: PropTypes.node.isRequired,
};

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};
