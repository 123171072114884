import { Card, Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDashboardData } from "../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import IMAGES from "../../../assets/images";
import PATH from "../../../utils/path";
import AvailableForRent from "./component/AvailableForRent";
import GrossIncomeAndCost from "./component/GrossIncomeAndCost";
import NearestExpiresOfAssets from "./component/NearestExpiresOfAssets";
import RecentTasks from "./component/RecentTasks/RecentTasks";
import TopFiveTenants from "./component/TopFiveTenants";

function Index() {
  const [countData, setcountData] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { dashboardData, assetsNearToExpireData } = useSelector(
    (state) => state.corporateDashboardData
  );

  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Dashboard";
    dispatch(getDashboardData());
  }, []);

  useEffect(() => {
    if (dashboardData && dashboardData.counts?.length > 0)
      setcountData(dashboardData.counts[0]);
  }, [dashboardData]);

  return (
    <div className="corporate-dashboard-wrapper">
      <Tooltip title="Show Task Assigned" color="#00215B" key="#00215B">
        <span
          className={`drawer-close-btn cursor-pointer ${
            isOpen ? "d-none" : ""
          }`}
          role="button"
          tabIndex="0"
        >
          <MdOutlineKeyboardArrowLeft
            size={25}
            fill="#fff"
            onClick={() => setIsOpen(!isOpen)}
          />
        </span>
      </Tooltip>
      <Row gutter={10}>
        <Col
          xs={24}
          md={24}
          lg={24}
          xl={
            user && user?.roleId && user?.roleId === 3
              ? 24
              : isOpen === true && 18
          }
        >
          <Row gutter={10}>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card
                role="button"
                tabIndex="0"
                onClick={() => navigate(PATH.PROJECT_LIST)}
                onKeyDown={() => navigate(PATH.PROJECT_LIST)}
                className="cursor-pointer"
              >
                <div className="triangle-down">
                  <FaArrowLeft className="arrow-right" />
                </div>
                <div className="d-flex align-items-center py-2">
                  <span className="bg-circle mr-2">
                    <img src={IMAGES.GREEN_Building} alt="building icon" />
                  </span>
                  <span>
                    <h4 className="text-black font-weight-light m-0">
                      Projects
                    </h4>
                    <h3 className="text-blue mb-0">
                      {countData ? countData?.totalProject : ""}
                    </h3>
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card
                // role="button"
                // tabIndex="0"
                // onClick={() => navigate(PATH.PROJECT_DETAILS)}
                // onKeyDown={() => navigate(PATH.PROJECT_DETAILS)}
                className="cursor-pointer"
              >
                <div className="triangle-down">
                  <FaArrowLeft className="arrow-right" />
                </div>
                <div className="d-flex align-items-center py-2">
                  <span
                    className="bg-circle mr-2"
                    style={{ backgroundColor: "#DB54610D" }}
                  >
                    <img src={IMAGES.WALLET} alt="building icon" />
                  </span>
                  <span>
                    <h4 className="text-black font-weight-light m-0">
                      Portfolio&apos;s
                    </h4>
                    <h3 className="text-blue mb-0">
                      {" "}
                      {countData ? countData?.totalPortfolio : ""}
                    </h3>
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card
                // role="button"
                // tabIndex="0"
                // onClick={() => navigate(PATH.PROJECT_DETAILS)}
                // onKeyDown={() => navigate(PATH.PROJECT_DETAILS)}
                className="cursor-pointer"
              >
                <div className="triangle-down">
                  <FaArrowLeft className="arrow-right" />
                </div>
                <div className="d-flex align-items-center py-2">
                  <span
                    className="bg-circle mr-2"
                    style={{ backgroundColor: "#676EB40D" }}
                  >
                    <img src={IMAGES.ASSETS} alt="building icon" />
                  </span>
                  <span>
                    <h4 className="text-black font-weight-light m-0">
                     Assets
                    </h4>
                    <h3 className="text-blue mb-0">
                      {countData ? countData?.totalAsset : ""}
                    </h3>
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card
                role="button"
                tabIndex="0"
                onClick={() => navigate(PATH.TOTAL_TENANTS)}
                onKeyDown={() => navigate(PATH.TOTAL_TENANTS)}
                className="cursor-pointer"
              >
                <div className="triangle-down">
                  <FaArrowLeft className="arrow-right" />
                </div>
                <div className="d-flex align-items-center py-2">
                  <span
                    className="bg-circle mr-2"
                    style={{ backgroundColor: "#2978A00D" }}
                  >
                    <img src={IMAGES.HOUSE_CHECK} alt="building icon" />
                  </span>
                  <span>
                    <h4 className="text-black font-weight-light m-0">
                      Tenants
                    </h4>
                    <h3 className="text-blue mb-0">
                      {countData ? countData?.totalTenants : ""}
                    </h3>
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <GrossIncomeAndCost />
            </Col>
            <Col xs={24} md={8} xl={6}>
              <TopFiveTenants dashboardData={dashboardData} />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <NearestExpiresOfAssets
                dashboardData={dashboardData}
                assetsNearToExpireData={assetsNearToExpireData}
              />
            </Col>
            <Col xs={24} md={12}>
              <AvailableForRent dashboardData={dashboardData} />
            </Col>
          </Row>
        </Col>
        {((user && user?.roleId && user?.roleId === 1) ||
          (user && user?.roleId && user?.roleId === 4)) && (
          <Col xs={24} md={24} lg={24} xl={isOpen ? 6 : 0}>
            <Row gutter={23}>
              <Col xs={24}>
                <RecentTasks isOpen={isOpen} setIsOpen={setIsOpen} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default Index;
