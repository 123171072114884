import Index from "./Index.jsx";
import UserDetail from "./UserDetail.jsx";
import CreateUpdate from "./CreateUpdate.jsx";
import ProjectManagerUser from "./ProjectManagerUser.jsx";

const USER_MANAGEMENT = {
  INDEX: Index,
  USER_DETAIL: UserDetail,
  CREATE_UPDATE_USER: CreateUpdate,
  PROJECT_MANAGER_USER: ProjectManagerUser, 
};

export default USER_MANAGEMENT;