import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import corporateDashboardData from "./corporateDashboard.service";

const initialState = {
  dashboardData: [],
  AssetTerminalValueCalulationData: {},
  assetsNearToExpireData: [],
  tenantDetailData: [],
  assignedTask: [],
  assignedTaskDetail: [],
  getComment: [],
  updateAssetTerminalValue: null,
  availableAssetsForRentData: [],
  comment: null,
  updateAssignedStatus: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getDashboardData = createAsyncThunk(
  "corporateDashboardData/getDashboardData",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await corporateDashboardData.getDashboardData(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTenantDetail = createAsyncThunk(
  "corporateDashboardData/getTenantDetail",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await corporateDashboardData.getTenantDetail(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssignedTaskDashboard = createAsyncThunk(
  "corporateDashboardData/getAssignedTaskDashboard",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await corporateDashboardData.getAssignedTaskDashboard(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssignedTaskDetail = createAsyncThunk(
  "corporateDashboardData/getAssignedTaskDetail",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await corporateDashboardData.getAssignedTaskDetail(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCommentByUser = createAsyncThunk(
  "corporateDashboardData/getCommentByUser",
  async (finalData, thunkAPI) => {
    try {
      return await corporateDashboardData.getCommentByUser(finalData);
    } catch (error) {
      // notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateAssignedTaskStatus = createAsyncThunk(
  "corporateDashboardData/updateAssignedTaskStatus",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await corporateDashboardData.updateAssignedTaskStatus(
        finalData
      );
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const saveComment = createAsyncThunk(
  "corporateDashboardData/saveComment",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await corporateDashboardData.saveComment(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssetsNearToExpireByName = createAsyncThunk(
  "corporateDashboardData/getAssetsNearToExpireByName",
  async (finalData, thunkAPI) => {
    try {
      return await corporateDashboardData.getAssetsNearToExpireByName(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssetByName = createAsyncThunk(
  "corporateDashboardData/getAssetByName",
  async (finalData, thunkAPI) => {
    try {
      return await corporateDashboardData.getAssetByName(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const SearchAssetsInByTenant = createAsyncThunk(
  "corporateDashboardData/SearchAssetsInByTenant",
  async (finalData, thunkAPI) => {
    try {
      return await corporateDashboardData.SearchAssetsInByTenant(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AssetTerminalValueCalulation = createAsyncThunk(
  "corporateDashboardData/AssetTerminalValueCalulation",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await corporateDashboardData.AssetTerminalValueCalulation(
        finalData
      );
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const SaveAssetTerminalValueCalulation = createAsyncThunk(
  "calculation/SaveAssetTerminalValueCalulation",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response =
        await corporateDashboardData.SaveAssetTerminalValueCalulation(
          finalData
        );
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const corporateDashboardSlice = createSlice({
  name: "corporateDashboardData",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getDashboardData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboardData = action.payload;
        state.assetsNearToExpireData = action.payload.assetsNearToExpire;
        state.availableAssetsForRentData = action.payload.availableAssets;
        console.log();
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getTenantDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTenantDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tenantDetailData = action.payload;
      })
      .addCase(getTenantDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssignedTaskDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignedTaskDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignedTask = action.payload;
      })
      .addCase(getAssignedTaskDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssignedTaskDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignedTaskDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignedTaskDetail = action.payload;
      })
      .addCase(getAssignedTaskDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getCommentByUser.pending, (state) => {
        state.isLoading = true;
        state.getComment = [];
      })
      .addCase(getCommentByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getComment = action.payload;
      })
      .addCase(getCommentByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateAssignedTaskStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAssignedTaskStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAssignedStatus = action.payload;
      })
      .addCase(updateAssignedTaskStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveComment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.comment = action.payload;
      })
      .addCase(saveComment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetsNearToExpireByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetsNearToExpireByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assetsNearToExpireData = action.payload;
      })
      .addCase(getAssetsNearToExpireByName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.availableAssetsForRentData = action.payload;
      })
      .addCase(getAssetByName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(SearchAssetsInByTenant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SearchAssetsInByTenant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tenantDetailData = action.payload;
      })
      .addCase(SearchAssetsInByTenant.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(AssetTerminalValueCalulation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AssetTerminalValueCalulation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.AssetTerminalValueCalulationData = action.payload;
      })
      .addCase(AssetTerminalValueCalulation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(SaveAssetTerminalValueCalulation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SaveAssetTerminalValueCalulation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAssetTerminalValue = action.payload;
      })
      .addCase(SaveAssetTerminalValueCalulation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = corporateDashboardSlice.actions;

export default corporateDashboardSlice.reducer;
