import PropTypes from "prop-types";
import { Modal } from 'antd'
import { CloseOutlined } from "@ant-design/icons";

export default function ImagePreview(props) {

    const { visible, handleCancel, setShowMap, image } = props;

    return (
        <Modal
            className="image-preview-modal"
            open={visible}
            handleCancel={handleCancel}
            onCancel={handleCancel}
            width={700}
            centered
            footer={null}
        >
            <div className="d-flex justify-content-end"><CloseOutlined onClick={() => setShowMap(false)} /></div>
            <img className="image-preview pt-3" src={image} alt="test" />
        </Modal>
    )
}


ImagePreview.propTypes = {
    visible: PropTypes.node.isRequired,
    handleCancel: PropTypes.node.isRequired,
    setShowMap: PropTypes.node.isRequired,
    image: PropTypes.string.isRequired,
};
