import { Typography, Row, Col, Input, Button } from "antd";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../components/Layout/Table/DataTable";
import PATH from "../../../utils/path";

function ProjectManagerUser() {
  const { Search } = Input;
  const { Text } = Typography;
  const navigate = useNavigate();
  const param = useParams();
  const { user } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.userManagement);
  const initialColState = [
    {
      id: 1,
      title: "UID",
      dataIndex: "userId",
      width: "10%",
    },
    {
      id: 2,
      hidden: false,
      title: "Date",
      dataIndex: "createdDate",
      width: "15%",
      render: (text) => moment(text).format("MMMM DD YYYY"),
    },
    {
      id: 3,
      hidden: false,
      title: "First Name",
      dataIndex: "firstName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Last Name",
      dataIndex: "lastName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 5,
      hidden: false,
      title: "Email",
      dataIndex: "email",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 10,
      hidden: user?.roleId === 2 ? "false" : "true",
      title: "Role",
      dataIndex: "roleName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 6,
      hidden: false,
      title: "Location",
      dataIndex: "address",
      render: (text) => (
        <Text
          style={{
            width: 170,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text !== null ? text : "N/A"}
        </Text>
      ),
    },
  ];
  const navigateRow = (record, rowIndex) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(
          PATH.USER_MANAGEMENT_USER_DETAIL.replace(":userId", record?.userId)
        ); // to avoid es lint error
        console.log("record", record, rowIndex, path); // to avoid es lint error
      }, // click row
    };
  };
  return (
    <div className="user-management">
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col xl={5} lg={5} md={12} sm={12} xs={24}>
          <div className="table-footer-btn d-flex align-items-center justify-content-start">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
              <Button
                className="btn-action d-flex justify-content-center align-items-center asset-btn"
                onClick={() =>
                  navigate(
                    PATH.USER_MANAGEMENT_USER_DETAIL.replace(
                      ":userId",
                      param?.userId
                    )
                  )
                }
              >
                <div
                  className="active-action"
                  // style={{
                  //   backgroundColor: active === true ? "#f2f3f5" : " ",
                  // }}
                >
                  <div
                    className="active-action d-flex align-items-center"
                    //   style={{
                    //     Color: active === true ? "#000" : "#eee",
                    //   }}
                  >
                    {/* <BsFillCircleFill size={10} fill="#2978A0" /> */}
                    <h4 className="mb-0 ml-2 text-black">Corporate</h4>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </Col>
        <Col
          xl={7}
          lg={7}
          md={12}
          sm={12}
          xs={24}
          className="mt-2 mt-xs-2 mt-lg-0 w-100"
        >
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              // onChange={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              // onChange={handleSearch}
              className="mr-2"
              placeholder="Search a User"
            />
            {/* <span
            onClick={() => setIsModalOpen(true)}
            className="filter-icon-span mr-2 cursor-pointer"
          >
            <FaFilter />
          </span>  */}
            {/* <Dropdown
            overlay={hideTableColumns}
            trigger={["click"]}
            onOpenChange={handleOpenChange}
            open={open}
          >
            <span className="filter-icon-span cursor-pointer">
              <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
            </span>
        </Dropdown> */}
          </div>
        </Col>
      </Row>
      <div>
        <DataTable
          columns={initialColState.filter((item) => item.hidden === false)}
          dataSource={users?.filter(
            (item) => item.roleName === "ProjectManager"
          )}
          pagination="true"
          // pagination={{
          //   current: userFilter?.pagination?.pageNo || 1,
          //   pageSize: 10,
          //   total: users?.[0]?.totalRecords,
          //   showSizeChanger: false,
          //   disabled: false,
          //   onChange: (page, pageSize) => {
          //     setUserFilter({
          //       ...userFilter,
          //       pagination: {
          //         pageNo: page,
          //         size: pageSize,
          //       },
          //     });
          //   },
          // }}
          className="antd-table-striped"
          // loading={isLoading}
          // rowSelection={rowSelection}
          rowKey={(record) => record}
          onRow={navigateRow}
          scroll={{
            x: 1000,
            y: "calc(100vh - 355px)",
          }}
        />
      </div>
      {/* {users?.length > 0 && ( */}

      <div className="table-count">
        <h5 className="table-count-content">
          {/* Showing {pageNumbers} to {nextPageNumber} of{" "}
            {users?.[0]?.totalRecords} entries */}
          Showing 1 to 10 of 18 entries
        </h5>
      </div>

      {/* <Row gutter={24}>
          <Col
            xs={24}
            style={{
              padding: 0,
            }}
          >
            <div className="table-footer-btn d-flex align-items-center">
              <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                <Button
                  className="btn-action d-flex justify-content-center align-items-center text-danger"
                  onClick={() => setDeleteUserModal(true)}
                  disabled={selectedRowKeys?.length === 0 ? "true" : false}
                >
                  <div className="active-action d-flex align-items-center">
                    <MdDelete />
                    <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                  </div>
                </Button>
                {selectedRowKeys?.length > 1 ? (
                  ""
                ) : (
                  <>
                    <Divider type="vertical" />
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center"
                      onClick={() => {
                        setFlag(true);
                        setAddUpdateUserModal(true);
                      }}
                      disabled={
                        selectedRowKeys?.length === 0 ? "true" : false
                      }
                    >
                      <div className="active-action d-flex align-items-center">
                        <MdModeEdit className="mt-1" />
                        <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                      </div>
                    </Button>
                  </>
                )}
              </div>

              {/* <Button className="btn-cancel py-2">
            <h5>Cancel</h5>
          </Button> */}
      {/* </div>
          </Col>
        </Row>  */}
      {/* )} */}
      {/* <SearchForm
      visible={isModalOpen}
      handleCancel={closeModal}
      setCreatedDateValue={setCreatedDateValue}
      setEmailValue={setEmailValue}
      setLocationValue={setLocationValue}
      setSearchValue={setSearchValue}
      setIsModalOpen={setIsModalOpen}
      form={form}
    /> */}
      {/* 
    <CreateUpdate
      visible={addUpdateUserModal}
      handleCancel={closeAddUpdateUserModal}
      selectedRowKeys={selectedRowKeys}
      isLoaded={isLoading}
      setSelectedRowKeys={setSelectedRowKeys}
      flag={flag}
      setFlag={setFlag}
      userFilter={userFilter}
    />

    <DeleteUserModal
      visible={deleteUserModal}
      handleCancel={closeDeleteUserModal}
      onSubmit={deleteUser}
      isLoaded={isLoading}
      user="User"
    /> */}
    </div>
  );
}

export default ProjectManagerUser;
