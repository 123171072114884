import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import { AiOutlineDelete } from "react-icons/ai";

function DeleteConfirmation(props) {
  const { visible, handleCancel, user } = props;
  return (
    <div className="addNewProjectComponent">
      <Modal
        open={visible}
        handleCancel={handleCancel}
        onCancel={handleCancel}
        footer={false}
        centered
        className="delete-model"
      >
        <div className="user-management-delete-modal">
          <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
            <AiOutlineDelete size={24} style={{ color: "#FC0606" }} />
            <div>
              <h4 style={{ fontWeight: 600, color: "#1F1F1F" }}>
                Delete Project
              </h4>
              <h5 style={{ color: "#1F1F1F" }}>
                Are you sure you want to delete this {user}?
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Button className="btn-cancel" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button className="btn-danger">Delete</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteConfirmation;
DeleteConfirmation.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  user: PropTypes.node.isRequired,
};
