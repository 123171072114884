/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projects from "./project.service";

const initialState = {
  project: null,
  projects: null,
  createProject: null,
  assignProjects: null,
  projectManagers: [],
  archiveProject: null,
  getArchivedProjects: null,
  completeProject: null,
  getCompletedProjects: null,
  createProjectSuccess: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createOrUpdateProject = createAsyncThunk(
  "projects/createProject",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      let response;
      if(finalData.id === null || finalData.id === undefined )
      {
        response = await projects.createProject(finalData);  
      }else{
        response = await projects.updateProject(finalData);
      }
      
      if (response.status === 200) {
        if (notifyToaster) {
          notifyToaster("Project created", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster("Request failed! Response Status: "+response.status, "error");
      }
      response.isEdit = finalData.id !== 0;
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllProjectList = createAsyncThunk(
  "projects/getAllProjectList",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllProjectList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleProject = createAsyncThunk(
  "projects/getSingleProject",
  async ({finalData}, thunkAPI) => {
    try {
      return await projects.getSingleProject(finalData.projectId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const getAllProjectManager = createAsyncThunk(
//   "projects/getAllProjectManager",
//   async (finalData, thunkAPI) => {
//     try {
//       return await projects.getAllProjectManager(finalData);
//     } catch (error) {
//       notifyToaster(error.message, "error");
//       const message =
//         (error.response &&
//           error.response.detail &&
//           error.response.detail) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async ({ finalData, moveToNextDeleteProject, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.deleteProjects(finalData);
      if (response.code) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDeleteProject) {
          moveToNextDeleteProject();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const archiveProject = createAsyncThunk(
  "projects/archiveProject",
  async ({ finalData, moveToNextArchived, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.archiveProject(finalData);
      if (response.archived === true) {
        if (notifyToaster) {
          notifyToaster("Project archived", "success");
        }
        if (moveToNextArchived) {
          moveToNextArchived();
        }
      } else {
        notifyToaster("Failed archive function", "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllArchivedProjects = createAsyncThunk(
  "projects/getAllArchivedProjects",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllArchivedProjects(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const completeProject = createAsyncThunk(
  "projects/completeProject",
  async ({ finalData, moveToNextArchived, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.completeProject(finalData);
      if (response.completed === true) {
        if (notifyToaster) {
          notifyToaster("Project completed", "success");
        }
        if (moveToNextArchived) {
          moveToNextArchived();
        }
      } else {
        notifyToaster("Project completion failed", "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCompletedProjects = createAsyncThunk(
  "projects/getAllCompletedProjects",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllCompletedProjects(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assignProjectsToUser = createAsyncThunk(
  "projects/assignProjectsToUser",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.assignProjectsToUser(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.detail &&
          error.response.detail) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrUpdateProject.fulfilled, (state, action) => {
        if(state.projects)
        {
          let projectPage = JSON.parse(JSON.stringify(state.projects));
          let updateProjectList = projectPage.content;
          
          if (action.payload.isEdit) {
            for (let i = 0; i < updateProjectList.length; i++) {
              if (
                  updateProjectList[i].id === action.payload.data.id
              ) {
                updateProjectList[i] = action.payload.data;
                break;
              }
            }
          } else {
            updateProjectList.unshift(action.payload.data);
          }
          projectPage.content = updateProjectList;
          state.isLoading = false;
          state.isSuccess = true;
          state.projects = projectPage;  
        }
      })
      .addCase(createOrUpdateProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllProjectList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getAllProjectList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getSingleProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.project = action.payload;
      })
      .addCase(getSingleProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const deleteMultipleProjects = state.projects.content.filter(
          (item) => item.projectId !== action.payload
        );
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = deleteMultipleProjects;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      // .addCase(getAllProjectManager.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(getAllProjectManager.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.projectManagers = action.payload;
      // })
      // .addCase(getAllProjectManager.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.statusMessage = action.payload;
      // })
      .addCase(archiveProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.archiveProject = action.payload;
      })
      .addCase(archiveProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllArchivedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllArchivedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getArchivedProjects = action.payload;
      })
      .addCase(getAllArchivedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(completeProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.completeProject = action.payload;
      })
      .addCase(completeProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllCompletedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCompletedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getCompletedProjects = action.payload;
      })
      .addCase(getAllCompletedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(assignProjectsToUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignProjectsToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignProjects = action.payload;
      })
      .addCase(assignProjectsToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createProjectSlice.actions;

export default createProjectSlice.reducer;
