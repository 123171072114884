/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * ProjectControllerApi - axios parameter creator
 * @export
 */
export const ProjectControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: (project, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'project' is not null or undefined
            assertParamExists('addProject', 'project', project);
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId);
            const localVarPath = `/api/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteProjects', 'requestBody', requestBody);
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId);
            const localVarPath = `/api/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProjects: (search, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchProjects', 'search', search);
            const localVarPath = `/api/projects/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: (projectId, project, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId);
            // verify required parameter 'project' is not null or undefined
            assertParamExists('updateProject', 'project', project);
            const localVarPath = `/api/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = ProjectControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(project, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addProject(project, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteProject(projectId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects(requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteProjects(requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllProjects(page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProject(projectId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProjects(search, page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchProjects(search, page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, project, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateProject(projectId, project, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ProjectControllerApiFp(configuration);
    return {
        /**
         *
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(project, options) {
            return localVarFp.addProject(project, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId, options) {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects(requestBody, options) {
            return localVarFp.deleteProjects(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page, size, sort, options) {
            return localVarFp.getAllProjects(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId, options) {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProjects(search, page, size, sort, options) {
            return localVarFp.searchProjects(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {Project} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, project, options) {
            return localVarFp.updateProject(projectId, project, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI {
    /**
     *
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    addProject(project, options) {
        return ProjectControllerApiFp(this.configuration).addProject(project, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    deleteProject(projectId, options) {
        return ProjectControllerApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    deleteProjects(requestBody, options) {
        return ProjectControllerApiFp(this.configuration).deleteProjects(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    getAllProjects(page, size, sort, options) {
        return ProjectControllerApiFp(this.configuration).getAllProjects(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    getProject(projectId, options) {
        return ProjectControllerApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Search} search
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    searchProjects(search, page, size, sort, options) {
        return ProjectControllerApiFp(this.configuration).searchProjects(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    updateProject(projectId, project, options) {
        return ProjectControllerApiFp(this.configuration).updateProject(projectId, project, options).then((request) => request(this.axios, this.basePath));
    }
}
