import { useState } from "react";
import { BiFilter } from "react-icons/bi";
import { Card, Select } from "antd";
import ReactApexChart from "react-apexcharts";
import AllAssetsModal from "./AllAssetsModal";

const { Option } = Select;

export default function GrossIncomeAndCost() {

  const [allAssets, setAllAssets] = useState(false);

  const data = {
    series: [
      {
        name: "Gross income",
        data: [500, 600, 1200, 400, 550, 750, 330, 530, 500, 1200, 900, 750],
        type: "column",
      },
      {
        name: "Cost",
        data: [400, 550, 750, 330, 530, 500, 600, 700, 500, 550, 750, 550],
        type: "column",
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        type: "line",
      },
      title: {
        text: 'Income & Cost Analysis',
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#263238'
        },
        offsetY: 12
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#5CDB5C", "#FF5454"],
      stroke: {
        show: true,
        width: [3, 3],
        colors: '#fff',
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        axisBorder: {
          show: true,
          color: '#000000'
        },
      },
      yaxis: [
        {
          show: true,
          labels: {
            formatter: (value) => `€ ${value}k`,
          },
          axisBorder: {
            show: true,
            color: '#000000'
          },
        },
      ],
      legend: {
        show: true,
        horizontalAlign: 'center',
        position: "top",
        markers: {
          radius: 10,
          shape: "circle",
          // width: [15, 15],
          // height: [15, 15],
        },
        offsetX: 0,
        offsetY: -27,
      },
      responsive: [{
        breakpoint: 1415,
        options: {
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0
          }
        }
      }],
    },
  };

  const closeAllAssets = () => {
    setAllAssets(false);
  }
  return (
    <div>
      <Card>
        <div className="income-cost-filter d-flex align-items-center">
          <Select
            size="small"
            // defaultValue="2022"
            mode="multiple"
            style={{
              width: 155,
            }}
            placeholder="Select Year"
          >
            <Option value="2022">2022</Option>
            <Option value="2021">2021</Option>
            <Option value="2020">2020</Option>
          </Select>
          <BiFilter
            onClick={() => setAllAssets(true)}
            className="ml-2 p-1 rounded-sm cursor-pointer filter-hover"
            size={36}
          />
          <hr />
        </div>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="line"
          height={300}
        />
      </Card>
      <AllAssetsModal visible={allAssets} handleCancel={closeAllAssets} />
    </div>
  );
}