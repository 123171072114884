import {BrowserRouter, createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom";
import RouterConfig from "./Navigation/RouterConfig";

function App() {
    const router = RouterConfig();
    
  return (
        <RouterProvider router={router}/>
  );
}

export default App;
