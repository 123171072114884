import { Button, Card, Checkbox, Col, DatePicker, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getByTypeDropdown } from "../../../../../../../../app/features/Lookups/lookup.slice";
import { defaultNotifyToaster } from "../../../../../../../../utils/helpers";
import { I18n } from "reia-rest-client";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import {
    getFirstAndLastAnalysisDate,
    getMaxDurationForPortfolio,
    getMinDurationForPortfolio
} from "../../../../../../../../utils/portfolioHelper";
import moment from "moment/moment";
import {getFormattedDecimalNumber, getFormattedEnglishNumber} from "../../../../../../../../utils/digitHelper";

function VeAssetBaseRentType({showDrawer}) {
    const dispatch = useDispatch();
    const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess, calculationModified, dcfResultsModified, portfolioValueMismatched, autoSaveCalc, portfolioDCFParams } = useSelector((state) => state.portfolio);
    const { durationInMonths, analysisDate } = portfolioDCFParams;
    const { firstDate, lastDate } = getFirstAndLastAnalysisDate(calculationDetailsByPortfolio?.calculationDetailDtos);

    const setAssetDCFParams = (params) => {
        dispatch({ type: "portfolio/setPortfolioDCFParams", payload: params })
    }

    return (
        <Card bordered={false} className="assets_card">
            <h3 className="text-black">Holding Period</h3>
            {/* <hr className="mt-0 border-top-0 border-2" /> */}

            <Row className="pt-2">
                <Col sx={24} md={6} className="pr-2">
                    <h4 className="text-black">Holding Period</h4>
                    <IncDecCounter min={1} max={30} incDecStep={1} value={durationInMonths / 12} label={"Years"}
                                   formatter={getFormattedEnglishNumber}
                        setIncDecValue={(newValue) => setAssetDCFParams({ durationInMonths: newValue * 12 })}
                        className="w-100" />
                    Min: {getMinDurationForPortfolio(calculationDetailsByPortfolio?.calculationDetailDtos)}
                    &nbsp;-&nbsp;
                    Max: {getMaxDurationForPortfolio(calculationDetailsByPortfolio?.calculationDetailDtos)}
                </Col>
                <Col sx={24} md={showDrawer?11:9} className="pr-2">
                    <h4 className="text-black">Analysis Date</h4>
                    <DatePicker
                        format="MM/DD/YYYY"
                        defaultValue={analysisDate ? moment(analysisDate) : ""
                        }
                        picker="date"
                        className="w-100"
                        onChange={(newValue) => { setAssetDCFParams({ analysisDate: newValue }) }}

                    />
                    FirstDate: {firstDate.format("DD.MM.YYYY")}
                    &nbsp;-&nbsp;
                    LastDate: {lastDate.format("DD.MM.YYYY")}
                </Col>
            </Row>
        </Card>)

}

export default VeAssetBaseRentType;