import { Col, DatePicker, Form, Input, Row, Select, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountry, getAllState, getByTypeDropdown } from "../../../../../../../app/features/Lookups/lookup.slice";
import dayjs from "dayjs";
const { TextArea } = Input;
export const EditAssetSection = () => {

    const dispatch = useDispatch();
    const { assetTypes } = useSelector((state) => state.lookUps);


    function notifyToaster(data, condition) {
        notification.config({
            maxCount: 1,
        });
        if (condition === "success") {
            notification.success({
                message: data,
            });
        } else {
            notification.error({
                message: data,
            });
        }
    }
    //**********************
    const [countryIdData, setCountryIdData] = useState();
    const [rowRecord, setRowRecord] = useState("");
    const [stateId, setStateId] = useState();
    const { getPropertyAddress } = useSelector(
        (state) => state.projectDetails
    );
    const { country, states } = useSelector((state) => state.lookUps);

    useEffect(() => {
        dispatch(getAllCountry());
    }, [dispatch]);

    useEffect(() => {
        const i18nItem = country?.find((item: I18n) => item.id === rowRecord?.address?.country?.id);
        dispatch(getAllState(i18nItem));
    }, [dispatch, rowRecord]);
    
    const getStates = (e) => {
        const i18nItem = country?.find((item: I18n) => item.id === e);
        dispatch(getAllState(i18nItem));
    };
    
    //**********************
    const { developmentStatus } = useSelector((state) => state.lookUps);
    //**********************
    const { propertyRating } = useSelector((state) => state.lookUps);
    //**********************
    const { priorityClass, riskClass } = useSelector((state) => state.lookUps);
    //**********************
    const { ownershipTypes } = useSelector((state) => state.lookUps);
    //**********************
    const { inspectionTypes } = useSelector((state) => state.lookUps);
    //**********************
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "assetTypes",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "developmentStatus",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "propertyRating",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "riskClass",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "priorityClass",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "ownershipTypes"
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "inspectionTypes",
        };
        dispatch(getByTypeDropdown({ finalData, notifyToaster }));
    }, [dispatch]);
    return (
        <>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Basic Data </h4>
            <Row gutter={24}>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Asset Name</span>}
                        name="assetName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter asset name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Asset Type</span>}
                        name="assetType"
                        rules={[
                            {
                                required: true,
                                message: 'Please select asset type!',
                            },
                        ]}
                    >
                        <Select placeholder="Select" allowClear >
                            {assetTypes?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Analysis Date</span>}
                        name="analysisDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select analysis date!',
                            },
                        ]}
                    >
                        <DatePicker
                            className="w-100 rounded-sm"
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Property Address</h4>
            <Row gutter={24}>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Country</span>}
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please select country!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select"
                            allowClear
                            value={countryIdData}
                            onChange={(e) => {
                                setCountryIdData(e);
                                getStates(e);
                            }}
                        >
                            {country?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>State(Federal)</span>}
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please select state!',
                            },
                        ]}
                    >
                        <Select placeholder="Select"
                            allowClear
                            value={stateId}
                            onChange={(e) => {
                                setStateId(e);
                            }}
                        >
                            {states?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>City</span>}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: 'Please select city!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Zip Code</span>}
                        name="zipCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter zip code!',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Street</span>}
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter street no!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>House No#</span>}
                        name="houseNo"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter house no!',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Property Attributes</h4>
            <Row gutter={24}>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Development Status</span>}
                        name="developmentStatus"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select development status!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {developmentStatus?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Year of Construction</span>}
                        name="yearOfConstruction"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select year of construction!',
                    //     },
                    // ]}
                    >
                        <DatePicker
                            className="w-100 rounded-sm"
                            format="YYYY"
                            picker="year"
                        />
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Year of Refurbishment</span>}
                        name="yearOfRefurbishment"
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'Please select year of refurbishment!',
                            // },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    console.log(dayjs(value).format('YYYY'), 'values of refurbishment')
                                    const yearOfConstruction = getFieldValue('yearOfConstruction');
                                    if (dayjs(value).format('YYYY')?.includes("Invalid") || !yearOfConstruction || !value) {
                                        return Promise.resolve();
                                    }
                                    return moment(value).isAfter(yearOfConstruction, 'year')
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Year of refurbishment must be greater than year of construction!'));
                                },
                                dependencies: ['yearOfConstruction'], // Triggers re-validation when 'yearOfConstruction' changes
                            }),
                        ]}
                    >
                        <DatePicker
                            className="w-100 rounded-sm"
                            format="YYYY"
                            picker="year"
                        />
                    </Form.Item>
                </Col>
            </Row >
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Property Rating</h4>
            <Row gutter={24}>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Location ( 1 to 5 )</span>}
                        name="locationRating"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select location rating!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {propertyRating?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Situation ( 1 to 5 )</span>}
                        name="situationRating"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select situation rating!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {propertyRating?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Building ( 1 to 5 )</span>}
                        name="buildingRating"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select building rating!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {propertyRating?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Tenancy ( 1 to 5 )</span>}
                        name="tenancyRating"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select tenancy rating!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {propertyRating?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Investment Strategy</h4>
            <Row gutter={24}>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Risk Class</span>}
                        name="riskClass"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select risk class!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {riskClass?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Upside Potential</span>}
                        name="upsidePriority"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select upside potential!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {priorityClass?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Capex Requirement</span>}
                        name="capexPriority"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select capex requirement!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {priorityClass?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Asset Management Requirement</span>}
                        name="assetManagementPriority"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select asset management requirement!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {priorityClass?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Expected Liquidity</span>}
                        name="exLiquidityPriority"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select expected liquidity!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {priorityClass?.map((item: I18n) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.translations.en}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Site</h4>
            <Row gutter={24}>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Ownership</span>}
                        name="ownershipType"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select ownership!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {ownershipTypes?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Section II</span>}
                        name="sectionii"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter sectionii !',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Total Site Area</span>}
                        name="totalSiteArea"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter total site area!',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Inspection</h4>
            <Row gutter={24}>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Type</span>}
                        name="type"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select type!',
                    //     },
                    // ]}
                    >
                        <Select placeholder="Select" allowClear>
                            {inspectionTypes?.map((item: I18n) => (
                                <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Date</span>}
                        name="inspectionDate"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please select inspection date!',
                    //     },
                    // ]}
                    >
                        <DatePicker
                            className="w-100 rounded-sm"
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Notes</span>}
                        name="notes"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter notes!',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>ESG</h4>
            <Row gutter={24}>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Selection</span>}
                        name="selection"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter selection!',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={16} xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>ESG Comments</span>}
                        name="esgComments"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter esg comments!',
                    //     },
                    // ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <h4 style={{ color: '#01215D', fontWeight: 700, paddingBottom: '12px' }}>Other</h4>
            <Row gutter={24}>
                <Col xs={24}>
                    <Form.Item
                        label={<span style={{ color: '#000' }}>Comments</span>}
                        name="otherComments"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Please enter comments!',
                    //     },
                    // ]}
                    >
                        <TextArea rows={4} placeholder="Add comments" maxLength={6} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}