import { Asset, DCFInput, OtherCF, RentRoll } from "reia-dcf-client";
import moment from "moment";

import {
    CalculationDetailDto, DCFParameters,
    DCFResult,
    OtherIncomeCost,
    RentRollAssumptions,
    RentRollDto
} from "reia-rest-client";
import { getFormattedInt, getFormattedEnglishNumber } from "./digitHelper";
import { DCFOutput } from "reia-dcf-client";
import {
    DCF_FINANCING_AMORTIZATION,
    DCF_FINANCING_INTEREST_RATE,
    DCF_FINANCING_LTV,
    DCF_FINANCING_PREPAYMENT_FEE,
    DCF_FINANCING_SWAP_RATE,
    DCF_FINANCING_TARGET_VALUE,
    DCF_FINANCING_TYPE_I18N_KEY,
    DCF_RENT_ROLL_HVL_AREA_UNIT,
    DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM,
    DCF_RENT_ROLL_HVL_MARKET_RENT_SQM, DCF_RENT_ROLL_HVL_OCCUPIED_AREA,
    DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED
} from "./dcfDefaults";
import { Moment } from "moment";
import dayjs from "dayjs";
import {
    getAgentCostsExitOrDefault,
    getAgentCostsOrDefault,
    getBaseRentTypeOrDefault,
    getCapRateOrDefault,
    getDiscountRateOrDefault,
    getDurationInMonthsOrDefault,
    getERVGrowthPrefilledWithDefault,
    getHVLRentRollDefaults,
    getInflationPrefilledWithDefault,
    getLandtransferTaxExitOrDefault,
    getLandtransferTaxOrDefault,
    getNotaryCostsExitOrDefault,
    getNotaryCostsOrDefault, getRentRollAgentCosts,
    getRentRollContinuingRenewalProb,
    getRentRollIndexationType,
    getRentRollInitialRenewalProb,
    getRentRollLeaseDurationEnd,
    getRentRollLeaseDurationStart,
    getRentRollMarketRentMonthly,
    getRentRollNewLeases,
    getRentRollNonRecCostReletMaint,
    getRentRollNonRecCostReletMgmt,
    getRentRollNonRecCostReletOther,
    getRentRollNonRecCostTermMaint,
    getRentRollNonRecCostTermMgmt,
    getRentRollNonRecCostTermOther,
    getRentRollOpenEnded,
    getRentRollOption1,
    getRentRollOption2, getRentRollTenantImprovementContinuing,
    getRentRollTenantImprovementInitial,
    getRentRollVacancyCosts,
    getRentRollVoidPeriodContinuing,
    getRentRollVoidPeriodInitial,
    getSyncWithInflationOrDefault,
    getTerminalRentTypeOrDefault
} from "./dcfParamsHelper";
import {DCFHVLParameters} from "reia-rest-client";


export const getDCFInput = (assetId: string, analysisDate: string, assetDCFParams: any, assetHVLParams: any, rentRolls: RentRollDto[], rentRollAssumptions: RentRollAssumptions, indicesValues: Object, dcfResult: DCFOutput, landTaxes: {},acquisitionCostsDefaults: {}, federalState: string, assetTypesCapRatesDefaults: {}, useTypesCostsDefaults: {}, assetType: string ): DCFInput => {
    const validatedAnalysisDate: Moment = getStartingDate(analysisDate)
    const dcfAsset: Asset = getDCFAsset(assetId, validatedAnalysisDate, assetDCFParams, dcfResult, landTaxes , acquisitionCostsDefaults, federalState, assetTypesCapRatesDefaults, assetType);
    let dcfRentRolls: RentRoll[];
    if (rentRolls?.length > 0) {
        dcfRentRolls = rentRolls.map(rentRoll => {
            let rentRollItem = rentRoll;
            if (rentRollAssumptions.hasOwnProperty(rentRoll.useType.id)) {
                rentRollItem = overrideAssumptionValues(rentRoll, rentRollAssumptions[rentRoll.useType.id])
            }
            const cpiValue = indicesValues ? indicesValues[getCpiType(rentRollItem.cpiType?.key)] : undefined
            return getDCFRentRoll(rentRollItem, cpiValue, validatedAnalysisDate, assetType, assetTypesCapRatesDefaults, useTypesCostsDefaults)
        })
    } else {
        dcfRentRolls = getHVLDCFRentRolls(validatedAnalysisDate, assetHVLParams, indicesValues)
    }
    const otherCFs: OtherCF[] = getOtherDCFs(assetDCFParams);

    return { dcf_id: "ignore", asset: dcfAsset, rentRolls: dcfRentRolls, otherCFs: otherCFs }
}

const overrideAssumptionValues = (rentRoll: RentRollDto, rentRollAssumption: any) => {
    const updatedRentRoll: RentRollDto = JSON.parse(JSON.stringify(rentRoll));
    Object.keys(rentRollAssumption).forEach(paraName => {
        const rentRollParamName = paraName.replace("Avg", "");
        updatedRentRoll[rentRollParamName] = rentRollAssumption[paraName];
        if (paraName === "marketRentPerSqmAvg") {
            updatedRentRoll.marketRentPerMonth = updatedRentRoll.marketRentPerSqm * updatedRentRoll.rentalSpace
        }
    })
    return updatedRentRoll;
}

const getDCFAsset = (assetId: string, validatedAnalysisDate: Moment, assetDCFParams: DCFParameters, dcfResult: DCFOutput, landTaxes , acquisitionCostsDefaults, federalState, assetTypesCapRatesDefaults: {}, assetType: string): Asset => {
    const dcfAsset: Asset = {
        "assetId": "" + assetId,
        "analysisDate": validatedAnalysisDate?.format("YYYY-MM-DD"),
        "durationInMonths": getDurationInMonthsOrDefault(assetDCFParams),
        "inflation": getInflationPrefilledWithDefault(assetDCFParams, validatedAnalysisDate),
        "ervGrowth": getERVGrowthPrefilledWithDefault(assetDCFParams, validatedAnalysisDate),
        "ervGrowthType": getSyncWithInflationOrDefault(assetDCFParams) ? 1 : 2,
        "discountRate": getMonthlyPercentageAsDecimal(getDiscountRateOrDefault(assetDCFParams, assetTypesCapRatesDefaults, assetType)),
        "capRate": getCapRateOrDefault(assetDCFParams, assetTypesCapRatesDefaults, assetType)/100/12,
        "baseRent": getBaseRentTypeFromI18nKey(getBaseRentTypeOrDefault(assetDCFParams)),
        "terminalValueType": getTerminalRentTypeFromI18nKey(getTerminalRentTypeOrDefault(assetDCFParams)),
        "landTransferTax": getLandtransferTaxOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "agentCosts_Sale": getAgentCostsOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "notaryCosts": getNotaryCostsOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "landTransferTaxExit": getLandtransferTaxExitOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "agentCostsExit": getAgentCostsExitOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "notaryCostsExit": getNotaryCostsExitOrDefault(assetDCFParams,landTaxes,acquisitionCostsDefaults,federalState, dcfResult?.assetCashFlow?.netAssetValue)/100,
        "individualRent": assetDCFParams.individualRent ? assetDCFParams.individualRent : 0,

    }

    if (assetDCFParams?.triggerFinancingCosts) {
        dcfAsset.financing_Type = getFinancingType(assetDCFParams?.financingType?.key);
        dcfAsset.financing_LTV = getFinancingLTV(assetDCFParams, dcfResult) / 100;
        dcfAsset.financing_Loan = assetDCFParams?.financingLoan ? assetDCFParams?.financingLoan : undefined;
        dcfAsset.financing_Equity = assetDCFParams?.financingEquity ? assetDCFParams?.financingEquity : undefined;
        dcfAsset.financing_Armortization = getFinancingAmortization(assetDCFParams,dcfAsset);
        dcfAsset.financing_TargetValue = dcfAsset.financing_Type !== 2 ? 0 : assetDCFParams.financingTargetValue ? assetDCFParams.financingTargetValue : DCF_FINANCING_TARGET_VALUE;
        dcfAsset.financing_PrepaymentFee = assetDCFParams.financingPrepaymentFee ? assetDCFParams.financingPrepaymentFee : DCF_FINANCING_PREPAYMENT_FEE;
        dcfAsset.financing_AllIntestRate = getFinancingAllInterestRate(assetDCFParams, dcfResult)
    }

    return dcfAsset;
}

export const getMonthlyPercentageAsDecimal = (percent: number) => {
    return Math.pow(1 + (percent / 100), 1 / 12) - 1
}

const getMonthlyPercentageAsPercentage = (percent: number) => {
    return getMonthlyPercentageAsDecimal(percent) * 100
}

export const compareAssetValue = (calculationDetail: CalculationDetailDto, assetDCFResult: DCFResult) => {

    return Math.round(calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue) === Math.round(assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue)
}


export const deepObjectAsStringCompare = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2); // true
}

const getDCFRentRoll: RentRoll = (rentRollDto: RentRollDto, cpiSelected: number, validatedAnalysisDate: Moment, assetType: string, assetTypesCapRatesDefaults: {}, useTypesBasedDefaults: {}): RentRoll => {

    const useType =  rentRollDto.useType.translations.en
    
    const rentRoll: RentRoll = {
        "rentRollId": "" + rentRollDto.id,
        "status": getRentRollStatusNumber(rentRollDto.rentRollStatusType?.key),
        "use": rentRollDto.useType.key,
        "areaUnit": rentRollDto.rentalSpace,
        "currentRent": rentRollDto.currentRentPerMonth && rentRollDto.currentRentPerMonth > 0 ? rentRollDto.currentRentPerMonth : 0.000000000000001, //Todo: calc api do not accept 0
        "leaseStart": getRentRollLeaseDurationStart(rentRollDto,validatedAnalysisDate),
        "leaseEnd": getRentRollLeaseDurationEnd(rentRollDto,validatedAnalysisDate, assetType, assetTypesCapRatesDefaults),
        "option1": getRentRollOption1(rentRollDto),
        "option2": getRentRollOption2(rentRollDto),
        "initialRenewalProbability": getRentRollInitialRenewalProb(rentRollDto) / 100,
        "continuingRenewalProbability": getRentRollContinuingRenewalProb(rentRollDto) / 100,
        "leaseDurationOpenEnd": getRentRollOpenEnded(rentRollDto,assetType,assetTypesCapRatesDefaults),
        "leaseDurationNewLeases": getRentRollNewLeases(rentRollDto,assetType,assetTypesCapRatesDefaults),
        "marketRent": getRentRollMarketRentMonthly(rentRollDto),
        "maintenance_Term": getRentRollNonRecCostTermMaint(rentRollDto,useTypesBasedDefaults),
        "management_Term": getRentRollNonRecCostTermMgmt(rentRollDto,useTypesBasedDefaults) / 100,
        "otherCosts_Term": getRentRollNonRecCostTermOther(rentRollDto,useTypesBasedDefaults) / 100,
        "maintenance_Reletting": getRentRollNonRecCostReletMaint(rentRollDto,useTypesBasedDefaults),
        "management_Reletting": getRentRollNonRecCostReletMgmt(rentRollDto,useTypesBasedDefaults) / 100,
        "otherCosts_Reletting": getRentRollNonRecCostReletOther(rentRollDto,useTypesBasedDefaults) / 100,
        "voidPeriodInitial": getRentRollVoidPeriodInitial(rentRollDto,useTypesBasedDefaults),
        "voidPeriodContinuing": getRentRollVoidPeriodContinuing(rentRollDto,useTypesBasedDefaults),
        "vacancyCosts": getRentRollVacancyCosts(rentRollDto,useTypesBasedDefaults),
        "initialTenantImprovements": getRentRollTenantImprovementInitial(rentRollDto,useTypesBasedDefaults),
        "continuingTenantImprovements": getRentRollTenantImprovementContinuing(rentRollDto,useTypesBasedDefaults),
        "agentCosts_Reletting": getRentRollAgentCosts(rentRollDto,useTypesBasedDefaults),
        "indexationType": getIndexationTypeNumber(getRentRollIndexationType(rentRollDto)),
        "cpiSelected": cpiSelected ? cpiSelected : undefined,
        "baseIndexValue": rentRollDto.indexationValue && rentRollDto.indexationValue > 0 ? rentRollDto.indexationValue : 1,
        "nextAdjustment": rentRollDto.indexationNextDate ? moment(rentRollDto.indexationNextDate)?.format("YYYY-MM-DD") : undefined,
        "indexationCycle": rentRollDto.indexationCycleInMonths ? rentRollDto.indexationCycleInMonths : undefined,
        "indexationHurdle": rentRollDto?.indexationHurdle ? rentRollDto.indexationHurdle : undefined,
        "indexationAdjustmentFactor": rentRollDto.indexationAdjustmentFactor ? rentRollDto.indexationAdjustmentFactor / 100 : undefined,
        "nextAdjustmmentCPI": rentRollDto.nextIndexValue && rentRollDto.nextIndexValue > 0 ? rentRollDto.nextIndexValue : 1
    }

    if (rentRollDto?.individualIndexations) {
        Object.entries(rentRollDto?.individualIndexations).forEach((entry, index) => {
            rentRoll["individualIndexationDate" + (index + 1)] = moment(entry[0])?.format("YYYY-MM-DD")
            rentRoll["individualIndexationRent" + (index + 1)] = entry[1]
        });
    }

    return rentRoll
}

const getHVLDCFRentRolls = (validatedAnalysisDate: Moment, assetHVLParams: DCFHVLParameters, indicesValues) => {
    const rentRolls = [];
    const defaultRentRoll = getHVLRentRollDefaults();
    
    const leaseEnd = assetHVLParams.leaseDuration ? validatedAnalysisDate.clone().set('year', validatedAnalysisDate.year() + assetHVLParams.leaseDuration) : validatedAnalysisDate.clone().set('year', validatedAnalysisDate.year() + DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12)
    const option1 = assetHVLParams.option1InYears ? leaseEnd.clone().set('year', leaseEnd.year() + assetHVLParams.option1InYears) : leaseEnd.clone().set('year', leaseEnd.year() + + DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12)
    const occupation = assetHVLParams.occupiedArea ? assetHVLParams.occupiedArea : DCF_RENT_ROLL_HVL_OCCUPIED_AREA
    const letAreaUnit = assetHVLParams.totalLettableArea ? assetHVLParams.totalLettableArea * occupation / 100 : DCF_RENT_ROLL_HVL_AREA_UNIT * occupation / 100
    const currentRentSQM = assetHVLParams.currentRentSQM ? assetHVLParams.currentRentSQM : DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM
    const marketRentSQM = assetHVLParams.marketRentSQM ? assetHVLParams.marketRentSQM : DCF_RENT_ROLL_HVL_MARKET_RENT_SQM

    const cpiValue = indicesValues ? indicesValues["cpi2020"] : undefined

    const tempRentRoll : RentRoll = {
        ...defaultRentRoll,
        "use": defaultRentRoll.use,
        "leaseStart": validatedAnalysisDate?.format("YYYY-MM-DD"),
        "leaseEnd": leaseEnd?.format("YYYY-MM-DD"),
        "option1": option1?.format("YYYY-MM-DD"),
        "initialRenewalProbability": assetHVLParams.renewalProbability ? assetHVLParams.renewalProbability / 100 : defaultRentRoll.initialRenewalProbability,
        "continuingRenewalProbability": assetHVLParams.renewalProbability ? assetHVLParams.renewalProbability / 100 : defaultRentRoll.initialRenewalProbability,
        "leaseDurationNewLeases": assetHVLParams.marketLease ? assetHVLParams.marketLease * 12 : defaultRentRoll.leaseDurationNewLeases,
        "otherCosts_Term": assetHVLParams.otherCosts ? assetHVLParams.otherCosts / 100 : defaultRentRoll.otherCosts_Term,
        "voidPeriodInitial": assetHVLParams.voidPeriodInitial ? assetHVLParams.voidPeriodInitial : defaultRentRoll.voidPeriodInitial,
        "voidPeriodContinuing": assetHVLParams.voidPeriodContinuing ? assetHVLParams.voidPeriodContinuing : defaultRentRoll.voidPeriodContinuing,
        "initialTenantImprovements": assetHVLParams.initialTenantImprovements ? assetHVLParams.initialTenantImprovements : defaultRentRoll.initialTenantImprovements,
        "continuingTenantImprovements": assetHVLParams.continuingTenantImprovements ? assetHVLParams.continuingTenantImprovements : defaultRentRoll.continuingTenantImprovements,
        "indexationType": assetHVLParams.indexationType && assetHVLParams.indexationType === "Ja" ? 3 : defaultRentRoll.indexationType,
        "nextAdjustment": validatedAnalysisDate.set('year', validatedAnalysisDate.year() + 1)?.format("YYYY-MM-DD"),
        "indexationAdjustmentFactor": assetHVLParams.indexationAdjustmentFactor ? assetHVLParams.indexationAdjustmentFactor / 100 : undefined,
        "cpiSelected": cpiValue,
        "baseIndexValue": cpiValue
    }
    
    if (occupation > 0) {
        const letRentRoll: RentRoll = JSON.parse(JSON.stringify(tempRentRoll))
        
        letRentRoll.rentRollId = "rentroll-let"
        letRentRoll.status = 1
        letRentRoll.areaUnit = letAreaUnit
        letRentRoll.currentRent = currentRentSQM * letAreaUnit
        letRentRoll.marketRent = marketRentSQM * letAreaUnit
        
        rentRolls.push(letRentRoll);
    }

    if (occupation < 100) {
        const vacantRentRoll: RentRoll = JSON.parse(JSON.stringify(tempRentRoll))
        const vacantArea = letAreaUnit * (1-occupation / 100)
        vacantRentRoll.rentRollId = "rentroll-vacant"
        vacantRentRoll.status = 0
        vacantRentRoll.areaUnit = vacantArea
        vacantRentRoll.currentRent = 0.000000001 //TODO: DCF Calc do not accept 0 Value!
        vacantRentRoll.marketRent = marketRentSQM * vacantArea
        
        rentRolls.push(vacantRentRoll);
    }
    return rentRolls;
}

const getOtherDCFs = (assetDCFParams: any): OtherCF[] => {
    let otherCFs: OtherCF[] = [];

    if (assetDCFParams.otherIncomeCosts) {
        otherCFs = assetDCFParams.otherIncomeCosts.map((item: OtherIncomeCost) => {

            const type: number = item.type ? item.type === "OPEX" ? 1 : 2 : 0;
            let cycle: number = 0;
            let endDate = item.end;

            switch (item.rotation) {

                case "ONETIME":
                    cycle = 1;
                    endDate = item.start;
                    break;
                case "MONTHLY": cycle = 2; break;
                case "QUARTER": cycle = 3; break;
                case "HALFYEARLY": cycle = 4; break;
                case "YEARLY": cycle = 5; break;

            }

            return {
                "description": item.description,
                "amount": item.value,
                "type": type,
                "cycle": cycle,
                "startDate": item.start,
                "endDate": endDate
            };
        });
    }

    return otherCFs;
}

const getFinancingType = (key: string): number => {
    switch (key) {
        case "core-data.financingTypes.amortization-loan": return 1
        case "core-data.financingTypes.annuity-loan": return 2
        case "core-data.financingTypes.bullet-loan": return 3
        default: return 3;
    }
}

export const getFinancingTypeByParams = (assetDCFParams: DCFParameters) => {
    return getFinancingType(assetDCFParams.financingType
        ? assetDCFParams.financingType.key
        : DCF_FINANCING_TYPE_I18N_KEY)
}

const getCpiType = (key: string): String => {
    switch (key) {
        case "core-data.cpiTypes.vpi-2015": return "cpi2015"
        case "core-data.cpiTypes.vpi-2020": return "cpi2020"
        case "core-data.cpiTypes.vpi-2025": return "cpi2025"

        default: return null;
    }
}

export const getIndexationTypeNumber = (key: string): number => {
    switch (key) {
        case "core-data.indexationTypes.none": return 1
        case "core-data.indexationTypes.fixed": return 2
        case "core-data.indexationTypes.cpi-period": return 3
        case "core-data.indexationTypes.cpi-hurdle-": return 4
        case "core-data.indexationTypes.cpi-hurdle-pts": return 5
        case "core-data.indexationTypes.individual": return 6

        //Default return none!
        default: return 1;
    }
}

export const getRentRollStatusNumber = (key: string): number => {
    switch (key) {
        case "core-data.rentRollStatusTypes.vacant": return 0
        case "core-data.rentRollStatusTypes.let": return 1

        default: return undefined;
    }
}

export const getTerminalRentTypeFromI18nKey = (key: string) => {
    switch (key) {
        case "core-data.terminalValueTypes.gross": return 1
        case "core-data.terminalValueTypes.net": return 2

        default: return undefined;
    }
}

export const getBaseRentTypeFromI18nKey = (key: string) => {
    switch (key) {
        case "core-data.baseRentTypes.potential-rent": return 2
        case "core-data.baseRentTypes.market-rent": return 1
        case "core-data.baseRentTypes.current-rent": return 3
        case "core-data.baseRentTypes.individual-rent": return 4

        default: return undefined;
    }
}

export const getPerSpaceValue = (value: number, assetHVLParams: any, rentRolls: RentRollDto[]) => {
    // return getFormattedInt(value/getTotalSpace(assetHVLParams, rentRolls));
    return getFormattedEnglishNumber(value / getTotalSpace(assetHVLParams, rentRolls));
}

export const getPerSpaceValueUnformatted = (value: number, assetHVLParams: any, rentRolls: RentRollDto[]) => {
    return value / getTotalSpace(assetHVLParams, rentRolls);
}

export const getTotalSpace = (assetHVLParams: any, rentRolls: RentRollDto[]) => {

    let totalSpace;
    if (rentRolls?.length > 0) {
        totalSpace = rentRolls.reduce((summedSpace, rentRoll) => summedSpace + rentRoll.rentalSpace, 0)
    } else {
        totalSpace = assetHVLParams.totalLettableArea ? assetHVLParams.totalLettableArea : DCF_RENT_ROLL_HVL_AREA_UNIT;
    }

    return totalSpace;
}

export const getNetGrossTerminalValue = (assetDCFParams: DCFParameters, dcfResult: DCFOutput, accCostPercentage) => {
    
    const terminalType: number = getTerminalRentTypeFromI18nKey(getTerminalRentTypeOrDefault(assetDCFParams));

    let netValue;
    let grossValue;
    if (terminalType === 1) {
        grossValue = dcfResult?.assetCashFlow?.terminalValue;
        netValue = grossValue * 100 / (100 + accCostPercentage)
    }
    else {
        netValue = dcfResult?.assetCashFlow?.terminalValue;
        grossValue = netValue * (100 + accCostPercentage) / 100
    }
    return {
        grossValue, netValue
    };
}

export const getAccCostsTerminalValue = (assetDCFParams: DCFParameters, dcfResult: DCFOutput, landTransferTaxExit, agentCostsExit, notaryCostsExit) => {
    
    const { grossValue, netValue } = getNetGrossTerminalValue(assetDCFParams, dcfResult, landTransferTaxExit+agentCostsExit+notaryCostsExit)
    const landTransferExitValue = netValue * landTransferTaxExit / 100
    const notaryCostsExitValue = netValue * notaryCostsExit / 100
    const agentCostsExitValue = netValue * agentCostsExit / 100
    const totalAcquisitionCostsExitValue = landTransferExitValue + notaryCostsExitValue + agentCostsExitValue;

    return {
        grossValue, netValue, landTransferExitValue, notaryCostsExitValue, agentCostsExitValue, totalAcquisitionCostsExitValue
    };
}

export const getFinancingInterestRate = (assetDCFParams: DCFParameters) => {
    return assetDCFParams?.financingInterestRate ? assetDCFParams.financingInterestRate : DCF_FINANCING_INTEREST_RATE
}

export const getFinancingAllInterestRate = (assetDCFParams: DCFParameters) => {
    let interestRate: number = assetDCFParams?.financingInterestRate ? assetDCFParams.financingInterestRate : DCF_FINANCING_INTEREST_RATE
    let swapRate: number = assetDCFParams?.financingSwapRate ? assetDCFParams.financingSwapRate : DCF_FINANCING_SWAP_RATE
    return Math.pow(1 + (parseFloat(interestRate) + parseFloat(swapRate)) / 100, 1 / 12) - 1;
}

export const getFinancingLoan = (assetDCFParams: DCFParameters, dcfResult: DCFOutput) => {
    if (assetDCFParams?.financingLoan)
        return assetDCFParams?.financingLoan;

    let percentage = getFinancingLTV(assetDCFParams, dcfResult);
    const assetValue = dcfResult?.assetCashFlow?.netAssetValue
    return percentage * assetValue;
}

export const getFinancingEquity = (assetDCFParams: DCFParameters, dcfResult: DCFOutput): number => {
    if (assetDCFParams?.financingEquity)
        return assetDCFParams?.financingEquity;

    let loan;
    if (assetDCFParams?.financingLoan) {
        loan = assetDCFParams?.financingLoan;
    } else {
        loan = getFinancingLoan(assetDCFParams, dcfResult)
    }
    const grossAssetValue = dcfResult?.assetCashFlow?.grossAssetValue
    return grossAssetValue - loan;
}

export const getFinancingLTV = (assetDCFParams: DCFParameters, dcfResult: DCFOutput) => {
    //=WENN(UND(ISTLEER(LTV);ISTLEER(LOAN));0,5;WENN(LOAN<>0;LOAN/SUMME(LOAN:EQUITY);LTV))
    let ltv;
    if (!assetDCFParams?.financingLTV && !assetDCFParams?.financingLoan) {
        ltv = DCF_FINANCING_LTV;
    } else if (assetDCFParams?.financingLoan) {
        const equity = getFinancingEquity(assetDCFParams, dcfResult);
        ltv = getFinancingLoan(assetDCFParams,dcfResult) / (parseFloat(assetDCFParams.financingLoan) + parseFloat(equity));
    } else {
        ltv = assetDCFParams?.financingLTV / 100;
    }
    return ltv;
}

export const getFinancingAmortization = (assetDCFParams: DCFParameters) => {
    return getFinancingType(assetDCFParams?.financingType?.key) !== 1 ? 0 : assetDCFParams.financingAmortization ? assetDCFParams.financingAmortization / 12 : DCF_FINANCING_AMORTIZATION / 12;
}

export const getStartingDate = (date: String): Moment => {
    if (date) {
        const dateMoment = moment(date);
        return dateMoment.date() > 15 ?
            dateMoment.set("date", 1).set("month", dateMoment.month() + 1) :
            dateMoment.set("date", 1)
    } else {
        return moment(moment.now()).set("date", 1);
    }
}
// Calculating total lettable area 
export const getTotalLettableArea = (calculationDetail) => {
    const rentRollsLettableArea = calculationDetail?.rentRolls
        ? Object.values(calculationDetail?.rentRolls)?.reduce(
            (total, item) => total + item.rentalSpace,
            0
        )
        : null;
    const totalLettableArea = rentRollsLettableArea ??
        calculationDetail?.assetHVLParams?.totalLettableArea;
    return totalLettableArea
}

export const getRentalPerAnum = (calculationDetail, type) => {
    const currentRental =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalGrossRentalIncome[0] * 12;

    const marketRental =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalMArketRent[0] * 12;

    const potentialRental =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalPotentialRent[0] * 12;
    if (type === 'currentRent') {
        return currentRental;
    } else if (type === 'marketRent') {
        return marketRental;
    } else {
        return potentialRental;
    }
}

export const getAssetValue = (calculation) => {
    const assetValue = calculation?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue
    return assetValue;
}
export const getAssetValuePSM = (calculation) => {
    const assetValue = calculation?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue
    const assetValuePSM = getPerSpaceValue(assetValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls));
    return assetValuePSM;
}
export const getVacancyRate = (calculationDetail) => {
    const totalLettableArea = getTotalLettableArea(calculationDetail)
    const vacantRentalSpace =
        calculationDetail?.rentRolls &&
        Object.values(calculationDetail?.rentRolls)
            ?.filter(
                (list) =>
                    list?.rentRollStatusType?.key ===
                    "core-data.rentRollStatusTypes.vacant"
            )
            ?.reduce((total, item) => total + item.rentalSpace, 0);
    const vacancyRate = vacantRentalSpace / totalLettableArea;
    return vacancyRate;
}

export const getTerminalType = (calculationDetail) => {
    const terminalType = calculationDetail?.assetDCFParams?.terminalValueType
        ? getTerminalRentTypeFromI18nKey(
            calculationDetail?.assetDCFParams?.terminalValueType?.key
        )
        : 0
    return terminalType;
}

export const getTerminalValue = (calculationDetail, type) => {
    const terminalType = getTerminalType(calculationDetail);
    const accCostPercentage =
        calculationDetail?.assetDCFParams?.landTransferTaxExit +
        calculationDetail?.assetDCFParams?.agentCostsExit +
        calculationDetail?.assetDCFParams?.notaryCostsExit;
    let netValue;
    if (terminalType === 1) {
        const grossValue =
            calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                ?.terminalValue;
        netValue = (grossValue * 100) / (100 + accCostPercentage);
        if (type === 'gross') {
            return grossValue;
        } else {
            return netValue
        }
    } else {
        netValue =
            calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                ?.terminalValue;
        if (type === 'gross') {
            return (netValue * (100 + accCostPercentage)) / 100;
        } else {
            return netValue
        }
    }
}


export const getYeildProfileCalculations = (calculationDetail) => {
    const yielCurrentNIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalNetOperatingIncome[0] *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.grossAssetValue;

    const yielCurrentGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalGrossRentalIncome[0] *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

    const yielPotentialNIY =
        ((calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalPotentialRent[0] -
            (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                ?.totalNonRecs[0] +
                calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                    ?.otherIncomeCosts_beforeNOI[0])) *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.grossAssetValue;

    const yielPotentialGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalPotentialRent[0] *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

    const yielMarketNIY =
        ((calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalMArketRent[0] -
            (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                ?.totalNonRecs[0] +
                calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                    ?.otherIncomeCosts_beforeNOI[0])) *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.grossAssetValue;

    const yielMarketGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalMArketRent[0] *
            12) /
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;

    return { yielMarketGIY, yielMarketNIY, yielPotentialGIY, yielPotentialNIY, yielCurrentGIY, yielCurrentNIY }
}

export const getYeildProfileAtSaleCalculations = (calculationDetail) => {
    const grossTerminalValue = getTerminalValue(calculationDetail, 'gross');
    const netTerminalValue = getTerminalValue(calculationDetail, 'net');
    const yielSaleCurrentNIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalNetOperatingIncome?.at(
            -1
        ) *
            12) /
        grossTerminalValue;

    const yielSaleCurrentGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalGrossRentalIncome?.at(
            -1
        ) *
            12) /
        netTerminalValue;

    const yielSalePotentialNIY =
        ((calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalPotentialRent?.at(
            -1
        ) -
            (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs?.at(
                -1
            ) +
                calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.otherIncomeCosts_beforeNOI?.at(
                    -1
                ))) *
            12) /
        grossTerminalValue;

    const yielSalePotentialGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalPotentialRent?.at(
            -1
        ) *
            12) /
        netTerminalValue;

    const yielSaleMarketNIY =
        ((calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalMArketRent?.at(
            -1
        ) -
            (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalNonRecs?.at(
                -1
            ) +
                calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.otherIncomeCosts_beforeNOI?.at(
                    -1
                ))) *
            12) /
        grossTerminalValue;

    const yielSaleMarketGIY =
        (calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow?.totalMArketRent?.at(
            -1
        ) *
            12) /
        netTerminalValue;

    return { yielSaleMarketGIY, yielSaleMarketNIY, yielSalePotentialGIY, yielSalePotentialNIY, yielSaleCurrentGIY, yielSaleCurrentNIY }
}

export const getWALTYears = (calculationDetail) => {
    const rentRolls =
        calculationDetail?.rentRolls && Object.values(calculationDetail?.rentRolls);
    const totalGrossRentalIncome =
        calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
            ?.totalGrossRentalIncome;
    const grossRentalIncomeTenant =
        calculationDetail?.assetDCFResult?.dcfResult?.tenantCashFlows[0]
            ?.grossRentalIncome;

    let waltRationPerRentalUnit;

    if (
        calculationDetail?.rentRolls &&
        dayjs(rentRolls[0]?.leaseEndDate).diff(
            dayjs(calculationDetail?.analysisDate),
            "days"
        ) > 0
    ) {
        const divisionResults = grossRentalIncomeTenant
            ?.map(
                (value, index) => (value / totalGrossRentalIncome[index]) * 2 // will replace with leaseEnd Date
            )
            ?.filter((num) => !isNaN(num));
        waltRationPerRentalUnit = divisionResults?.length > 0 && divisionResults?.reduce(
            (total, item) => total + item,
            0
        );
    } else {
        const divisionResults = grossRentalIncomeTenant
            ?.map(
                (value, index) => (value / totalGrossRentalIncome[index]) * 2 // will replace with analysis Date
            )
            ?.filter((num) => !isNaN(num));
        waltRationPerRentalUnit = divisionResults?.length > 0 && divisionResults?.reduce(
            (total, item) => total + item,
            0
        );
    }

    let waltYears =
        (waltRationPerRentalUnit -
            calculationDetail?.assetHVLParams?.leaseDuration) /
        36525;
    return waltYears;
}