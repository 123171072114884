import { Badge, Card, Col, Divider, Dropdown, Input, Menu, Row } from "antd";
import { BiFilter } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import { IoIosArrowForward } from "react-icons/io";
import PATH from "../../../../utils/path";

export default function UserCard(props) {
  const { data, setTanents } = props;
  const { Search } = Input;
  const [filteredUser, setFilteredUser] = useState(data);
  const [active, setActive] = useState();
  const navigate = useNavigate();
  const userFilter = (
    <Menu
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "1",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => setFilteredUser(data)}
            >
              All
            </div>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //     <div
        //       role="presentation"
        //       className="d-flex justify-content-left align-items-center table-dropdown"
        //       onClick={() =>
        //         setFilteredUser(data.filter((obj) => obj.status === "Admin"))
        //       }
        //     >
        //       Admin
        //     </div>
        //   ),
        // },
        {
          key: "3",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() =>
                setFilteredUser(
                  data.filter((obj) => obj.status === "Individual")
                )
              }
            >
              Individual
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() =>
                setFilteredUser(
                  data.filter((obj) => obj.status === "Corporate")
                )
              }
            >
              Corporate
            </div>
          ),
        },
      ]}
    />
  );

  const handleClick = (item) => {
    setTanents(item.tanents);
    setActive(item.id);
  };
  return (
    <Card bordered={false} className="w-100 admin-dashboard-user">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="d-inline mb-0 text-black">Users</h3>
        <div className="d-flex align-items-center">
          <Link to={PATH.USER_MANAGEMENT_LIST} className="mr-2">All Users</Link>
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              // onChange={(e) => {
              //   if (e.key === "Enter") {
              //     setSearchValue(e.target.value);
              //   }
              // }}
              className="mr-2"
              placeholder="Search User"

              // onClick={() => setIsModalOpen(true)}
            />
          </div>
          <Dropdown overlay={userFilter} trigger={["click"]}>
            <BiFilter
              className=" p-1 rounded-sm cursor-pointer"
              style={{ backgroundColor: "#F2F3F5" }}
              size={37}
            />
          </Dropdown>
        </div>
      </div>
      <hr />
      <div className="user-card custom-scroll">
        {filteredUser?.map((item) => (
          <div>
            <Row
              key={item.id}
              className={`w-100 bg-white rounded-sm p-2 d-flex align-items-center m-0 cursor-pointer row-active-hover ${
                active === item.id ? "active" : ""
              }`}
              gutter={24}
              onClick={() => handleClick(item)}
            >
              <Col xs={12}>
                <Row gutter={16} className="d-flex align-items-center">
                  <Col flex="none">
                    <img
                      style={{ width: "50px" }}
                      className="rounded-circle"
                      src={item.image}
                      alt="child avatar"
                    />
                  </Col>
                  <Col flex="auto">
                    <Row className="mb-1" gutter={10}>
                      <Col xs={12}>
                        <h5 className="mb-0 d-inline">{item.name}</h5>
                      </Col>
                      <Col xs={12}>
                        <Badge
                          count={item.status}
                          style={{
                            color: "#676EB4",
                            backgroundColor: "#F2F3F5",
                          }}
                        />
                      </Col>
                    </Row>
                    <p className="mb-0 text-light-gray">{item.address}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row gutter={16} className="d-flex align-items-center">
                  <Col>
                    <span className="bg-light-grey d-flex align-items-center justify-content-center ">
                      <span className="color-green font-weight-semibold">
                        10&nbsp;
                      </span>{" "}
                      Projects
                    </span>
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <span className="bg-light-grey d-flex align-items-center justify-content-center ">
                      <span className="color-red font-weight-semibold">
                        10&nbsp;
                      </span>{" "}
                      Portfolio
                    </span>
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <span className="bg-light-grey d-flex align-items-center justify-content-center ">
                      <span className="color-blue font-weight-semibold">
                        10&nbsp;
                      </span>
                      Assets
                    </span>
                  </Col>
                  <Col
                    flex="auto"
                    className="d-flex justify-content-end"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    role="button"
                    tabIndex="0"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IoIosArrowForward
                      size={20}
                      role="button"
                      tabIndex="0"
                      onClick={() => navigate(PATH.USER_MANAGEMENT_USER_DETAIL)}
                      onKeyDown={() =>
                        navigate(PATH.USER_MANAGEMENT_USER_DETAIL)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="m-0" />
          </div>
        ))}
      </div>
    </Card>
  );
}

UserCard.propTypes = {
  data: PropTypes.isRequired,
  setTanents: PropTypes.isRequired,
};
