import TotalTenants from "./component/TotalTenants.jsx";
import Index from "./Index.jsx";
import TaskAssigned from "./TaskAssigned.jsx";

const DASHBOARD = {
  INDEX: Index,
  TOTAL_TENANTS: TotalTenants,
  TASKS_ASSIGNED: TaskAssigned
};

export default DASHBOARD;
