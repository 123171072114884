/* eslint-disable */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Checkbox,
  Menu,
  Form,
  Input,
  Select
} from "antd";
import { MdDelete, MdModeEdit } from "react-icons/md";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";

const { Option } = Select;

function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) {
  const inputNode =  inputType === "typeDropdown" ? (
    <Select placeholder="Selection" allowClear>
                <Option value="1">None</Option>
                <Option value="2">CPI - Hurdle [%]</Option>
                <Option value="3">CPI - Hurdle [Pts.]</Option>
                <Option value="4">CPI - Period</Option>
                <Option value="5">Static</Option>
                <Option value="6">Stepping Rent</Option>
              </Select>
  ) : inputType === "indexDropdown" ? (
    <Select placeholder="Selection" allowClear>
                <Option value="1">VPI 15</Option>
                <Option value="2">VPI 10</Option>
              </Select>
  ) : (
    <Input type={inputType} />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              inputType: { inputType },
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

function Indexation({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [toggle, setToggle] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const [dataSource, setDataSource] = useState([
    {
      key: 1,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "8/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100",
    },
    {
      key: 2,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "5/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 3,
      no: "1",
      pid: "01",
      status: "LET",
      type: "None",
      index: " ",
      last: " ",
      first: " ",
      next: " ",
      cycle: " ",
      hurdle: " ",
      factor: " ",
    },
    {
      key: 4,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "5/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 5,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "10/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 6,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "10/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 7,
      no: "1",
      pid: "01",
      status: "LET",
      type: "None",
      index: " ",
      last: " ",
      first: " ",
      next: " ",
      cycle: " ",
      hurdle: " ",
      factor: " ",
    },
    {
      key: 8,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "9/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 9,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "9/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 10,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "5/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 11,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "7/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
    {
      key: 12,
      no: "1",
      pid: "01",
      status: "LET",
      type: "CPI - Period",
      index: "VPI 15",
      last: " ",
      first: " ",
      next: "7/1/2019",
      cycle: "12",
      hurdle: " ",
      factor: "100%",
    },
  ]);

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const initialColState = [
    {
      id: 1,
      title: "PID",
      dataIndex: "pid",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "status",
      hidden: false,
    },
    {
      id: 3,
      title: "Type",
      dataIndex: "type",
      hidden: toggle.includes("type"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "typeDropdown",
        dataIndex: "type",
        title: "Type",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Index",
      dataIndex: "index",
      hidden: toggle.includes("index"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "indexDropdown",
        dataIndex: "index",
        title: "Index",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Last",
      dataIndex: "last",
      hidden: toggle.includes("last"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "last",
        title: "Last",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "First",
      dataIndex: "first",
      hidden: toggle.includes("first"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "first",
        title: "First",
        editing: isEditing(record),
      }),
    },
    {
      id: 7,
      title: "Next",
      dataIndex: "next",
      hidden: toggle.includes("next"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "next",
        title: "Next",
        editing: isEditing(record),
      }),
    },
    {
      id: 8,
      title: "Cycle",
      dataIndex: "cycle",
      hidden: toggle.includes("cycle"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "cycle",
        title: "Cycle",
        editing: isEditing(record),
      }),
    },
    {
      id: 9,
      title: "Hurdle",
      dataIndex: "hurdle",
      hidden: toggle.includes("hurdle"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "hurdle",
        title: "Hurdle",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Factor",
      dataIndex: "factor",
      hidden: toggle.includes("factor"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "factor",
        title: "Factor",
        editing: isEditing(record),
      }),
    },
    {
      id: 11,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record.key)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
          <div
            className="drawer-action-icons d-flex align-items-center cursor-pointer"
            role="presentation"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <MdModeEdit size={20} />
          </div>
          </div>
        );
      },
    },
  ];

  const handleReset = () => {
    setToggle([]);
  };
  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if(checked){
      setToggle((prev) => [...prev, value]);
    }
    else{
      setToggle((prev) => prev.filter((item) => item !== value));
    }
  };

  const basicDatahideTableColumns = (
    <Menu
      items={[
        {
          key: 1,
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        // {
        //   key: 2,
        //   label: (
        //     <Checkbox
        //       value="status"
        //       name="Status/Type"
        //       id={2}
        //       // checked={checkList.includes("valDate")}
        //       onChange={handleChange}
        //     >
        //       Status/Type
        //     </Checkbox>
        //   ),
        // },
        {
          key: 3,
          label: (
            <Checkbox
              value="type"
              name="Type"
              id={3}
              // checked={checkList.includes("projectNo")}
              onChange={handleChange}
            >
              Type
            </Checkbox>
          ),
        },
        {
          key: 4,
          label: (
            <Checkbox
              value="index"
              name="Index"
              id={4}
              // checked={checkList.includes("projectName")}
              onChange={handleChange}
            >
              Index
            </Checkbox>
          ),
        },
        {
          key: 5,
          label: (
            <Checkbox
              value="last"
              name="Last"
              id={5}
              // checked={checkList.includes("nameOfClient1")}
              onChange={handleChange}
            >
              Last
            </Checkbox>
          ),
        },
        {
          key: 6,
          label: (
            <Checkbox
              value="first"
              name="First"
              id={6}
              // checked={checkList.includes("nameOfClient2")}
              onChange={handleChange}
            >
              First
            </Checkbox>
          ),
        },
        {
          key: 7,
          label: (
            <Checkbox
              value="next"
              name="Next"
              id={7}
              // checked={checkList.includes("instruction")}
              onChange={handleChange}
            >
              Next
            </Checkbox>
          ),
        },
        {
          key: 8,
          label: (
            <Checkbox
              value="hurdle"
              name="Hurdle"
              id={8}
              // checked={checkList.includes("scope")}
              onChange={handleChange}
            >
              Hurdle
            </Checkbox>
          ),
        },
        {
          key: 9,
          label: (
            <Checkbox
              value="factor"
              name="Factor"
              id={9}
              // checked={checkList.includes("scope")}
              onChange={handleChange}
            >
              Factor
            </Checkbox>
          ),
        },
        {
          key: 10,
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn" onClick={handleReset}>Reset</Button>
            </div>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    sethideTableColumns(basicDatahideTableColumns);
  }, [activeKey]);
  return (
    <div>
      <Form form={form} component={false}>
        <DataTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          columns={initialColState.filter((item) => item.hidden === false)}
            dataSource={[...dataSource]}
          pagination="true"
          className="antd-table-striped"
          rowSelection={rowSelection}
        />
      </Form>
      <div className="table-count">
        <h5 className="table-count-content">Showing 1 to 9 of 120 entries</h5>
      </div>
      {/* <Row gutter={24}>
        <Col xs={24}>
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
              <Button
                className="btn-action d-flex justify-content-center align-items-center text-danger"
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
              >
                <div className="active-action d-flex align-items-center">
                  <MdDelete />
                  <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                </div>
              </Button>

              {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddUpdateNewRentRoll(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdModeEdit className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                    </div>
                  </Button>
                </>
              )}
            </div>
            <Button className="btn-cancel py-2">
              <h5 className="mb-0">Cancel</h5>
            </Button>
          </div>
        </Col>
      </Row> */}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </div>
  );
}

export default Indexation;
Indexation.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  editing: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  inputType: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
