import api from "../../../utils/api";

// Create an account for admin or organizations
const createAccount = async (userAccount) => {
    const response = await api.post(`/api/Account/register`, userAccount);
    return response.data;
  };

const signUp = {
    createAccount
  };
  
  export default signUp;