import NoPageFound from "./NoPageFound";
import CORPORATE_AND_INDIVIDUAL_PAGES from "./CorporateAndIndividual";
import ADMIN_PAGES from "./Admin";
import SHARED from "./Shared";
import PROFILE_SETTING_WEB_PAGES from "./ProfileSettings";

const WEB_PAGES = {
  // CORPORRATE & INDIVIDUAL PAGES
  CORPORATE_AND_INDIVIDUAL_DASHBOARD: CORPORATE_AND_INDIVIDUAL_PAGES.DASHBOARD,
  CORPORATE_AND_INDIVIDUAL_PROJECTS: CORPORATE_AND_INDIVIDUAL_PAGES.PROJECTS,
  CORPORATE_AND_INDIVIDUAL_USER_MANAGEMENT:
    CORPORATE_AND_INDIVIDUAL_PAGES.USER_MANAGEMENT,
  TOTAL_TENANTS: CORPORATE_AND_INDIVIDUAL_PAGES.DASHBOARD.TOTAL_TENANTS,
  // ADMIN PAGES

  ADMIN_DASHBOARD: ADMIN_PAGES.DASHBOARD,
  ADMIN_ACTIVITY_LOG: ADMIN_PAGES.ACTIVITY_LOG,
  ADMIN_REPORTS: ADMIN_PAGES.REPORTS,
  ADMIN_PARAMETERS: ADMIN_PAGES.PARAMETERS,
  ADMIN_INDICES: ADMIN_PAGES.INDICES,

  // SHARED

  USER_MANAGEMENT_LIST:SHARED.USER_MANAGEMENT.INDEX,
  USER_MANAGEMENT_LIST_PROJECT_MANAGER:SHARED.USER_MANAGEMENT.PROJECT_MANAGER_USER,
  USER_MANAGEMENT_USER_DETAIL: SHARED.USER_MANAGEMENT.USER_DETAIL,

  PROJECT_LIST: SHARED.PROJECTS.INDEX,
  PROJECT_DETAILS: SHARED.PROJECTS.PROJECTS_DETAILS.INDEX,
  ASSET_DETAILS: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.INDEX,
  PORTFOLIO_DETAILS: SHARED.PROJECTS.PROJECTS_DETAILS.PORTFOLIO_DASHBOARD.INDEX,
  ASSET_DETAILS_DASHBOARD: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.DASHBOARD,
  ASSET_DETAILS_CALCULATION: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.CALCULATION,
  ASSET_DETAILS_INCOME_AND_COST: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.INCOME_AND_COST,
  ASSET_DETAILS_RENT_ROLL: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.RENT_ROLL,
  ASSET_DETAILS_CASH_FLOWS: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.CASH_FLOWS,
  ASSET_DETAILS_CALCULATION_SETTING: SHARED.PROJECTS.PROJECTS_DETAILS.ASSET_DETAILS.CALCULATION_SETTING,
  
  // PROFILE SETTING

  PROFILE_SETTING: PROFILE_SETTING_WEB_PAGES.INDEX,
  TASK_ASSIGNED: CORPORATE_AND_INDIVIDUAL_PAGES.DASHBOARD.TASKS_ASSIGNED,
  NO_PAGE_FOUND: NoPageFound,
};
export default WEB_PAGES;
