/* eslint-disable */
import api from "../../../utils/api";

// Create an account for User
const createUser = async (userAccount) => {
  const response = await api.post(`/api/user/save`, userAccount);
  return response.data;
};

// Get All list of User
const getAllUserList = async (data) => {
  const response = await api.get(
    `/api/user/getAll`, {
      params: data,
    }
  );
  return response.data.data;
};

// Get Single User

const getSingleUser = async (data) => {
  const response = await api.get("/api/user/get", {
    params: data,
  });
  return response.data.data;
};

// Delete User
const deleteUsers = async (data) => {
  const response = await api.delete("/api/user/delete", { data: data });
  return response.data;
};

const userManagement = {
  createUser,
  getAllUserList,
  getSingleUser,
  deleteUsers,
};

export default userManagement;
