import {Card, Col, Input, InputNumber, Row, Slider, Switch} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IMAGES from '../../../../../../../../assets/images';
import {
    getFormattedDecimalNumber,
    getFormattedDecimalNumberOrNull,
    getFormattedEnglishNumber
} from '../../../../../../../../utils/digitHelper';
import {
    DCF_RENT_ROLL_HVL_AREA_UNIT,
    DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM,
    DCF_RENT_ROLL_HVL_MARKET_RENT_SQM, DCF_RENT_ROLL_HVL_OCCUPIED_AREA,
    DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED
} from "../../../../../../../../utils/dcfDefaults";

const VhlAreaLeaseInformation = () => {
    const dispatch = useDispatch();
    const {
        totalLettableArea,
        currentRentSQM,
        currentRentYearly,
        occupiedArea,
        leaseDuration,
        option1InYears,
        marketRentSQM,
        marketRentYearly,
    } = useSelector((state) => state.calculation.assetHVLParams);
    
    const getCurrentRentSQM = () => currentRentSQM ? currentRentSQM : DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM
    const getMarketRentSQM = () => marketRentSQM ? marketRentSQM : DCF_RENT_ROLL_HVL_MARKET_RENT_SQM
    const getOccupiedArea = () => occupiedArea ? occupiedArea : DCF_RENT_ROLL_HVL_OCCUPIED_AREA
    const getTotalLettableArea = () => totalLettableArea ? totalLettableArea : DCF_RENT_ROLL_HVL_AREA_UNIT

    const [isPassingRentSwitch, setPassingRentIsSwitch] = useState(false)
    const [isMarketRentSwitch, setMarketRentIsSwitch] = useState(false)

    const setAssetHVLParams = (params) => {
        dispatch({ type: "calculation/setAssetHVLParams", payload: params })
    }

    const passingRentSwitchChange = (checked) => {
        setPassingRentIsSwitch(checked);
    };
    const marketRentSwitchChange = (checked) => {
        setMarketRentIsSwitch(checked);
    };

    const getOccupiedAreaInSQM = (occupiedAreaInPercentage, newTotalLettableArea) => {
        if (newTotalLettableArea) {
            return newTotalLettableArea * occupiedAreaInPercentage / 100
        }
        else {
            return getTotalLettableArea() * occupiedAreaInPercentage / 100
        }

    }

    const getDefaultCurrentRentYearly = () => {
        return getCurrentRentSQM()*getOccupiedAreaInSQM(getOccupiedArea())*12
    }

    const getDefaultMarketRentYearly = () => {
        return getMarketRentSQM()*getOccupiedAreaInSQM(getOccupiedArea())*12
    }

    const setCurrentRentSQM = (rentSQM) => {
        const rentMonthly = getOccupiedAreaInSQM(getOccupiedArea()) * rentSQM;
        const rentYearly = rentMonthly * 12;
        setAssetHVLParams({
            currentRentSQM: rentSQM,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly
        });
    }
    
    const setCurrentRentYearly = (rentYearly) => {
        const rentMonthly = rentYearly / 12;
        const rentSQM = rentMonthly / getOccupiedAreaInSQM(getOccupiedArea());
        setAssetHVLParams({
            currentRentSQM: rentSQM,
            currentRentMonthly: rentMonthly,
            currentRentYearly: rentYearly
        });
    }

    const setMRentSQM = (mRentSQM) => {
        const mRentMonthly = getOccupiedAreaInSQM(getOccupiedArea()) * mRentSQM;
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            marketRentSQM: mRentSQM,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }
    
    const setMRentYearly = (mRentYearly) => {
        const mRentMonthly = mRentYearly / 12;
        const mRentSQM = mRentMonthly / getOccupiedAreaInSQM(getOccupiedArea());
        setAssetHVLParams({
            marketRentSQM: mRentSQM,
            marketRentMonthly: mRentMonthly,
            marketRentYearly: mRentYearly
        });
    }

    const setOccupiedArea = (occupiedArea) => {
        const calcOccupiedArea = occupiedArea ? occupiedArea : DCF_RENT_ROLL_HVL_OCCUPIED_AREA
        const rentMonthly = getOccupiedAreaInSQM(calcOccupiedArea) * getCurrentRentSQM();
        const rentYearly = rentMonthly * 12;
        const mRentMonthly = getOccupiedAreaInSQM(calcOccupiedArea) * getMarketRentSQM();
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            occupiedArea: occupiedArea,
            currentRentMonthly: currentRentSQM ? rentMonthly : null,
            currentRentYearly: currentRentSQM ? rentYearly : null,
            marketRentMonthly: marketRentSQM ? mRentMonthly : null,
            marketRentYearly: marketRentSQM ? mRentYearly : null
        });
    }

    const setTotalLettAbleArea = (newTotalLettableArea) => {
        console.log(totalLettableArea, 'totalLettableArea')
        const rentMonthly = getOccupiedAreaInSQM(getOccupiedArea(), newTotalLettableArea) * getCurrentRentSQM();
        const rentYearly = rentMonthly * 12;
        const mRentMonthly = getOccupiedAreaInSQM(getOccupiedArea(), newTotalLettableArea) * getMarketRentSQM();
        const mRentYearly = mRentMonthly * 12;
        setAssetHVLParams({
            totalLettableArea: newTotalLettableArea,
            currentRentMonthly: currentRentSQM ? rentMonthly : null,
            currentRentYearly: currentRentSQM ? rentYearly : null,
            marketRentMonthly: marketRentSQM ? mRentMonthly : null,
            marketRentYearly: marketRentSQM ? mRentYearly : null
        });
    }

    return (
        <Card bordered={false} className="assets_card">
            <div className="assets_card__header">
                <h3 className="text-black">Area & Lease Information</h3>
            </div>
            <Row gutter={20} className='pt-3'>
                <Col xs={24} md={16}>
                    <div className='area-lease-info' style={{ background: '#FAFAFA', border: '1px solid #C6C6C6', padding: '12px', borderRadius: "12px" }}>
                        <Row gutter={20}>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Total Area</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>SQM</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={1000} min={0} max={100000} value={getTotalLettableArea()} onChange={(value) => setTotalLettAbleArea(value)} style={styles.slider} />
                                </div>
                                <InputNumber controls={false} min={0} max={100000} className="w-100 removeStyles" placeholder={getFormattedEnglishNumber(DCF_RENT_ROLL_HVL_AREA_UNIT)} formatter={getFormattedEnglishNumber} value={totalLettableArea ? totalLettableArea : null} onChange={(newValue) => setTotalLettAbleArea(newValue)} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Occupancy</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>%</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={10} min={0} max={100} value={getOccupiedArea()} onChange={(value) => setOccupiedArea(value)} style={styles.slider} />
                                </div>
                                <InputNumber controls={false} min={0} max={100} className="w-100 removeStyles" placeholder={getFormattedDecimalNumber(getOccupiedArea())} formatter={getFormattedDecimalNumberOrNull} value={occupiedArea ? occupiedArea : null} onChange={(newValue) => setOccupiedArea(newValue)} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>WALT</h5>
                                {/* // lease duration */}
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>Years</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider vertical step={1} min={0} max={30} value={leaseDuration ? leaseDuration : DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12} onChange={(value) => setAssetHVLParams({ leaseDuration: value })} style={styles.slider} />
                                </div>
                                <InputNumber controls={false} min={0} max={30} className="w-100 removeStyles" placeholder={getFormattedEnglishNumber(DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12)} formatter={getFormattedEnglishNumber} value={leaseDuration ? leaseDuration : null} onChange={(newValue) => setAssetHVLParams({ leaseDuration: newValue })} />
                            </Col>
                            <Col xs={6} className='d-flex flex-column justify-content-center align-items-center' style={{ gap: '6px' }}>
                                {/* // lease duration extension */}
                                <h5 className='mb-0 font-weight-normal' style={{ color: '#01215D' }}>Prolongation</h5>
                                <h6 className='mb-0 font-weight-normal' style={{ color: '#797979' }}>Years</h6>
                                <div style={{ height: "132px" }}>
                                    <Slider
                                        vertical min={0} max={30}
                                        value={option1InYears ? option1InYears : DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12}
                                        step={1}
                                        style={styles.slider}
                                        onChange={(value) => setAssetHVLParams({ option1InYears: value })}
                                    />
                                </div>
                                <InputNumber controls={false} min={0} max={30} className="w-100 removeStyles" placeholder={getFormattedEnglishNumber(DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED/12)} formatter={getFormattedEnglishNumber} value={option1InYears ? option1InYears : null} onChange={(newValue) => setAssetHVLParams({ option1InYears: newValue })} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} md={8}>
                    <div style={{ background: '#FAFAFA', border: '1px solid #C6C6C6', padding: '12px', borderRadius: "12px" }}>
                        <div>
                            <h4 className='text-black text-center mb-0'>Passing Rent</h4>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '2px', paddingBlock: '12px' }}>
                                <h5 className='mb-0'>Per Year</h5>
                                <Switch size="small"
                                    checked={isPassingRentSwitch}
                                    onChange={passingRentSwitchChange}
                                />
                                <h5 className='mb-0'>Per sqm</h5>
                            </div>
                            <InputNumber controls={false} className={"removeStyles addon-right"} addonAfter={<img src={IMAGES.POUND} />} formatter={getFormattedEnglishNumber} placeholder={isPassingRentSwitch ? getFormattedEnglishNumber(DCF_RENT_ROLL_HVL_CURRENT_RENT_SQM) : getFormattedEnglishNumber(getDefaultCurrentRentYearly())} value={isPassingRentSwitch ? currentRentSQM : currentRentYearly}
                                onChange={value => isPassingRentSwitch ? setCurrentRentSQM(value) : setCurrentRentYearly(value)} />
                        </div>
                        <div>
                            <h4 className='text-black text-center mb-0 pt-1'>Market Rent</h4>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '2px', paddingBlock: '12px' }}>
                                <h5 className='mb-0'>Per Year</h5>
                                <Switch size="small"
                                    checked={isMarketRentSwitch}
                                    onChange={marketRentSwitchChange}
                                />
                                <h5 className='mb-0'>Per sqm</h5>
                            </div>
                            <InputNumber controls={false} className={"removeStyles addon-right"} addonAfter={<img src={IMAGES.POUND} />} formatter={getFormattedEnglishNumber} placeholder={isMarketRentSwitch ? getFormattedEnglishNumber(DCF_RENT_ROLL_HVL_MARKET_RENT_SQM) : getFormattedEnglishNumber(getDefaultMarketRentYearly())} value={isMarketRentSwitch ? marketRentSQM : marketRentYearly}
                                onChange={newValue => isMarketRentSwitch ? setMRentSQM(newValue) : setMRentYearly(newValue)}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default VhlAreaLeaseInformation


const styles = {
    slider: {
        height: '95%',
        "& .ant-slider-rail": {
            width: '10% !important',
            background: '#D8D8D8',
            borderRadius: '20px'
        },
        "& .ant-slider-track": {
            width: '10%',
            background: '#01215D',
            borderRadius: '20px'
        },
        "& .ant-slider-step": {
            width: '10%'
        },
        "& .ant-slider-handle": {
            marginLeft: '-5px',
            width: '20px',
            height: '12px',
            border: '1.45px solid #01215D'
        },
    }
}