import { Button, Card, Col, Row, Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";

function AcquisitionCosts() {
  return (
    <Form className="bg-white" style={{ borderRadius: "5px" }}>
      <div
        className="definitioncalculationSetting"
      >
        <div />
        <div className=" ml-auto mr-auto pt-3 AcquisitionCostsCalculationSetting">
          <Card
            title={
              <div>
                <h3 className="mt-1 ">Acquisition Costs</h3>
              </div>
            }
            className="card-head"
          >
            <Row gutter={24} className="justify-content-between">
              <Col md={10} xs={24}>
                <h5 className="text-center">Market Value</h5>
                <div className="d-flex div-media">
                  <h5 className=" mr-3 mt-2 less-media">&lt;</h5>
                  <Input
                    placeholder="0"
                    style={{ color: "black", textAlign: "text-end" }}
                  />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col md={4} xs={8} className="input-media">
                <div className="mr-3 text-center">
                  {" "}
                  <h5>Tax</h5>
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3 text-center">
                  {" "}
                  <h5>Agent</h5>
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  {" "}
                  <h5 className="text-center">Notary</h5>
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="aq-hr"/>

            <Row gutter={24} className="mt-3 justify-content-between">
              <Col md={10} xs={24}>
                <div className="d-flex div-media">
                  <h5 className=" mr-3 mt-2 less-media">&lt;</h5>
                  <Input
                    placeholder="0"
                    style={{ color: "black", textAlign: "text-end" }}
                  />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col md={4} xs={8} className="input-media">
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
            </Row> 
            <hr className="aq-hr"/>
            <Row gutter={24} className="mt-3 justify-content-between">
              <Col md={10} xs={24}>
                <div className="d-flex div-media">
                  <h5 className=" mr-3 mt-2 less-media">&lt;</h5>
                  <Input
                    placeholder="0"
                    style={{ color: "black", textAlign: "text-end" }}
                  />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col md={4} xs={8} className="input-media">
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="aq-hr"/>
            <Row gutter={24} className="mt-3 justify-content-between">
              <Col md={10} xs={24}>
                <div className="d-flex div-media">
                  <h5 className=" mr-2 mt-2 less-media">&lt;=</h5>
                  <Input
                    placeholder="0"
                    style={{ color: "black", textAlign: "text-end" }}
                  />
                  <h5 className="ml-1 mt-2">EUR</h5>
                </div>
              </Col>

              <Col md={4} xs={8} className="input-media">
                <div className="mr-3">
                  <div className="d-flex div-media">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={8}>
                <div className="mr-3">
                  <div className="d-flex">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="aq-hr"/>
            <div className="d-flex  mt-3">
              <h5 className="mt-2 mr-3">Adjustment of the land transfer tax</h5>
              <Select
                defaultValue="automatic"
                style={{
                  width: "35%",
                }}
              >
                <Option value="jack">automatic</Option>
              </Select>
            </div>
          </Card>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#fff" }}
        className="definitioncalculationSetting"
      >
        <div />
        <div className=" ml-auto mr-auto AcquisitionCostsCalculationSetting">
          <Card>
          <Row gutter={24} className="justify-content-between">
              <Col md={10} xs={24}>
                <h5 className="text-start mt-4 ">Presetting</h5>
                
              </Col>

              <Col md={4} xs={24}>
                <div className="mr-3 text-center">
                  {" "}
                  <h5>Tax</h5>
                  <div className="d-flex">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={24}>
                <div className="mr-3 text-center">
                  {" "}
                  <h5>Agent</h5>
                  <div className="d-flex">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={24}>
                <div className="mr-3">
                 
                  <h5 className="text-center">Notary</h5>
                  <div className="d-flex">
                    <Input
                      placeholder="0"
                      style={{ color: "black", textAlign: "text-end" }}
                    />
                    <h5 className="ml-1 mt-2">%</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>

          <div style={{ textAlign: "end" }} className="btn-style ">
            <Button key="submit" type="default" className="btn-primary mb-3">
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default AcquisitionCosts;
