import { Button, Col, Form, Input, Row, Select } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../../../../../app/features/Lookups/lookup.slice";
import {I18n} from "reia-rest-client";

export default function BasicData(props) {
  const { Option } = Select;
  const { next, prev, handleCancel } = props;
  const dispatch = useDispatch();
  const { rentRollUseGroupTypes, rentRollUseTypes } = useSelector((state) => state.lookUps);

  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("basicForm", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("basicForm");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "rentRollUseGroupTypes",
    }
   
    dispatch(
      getByTypeDropdown({finalData})
    );
  }, []);
  
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "rentRollUseTypes",
    }
    dispatch(getByTypeDropdown({finalData}));
  }, [dispatch]);

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Basic Data</h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Tenant Name"
              name="tenantName"
              initialValue={FormData?.tenantName}
              rules={[
                {
                  required: true,
                  message: "Please input your tanent name!",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Description#1"
              name="description1"
              initialValue={FormData?.description1}
              rules={[
                {
                  required: true,
                  message: "Please input description 1",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Description#2"
              name="description2"
              initialValue={FormData?.description2}
              rules={[
                {
                  required: true,
                  message: "Please input description 2",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Location/Floor"
              name="locationFloor"
              initialValue={FormData?.locationFloor}
              rules={[
                {
                  required: true,
                  message: "Please input Location!",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          {/*<Col xs={24} md={12} lg={8}>*/}
          {/*  <Form.Item*/}
          {/*    label="Use Group"*/}
          {/*    name="useGroup"*/}
          {/*    initialValue={FormData?.useGroup}*/}
          {/*  >*/}
          {/*    <Select placeholder="Select" allowClear>*/}
          {/*      {rentRollUseGroupTypes?.map((item:I18n) => (*/}
          {/*        <Option key={item.id} value={item.id}>*/}
          {/*          {item.translations.en}*/}
          {/*        </Option>*/}
          {/*      ))}*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col xs={24} md={12} lg={8}>
            <Form.Item label="Use" name="use" initialValue={FormData?.use}>
              <Select placeholder="Select" allowClear>
                {rentRollUseTypes?.map((item:I18n) => (
                    <Option key={item.id} value={item.id}>
                      {item.translations.en}
                    </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Area/Unit"
              name="areaUnit"
              initialValue={FormData?.areaUnit}
              rules={[
                {
                  required: true,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Sqm/Unit" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

BasicData.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
