import { Card, Checkbox, Col, Input, Row, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getDefaultNotifyToaster } from "../../../../../../../../utils/helpers";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, } from "../../../../../../../../utils/digitHelper";
import {
  getAccCostsTerminalValue,
  getTerminalRentTypeFromI18nKey
} from "../../../../../../../../utils/dcfHelper";
import {
  getDefaultAccCostsValuesUI,
  getSyncWithAnalysisDateOrDefault
} from "../../../../../../../../utils/dcfParamsHelper";
import {AssetDto} from "reia-rest-client";

function VeAcquisitionCostsExit() {
  const dispatch = useDispatch();
  const [bpDiscountRate, setBpDiscountRate] = useState({ land: 50, agent: 50, notary: 50, });
  const calculation = useSelector((state) => state.calculation);
  const { landTransferTaxExit, agentCostsExit, notaryCostsExit, landTransferTax, agentCosts_Sale, notaryCosts, terminalValueType } = calculation.assetDCFParams;
  const syncWithAnalysisDate = getSyncWithAnalysisDateOrDefault(calculation.assetDCFParams);
  const [landTransferValue, setLandTransferValue] = useState();
  const [agentCostsValue, setAgentCostsValue] = useState();
  const [notaryCostsExitValue, setNotaryCostsExitValue] = useState();
  const netAssetValue = calculation.assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;
  const { landTaxes, acquisitionCostsDefaults } = useSelector(state => state.settings)


  const accCosts = getDefaultAccCostsValuesUI(landTaxes, acquisitionCostsDefaults, calculation.calculationDetail.federalState, netAssetValue, calculation.assetDCFParams, true)
  
  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }
  
  useEffect(() => {
    const {
      landTransferExitValue, notaryCostsExitValue, agentCostsExitValue, totalAcquisitionCostsExitValue
    } = getAccCostsTerminalValue(calculation.assetDCFParams, calculation.assetDCFResult?.dcfResult, accCosts.landTransferTax, accCosts.agentCosts, accCosts.notaryCosts)
    
    setLandTransferValue(landTransferExitValue)
    setNotaryCostsExitValue(notaryCostsExitValue)
    setAgentCostsValue(agentCostsExitValue)

  }, [landTransferTaxExit, notaryCostsExit, agentCostsExit, calculation.assetDCFResult?.dcfResult?.assetCashFlow?.terminalValue])


  return (
    <div>
      <Card bordered={false} className="assets_card">
        <Row gutter={24} className="align-items-center pb-3 assets_card__header" style={{ margin: 0, paddingTop: '8px' }}>
          <Col xs={10} className="ml-0 pl-0">
            <h3 className="text-black mb-0">Acquisition Costs  @Exit</h3>
          </Col>
          <Col xs={4}>
            <h3 className="text-black mb-0">{getFormattedDecimalNumber(accCosts.landTransferTax + accCosts.agentCosts + accCosts.notaryCosts)} %</h3>
          </Col>
          <Col xs={4}>
            <h4 className="mb-0 font-weight-normal" style={{
              color: '#797979'
            }}>Steps</h4>
          </Col>
          <Col xs={6} className="d-flex justify-content-end mr-0 pr-0">
            <Checkbox
              checked={syncWithAnalysisDate}
              onChange={(e) =>
                setAssetDCFParams({ syncWithAnalysisDate: e.target.checked })
              }
              style={{ color: "#6F7173" }}
            >
              <span style={{ fontSize: '12px' }}>Sync with analysis</span>
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={24} className="pt-3 d-flex justify-content-between align-items-center" style={{ margin: 0 }}>
          <Col xs={8} className="align-items-center my-2 ml-0 pl-0">
            <h4 className="mb-0 text-black-50 inner_progress">Land Transfer Tax in %</h4>
            {/* <h6 className="mb-0"  style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              disabled={syncWithAnalysisDate}
              value={landTransferTaxExit && !syncWithAnalysisDate ? landTransferTaxExit : null}
              formatter={getFormattedDecimalNumber}
              placeholder={getFormattedDecimalNumber(accCosts.landTransferTax)}
              incDecStep={bpDiscountRate.land / 100}
              setIncDecValue={(e) => setAssetDCFParams({ landTransferTaxExit: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="d-flex align-items-center justify-content-center">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px", width: '110px'
              }}
              type="number"
              disabled={syncWithAnalysisDate}
              value={getFormattedEnglishNumber(bpDiscountRate.land)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, land: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center my-2 justify-content-end mr-0 pr-0"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(landTransferValue)} €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-3 d-flex justify-content-between align-items-center" style={{ margin: 0 }}>
          <Col xs={8} className="align-items-center my-2 ml-0 pl-0">
            <h4 className="mb-0 text-black-50 inner_progress">Agent Costs in %</h4>
            {/* <h6 className="mb-0"  style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              disabled={syncWithAnalysisDate}
              value={agentCostsExit && !syncWithAnalysisDate ? agentCostsExit : null}
              formatter={getFormattedDecimalNumber}
              placeholder={getFormattedDecimalNumber(accCosts.agentCosts)}
              incDecStep={bpDiscountRate.agent / 100}
              setIncDecValue={(e) => setAssetDCFParams({ agentCostsExit: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="d-flex align-items-center justify-content-center">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
                width: '110px'
              }}
              type="number"
              disabled={syncWithAnalysisDate}
              value={getFormattedEnglishNumber(bpDiscountRate.agent)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, agent: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center my-2 justify-content-end mr-0 pr-0"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(agentCostsValue)} €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-3 pb-2 d-flex justify-content-between align-items-center" style={{ margin: 0 }}>
          <Col xs={8} className="align-items-center my-2 ml-0 pl-0">
            <h4 className="mb-0 text-black-50 inner_progress">Notary/ Other Costs in %</h4>
            {/* <h6  style={{ color: '#797979' }}>In %</h6> */}
          </Col>
          <Col xs={6}>
            <IncDecCounter
              disabled={syncWithAnalysisDate}
              value={notaryCostsExit && !syncWithAnalysisDate ? notaryCostsExit : null}
              formatter={getFormattedDecimalNumber}
              placeholder={getFormattedDecimalNumber(accCosts.notaryCosts)}
              incDecStep={bpDiscountRate.notary / 100}
              setIncDecValue={(e) => setAssetDCFParams({ notaryCostsExit: e })}
              className="m-0"
              // label="%"
              min={0} max={100}
            // incDecStep={0.5}
            />
          </Col>
          <Col xs={6}>
            {/* <h4 className="holding_text">Steps BP</h4> */}
            {/* <div className="d-flex align-items-center justify-content-center">
              <div> */}
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px", width: '110px'
              }}
              type="number"
              disabled={syncWithAnalysisDate}
              value={getFormattedEnglishNumber(bpDiscountRate.notary)}
              onChange={(e) => setBpDiscountRate(prev => ({ ...prev, notary: e.target.value }))}
            />
            {/* </div>
            </div> */}
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center my-2 justify-content-end mr-0 pr-0"
          >
            <h4 className="mb-0 text-black-50 inner_price">{getFormattedEnglishNumber(notaryCostsExitValue)} €</h4>
          </Col>
        </Row>
        {/* <Row gutter={24} className="pt-2 mb-2 d-flex justify-content-between">
          <Col xs={9} className="align-items-center my-2">
            <h4 className="mb-0 text-black">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black">{landTransferTaxExit + agentCostsExit + notaryCostsExit} %</h4>
          </Col>
          <Col
            xs={7}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black">{getFormattedInt(landTransferValue + agentCostsValue + notaryCostsExitValue)} €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={9} className="align-items-center my-2">
            <h4 className="mb-0 text-blue">Gross Terminal Value</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2  justify-content-center"
          >
            <h4 className="mb-0 text-blue">{getPerSpaceValue(grossTerminalValue, calculation.assetHVLParams, Object.values(calculation.calculationDetail.rentRolls))} €/sqm</h4>
          </Col>
          <Col
            xs={7}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">{getFormattedInt(grossTerminalValue)} €</h4>
          </Col>
        </Row> */}
      </Card>
    </div>
  );
}

export default VeAcquisitionCostsExit;
