/* eslint-disable react/require-default-props */
import { Button, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";
import {getFormattedDecimalNumber, getFormattedEnglishNumber} from "../../utils/digitHelper";

function getCurrentOrDefault( value , defaultValue) {
  return value ? value : defaultValue;
}

function IncDecCounter(props) {
  const { className, value, min, max, incDecStep, setIncDecValue, label, placeholder, formatter } = props;
  
  const incNum = () => {
    const baseValue = getCurrentOrDefault(value, placeholder)
    if (baseValue <= max) {
      persistNewValue(baseValue + incDecStep);
    }
  };

  const decNum = () => {
    const baseValue = getCurrentOrDefault(value, placeholder)
    if (baseValue >= min) {
      persistNewValue(baseValue - incDecStep);
    }
  };

  const onChange = (newValue) => {
    persistNewValue(newValue)
  };

  const persistNewValue = (newValue) => {
    console.log(newValue)
    if (setIncDecValue !== null) {
      if(isNaN(newValue) || newValue === null || newValue === undefined){
        setIncDecValue(null);
      }else{
        if (newValue >= min && newValue <= max)
        {
          setIncDecValue(newValue);
        }else{
          //Do nothing!
        }
          
      }
    }
  }
  
  return (
    <div
      className={`counter-field p-0 ${props.disabled ? "counter-field-disabled" : ""} d-flex align-items-center justify-content-between ${className}`}
    >
      <Button
        disabled={props.disabled}
        icon={<AiOutlineMinus />}
        onClick={decNum}
        className="dec-num-btn"
      />
      <div className="incDec__inner">
        <InputNumber
            className={"removeStyles noBorder"}
            disabled={props.disabled}
            controls={false}
            formatter={(rawValue) => rawValue && formatter ? formatter(rawValue) : rawValue}
            placeholder={formatter ? formatter(placeholder) : placeholder}
            onChange={onChange}
            value={value}
        />
        {label}

      </div>
      <Button
        disabled={props.disabled}
        icon={<AiOutlinePlus />}
        onClick={incNum}
        className="inc-num-btn"
      />
    </div>
  );
}

export default IncDecCounter;

IncDecCounter.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  incDecStep: PropTypes.number,
  disabled: PropTypes.bool,
  setIncDecValue: PropTypes.func,
  formatter: PropTypes.func
};
IncDecCounter.defaultProps = {
  label: "",
  className: "",
  incDecStep: 1,
  value: null,
  min: -999,
  max: 999,
  disabled: false,
  setIncDecValue: null,
  formatter: null
};
