import { Button, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";

function NonRecoverableCostsTerm(props) {
  const { next, prev, handleCancel } = props;

  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("nonRecoverableCostTerm", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("nonRecoverableCostTerm");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;


  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Non Recoverable Costs Term
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Maintenance"
              name="maintenance"
              initialValue={FormData?.maintenance}
              rules={[
                {
                  required: true,
                  message: "Please input cost",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input  placeholder="Eur / Sq M" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Management"
              name="management"
              initialValue={FormData?.management}
              rules={[
                {
                  required: true,
                  message: "Please input Management",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input  placeholder="%" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Other Costs"
              name="otherCosts"
              initialValue={FormData?.otherCosts}
              rules={[
                {
                  required: true,
                  message: "Please input Other Costs!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input  placeholder="%" type="number" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default NonRecoverableCostsTerm;

NonRecoverableCostsTerm.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
