import DataTable from '../../../../../../../components/Layout/Table/DataTable';
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper';

const data = [
    {
        key: '1',
        useType: 'Residential',
        unitType: 'Parking internal',
        totalUnit: 60,
        totalArea: 5000,
        let: 4000,
        vacant: -1000,
        vacancy: -5.5,
        grossRentalIncomePerUnit: 20000,
        grossRentalIncomePerAnum: 100000,
        nonRecoverableCostsPercent: -3,
        nonRecoverableCostsPerAnum: -5000,
        netOperatingIncomePerUnit: 15000,
        netOperatingIncomePerAnum: 75000,
        marketRentIncomeArea: 5000,
        marketRentIncomePerSQM: 12,
        walt: 10,
        rentalLevel: 90,
    },
    {
        key: '2',
        useTypeName: 'Commercial',
        unitType: 'Parking internal',
        totalUnit: 60,
        totalArea: 8000,
        let: 7000,
        vacant: 1000,
        vacancy: 12.5,
        grossRentalIncomePerUnit: -30000,
        grossRentalIncomePerAnum: 200000,
        nonRecoverableCostsPercent: 4,
        nonRecoverableCostsPerAnum: 8000,
        netOperatingIncomePerUnit: -20000,
        netOperatingIncomePerAnum: 100000,
        marketRentIncomeArea: 8000,
        marketRentIncomePerSQM: 15,
        walt: 8,
        rentalLevel: -70,
    },
    {
        key: '3',
        useTypeName: 'Total Area',
        unitType: 'Total Unit',
        totalUnit: 60,
        totalArea: 8000,
        let: 7000,
        vacant: 1000,
        vacancy: 12.5,
        grossRentalIncomePerUnit: -30000,
        grossRentalIncomePerAnum: 200000,
        nonRecoverableCostsPercent: 4,
        nonRecoverableCostsPerAnum: 8000,
        netOperatingIncomePerUnit: -20000,
        netOperatingIncomePerAnum: 100000,
        marketRentIncomeArea: 8000,
        marketRentIncomePerSQM: 15,
        walt: 8,
        rentalLevel: -70,
    },
];

const AssetDetailsTables = ({ assetAreaAndIncomeDetails }) => {
    const firstTableColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Use Type</p>,
            align: "left",
            children: [{
                width: "200px",
                dataIndex: "useTypeName",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Area</p>,
                align: "left",
                render: (text) => (
                    <span style={{ fontSize: '12px' }}>
                        {text ? text : "N/A"}
                    </span>
                ),
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Total Area</p>,
            align: "right",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "totalArea",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Let</p>,
            align: "right",
            children: [{
                dataIndex: "let",
                align: "right",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : null}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacant</p>,
            align: "right",
            children: [{
                dataIndex: "vacant",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacancy</p>,
            align: "right",
            children: [{
                dataIndex: "vacancy",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
        {
            id: 5,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "grossRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Non Recoverable Costs</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPercentage",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }, {
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPerAnum",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Net Operating Income</p>,
            align: "center",
            children: [{
                dataIndex: "netOperatingIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "netOperatingIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Market Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "marketRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "marketRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 9,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "right",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>years</p>,
                align: "right",
            }]
        },
        {
            id: 10,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Rental Level</p>,
            align: "right",
            children: [{
                dataIndex: "rentalLevel",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
    ];
    const SecondTableColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Unit Type</p>,
            align: "left",
            children: [{
                width: "200px",
                dataIndex: "unitType",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Unit</p>,
                align: "left",
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Total Units</p>,
            align: "right",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "totalArea",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Let</p>,
            align: "right",
            children: [{
                dataIndex: "let",
                align: "right",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : null}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacant</p>,
            align: "right",
            children: [{
                dataIndex: "vacant",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Vacancy</p>,
            align: "right",
            children: [{
                dataIndex: "vacancy",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
        {
            id: 5,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "grossRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Non Recoverable Costs</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPercentage",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }, {
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                dataIndex: "nonRecoverableCostsPerAnum",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Net Operating Income</p>,
            align: "center",
            children: [{
                dataIndex: "netOperatingIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "netOperatingIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Market Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "marketRentalIncomeSqm",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/sq m</p>,
                align: "right",
            }, {
                dataIndex: "marketRentalIncomeYear",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>€/p.a.</p>,
                align: "right",
            }]
        },
        {
            id: 9,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "right",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>years</p>,
                align: "right",
            }]
        },
        {
            id: 10,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Rental Level</p>,
            align: "right",
            children: [{
                dataIndex: "rentalLevel",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit', fontSize: '12px' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>in %</p>,
                align: "right",
            }]
        },
    ];

    // const useTypeTableData = assetAreaAndIncomeDetails?.useTypeValues ? Object.values(assetAreaAndIncomeDetails?.useTypeValues) : [];
    // const totalValues = assetAreaAndIncomeDetails?.totalValues;
    const useTypeTableData = data;
    const totalValues = data[0];

    const totalSummary = []
    // if (totalValues) {
    // const newTotalValues = {
    //     ...totalValues,
    //     useTypeName: "Total Area"
    // };
    const newTotalSummaryValues = {
        ...totalValues,
        useTypeName: "Total"
    };
    // useTypeTableData.push(newTotalValues);
    totalSummary.push(newTotalSummaryValues);
    // }

    return (
        <div className='custom-card'>
            <h2 style={{ fontSize: '24px', paddingBottom: '14px' }}>Area & Income Overview</h2>

            <div>
                <DataTable
                    columns={firstTableColumns.filter((item) => item.hidden === false)}
                    dataSource={totalSummary}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-header-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
            <div>
                <DataTable
                    columns={firstTableColumns.filter((item) => item.hidden === false)}
                    dataSource={useTypeTableData}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
            <div className='pt-4'>
                <DataTable
                    columns={SecondTableColumns.filter((item) => item.hidden === false)}
                    dataSource={useTypeTableData}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
        </div>
    )
}

export default AssetDetailsTables