import {
  Avatar,
  // Button,
  Card,
  Col,
  Row,
  // Upload,
  // message,
  Form,
  Input,
} from "antd";
import { useState } from "react";
// import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import IMAGES from "../../../assets/images";

export default function EditProfile() {
  const [image, setImage] = useState(IMAGES.CHILD_IMG);

  const onImageChange = event => {
    setImage(URL.createObjectURL(event.target.files[0]))
  }

  return (
    <div className="edit-profile">
      <Card className="w-100">
        <div className="d-flex justify-content-between">
          <h3 className="text-blue mb-0">Edit Profile</h3>
        </div>
        <hr />
        <Row gutter={24}>
          <Col xs={24} className="mt-4 d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <Avatar
                size={165}
                icon={<img src={image} alt="page-icon" className="page-icon" />}
              />
              <Col flex='none' className="p-0">
                <div className="upload_btn">
                  <label htmlFor="upload" className="d-flex bg-circle">
                    <FaRegEdit
                      size={25}
                      className="p-1 cursor-pointer upload-icon"
                    />
                    <input id="upload" type="file" multiple accept="image/*" onChange={onImageChange} />
                  </label>
                </div>
              </Col>
              {/* <div className="text-center mt-3"> */}
              {/* <Upload
                abcdProps={abcProps}
              onChange={() => handleChange()}
              id="upload"
              accept="image/*"
              style={{ display: "none" }}
              > */}

              {/* </div> */}
              {/* <Button
                className="d-flex align-items-center justify-content-center rounded-circle upload-btn"
                icon={<FaRegEdit />}
              /> */}
              {/* </Upload> */}
            </div>
          </Col>
          <Col xs={24}>
            <Form
              name="basic"
              className="user-modal"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                remember: true,
              }}
              // onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name="fname"
                    label="First Name"
                    rules={[
                      {
                        required: false,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="lname"
                    label="Last Name"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Last name!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: false,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
