/* eslint-disable */
import api from "../../../utils/api";
import {apiClient} from "../../../index";
import {AssetDto, Search, SearchCriteria} from "reia-rest-client";
import {RentRollDto} from "reia-rest-client";

// Create an account for projects
const createRentRoll = async (data) => {
  const response = await apiClient.rentRollApi().addRentRoll(data);
  return response.data;
};

const getRentRollsByAsset = async (data) => {
  const assetId: SearchCriteria = {filterKey: "asset.id", value: data.assetId, operation: "eq"}
  const search: Search = {dataOption: "all", searchCriteriaList: [assetId]}
  const response = await apiClient.rentRollApi().searchRentRolls(search, data.page-1, data.size);
  return response.data;
};

const updateRentRoll = async (rentRollDto: RentRollDto) => {
  const response = await apiClient.rentRollApi().updateRentRoll(rentRollDto.id,rentRollDto);
  return response.data;
};

// Delete projects
const deleteRentRolls = async (data) => {
  const response = await apiClient.rentRollApi().deleteRentRolls(data);
  return response.data;
};

// Get Single projects
const getRentRollById = async (data) => {
  const response = await apiClient.rentRollApi().getRentRoll(data.rentRollId)
  return response.data;
};

const rentRoll = {
  createRentRoll,
  getRentRollsByAsset,
  updateRentRoll,
  deleteRentRolls,
  getRentRollById
};

export default rentRoll;
