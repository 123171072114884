import { useDispatch } from "react-redux";
import { useAuth } from "react-oidc-context"
import PropTypes from "prop-types";
import FullScreenLoader from "../../components/Layout/Loader/FullScreenLoader";
import AppLayout from "../../components/Layout/AppLayout/AppLayout";
import Title from "antd/es/skeleton/Title";
import jwt_decode from "jwt-decode";
import {setUser} from "../../app/features/auth/auth.slice";

function ProtectedRoute({ element }) {
  const dispatch = useDispatch();
  const auth = useAuth();
  
  if (auth.isLoading) {
    return <FullScreenLoader />;
  }

  if (auth.error) {
    return (
        <div>
          <Title>Oops ...</Title>
          <Title level={2}>{auth.error.message}</Title>
        </div>
    )
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect()
    return null
  }
  const user = auth.user;
  Object.assign(user, jwt_decode(user.access_token));

  const app_user = {};
  app_user.userId = user.sub;
  app_user.token = user.access_token;
  app_user.roles = user.resource_access["reia-management-app"].roles;
  app_user.email = user.email;
  app_user.name = user.name;
  app_user.imageUrl = ""
  dispatch(setUser(app_user));
  
  return <AppLayout>{element}</AppLayout>;
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
