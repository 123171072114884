/* eslint-disable */

import {
  Avatar,
  // Button,
  // Checkbox,
  Col,
  Form,
  // Dropdown,
  Input,
  // Menu,
  Row,
} from "antd";
import moment from "moment";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
// import IMAGES from "../../../../assets/images";
import DataTable from "../../../../components/Layout/Table/DataTable";
import SearchForm from "./TotalTenants/SearchForm";
import TenantsDetailAndProfile from "./TotalTenants/TenantsDetailAndProfile";
export default function TotalTenants() {
  const { Search } = Input;
  const [form] = Form?.useForm();
  // const [cheacky, setCheacky] = useState([]);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showRecordTenants, setShowRecordTenants] = useState(true);
  const [showProfileTenants, setShowProfileTenants] = useState(false);
  const [tenantsDetails, setTenantsDetail] = useState(false);
  const [tenantsRecord, setTenantsRecord] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [contractStartValue, setContractStartValue] = useState(null);
  const [contractEndValue, setContractEndValue] = useState(null);
  const { allTenants, isLoading } = useSelector((state) => state.rentRoll);

  const [tenantsFilter, setTenantsFilter] = useState({
    search: "",
    contractStartDate: "",
    contractEndDate: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  const closeModal = () => {
    setIsModalOpen(false);
    setContractEndValue(null);
    setContractStartValue(null);
    setSearchValue("");
    form.resetFields();
  };
  const closeTenantsDetailModal = () => {
    setTenantsDetail(false);
    setTenantsRecord("");
    setShowRecordTenants(true);
    setShowProfileTenants(false);
    form.resetFields();
  };

  const pageNumbers =
    tenantsFilter.pagination.pageNo * tenantsFilter.pagination.size -
    tenantsFilter.pagination.size +
    1;

  const nextPageNumber =
    tenantsFilter.pagination.pageNo * tenantsFilter.pagination.size >
    allTenants?.totalRecords
      ? allTenants?.totalRecords
      : tenantsFilter.pagination.pageNo * tenantsFilter.pagination.size;

  const initialColState = [
    {
      id: 0,
      hidden: false,
      title: "Photo",
      dataIndex: "imageUrl",
      render: (imageUrl, values) => (
        <div>
          <Avatar
            size={45}
            src={
              imageUrl
                ? imageUrl
                : `https://ui-avatars.com/api/?name=${`${values.tenantName}`}&background=00215b&color=fff`
            }
            // icon={
            //   <img src={record.photo} alt="page-icon" className="page-icon" />
            // }
          />
        </div>
      ),
    },
    {
      id: 1,
      hidden: false,
      title: "Tenant Name",
      dataIndex: "tenantName",
    },
    {
      id: 2,
      hidden: false,
      title: "Total Assets",
      dataIndex: "totalAssets",
    },
    {
      id: 3,
      hidden: false,
      title: "Total Revenue",
      dataIndex: "totalRevenue",
    },
    {
      id: 4,
      hidden: false,
      title: "Contract Start Date",
      dataIndex: "contractStartDate",
      render: (text) => (
        <div className="d-flex align-items-center justify-content-center">
          {text?.split("T")[0]}
        </div>
      ),
    },
    {
      id: 5,
      hidden: false,
      title: "Contract End Date",
      dataIndex: "contractEndDate",
      render: (text) => (
        <div className="d-flex align-items-center justify-content-center">
          {text?.split("T")[0]}
          <IoIosArrowForward size={20} className="ml-5" />
        </div>
      ),
    },
  ];
  // const [columns, setColumns] = useState(initialColState);

  // const handleChange = (e) => {
  //   // console.log(e.target.checked, e.target.value, e.target.id);
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //     setCheacky((current) => [...current, e.target.value]);
  //   } else {
  //     setCheacky((current) =>
  //       current.filter((option) => option !== e.target.value)
  //     );
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  //   // setColumns(current => current.filter((val) => val.dataIndex !== e.target.value))
  //   // console.log(e.target.checked)
  // };

  // const handleReset = () => {
  //   setColumns(initialColState);
  //   setCheacky([]);
  // };

  // const hideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="tenantName"
  //             name="Tenant Name"
  //             id={0}
  //             checked={cheacky.includes("tenantName")}
  //             onChange={handleChange}
  //           >
  //             Tenant Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="totalAssets"
  //             name="Total Assets"
  //             id={1}
  //             checked={cheacky.includes("totalAssets")}
  //             onChange={handleChange}
  //           >
  //             Total Assets
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="totalRevenue"
  //             name="Total Revenue"
  //             id={2}
  //             checked={cheacky.includes("totalRevenue")}
  //             onChange={handleChange}
  //           >
  //             Total Revenue
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="contractStartDate"
  //             name="Contract Start Date"
  //             id={3}
  //             checked={cheacky.includes("contractStartDate")}
  //             onChange={handleChange}
  //           >
  //             Contract Start Date
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="contractEndDate"
  //             name="Contract End Date"
  //             id={4}
  //             checked={cheacky.includes("contractEndDate")}
  //             onChange={handleChange}
  //           >
  //             Contract End Date
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: "8",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={handleReset}>
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  // const [open, setOpen] = useState(false);

  // const handleOpenChange = (flag) => {
  //   setOpen(flag);
  // };

  // const handleStartDate = (date, dateString) => {
  //   setContractStartValue(dateString);
  // };
  // const handleEndtDate = (date, dateString) => {
  //   setContractEndValue(dateString);
  // };

  // const onSearch = () => {
  //   dispatch(
  //     getAllTenants({
  //       search: searchValue ? searchValue : "",
  //       contractStartDate: contractStartValue ? contractStartValue : "",
  //       contractEndDate: contractEndValue ? contractEndValue : "",
  //       pagination: {
  //         pageNo: tenantsFilter.pagination.pageNo,
  //         size: tenantsFilter.pagination.size,
  //       },
  //     })
  //   );
  // };

  useEffect(() => {
    // dispatch(
    //   getAllTenants({
    //     search: searchValue ? searchValue : "",
    //     contractStartDate: contractStartValue
    //       ? moment(contractStartValue)?.format("YYYY-MM-DD")
    //       : null,
    //     contractEndDate: contractEndValue
    //       ? moment(contractEndValue)?.format("YYYY-MM-DD")
    //       : null,
    //     pagination: {
    //       pageNo: tenantsFilter.pagination.pageNo,
    //       size: tenantsFilter.pagination.size,
    //     },
    //   })
    // );
  }, [
    dispatch,
    searchValue,
    contractStartValue,
    contractEndValue,
    tenantsFilter,
  ]);

  const navigateRow = (record, rowIndex) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        setTenantsDetail(true);
        setTenantsRecord(record);
        console.log(record, rowIndex, path); // to avoid es lint error
      }, // click row
    };
  };
  return (
    <div className="total-tenants">
      <Row
        gutter={24}
        className="d-flex justify-content-end align-items-center button-search"
      >
        <Col xs={24} sm={7} className="mt-1">
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              onChange={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              className="mr-2"
              placeholder="Search Tenant"
              // onClick={() => setIsModalOpen(true)}
            />
            <span
              onClick={() => setIsModalOpen(true)}
              className="filter-icon-span mr-2 cursor-pointer"
            >
              <FaFilter />
            </span>
            {/* <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <DataTable
          columns={initialColState}
          dataSource={allTenants?.tenants}
          className="antd-table-striped"
          onRow={navigateRow}
          loading={isLoading}
          pagination={{
            current: tenantsFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: allTenants?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setTenantsFilter({
                ...tenantsFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
        />
      </div>

      {allTenants?.tenants?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of {""}
            {allTenants?.totalRecords} entries
          </h5>
        </div>
      )}

      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setIsModalOpen={setIsModalOpen}
        setSearchValue={setSearchValue}
        setContractStartValue={setContractStartValue}
        setContractEndValue={setContractEndValue}
        form={form}
      />
      <TenantsDetailAndProfile
        tenantsRecord={tenantsRecord}
        visible={tenantsDetails}
        handleCancel={closeTenantsDetailModal}
        showRecordTenants={showRecordTenants}
        setShowRecordTenants={setShowRecordTenants}
        showProfileTenants={showProfileTenants}
        setShowProfileTenants={setShowProfileTenants}
        form={form}
      />
    </div>
  );
}
