/* eslint-disable */
import api from "../../../utils/api";
import {apiClient} from "../../../index";

const updateVisibleColumns = async (data) => {
  const response = await api.post(`/api/common/updateVisibleColumns`, data);
  return response.data;
};

const getAllCountry = async (country) => {
  const response = await api.get(`/api/common/getAllCountries`, country);
  return response.data;
};

const getAllState = async (data) => {
  const response = await api.get("/api/common/getAllStates", {
    params: data,
  });
  return response.data.data;
};

const getAllCities = async (data) => {
  const response = await api.get("/api/common/getAllCities", {
    params: data,
  });
  return response.data.data;
};

const getByTypeDropdown = async (data) => {
  const response = await apiClient.i18nApi().getI18nItemsForContext(data.catalog, data.context, 0, -1)
  return response.data;
};

const getI18nItem = async (data) => {
  const response = await apiClient.i18nApi().getI18nItem(data.i18nKey)
  return response.data;
};

const updateI18nItem = async (data) => {
  console.log(data)
  const response = await apiClient.i18nApi().updateI18nItem(data.i18nItem.key, data.i18nItem)
  return response.data;
};

// const uploadBase64 = async (data) => {
//   const response = await api.post(`/api/common/uploadBase64`, data);
//   return response.data;
// };
const uploadBase64 = async (file) => {
  const response = await api.post(`/api/common/uploadBase64`, file);
  // const response = await API.post(`/api/azure/post`, file);

  return response.data;
};

const lookUps = {
  getAllCountry,
  getAllState,
  getAllCities,
  getByTypeDropdown,
  updateVisibleColumns,
  uploadBase64,
  getI18nItem,
  updateI18nItem
};

export default lookUps;
