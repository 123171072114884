import { Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import AcquisitionCosts from "./component/CalculationSetting/AcquisitionCosts";
import Definition from "./component/CalculationSetting/Definition";
import General from "./component/CalculationSetting/General";
import MarketValue from "./component/CalculationSetting/MarketValue";
import PriceIndexOne from "./component/CalculationSetting/PriceIndexOne";
import PriceIndexTwo from "./component/CalculationSetting/PriceIndexTwo";


function CalculationSetting() {
  
  const [isMobile, setIsMobile] = useState("left");

  useEffect(() => 
    window.screen.width < 994 ? setIsMobile("top") : setIsMobile("left")
  , [window.screen.width]);

  const detectWindowSize=() =>window.innerWidth < 994 ? setIsMobile("top"):setIsMobile("left");        


window.onresize = detectWindowSize;


  return (
    <div className="calculation-setting">

      <Row gutter={24}>
        <Col xs={24} >
          <div className="w-100">
            <Tabs tabPosition={isMobile} defaultActiveKey="1">
              <Tabs.TabPane tab="General" key="1">
                <General />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Definitions" key="2">
                <Definition />
              </Tabs.TabPane>
              {/* <Tabs.TabPane tab="Calculations" key="3">
                <p>calculation</p>
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="Market Value" key="3">
                <MarketValue />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Acquisition Costs" key="4">
                <AcquisitionCosts />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Price Index #1" key="5">
                <PriceIndexOne />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Price Index #2" key="6">
                <PriceIndexTwo />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CalculationSetting;
