/* eslint-disable */
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { AiOutlinePlus } from "react-icons/ai";
import {
  Button,
  Input,
  Dropdown,
  Menu,
  // Checkbox,
  Row,
  Col,
  Avatar,
  Badge,
  // Collapse,
  Tag,
  Divider,
  notification,
  Form,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { FaFilter, FaShareSquare } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import {
  // BsEyeFill,
  BsFillEyeFill,
  // BsFileEarmarkCheckFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import {
  // MdArchive,
  MdDelete,
  MdEdit,
  // MdShare,
} from "react-icons/md";
// import {
//   UpCircleOutlined,
//   DownCircleOutlined,
//   CheckCircleFilled,
// } from "@ant-design/icons";
import PATH from "../../../utils/path";
import DataTable from "../../../components/Layout/Table/DataTable";
import IMAGES from "../../../assets/images";
// import AddUpdateNewProject from "./AddUpdateNewProject";
import CompleteProjects from "./component/UserDetails/CompleteProjects";
import ArchiveProjects from "./component/UserDetails/ArchiveProjects";
import SearchForm from "./component/UserDetails/SearchForm";
import DeleteUserModal from "../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdate from "./CreateUpdate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllArchivedByUserProjects,
  getAllCompletedByUserProjects,
  getAllProjectByUserList,
} from "../../../app/features/userDetail/userDetail.slice";
// import { deleteProject } from "../../../app/features/Projects/project.slice";
import { deleteUsers } from "../../../app/features/userManagement/user.slice";
import moment from "moment";
import ProjectManagerUser from "./ProjectManagerUser";
// import {
//   archiveProject,
//   completeProject,
// } from "../../../app/features/Projects/project.slice";

// const { Panel } = Collapse;
const { Search } = Input;

function UserDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const param = useParams();
  const userId = param.userId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  // const [addUpdateNewProjectModal, setAddUpdateNewProjectModal] =
  //   useState(false);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [projectUserShow, setProjectUserShow] = useState(1);
  const [showNewProject, setShowNewProject] = useState(true);
  const [showCompletedProject, setShowCompletedProject] = useState(false);
  const [showProjectManagers, setShowProjectManagers] = useState(false);
  const [showArchiveProject, setShowArchiveProject] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [searchProjectManagerIdValue, setSearchProjectManagerIdValue] =
    useState();
  const [searchProjectTypeIdValue, setSearchProjectTypeIdValue] = useState();
  const [searchProjectStatusIdValue, setSearchProjectStatusIdValue] =
    useState();
  const { projectsByUser, isLoading } = useSelector(
    (state) => state.userDetail
  );
  const { user } = useSelector((state) => state.auth);
  const [flag, setFlag] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  // const [cheacky, setCheacky] = useState([]);
  const [projectFilter, setProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const onNewProjects = () => {
    setShowNewProject(true);
    setShowCompletedProject(false);
    setShowProjectManagers(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onCompletedProjects = () => {
    setShowCompletedProject(true);
    setShowNewProject(false);
    setShowProjectManagers(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onArchiveProjects = () => {
    setShowArchiveProject(true);
    setShowCompletedProject(false);
    setShowProjectManagers(false);
    setShowNewProject(false);
  };
  const onProjectManager = () => {
    setShowProjectManagers(true);
    setShowCompletedProject(false);
    setShowNewProject(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeAddUpdateNewProjectModal = () => {
  //   setAddUpdateNewProjectModal(false);
  // };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpen(false);
    form.resetFields();
    setSearchProjectStatusIdValue(null);
    setSearchProjectTypeIdValue(null);
    setSearchProjectManagerIdValue(null);
    setSearchValue("");
  };
  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  // const closeDeleteProjectModal = () => {
  //   setDeleteProjectModal(false);
  // };

  // profile Dropdown
  // const profileDropdown = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <div
  //             className="d-flex justify-content-left align-items-center table-dropdown"
  //             role="button"
  //             tabIndex="0"
  //             onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
  //             onKeyDown={() =>
  //               navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)
  //             }
  //           >
  //             <BsEyeFill />
  //             <h5 className="mb-0 ml-3">View Project</h5>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "3",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <MdArchive />
  //             <h5 className="mb-0 ml-3">Archive Project</h5>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "3",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <CheckCircleFilled />
  //             <h5 className="mb-0 ml-3">Complete Project</h5>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "5",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <FaShareSquare />
  //             <h5 className="mb-0 ml-3">Export Project</h5>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const initialColState = [
    {
      id: 0,
      hidden: false,
      title: "Project Reference",
      dataIndex: "projectId",
    },
    {
      id: 1,
      hidden: false,
      title: "Project Name",
      dataIndex: "projectName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 12,
      title: "Project Manager",
      dataIndex: "projectManager",
      key: "projectManager",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 2,
      hidden: false,
      title: "Client Name",
      dataIndex: "clientName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 3,
      hidden: false,
      title: "Project Type",
      dataIndex: "projectType",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Project Status",
      dataIndex: "projectStatus",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 5,
      hidden: false,
      title: "Portfolio's",
      dataIndex: "totalPorfolio",
      render: (text, value) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="portfolio-tag"
            role="button"
            tabIndex="0"
            onClick={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${value.projectId}/project-detail`
              )
            }
            onKeyDown={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${value.projectId}/project-detail`
              )
            }
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 6,
      hidden: false,
      title: "Assets",
      dataIndex: "totalAssets",
      width: "10%",
      render: (text, value) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="assets-tag"
            role="button"
            tabIndex="0"
            onClick={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${value.projectId}/project-detail`
              )
            }
            onKeyDown={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${value.projectId}/project-detail`
              )
            }
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 7,
      hidden: false,
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
                          )
                        }
                      >
                        <BsFillEyeFill />
                        <h5 className="mb-0 ml-3">View project</h5>
                      </div>
                    ),
                  },
                  // {
                  //   key: "10",
                  //   label: (
                  //     <div
                  //       className="d-flex justify-content-left align-items-center table-dropdown"
                  //       role="button"
                  //       tabIndex="0"
                  //       onClick={() => completeProjectFunction(row)}
                  //       onKeyDown={() => completeProjectFunction(row)}
                  //     >
                  //       <CheckCircleFilled />
                  //       <h5 className="mb-0 ml-3">Complete Project</h5>
                  //     </div>
                  //   ),
                  // },
                  // {
                  //   key: "4",
                  //   label: (
                  //     <div
                  //       className="d-flex justify-content-left align-items-center table-dropdown"
                  //       role="button"
                  //       tabIndex="0"
                  //       onClick={() => archiveProjectFunction(row)}
                  //       onKeyDown={() => archiveProjectFunction(row)}
                  //     >
                  //       <MdArchive />
                  //       <h5 className="mb-0 ml-3">Archive Project</h5>
                  //     </div>
                  //   ),
                  // },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  const [selectedProject, setselectedProject] = useState(null);
  // const [columns, setColumns] = useState(initialColState);
  // const handleChange = (e) => {
  //   // console.log(e.target.checked, e.target.value, e.target.id);
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //     setCheacky((current) => [...current, e.target.value]);
  //   } else {
  //     setCheacky((current) =>
  //       current.filter((option) => option !== e.target.value)
  //     );
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  //   // setColumns(current => current.filter((val) => val.dataIndex !== e.target.value))
  //   // console.log(e.target.checked)
  // };
  // const handleReset = () => {
  //   setColumns(initialColState);
  //   setCheacky([]);
  // };

  // Hide Table Column Dropdown
  // const initialHideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="projectId"
  //             name="Project ID"
  //             id={0}
  //             checked={cheacky.includes("projectId")}
  //             onChange={handleChange}
  //           >
  //             Project ID
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="projectName"
  //             name="Project Name"
  //             id={1}
  //             checked={cheacky.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Project Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="clientName"
  //             name="Client Name"
  //             id={2}
  //             checked={cheacky.includes("clientName")}
  //             onChange={handleChange}
  //           >
  //             Client Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="projectType"
  //             name="Project Type"
  //             id={3}
  //             checked={cheacky.includes("projectType")}
  //             onChange={handleChange}
  //           >
  //             Project Type
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="projectStatus"
  //             name="Project Staus"
  //             id={4}
  //             checked={cheacky.includes("projectStatus")}
  //             onChange={handleChange}
  //           >
  //             Project Status
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="portfolios"
  //             name="Portfolios"
  //             id={5}
  //             checked={cheacky.includes("portfolios")}
  //             onChange={handleChange}
  //           >
  //             Portfolios
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <Checkbox
  //             value="assets"
  //             name="Assets"
  //             id={5}
  //             checked={cheacky.includes("assets")}
  //             onChange={handleChange}
  //           >
  //             Assets
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: "8",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={handleReset}>
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // const [hideTableColumns, sethideTableColumns] = useState(initialHideTableColumns);

  // const [open, setOpen] = useState(false);

  // const handleOpenChange = (flag) => {
  //   setOpen(flag);
  // };
  const pageNumbers =
    projectFilter.pagination.pageNo * projectFilter.pagination.size -
    projectFilter.pagination.size +
    1;

  const nextPageNumber =
    projectFilter.pagination.pageNo * projectFilter.pagination.size >
    projectsByUser?.projectList?.[0]?.totalRecords
      ? projectsByUser?.projectList?.[0]?.totalRecords
      : projectFilter.pagination.pageNo * projectFilter.pagination.size;

  useEffect(() => {
    if (showNewProject) {
      dispatch(
        getAllProjectByUserList({
          userId: userId,
          search: searchValue ? searchValue : "",
          projectManagerId: searchProjectManagerIdValue
            ? searchProjectManagerIdValue
            : null,
          ProjectStatusId: searchProjectStatusIdValue
            ? searchProjectStatusIdValue
            : null,
          ProjectTypeId: searchProjectTypeIdValue
            ? searchProjectTypeIdValue
            : null,
          pageNo: projectFilter.pagination.pageNo,
          size: projectFilter.pagination.size,
        })
      );
    } else if (showCompletedProject) {
      dispatch(
        getAllCompletedByUserProjects({
          userId: userId,
          search: searchValue ? searchValue : "",
          projectManagerId: searchProjectManagerIdValue
            ? searchProjectManagerIdValue
            : null,
          ProjectStatusId: searchProjectStatusIdValue
            ? searchProjectStatusIdValue
            : null,
          ProjectTypeId: searchProjectTypeIdValue
            ? searchProjectTypeIdValue
            : null,
          pageNo: projectFilter.pagination.pageNo,
          size: projectFilter.pagination.size,
        })
      );
    } else if (showArchiveProject) {
      dispatch(
        getAllArchivedByUserProjects({
          userId: userId,
          search: searchValue ? searchValue : "",
          projectManagerId: searchProjectManagerIdValue
            ? searchProjectManagerIdValue
            : null,
          ProjectStatusId: searchProjectStatusIdValue
            ? searchProjectStatusIdValue
            : null,
          ProjectTypeId: searchProjectTypeIdValue
            ? searchProjectTypeIdValue
            : null,
          pageNo: projectFilter.pagination.pageNo,
          size: projectFilter.pagination.size,
        })
      );
    }
  }, [
    dispatch,
    searchValue,
    searchProjectManagerIdValue,
    searchProjectStatusIdValue,
    searchProjectTypeIdValue,
    projectFilter,
    showNewProject,
    showCompletedProject,
    showArchiveProject,
  ]);
  // useEffect(() => {
  //   const finalData = {
  //     userId: userId,
  //   };
  //   dispatch(getAllProjectByUserList(finalData));
  // }, [dispatch]);

  const userDetailUpdate = () => {
    const finalData = {
      userId: userId,
    };
    dispatch(getAllProjectByUserList(finalData));
  };

  // const expandIcon = ({ isActive }) =>
  //   isActive ? <UpCircleOutlined /> : <DownCircleOutlined />;

  // const [handleCollapse, setHandleCollapse] = useState(1);
  const handleRowClick = (record, rowIndex) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        console.log(rowIndex, path); // to avoid es lint error
        setselectedProject(record);
        setProjectUserShow(2);
      }, // click row
    };
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  // const moveToNextDeleteProject = () => {
  //   const finalData = {
  //     userId: userId,
  //   };
  //   dispatch(getAllProjectByUserList(finalData));
  //   closeDeleteProjectModal();
  // };

  // const deleteProjects = () => {
  //   const finalData = [selectedProject?.projectId];
  //   dispatch(
  //     deleteProject({ finalData, moveToNextDeleteProject, notifyToaster })
  //   );
  // };

  const moveToNextDeleteUser = () => {
    closeDeleteUserModal();
    navigate(PATH.USER_MANAGEMENT_LIST);
  };

  const deleteUser = () => {
    const finalData = [projectsByUser?.basicUserData?.[0]?.userId];
    dispatch(deleteUsers({ finalData, moveToNextDeleteUser, notifyToaster }));
  };

  // const moveToNextArchived = () => {
  //   const finalData = {
  //     userId: userId,
  //   };
  //   dispatch(getAllProjectByUserList(finalData));
  // };

  // const archiveProjectFunction = (row) => {
  //   const finalData = {
  //     projectId: row.projectId,
  //     isArchived: true,
  //   };
  //   dispatch(archiveProject({ finalData, moveToNextArchived, notifyToaster }));
  // };

  // const completeProjectFunction = (row) => {
  //   const finalData = {
  //     projectId: row.projectId,
  //   };
  //   dispatch(completeProject({ finalData, moveToNextArchived, notifyToaster }));
  // };

  return (
    <div>
      <Row gutter={24} className="user-detail-wrapper">
        <Col xs={24} md={15} lg={18}>
          <Row
            gutter={24}
            className="d-flex justify-content-between align-items-center button-search"
          >
            <Col xl={5} lg={7} md={24}>
              <div>
                <div className="table-footer-btn d-flex align-items-center">
                  <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center asset-btn"
                      onClick={onNewProjects}
                    >
                      <div
                        className="active-action"
                        style={{
                          backgroundColor: active === true ? "#f2f3f5" : " ",
                        }}
                      >
                        <div
                          className="active-action d-flex align-items-center"
                          style={{
                            Color: active === true ? "#000" : "#eee",
                          }}
                        >
                          {/* <BsFillCircleFill size={10} fill="#2978A0" /> */}
                          <h4 className="mb-0 ml-2 text-black">New</h4>
                        </div>
                      </div>
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                      onClick={onCompletedProjects}
                    >
                      <div className="active-action d-flex align-items-center">
                        {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                        <h4 className="mb-0 ml-2 text-black">Completed</h4>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={14}
              lg={10}
              md={24}
              className="w-100 mt-2 mt-xs-2 mt-lg-0 d-flex align-items-center justify-content-end"
            >
              {projectsByUser?.basicUserData?.[0]?.roleName === "Corporate" && (
                <div className="table-footer-btn d-flex align-items-center mr-2">
                  <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                      onClick={() =>
                        navigate(
                          PATH.USER_MANAGEMENT_PROJECT_MANAGER.replace(
                            ":userId",
                            userId
                          )
                        )
                      }
                    >
                      <div className="active-action d-flex align-items-center">
                        {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                        <h4 className="mb-0 ml-2 text-black">
                          Project Managers
                        </h4>
                      </div>
                    </Button>
                  </div>
                </div>
              )}
              <div className="d-flex searchbar-project">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                <Search
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchValue(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    if (e.key === "Enter") {
                      setSearchValue(e.target.value);
                    }
                  }}
                  className="mr-2"
                  placeholder="Search project"

                  // onClick={() => setIsModalOpen(true)}
                />
                <span
                  onClick={() => setIsModalOpen(true)}
                  className="filter-icon-span mr-2 cursor-pointer"
                >
                  <FaFilter />
                </span>
                <span
                  className="filter-icon-span mr-2 cursor-pointer"
                  onClick={() => {
                    onArchiveProjects();
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={() => {
                    onArchiveProjects();
                  }}
                >
                  <img src={IMAGES.ARCHIVE_ICON} alt="Table Icon" />
                </span>
                {/* <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
              </div>
            </Col>
          </Row>
          {showNewProject && (
            <div className="mt-3">
              <DataTable
                onRow={handleRowClick}
                columns={initialColState}
                dataSource={projectsByUser?.projectList}
                pagination={{
                  current: projectFilter?.pagination?.pageNo || 1,
                  pageSize: 10,
                  total: projectsByUser?.projectList?.[0]?.totalRecords,
                  showSizeChanger: false,
                  disabled: false,
                  onChange: (page, pageSize) => {
                    setProjectFilter({
                      ...projectFilter,
                      pagination: {
                        pageNo: page,
                        size: pageSize,
                      },
                    });
                  },
                }}
                className="antd-table-striped"
                scroll={{
                  x: 800,
                  y: "calc(100vh - 345px)",
                }}
                loading={isLoading}
              />
            </div>
          )}
          {projectsByUser?.projectList?.length > 0 && (
            <div className="table-count">
              <h5 className="table-count-content">
                Showing {pageNumbers} to {nextPageNumber} of{" "}
                {projectsByUser?.projectList?.[0]?.totalRecords} entries
              </h5>
            </div>
          )}

          {showCompletedProject && (
            <CompleteProjects
              showCompletedProject={showCompletedProject}
              // sethideTableColumns={sethideTableColumns}
              setShowNewProject={setShowNewProject}
              // setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
              setActive={setActive}
              handleRowClick={handleRowClick}
            />
          )}
          {showArchiveProject && (
            <ArchiveProjects
              showArchiveProject={showArchiveProject}
              // sethideTableColumns={sethideTableColumns}
              setShowNewProject={setShowNewProject}
              // setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
              setActive={setActive}
              handleRowClick={handleRowClick}
            />
          )}
          {showProjectManagers && (
            <ProjectManagerUser
              showArchiveProject={showArchiveProject}
              // sethideTableColumns={sethideTableColumns}
              setShowNewProject={setShowNewProject}
              // setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
              setActive={setActive}
              handleRowClick={handleRowClick}
            />
          )}
        </Col>
        <Col
          xs={24}
          md={9}
          lg={6}
          className="bg-white p-0 rounded"
          style={{ height: "550px" }}
        >
          {/* <Collapse
            activeKey={handleCollapse}
            accordion
            expandIcon={expandIcon}
            expandIconPosition="end"
            defaultActiveKey={["2"]}
          >
            <Panel
              header="User Details"
              key="1"
              onClick={() => setHandleCollapse(1)}
            > */}
          {projectUserShow === 1 && (
            <div className="detail-drawer custom-drawer-content custom-drawer bg-white rounded-sm text-center p-3">
              <Avatar
                size={64}
                src={IMAGES.KID}
                className="mt-3"
                style={{ border: "#e6e6e6 3px solid" }}
              />
              <h3>
                {projectsByUser?.basicUserData?.[0]?.firstName
                  ? `${projectsByUser?.basicUserData?.[0]?.firstName} ${projectsByUser?.basicUserData?.[0]?.lastName}`
                  : "N/A"}
              </h3>
              <Badge
                count={
                  projectsByUser?.basicUserData?.[0]?.roleName
                    ? projectsByUser?.basicUserData?.[0]?.roleName
                    : "N/A"
                }
                style={{
                  backgroundColor: "#6F7173",
                }}
              />
              <div className="d-flex align-items-center justify-content-center mt-4">
                {user.roleId !== 2 && (
                  <>
                    <MdEdit
                      size={33}
                      className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                      onClick={() => {
                        setAddUpdateUserModal(true);
                        setSelectedRowKeys(projectsByUser?.basicUserData);
                        setFlag(true);
                      }}
                    />
                    <MdDelete
                      size={33}
                      className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                      onClick={() => setDeleteUserModal(true)}
                    />
                  </>
                )}
              </div>
              <Row className="mt-4 d-flex justify-content-center" gutter={10}>
                <Col flex="none">
                  <h5 className="mb-1">Projects</h5>
                  <h1 className="projects-count rounded-sm d-flex align-items-center justify-content-center text-blue">
                    {projectsByUser?.basicUserData?.[0]?.numActiveProjects
                      ? projectsByUser?.basicUserData?.[0]?.numActiveProjects
                      : 0}
                  </h1>
                </Col>
                <Col flex="none">
                  <h5 className="mb-1">Portfolio</h5>
                  <h1 className="projects-count rounded-sm d-flex align-items-center justify-content-center text-blue">
                    {projectsByUser?.basicUserData?.[0]?.totalPortfolios
                      ? projectsByUser?.basicUserData?.[0]?.totalPortfolios
                      : 0}
                  </h1>
                </Col>
                <Col flex="none">
                  <h5 className="mb-1">Assets</h5>
                  <h1 className="projects-count rounded-sm d-flex align-items-center justify-content-center text-blue">
                    {projectsByUser?.basicUserData?.[0]?.numActiveAssets
                      ? projectsByUser?.basicUserData?.[0]?.numActiveAssets
                      : 0}
                  </h1>
                </Col>
              </Row>
              <div className="custom-scroll custom-height mt-2">
                <li className="mt-4 px-3">
                  <h5 className="m-0 text-left">User ID</h5>
                  <p className="text-left">
                    {projectsByUser?.basicUserData?.[0]?.userId
                      ? projectsByUser?.basicUserData?.[0]?.userId
                      : "N/A"}
                  </p>
                  <hr />
                </li>
                <li className="mt-4 px-3">
                  <h5 className="m-0 text-left">Created Date</h5>
                  <p className="text-left">
                    {projectsByUser?.basicUserData?.[0]?.createdDate
                      ? moment(
                          projectsByUser?.basicUserData?.[0]?.createdDate
                        ).format("DD-MM-YYYY")
                      : "N/A"}
                  </p>
                  <hr />
                </li>
                <li className="mt-4 px-3">
                  <h5 className="m-0 text-left">Email</h5>
                  <p className="text-left">
                    {projectsByUser?.basicUserData?.[0]?.email
                      ? projectsByUser?.basicUserData?.[0]?.email
                      : "N/A"}
                  </p>
                  <hr />
                </li>
                <li className="mt-4 px-3">
                  <h5 className="m-0 text-left">Location</h5>
                  <p className="text-left">
                    {projectsByUser?.basicUserData?.[0]?.address
                      ? projectsByUser?.basicUserData?.[0]?.address
                      : "N/A"}
                  </p>
                  <hr />
                </li>
              </div>
            </div>
          )}
          {/* </Panel>
            <Panel
              header="Project Details"
              key="2"
              onClick={() => setHandleCollapse(2)}
            > */}
          {projectUserShow === 2 && (
            //  selectedProject === null ? (
            //   <div
            //     // style={{ height: "550px" }}
            //     className="d-flex align-items-center"
            //   >
            //     <img
            //       src={IMAGES.NO_USER}
            //       alt="no user found"
            //       className="w-75 pb-2 ml-4"
            //     />
            //     <p className="text-center px-2 py-5" style={{ color: "#595A5C" }}>
            //       Please select the user to see all the activity logs
            //     </p>
            //   </div>
            // ) : (
            <div className=" custom-drawer-content detail-drawer custom-drawer bg-white rounded-sm p-4">
              <div className="text-center">
                <img src={IMAGES.DRAWER_ICON} alt="" />
              </div>
              <div>
                <h2 className="text-black font-weight-bold text-center pt-2 mb-1">
                  {selectedProject.projectName
                    ? selectedProject.projectName
                    : "N/A"}
                </h2>
              </div>

              <div className="d-flex align-items-center justify-content-center mt-4">
                {/* <MdEdit
                      size={33}
                      className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                      onClick={() => setAddUpdateNewProjectModal(true)}
                    />
                    <MdDelete
                      size={33}
                      className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                      onClick={() => setDeleteProjectModal(true)}
                    /> */}
              </div>
              <Row gutter={10} className="pt-3 pb-4 cards-row">
                <Col xs={12}>
                  <Tag
                    color="#DB5461"
                    className="pt-2 pb-1 mr-0 drawer-card w-100 cursor-pointer"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      navigate(
                        `/user-management/${param.userId}/user-details/${selectedProject.projectId}/project-detail`
                      )
                    }
                    onKeyDown={() =>
                      navigate(
                        `/user-management/${param.userId}/user-details/${selectedProject.projectId}/project-detail`
                      )
                    }
                  >
                    <p className="text-white mb-0">Portfolio&apos;s</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={IMAGES.PORTFOLIO_ICON}
                          alt=""
                          className="portfolio-img"
                        />
                      </div>
                      <div>
                        <h1 className="text-white mb-0">
                          {selectedProject.totalPorfolio
                            ? selectedProject.totalPorfolio
                            : 0}
                        </h1>
                      </div>
                    </div>
                  </Tag>
                </Col>
                <Col xs={12}>
                  <Tag
                    color="#2978A0"
                    className="pt-2 pb-1 mr-0 drawer-card w-100 cursor-pointer"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      navigate(
                        `/user-management/${param.userId}/user-details/${selectedProject.projectId}/project-detail`
                      )
                    }
                    onKeyDown={() =>
                      navigate(
                        `/user-management/${param.userId}/user-details/${selectedProject.projectId}/project-detail`
                      )
                    }
                  >
                    <p className="text-white mb-0">Assets</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={IMAGES.ASSETS_ICON}
                          alt=""
                          className="portfolio-img"
                        />
                      </div>
                      <div>
                        <h1 className="text-white mb-0">
                          {selectedProject.totalAssets
                            ? selectedProject.totalAssets
                            : 0}
                        </h1>
                      </div>
                    </div>
                  </Tag>
                </Col>
              </Row>
              <div className="project-drawer-details custom-scroll">
                <div>
                  <h5 className="mb-0 text-black">Project ID</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.projectId
                      ? selectedProject.projectId
                      : "N/A"}
                  </p>
                </div>
                <hr className="my-2" />
                <div>
                  <h5 className="mb-0 text-black">Project manager</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.projectManager
                      ? selectedProject.projectManager
                      : "N/A"}
                  </p>
                </div>
                <hr className="my-2" />
                <div>
                  <h5 className="mb-0 text-black">Client Name</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.clientName
                      ? selectedProject.clientName
                      : "N/A"}
                  </p>
                </div>
                <hr className="my-2" />
                <div>
                  <h5 className="mb-0 text-black">Project type</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.projectType
                      ? selectedProject.projectType
                      : "N/A"}
                  </p>
                </div>
                <hr className="my-2" />
                <div>
                  <h5 className="mb-0 text-black">Project Status</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.projectStatus
                      ? selectedProject.projectStatus
                      : "N/A"}
                  </p>
                </div>
                <hr className="my-2" />
                <div>
                  <h5 className="mb-0 text-black">Notes</h5>
                  <p className="mb-0 text-gray pt-2">
                    {selectedProject.description
                      ? selectedProject.description
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* </Panel>
          </Collapse> */}
        </Col>
      </Row>

      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setIsModalOpen={setIsModalOpen}
        setSearchValue={setSearchValue}
        form={form}
        setSearchProjectManagerIdValue={setSearchProjectManagerIdValue}
        setSearchProjectTypeIdValue={setSearchProjectTypeIdValue}
        setSearchProjectStatusIdValue={setSearchProjectStatusIdValue}
      />
      {/* <AddUpdateNewProject
        visible={addUpdateNewProjectModal}
        handleCancel={closeAddUpdateNewProjectModal}
      /> */}
      <CreateUpdate
        visible={addUpdateUserModal}
        handleCancel={closeAddUpdateUserModal}
        selectedRowKeys={selectedRowKeys}
        flag={flag}
        setFlag={setFlag}
        userDetailUpdate={userDetailUpdate}
      />
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="User"
        onSubmit={deleteUser}
      />
      {/* <DeleteUserModal
        visible={deleteProjectModal}
        handleCancel={closeDeleteProjectModal}
        user="Project"
        onSubmit={deleteProjects}
      /> */}
    </div>
  );
}
export default UserDetail;
