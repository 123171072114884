import {
  Button,
  Col,
  Input,
  Row,
  Switch,
  Tooltip,
} from "antd";
import { useState } from "react";
import SearchForm from "./component/CashFlow/SearchForm";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import CashflowDataTable from "./component/CashflowDataTable";
import { getFormattedEnglishNumber } from "../../../../../utils/digitHelper";
import { useSelector } from "react-redux";
import { getPortfolioAssetValue } from "../../../../../utils/portfolioHelper";

function CashFlows({ handleToggleDrawer, PortfolioSelect, showDrawer }) {
  const { calculationDetailsByPortfolio, dcfResultsModified } = useSelector(state => state.portfolio);
  const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);
  // Hide Table Column Dropdown
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTanents, setShowTanents] = useState(false);
  const [active, setActive] = useState('monthly');

  // const hideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "2",
  //         label: <Checkbox>Column 1</Checkbox>,
  //       },
  //       {
  //         key: "3",
  //         label: <Checkbox>Column 2</Checkbox>,
  //       },
  //       {
  //         key: "4",
  //         label: <Checkbox>Column 3</Checkbox>,
  //       },
  //       {
  //         key: "5",
  //         label: <Checkbox>Column 4</Checkbox>,
  //       },
  //       {
  //         key: "6",
  //         label: <Checkbox>Column 5</Checkbox>,
  //       },
  //       {
  //         key: "7",
  //         label: <Checkbox>Column 6</Checkbox>,
  //       },
  //       {
  //         key: "8",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn">Reset</Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="cashFlowsComponent">
      <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
        <div
          className="d-flex justify-content-between align-items-center button-search"
        >
          {/* <div className="pr-0" style={{ flexWrap: 'wrap', gap: '10px' }}> */}
          <div className="cashflows-first-btns ml-2 d-flex align-items-center">
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-monthly-btn"
                className={`cashflows-monthly-btn ${active === "monthly" ? "active" : ""
                  }`}
                onClick={() => setActive("monthly")}
              >
                Monthly
              </Button>
            </span>
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-yearly-btn ml-1"
                className={`cashflows-monthly-btn ml-1 ${active === "yearly" ? "active" : ""
                  }`}
                onClick={() => setActive("yearly")}
              >
                Yearly
              </Button>
            </span>
            <span>
              <Switch
                className="ml-3 my-auto"
                onChange={() => setShowTanents(!showTanents)}
              />
            </span>
            <span className="ml-2 my-auto show-tenants">Show Assets</span>
            {!showDrawer &&
              <h4 className="mb-0 ml-2"> Portfolio Value: {getFormattedEnglishNumber(assetValue)} €</h4>
            }
          </div>
          {/* </div> */}
          <div className="d-flex align-items-center pr-2">
            {PortfolioSelect && <PortfolioSelect />}
            <Tooltip
              title="show portfolio detail"
              color="#00215B"
              key="#00215B"
            >
              {/* drawer-close-btn */}
              <span
                className={`project-drawer-close-btn cursor-pointer  ${showDrawer ? "d-none" : ""
                  }`}
                role="button"
                tabIndex="0"
                style={{ marginLeft: '20px' }} onClick={handleToggleDrawer}
              >
                <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Sticky>
      <Row>
        <Col xs={24}>
          <CashflowDataTable showTanents={showTanents} active={active} />
        </Col>
      </Row>
      {isModalOpen && <SearchForm visible={isModalOpen} handleCancel={closeModal} />}
    </div>
  );
}

export default CashFlows;
