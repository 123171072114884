/* eslint-disable */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../app/features/Lookups/lookup.slice.js";
import {
  createUser,
  getAllUserList,
} from "../../../app/features/userManagement/user.slice.js";
import { Loader1 } from "../../../components/Layout/Loader/FullScreenLoader.jsx";

export default function CreateUpdate(props) {
  const {
    visible,
    handleCancel,
    selectedRowKeys,
    isLoaded,
    setSelectedRowKeys,
    flag,
    setFlag,
    userFilter,
    userDetailUpdate,
  } = props;
  const { role } = useSelector((state) => state.lookUps);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    handleCancel();
    form.resetFields();
    dispatch(getAllUserList(userFilter));
    setFlag(false);
  };
  const onFinish = (data) => {
    const finalData = {
      userId: (flag && selectedRowKeys?.[0]?.userId) || 0,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      roleId: data.role,
      address: data.location,
    };
    dispatch(createUser({ finalData, moveToNext, notifyToaster }));
  };
  useEffect(() => {
    if (selectedRowKeys) {
      form.setFieldsValue({
        firstName: flag ? selectedRowKeys?.[0]?.firstName : "",
        lastName: flag ? selectedRowKeys?.[0]?.lastName : "",
        email: flag ? selectedRowKeys?.[0]?.email : "",
        password: flag ? selectedRowKeys?.[0]?.password : "",
        role: flag ? selectedRowKeys?.[0]?.roleId : null,
        location: flag ? selectedRowKeys?.[0]?.address : "",
      });
    }
  }, [selectedRowKeys, flag]);

  const handleCancelModal = () => {
    handleCancel();
    form.resetFields();
    setSelectedRowKeys([]);
    setFlag(false);
  };
  useEffect(() => {
    const finalData = {
      type: "role",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);
  console.log("user", user);
  return (
    <Modal
      centered
      open={visible}
      handleCancel={handleCancelModal}
      width={630}
      footer={false}
    >
      <div className="add-user-management-modal">
        <h3 className="mb-0 modal-heading">
          {flag ? "Update user" : "Create new User"}
        </h3>

        <Form
          name="basic"
          className="user-modal"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          form={form}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your First Name!" },
                  {
                    max: 50,
                    message: "The max lenght of 50 Characters is reached!",
                  },
                  {
                    pattern: "^([^0-9]*)$",
                    message: "First Name does not accept numbers",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" disabled={isLoaded} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                  {
                    max: 50,
                    message: "The max lenght of 50 Characters is reached!",
                  },
                  {
                    pattern: "^([^0-9]*)$",
                    message: "Last Name does not accept numbers",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" disabled={isLoaded} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                  {
                    max: 50,
                    message: "The max lenght of 50 Characters is reached!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter email"
                  type="email"
                  disabled={flag === true || isLoaded}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="role"
                label="User Role"
                rules={[
                  {
                    required: true,
                    message: "Please input your role!",
                  },
                ]}
              >
                <Select
                  size="large"
                  className="antd-custom-select"
                  placeholder="Role"
                  disabled={flag === true || isLoaded}
                >
                  {/* {role?.map((item) => {
                    console.log("item", item);
                    return (
                      <> */}
                  {user?.roleId === 1 && (
                    <Select.Option key={4} value={4}>
                      {"Project Manager"}
                    </Select.Option>
                  )}
                  {user?.roleId === 2 && (
                    <>
                      <Select.Option key={1} value={1}>
                        {"Corporate"}
                      </Select.Option>
                      <Select.Option key={3} value={3}>
                        {"Individual"}
                      </Select.Option>
                    </>
                  )}
                  {/* </>
                    );
                  })} */}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  {
                    max: 100,
                    message: "The max lenght of 100 Characters is reached!",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Enter Password"
                  disabled={isLoaded}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  { required: false, message: "Please input your Location!" },
                  {
                    max: 100,
                    message: "The max lenght of 100 Characters is reached!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter user address"
                  type="location"
                  disabled={isLoaded}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={24}
              className="align-items-center d-flex justify-content-end mt-2"
            >
              <Button
                className="btn-cancel cursor-pointer"
                onClick={handleCancelModal}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="default"
                htmlType="submit"
                className={`btn-primary cursor-pointer ${
                  isLoaded && "disabled"
                }`}
                disabled={isLoaded}
              >
                <div className="d-flex justify-content-center align-items-center">
                  {flag ? (
                    <div className="mr-2">Update</div>
                  ) : (
                    <div className="mr-2">Save</div>
                  )}
                  {isLoaded && <Loader1 />}
                </div>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
CreateUpdate.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  selectedRowKeys: PropTypes.node.isRequired,
  isLoaded: PropTypes.node.isRequired,
  setSelectedRowKeys: PropTypes.node.isRequired,
  setFlag: PropTypes.node.isRequired,
  flag: PropTypes.node.isRequired,
  userFilter: PropTypes.node.isRequired,
  userDetailUpdate: PropTypes.node.isRequired,
};
