import Index from "./Index.jsx";
import Dashboard from "./Dashboard.jsx";
import Calculation from "./Calculation.jsx";
import IncomeAndCost from "./IncomeAndCost.jsx";
import RentRoll from "./RentRoll.jsx";
import CashFlows from "./CashFlow.jsx";
import CalculationSetting from "./CalculationSetting.jsx";

const PORTFOLIO_DASHBOARD = {
  INDEX: Index,
  DASHBOARD: Dashboard,
  CALCULATION: Calculation,
  INCOME_AND_COST: IncomeAndCost,
  RENT_ROLL: RentRoll,
  CASH_FLOWS: CashFlows,
  CALCULATION_SETTING: CalculationSetting,
};

export default PORTFOLIO_DASHBOARD;