import {Button, Input, Row, Col, Tabs} from "antd";
const { Search } = Input;
import { useEffect, useState } from "react";
import PriceIndex from "./components/PriceIndex";


export default function Index() {

  const [isMobile, setIsMobile] = useState("top");

  useEffect(() =>
          window.screen.width < 994 ? setIsMobile("top") : setIsMobile("top")
      , [window.screen.width]);

  const detectWindowSize=() =>window.innerWidth < 994 ? setIsMobile("top"):setIsMobile("top");


  window.onresize = detectWindowSize;


  return (
      <div className="">

        <Row gutter={24}>
          <Col xs={24} >
            <div className="w-100">
              <Tabs tabPosition={isMobile} defaultActiveKey="1">
                <Tabs.TabPane tab="VPI 2015" key="1">
                  <PriceIndex title={"Verbraucher Preis Index 2015"} i18nContext={"indices.cpi2015"} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="VPI 2020" key="2">
                  <PriceIndex title={"Verbraucher Preis Index 2020"} i18nContext={"indices.cpi2020"} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
  );
}
