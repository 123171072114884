import { Col, Row } from "antd";
import GroundRentsOtherPeriodicCosts from "./component/IncomeAndCost/GroundRentsOtherPeriodicCosts";
import { useSelector } from "react-redux";


function IncomeAndCost({
    handleToggleDrawer, showDrawer, AssetSelect, saveCalculationDetails, resetCalculationDetail, autoSaveCalc,calculationDetail,
    isCalcLoading,
    allDataLoaded,
    calculationModified,
    assetValueMismatched
}) {

    return (
        <Row gutter={24} className="incomecost-wrapper">
            <Col xs={24}>
                <GroundRentsOtherPeriodicCosts
                    handleToggleDrawer={handleToggleDrawer} showDrawer={showDrawer} AssetSelect={AssetSelect}
                    autoSaveCalc={autoSaveCalc}
                    isCalcLoading={isCalcLoading}
                    allDataLoaded={allDataLoaded}
                    calculationDetail={calculationDetail}
                    calculationModified={calculationModified}
                    assetValueMismatched={assetValueMismatched}
                    resetCalculationDetail={resetCalculationDetail}
                    saveCalculationDetails={saveCalculationDetails}
                />
            </Col>
        </Row>
    );
}

export default IncomeAndCost;
