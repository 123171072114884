/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-debugger */
import { Card, Col, Input, Modal, Row } from "antd";
import { FiSearch } from "react-icons/fi";
import { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
// import PropTypes from "prop-types";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../components/Layout/Table/DataTable";
import {
  getAssetsNearToExpireByName,
  getDashboardData,
} from "../../../../app/features/CorporateDashboard/CorporateDashboard.slice";

function NearestExpiresOfAssets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Search } = Input;
  const [modalOpen, setmodalOpen] = useState(false);
  const { dashboardData, assetsNearToExpireData, isLoading } = useSelector(
    (state) => state.corporateDashboardData
  );
  // const [searchAsset, setSearchAsset] = useState();
  const [searchValue, setSearchValue] = useState();

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    const finalData = {
      PageNo: 1,
      Size: 1,
      Search: searchValue ? searchValue : "",
    };
    dispatch(getAssetsNearToExpireByName(finalData));
  };
  const onSearchCancle = () => {
    setSearchValue("");
    const finalData = {
      PageNo: 1,
      Size: 1,
      Search: "",
    };
    dispatch(getAssetsNearToExpireByName(finalData));
  };

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "assetId",
      key: "assetId",
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
      key: "assetName",
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
    },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (text) => (
        <div className="d-flex align-items-center justify-content-end">
          {moment(text).format("DD/MM/YYYY")}
          <div className="d-flex" style={{ margin: "0 0 0 20px" }}>
            <IoIosArrowForward />
          </div>
        </div>
      ),
    },
  ];

  // const dataSource = [
  //   {
  //     id: 0,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 1,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 2,
  //     assetName: "Johar Town",
  //     assetType: "Office Building",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 3,
  //     assetName: "Johar Town",
  //     assetType: "Hotel",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 4,
  //     assetName: "Johar Town",
  //     assetType: "Hotel",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 5,
  //     assetName: "Johar Town",
  //     assetType: "Commercial Park",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 6,
  //     assetName: "Johar Town",
  //     assetType: "Commercial Park",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 7,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 8,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 9,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 10,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     expirationDate: "12/12/2022",
  //   },
  //   {
  //     id: 11,
  //     assetName: "Johar Town",
  //     assetType: "Cinema",
  //     expirationDate: "12/12/2022",
  //   },
  // ];

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(
          `/projects/${path.projectId}/project-detail/${path.assetId}/asset-detail`
        ); // to avoid es lint error
      }, // click row
    };
  };
  return (
    <div className="expires-assets">
      <Card className="w-100">
        <div className="d-flex justify-content-between">
          <h3 className="text-black mb-0 pt-1">Assets Near to Expire</h3>
          <div className="d-flex align-items-center text-blue pt-2">
            <span
              role="presentation"
              className="mr-1 cursor-pointer"
              onClick={() => setmodalOpen(true)}
            >
              View all
            </span>
            <BsArrowRight size={18} />
          </div>
        </div>
        <hr />
        <DataTable
          columns={columns}
          dataSource={dashboardData?.assetsNearToExpire}
          scroll={{
            y: 207,
          }}
          className="antd-table-striped"
          pagination={false}
          onRow={navigateRow}
        />
      </Card>

      <Modal
        centered
        open={modalOpen}
        onOk={() => setmodalOpen(false)}
        onCancel={() => setmodalOpen(false)}
        footer={false}
        width={650}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="text-blue">Assets Near to Expire</h2>
          <GrClose
            className="cursor-pointer"
            onClick={() => {
              setmodalOpen(false);
              onSearchCancle();
            }}
          />
        </div>
        <Row gutter={24} className="button-search">
          <Col xs={24} className="mt-1">
            <div className="d-flex searchbar-project">
              <span className="searchbar-icon">
                <FiSearch size={20} />
              </span>
              {/* <Search className="mr-2" placeholder="Search Asset" /> */}
              <Search
                value={searchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }}
                onChange={handleSearch}
                className="mr-2"
                placeholder="Search Assets Name"
              />
            </div>
          </Col>
        </Row>
        <div className="asset-expire-wrapper mt-3">
          <DataTable
            columns={columns}
            loading={isLoading}
            dataSource={assetsNearToExpireData}
            scroll={{
              y: 300,
            }}
            className="antd-table-striped"
            pagination={false}
            onRow={navigateRow}
          />
        </div>
      </Modal>
    </div>
  );
}

export default NearestExpiresOfAssets;
NearestExpiresOfAssets.propTypes = {
  // dashboardData: PropTypes.node.isRequired,
  // assetsNearToExpireData: PropTypes.node.isRequired,
};
