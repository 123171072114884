import Index from "./Index.jsx";
import CreateUpdate from "./CreateUpdate.jsx";
import PROJECTS_DETAILS from "./ProjectDetail";

const PROJECTS = {
  INDEX: Index,
  CREATE_UPDATE_PROJECT: CreateUpdate,
  PROJECTS_DETAILS,
};

export default PROJECTS;