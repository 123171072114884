/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
import { Button, Col, Input, Modal, notification, Row } from "antd";
// import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { assignProjectsToUser } from "../../../../app/features/Projects/project.slice";
import { getAllUserList } from "../../../../app/features/userManagement/user.slice";
import ConfirmationModal from "../../../../components/Layout/ConfirmationModel/ConfirmationModal";
import DataTable from "../../../../components/Layout/Table/DataTable";

export default function UserList(props) {
  const { visible, handleCancel, selectedRowKeys, setSelectedRowKeys } = props;
  console.log("selectedRowKeys", selectedRowKeys);
  const { Search } = Input;
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const { users, isLoading } = useSelector((state) => state.userManagement);
  const [searchValue, setSearchValue] = useState("");

  const [userFilter, setUserFilter] = useState({
    search: "",
    pagination: {
      page: 1,
      size: 10,
    },
  });

  const pageNumbers =
    userFilter.pagination.page * userFilter.pagination.size -
    userFilter.pagination.size +
    1;

  const nextPageNumber =
    userFilter.pagination.page * userFilter.pagination.size >
    users?.totalElements
      ? users?.totalElements
      : userFilter.pagination.page * userFilter.pagination.size;

  const [selectedRowKeysMerge, setSelectedRowKeysMerge] = useState(null);
  const onSelectChange = (newSelectedRowKeysMerge) => {
    setSelectedRowKeysMerge(newSelectedRowKeysMerge);
  };
  const rowSelection = {
    selectedRowKeysMerge,
    onChange: onSelectChange,
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  useEffect(() => {
    dispatch(getAllUserList(userFilter));
  }, [dispatch, userFilter]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    setSelectedRowKeysMerge([]);
    setSelectedRowKeys([]);
    handleCancel();

    const filters = {
      search: searchValue ? searchValue : "lkndsac",
      pagination: {
        page: 1,
        size: 10,
      },
    };
    setUserFilter(filters);
  };

  const assignProjects = () => {
    let projectId = [];
    projectId = selectedRowKeys.map((item) => item.projectId);
    const finalData = {
      userId: parseInt(selectedRowKeysMerge.join()),
      projectIds: projectId,
    };
    dispatch(assignProjectsToUser({ finalData, moveToNext, notifyToaster }));
    setConfirmModal(false);
  };

  const onSearch = () => {
    dispatch(
      getAllUserList({
        search: searchValue ? searchValue : "",
        pagination: {
          page: userFilter.pagination.page,
          size: userFilter.pagination.size,
        },
      })
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const columns = [
    {
      title: "ID",
      onCell: (data) => ({
        className: data.type === 1 ? "red-border" : "blue-border",
      }),
      dataIndex: "userId",
      key: "userId",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
  ];

  return (
    <Modal
      open={visible}
      handleCancel={handleCancel}
      width={800}
      centered
      footer={[
        <Button onClick={handleCancel} className="btn-cancel">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="default"
          htmlType="submit"
          className="btn-primary"
          disabled={selectedRowKeysMerge?.length === 1 ? false : true}
          onClick={() => setConfirmModal(true)}
        >
          Assign
        </Button>,
      ]}
    >
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center"
      >
        <Col flex="none">
          <h3 className="mb-0 modal-heading">Select User</h3>
        </Col>
        <Col xs={8}>
          <Input
            size="large"
            // style={{ width: "287px" }}
            prefix={
              <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
            onChange={handleSearch}
            className="mr-2"
            placeholder="Search a User"
          />
        </Col>
      </Row>

      <div className="mt-3 user-list user-pagination-style">
        <DataTable
          columns={columns}
          dataSource={users ? users : []}
          pagination={{
            current: userFilter?.pagination?.page || 1,
            pageSize: 10,
            total: users?.totalElements,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setUserFilter({
                ...userFilter,
                pagination: {
                  page: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped custom-scroll"
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.userId}
          scroll={{
            y: "calc(100vh - 360px)",
          }}
        />
      </div>
      <div
      // className="table-count"
      >
        <h5
        // className="table-count-content"
        >
          Showing {pageNumbers} to {nextPageNumber} of {users?.totalElements}{" "}
          entries
        </h5>
      </div>
      <ConfirmationModal
        visible={confirmModal}
        handleCancel={closeConfirmModal}
        confirmMessage="Assign the project"
        onSubmit={assignProjects}
      />
    </Modal>
  );
}
UserList.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  selectedRowKeys: PropTypes.node.isRequired,
  setSelectedRowKeys: PropTypes.node.isRequired,
};
