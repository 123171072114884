import { Button, Card, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";

export default function ChangePassword(props) {
  const { user } = props;
  return (
    <div>
      <Card className="w-100">
        <div className="d-flex justify-content-between">
          <h3 className="text-blue mb-0">Change Password</h3>
        </div>
        <hr />
        <Form
          name="basic"
          className="user-modal"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24}>
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[
                  {
                    required: false,
                    message: "Please input your Old Password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Old Password" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: false,
                    message: "Please input your New Password!",
                  },
                ]}
              >
                <Input type="password" placeholder="New Password" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: false,
                    message: "Please input your Confirm Password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Confirm Password" />
              </Form.Item>
            </Col>
            {user?.roleId === 2 && (
              <Col xs={24} className="d-flex justify-content-end">
                <Button key="submit" type="default" className="btn-primary">
                  Save
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    </div>
  );
}
ChangePassword.propTypes = {
  user: PropTypes.node.isRequired,
};
