import {
  // Button,
  // Checkbox,
  Dropdown,
  Menu,
  Typography,
  notification,
} from "antd";
// import PropTypes from "prop-types";
import { useState } from "react";
import { BsEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
import { MdUnarchive, MdArchive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  archiveProject,
  getAllArchivedProjects,
} from "../../../../../app/features/Projects/project.slice";
import DataTable from "../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../utils/path";

export default function ArchiveProjects({ projectFilter }) {
  // const { showArchiveProject } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Text } = Typography;

  const { getArchivedProjects, isLoading } = useSelector(
    (state) => state.projects
  );
  // console.log(getArchivedProjects?.content, 'getArchivedProjects')
  const [archieveProjectFilter, setArchieveProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      page: 1,
      size: 10,
    },
  });
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextArchived = () => {
    dispatch(getAllArchivedProjects({
      page: projectFilter?.pagination?.page,
      size: projectFilter?.pagination?.size,
    }));
  };
  const archiveProjectFunction = (row) => {
    console.log(row, 'row id in archive project')
    const finalData = {
      id: row.id,
      // projectId: row.id,
      isArchived: false,
    };
    dispatch(archiveProject({ finalData, moveToNextArchived, notifyToaster }));
  };
  const initialColState = [
    {
      id: 1,
      title: "Project Reference",
      dataIndex: "projectId",
      render: (text) => (
        <div>
          <MdArchive className="completed-icon" fill="#00215B" size={30} />
          <span>{text}</span>
        </div>
      ),
    },
    {
      id: 2,
      hidden: false,
      title: "Project Name",
      dataIndex: "projectName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 3,
      hidden: false,
      title: "Project Manager",
      dataIndex: "projectManager",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Client Name",
      dataIndex: "clientName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 5,
      hidden: false,
      title: "Project Type",
      dataIndex: "projectType",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 6,
      hidden: false,
      title: "Project Status",
      dataIndex: "projectStatus",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 8,
      hidden: false,
      title: "Notes",
      dataIndex: "description",
      render: (text) => (
        <Text
          style={{
            width: 200,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text !== null ? text : "N/A"}
        </Text>
      ),
    },
    {
      id: 8,
      hidden: false,
      title: "Actions",
      dataIndex: "actions",
      width: '80px',
      fixed: 'right',
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.id
                            )
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.id
                            )
                          )
                        }
                      >
                        <BsEyeFill />
                        <h5 className="mb-0 ml-3">View Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => archiveProjectFunction(row)}
                        onKeyDown={() => archiveProjectFunction(row)}
                      >
                        <MdUnarchive />
                        <h5 className="mb-0 ml-3">UnArchive Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(PATH.PROJECT_DETAILS.replace(":projectId", path.id)); // to avoid es lint error
      }, // click row
    };
  };

  // const [columns, setColumns] = useState(initialColState);

  // const handleReset = () => {
  //   setColumns(initialColState);
  // };

  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //   } else {
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };

  // const basicDatahideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="projectName"
  //             name="Project Name"
  //             id={2}
  //             // checked={checkList.includes("valDate")}
  //             onChange={handleChange}
  //           >
  //             Project Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="projectManager"
  //             name="Project Manager"
  //             id={3}
  //             // checked={checkList.includes("projectNo")}
  //             onChange={handleChange}
  //           >
  //             Project Manager
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="clientName"
  //             name="Client Name"
  //             id={4}
  //             // checked={checkList.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Client Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="projectType"
  //             name="Project Type"
  //             id={5}
  //             // checked={checkList.includes("nameOfClient1")}
  //             onChange={handleChange}
  //           >
  //             Project Type
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="projectStatus"
  //             name="Project Status"
  //             id={6}
  //             // checked={checkList.includes("nameOfClient2")}
  //             onChange={handleChange}
  //           >
  //             Vacancy
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="description"
  //             name="Notes"
  //             id={7}
  //             // checked={checkList.includes("instruction")}
  //             onChange={handleChange}
  //           >
  //             Notes
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={() => handleReset()}>Reset</Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // useEffect(() => {
  //   // sethideTableColumns(basicDatahideTableColumns);
  //   dispatch(getAllArchivedProjects());
  // }, [showArchiveProject]);

  const pageNumbers =
    archieveProjectFilter.pagination.page *
    archieveProjectFilter.pagination.size -
    archieveProjectFilter.pagination.size +
    1;

  const nextPageNumber =
    archieveProjectFilter.pagination.page *
      archieveProjectFilter.pagination.size >
      getArchivedProjects?.totalElements
      ? getArchivedProjects?.totalElements
      : archieveProjectFilter.pagination.page *
      archieveProjectFilter.pagination.size;

  return (
    <div>
      <div className="mt-3">
        <DataTable
          columns={initialColState}
          dataSource={getArchivedProjects?.content}
          pagination={{
            current: archieveProjectFilter?.pagination?.page || 1,
            pageSize: 10,
            total: getArchivedProjects?.totalElements,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setArchieveProjectFilter({
                ...archieveProjectFilter,
                pagination: {
                  page: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          onRow={navigateRow}
          scroll={{
            x: 1150,
            y: "calc(100vh - 322px)",
          }}
          loading={isLoading}
          rowKey={(record) => record}
        />
      </div>
      {getArchivedProjects?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of {" "}
            {getArchivedProjects?.totalElements} entries
          </h5>
        </div>
      )}
    </div>
  );
}
ArchiveProjects.propTypes = {
  // setEditProjectModal: PropTypes.node.isRequired,
  // sethideTableColumns: PropTypes.node.isRequired,
  // showArchiveProject: PropTypes.node.isRequired,
};
