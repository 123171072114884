import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col, DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import PropTypes from "prop-types";
import { COST_TYPE, ROTATION } from "./GroundRentsOtherPeriodicCosts";
import moment from "moment/moment";


export default function Create(props) {
  const {
    visible,
    handleCancel,
    onSubmit
  } = props;

  const [form] = Form.useForm();
  const [rotation, setRotation] = useState()

  const handleCancelModal = () => {
    form.resetFields();
    handleCancel();
  };

  const handleSubmit = (data) => {
    form.resetFields();
    onSubmit(data)
  };

  const handleValues = (values) => {
    if (Object.keys(values).indexOf("rotation") > -1)
      setRotation(values.rotation);
  };

  return (
    <div className="addNewOtherCost">
      <Modal
        centered
        width={800}
        className="addNewOtherCost"
        open={visible}
        handleCancel={handleCancelModal}
        onCancel={handleCancelModal}
        footer={false}
      >
        <div className="add-user-management-modal">
          <h3 className="mb-0 modal-heading">
            Add new Costs
          </h3>
          <Form
            name="basic"
            className="user-modal"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            onValuesChange={handleValues}
            onFinish={handleSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Description!",
                    },
                  ]}
                >
                  <Input placeholder="Enter a Description" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Amount"
                  name="value"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Amount for the Cost!",
                    }
                  ]}
                >
                  <Input type="number" placeholder="Enter an Amount" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} >
                <Form.Item
                  label="Cost Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Cost type!",
                    },
                  ]}
                >
                  <Select placeholder="Select" allowClear disabled={false} >
                    {COST_TYPE.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Rotation"
                  name="rotation"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Rotation",
                    }
                  ]}
                >
                  <Select placeholder="Select" allowClear disabled={false} >
                    {ROTATION.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label={rotation && rotation !== "ONETIME" ? "Startdate" : "Date"}
                  name="start"
                  format="YYYY"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Startdate"
                    },
                  ]}
                >
                  <DatePicker
                    // type="name"
                    format="MM/DD/YYYY"
                    picker="date"
                    className="w-100 rounded-sm"
                  />
                </Form.Item>
              </Col>
              {
                rotation && rotation !== "ONETIME" ? <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Enddate"
                    name="end"
                    format="YYYY"
                    rules={[
                      {
                        required: true,
                        message: "Please select the Enddate"
                      },
                    ]}
                  >
                    <DatePicker
                      // type="name"
                      format="MM/DD/YYYY"
                      picker="date"
                      className="w-100 rounded-sm"
                    />
                  </Form.Item>
                </Col> : ""

              }

            </Row>
            <Row gutter={24}>
              <Col
                xs={24}
                className="align-items-center d-flex justify-content-end mt-2" style={{ gap: "10px" }}
              >
                <Button className="btn-cancel" onClick={handleCancelModal}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="default"
                  htmlType="submit"
                  className="btn-primary"
                  disabled={false}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="mr-2">Add</div>
                  </div>
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
Create.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired
};
