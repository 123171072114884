// /* eslint-disable */
import api from "../../../utils/api";
import {apiClient} from "../../../index";
import {Project, Search, SearchCriteria} from "reia-rest-client";

// Create an account for projects
const createProject = async (project: Project) => {
  return await apiClient.projectsApi().addProject(project);;
};

const updateProject = async (project: Project) => {
  return await apiClient.projectsApi().updateProject(project.id, project);
};

// Get All list of projects
const getAllProjectList = async (data) => {
  const response = await apiClient.projectsApi().getAllProjects(data.page-1, data.size, []);
  return response.data;
};
// Get Single projects
const getSingleProject = async (projectId) => {
  const response = await apiClient.projectsApi().getProject(projectId);
  return response.data;
};

// Delete projects
const deleteProjects = async (projectIds) => {
  const response = await apiClient.projectsApi().deleteProjects(projectIds);
  return response.data;
};

// // Get Project Managers
// const getAllProjectManager = async (data) => {
//   const response = await api.get("/api/Project/getAllProjectManagers", {
//     params: data,
//   });
//   return response.data.data;
// };

// Create an account for projects
const archiveProject = async (project: Project) => {
  let projectCopy: Project = JSON.parse(JSON.stringify(project));
  projectCopy.archived = true;
  const response = await apiClient.projectsApi().updateProject(projectCopy.id, projectCopy)
  return response.data;
};

// Get All list of projects
const getAllArchivedProjects = async (data) => {
  const archived: SearchCriteria = {filterKey: "archived", value: true, operation: "eq"}
  const search: Search = {dataOption: "all", searchCriteriaList: [archived]}
  
  const response = await apiClient.projectsApi().searchProjects(search, data.page-1, data.size);
  return response.data;
};

const completeProject = async (project: Project) => {
  let projectCopy: Project = JSON.parse(JSON.stringify(project));
  projectCopy.completed = true;
  const response = await apiClient.projectsApi().updateProject(projectCopy.id, projectCopy)
  return response.data;
};

// Get All list of projects
const getAllCompletedProjects = async (data) => {
  const completed: SearchCriteria = {filterKey: "completed", value: true, operation: "eq"}
  const notArchived: SearchCriteria = {filterKey: "archived", value: false, operation: "eq"}
  const search: Search = {dataOption: "all", searchCriteriaList: [completed,notArchived]}

  const response = await apiClient.projectsApi().searchProjects(search, data.page-1, data.size);
  return response.data;
};

const assignProjectsToUser = async (data) => {
  const response = await api.post(`/api/Project/assignProjectsToUser`, data);
  return response.data;
};

const projects = {
  createProject,
  updateProject,
  getAllProjectList,
  getSingleProject,
  deleteProjects,
  //getAllProjectManager,
  archiveProject,
  getAllArchivedProjects,
  completeProject,
  getAllCompletedProjects,
  assignProjectsToUser,
};

export default projects;
