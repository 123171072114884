import {Card, Col, Row, Form, Input, Table, Select, Button, InputNumber} from "antd";
import {useEffect, useState} from "react";
import lookUps from "../../../../app/features/Lookups/lookup.service";
import {I18n} from "reia-rest-client";
import IMAGES from "../../../../assets/images";  

const SHOW_VALUES_FOR_LAST_YEARS = 10;
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
export default function PriceIndex(props) {
  
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [columnsMonths, setColumnsMonths] = useState([])
  const [columnsData, setColumnsData] = useState([])
  const [i18nItem: I18n, setI18nItem] = useState(null)
  
  const saveIndexValue = (value, key) => {
    i18nItem.values[key] = value
  }
  
  useEffect(() => {
    lookUps.getI18nItem({i18nKey: props.i18nContext}).then(i18n => {
      setI18nItem(i18n)
      
    });
  }, []);
  
  useEffect( () => {
    console.log("I18nItem useEffect")
    if(i18nItem)
    {
      const columnsMonths = [{
        dataIndex: "Fname",
        key: "Fname",
      }];

      MONTHS.forEach(monthName => {
        columnsMonths.push(
            {
              title: monthName,
              dataIndex: monthName,
              key: monthName,

              width: 150,
            }
        )
      })

      setColumnsMonths(columnsMonths);
      
      const columnsData = [];

      for (let i = 0; i < SHOW_VALUES_FOR_LAST_YEARS; i++) {
        const year = currentYear-i;
        const yearData = {
          key: year,
          Fname: year
        }
        let month = 0;
        MONTHS.forEach(monthName => {
          const currentMonth = ++month;
          yearData[monthName] = <div>
                <InputNumber className={"indexValue"} controls={false} decimalSeparator={","} defaultValue={i18nItem?.values[year+"-"+currentMonth]} onChange={value => {
                  saveIndexValue(value,year+"-"+currentMonth )
                }}  />
              </div>
        })
        columnsData[i] = yearData
      }
      setColumnsData(columnsData)
    }
  }, [i18nItem])

    const saveI18nItem = () => {
      lookUps.updateI18nItem({i18nItem: i18nItem}).then(i18nItemUpdated => setI18nItem(i18nItemUpdated))
    }

    return (
    <Form>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
        className="definitioncalculationSetting"
      >
        <div />

        <div className="Consumer indexValue pt-3">
          <Card
            title={
              <div className="mb-1">
                <h3>{props.title}</h3>

                <Row className="justify-content-between" gutter={24}>
                  <Col span={6}>
                    {/*<Input placeholder="Verbraucherpreisindex 2010" />*/}
                  </Col>
                  <Col span={13} className="d-flex">
                    {/*<h5 className="mt-2 mr-1">Tokken</h5>*/}
                    {/*<Input className="w-25" placeholder="VPI 15" />*/}
                  </Col>
                  <Col className="d-flex justify-content-end">
                    {/*<h5 className="mt-2 mr-1">Current Year</h5>*/}
                    {/*<Select*/}
                    {/*  defaultValue="2019"*/}
                    {/*  style={{*/}
                    {/*    width: "100%",*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <Option value="2019">2019</Option>*/}
                    {/*</Select>*/}
                      <Button key="submit" type="default" className="btn-primary mb-3" onClick={saveI18nItem}>
                          Save Changes
                      </Button>
                  </Col>
                </Row>
              </div>
            }
            className="card-head"
            style={{
              width: "100%",
              paddingTop: "5px",
            }}
          >
            <Table loading={columnsData.length === 0} className="" pagination={false} columns={columnsMonths} dataSource={columnsData} />
          </Card>
        </div>

        <div className="Consumer-card">
          <Card title="Links">
            <div
              className="image-container"
              style={{ backgroundColor: "white!important" }}
            >
              <img alt="" src={IMAGES.DSTATIS} width="15%" />
            </div>
          </Card>
        </div>
      </div>
    </Form>
  );
}
