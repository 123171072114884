/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * PortfolioControllerApi - axios parameter creator
 * @export
 */
export const PortfolioControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetsToPortfolio: (portfolioId, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('addAssetsToPortfolio', 'portfolioId', portfolioId);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addAssetsToPortfolio', 'requestBody', requestBody);
            const localVarPath = `/api/portfolios/{portfolioId}/assets`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolio: (portfolioDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioDto' is not null or undefined
            assertParamExists('addPortfolio', 'portfolioDto', portfolioDto);
            const localVarPath = `/api/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetsFromPortfolio: (portfolioId, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deleteAssetsFromPortfolio', 'portfolioId', portfolioId);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteAssetsFromPortfolio', 'requestBody', requestBody);
            const localVarPath = `/api/portfolios/{portfolioId}/assets`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio: (portfolioId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolio', 'portfolioId', portfolioId);
            const localVarPath = `/api/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolios: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deletePortfolios', 'requestBody', requestBody);
            const localVarPath = `/api/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolios: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetsByPortfolios: (portfolioId, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getAssetsByPortfolios', 'portfolioId', portfolioId);
            const localVarPath = `/api/portfolios/{portfolioId}/assets`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalcualtionDetailsByPortfolios: (portfolioId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getCalcualtionDetailsByPortfolios', 'portfolioId', portfolioId);
            const localVarPath = `/api/portfolios/{portfolioId}/calculationDetails`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolio: (portfolioId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('getPortfolio', 'portfolioId', portfolioId);
            const localVarPath = `/api/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPortfolios: (search, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchPortfolios', 'search', search);
            const localVarPath = `/api/portfolios/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDetailDto} portfolioDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalcualtionDetailsByPortfolios: (portfolioId, portfolioDetailDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updateCalcualtionDetailsByPortfolios', 'portfolioId', portfolioId);
            // verify required parameter 'portfolioDetailDto' is not null or undefined
            assertParamExists('updateCalcualtionDetailsByPortfolios', 'portfolioDetailDto', portfolioDetailDto);
            const localVarPath = `/api/portfolios/{portfolioId}/calculationDetails`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioDetailDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio: (portfolioId, portfolioDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioId', portfolioId);
            // verify required parameter 'portfolioDto' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioDto', portfolioDto);
            const localVarPath = `/api/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PortfolioControllerApi - functional programming interface
 * @export
 */
export const PortfolioControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = PortfolioControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetsToPortfolio(portfolioId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addAssetsToPortfolio(portfolioId, requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolio(portfolioDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addPortfolio(portfolioDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetsFromPortfolio(portfolioId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAssetsFromPortfolio(portfolioId, requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio(portfolioId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePortfolio(portfolioId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolios(requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePortfolios(requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolios(page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllPortfolios(page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetsByPortfolios(portfolioId, page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAssetsByPortfolios(portfolioId, page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalcualtionDetailsByPortfolios(portfolioId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCalcualtionDetailsByPortfolios(portfolioId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolio(portfolioId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPortfolio(portfolioId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPortfolios(search, page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchPortfolios(search, page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDetailDto} portfolioDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio(portfolioId, portfolioDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePortfolio(portfolioId, portfolioDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * PortfolioControllerApi - factory interface
 * @export
 */
export const PortfolioControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PortfolioControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAssetsToPortfolio(portfolioId, requestBody, options) {
            return localVarFp.addAssetsToPortfolio(portfolioId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolio(portfolioDto, options) {
            return localVarFp.addPortfolio(portfolioDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssetsFromPortfolio(portfolioId, requestBody, options) {
            return localVarFp.deleteAssetsFromPortfolio(portfolioId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio(portfolioId, options) {
            return localVarFp.deletePortfolio(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolios(requestBody, options) {
            return localVarFp.deletePortfolios(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolios(page, size, sort, options) {
            return localVarFp.getAllPortfolios(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetsByPortfolios(portfolioId, page, size, sort, options) {
            return localVarFp.getAssetsByPortfolios(portfolioId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalcualtionDetailsByPortfolios(portfolioId, options) {
            return localVarFp.getCalcualtionDetailsByPortfolios(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolio(portfolioId, options) {
            return localVarFp.getPortfolio(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPortfolios(search, page, size, sort, options) {
            return localVarFp.searchPortfolios(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDetailDto} portfolioDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options) {
            return localVarFp.updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} portfolioId
         * @param {PortfolioDto} portfolioDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio(portfolioId, portfolioDto, options) {
            return localVarFp.updatePortfolio(portfolioId, portfolioDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PortfolioControllerApi - object-oriented interface
 * @export
 * @class PortfolioControllerApi
 * @extends {BaseAPI}
 */
export class PortfolioControllerApi extends BaseAPI {
    /**
     *
     * @param {number} portfolioId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    addAssetsToPortfolio(portfolioId, requestBody, options) {
        return PortfolioControllerApiFp(this.configuration).addAssetsToPortfolio(portfolioId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PortfolioDto} portfolioDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    addPortfolio(portfolioDto, options) {
        return PortfolioControllerApiFp(this.configuration).addPortfolio(portfolioDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    deleteAssetsFromPortfolio(portfolioId, requestBody, options) {
        return PortfolioControllerApiFp(this.configuration).deleteAssetsFromPortfolio(portfolioId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    deletePortfolio(portfolioId, options) {
        return PortfolioControllerApiFp(this.configuration).deletePortfolio(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    deletePortfolios(requestBody, options) {
        return PortfolioControllerApiFp(this.configuration).deletePortfolios(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    getAllPortfolios(page, size, sort, options) {
        return PortfolioControllerApiFp(this.configuration).getAllPortfolios(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    getAssetsByPortfolios(portfolioId, page, size, sort, options) {
        return PortfolioControllerApiFp(this.configuration).getAssetsByPortfolios(portfolioId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    getCalcualtionDetailsByPortfolios(portfolioId, options) {
        return PortfolioControllerApiFp(this.configuration).getCalcualtionDetailsByPortfolios(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    getPortfolio(portfolioId, options) {
        return PortfolioControllerApiFp(this.configuration).getPortfolio(portfolioId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Search} search
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    searchPortfolios(search, page, size, sort, options) {
        return PortfolioControllerApiFp(this.configuration).searchPortfolios(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {PortfolioDetailDto} portfolioDetailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options) {
        return PortfolioControllerApiFp(this.configuration).updateCalcualtionDetailsByPortfolios(portfolioId, portfolioDetailDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} portfolioId
     * @param {PortfolioDto} portfolioDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioControllerApi
     */
    updatePortfolio(portfolioId, portfolioDto, options) {
        return PortfolioControllerApiFp(this.configuration).updatePortfolio(portfolioId, portfolioDto, options).then((request) => request(this.axios, this.basePath));
    }
}
