import { useState } from "react";
import { AiFillDelete, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { Button, Input, Row, Col, Dropdown, Menu } from "antd";
import { FiSearch } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import SearchForm from "./components/SearchForm";
import DataTable from "../../../components/Layout/Table/DataTable";
import ParameterModal from "./components/ParameterModal";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiEditLine } from "react-icons/ri";

const { Search } = Input;

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewProjectModal, setAddNewProjectModal] = useState(false);
  const [editParameterModal, setEditParameterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const closeEditParameterModal = () => {
    setEditParameterModal(false);
  };

  const closeAddNewProjectModal = () => {
    setAddNewProjectModal(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: "createddate",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "90px",
      align: "center",
      render: () => (
        // <div>
        //   <AiFillDelete
        //     className="mr-2 cursor-pointer action-btn rounded-sm p-1"
        //     size={26}
        //     onClick={() => {
        //       setDeleteModal(true);
        //     }}
        //     fill="#ff8372"
        //   />
        //   <MdEdit
        //     className="cursor-pointer action-btn rounded-sm p-1"
        //     size={26}
        //     onClick={() => {
        //       setEditParameterModal(true);
        //     }}
        //     fill="#00215B"
        //   />
        // </div>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setEditParameterModal(true);
                        }}
                      >
                        <RiEditLine size={24} />
                        <h5 className="mb-0 ml-3">Edit</h5>
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        <AiOutlineDelete
                          size={24}
                          style={{ color: "#FC0606" }}
                        />
                        <h5 style={{ color: "#FC0606" }} className="mb-0 ml-3">
                          Delete
                        </h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  const dataSource = [
    {
      id: 0,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 1,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 2,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 3,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 4,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 5,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 6,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 7,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 8,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 9,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 10,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
    {
      id: 11,
      parameter: "Parameter1",
      type: "Text",
      unit: "sqm",
      value: "19.2",
      createddate: "02/02/2022",
    },
  ];

  return (
    <div className="admin-parameter-wrapper">
      <div
        className="button-search"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        <div className="ml-2">
          <Button
            type="default"
            className="add-project-btn d-flex justify-content-between align-items-center new-user"
            onClick={() => setAddNewProjectModal(true)}
          >
            <span className="project-btn-span">
              <AiOutlinePlus size={30} />
            </span>
            <h5 className="mb-0 ">Add New</h5>
          </Button>
        </div>
        <Input
          size="large"
          style={{ width: "287px" }}
          prefix={
            <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
          }
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setSearchValue(e.target.value);
          //   }
          // }}
          // onChange={(e) => {
          //   if (e.key === "Enter") {
          //     setSearchValue(e.target.value);
          //   }
          // }}
          // onClick={() => setIsModalOpen(true)}
          className="mr-2"
          placeholder="Search a parameter"
        />
      </div>
      {/* Data Table */}
      <div className="mt-3">
        <DataTable
          columns={columns}
          dataSource={dataSource}
          pagination="true"
          className="antd-table-striped"
          scroll={{
            x: 700,
            y: "calc(100vh - 310px)",
          }}
        />
      </div>
      {dataSource?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Page 1 of 2
            {/* Showing {pageNumbers} to {nextPageNumber} of{" "}
                  {projects?.totalElements} entries */}
          </h5>
        </div>
      )}
      <SearchForm visible={isModalOpen} handleCancel={closeModal} />
      <ParameterModal
        modalHeading="Add New Parameter"
        visible={addNewProjectModal}
        handleCancel={closeAddNewProjectModal}
      />
      <ParameterModal
        modalHeading="Edit Parameter"
        visible={editParameterModal}
        handleCancel={closeEditParameterModal}
      />
      <DeleteConfirmation
        visible={deleteModal}
        handleCancel={closeDeleteModal}
        user="Parameters"
      />
    </div>
  );
}
