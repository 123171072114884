import Index from "./Index.jsx";
import CreateUpdateAssets from "./CreateUpdateAssets.jsx";
import ASSET_DETAILS from "./AssetDetail";
import PORTFOLIO_DASHBOARD from "./PorfolioDashboard";

const PROJECTS_DETAILS = {
  INDEX: Index,
  CREATE_UPDATE_ASSETS: CreateUpdateAssets,
  ASSET_DETAILS,
  PORTFOLIO_DASHBOARD,
};

export default PROJECTS_DETAILS;