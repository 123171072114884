import { createSlice} from "@reduxjs/toolkit";

// Get user from localStorage
const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoggedIn: false,
};

export const setUser = (userObj) => {
  return {
    type: "auth/login",
    payload: userObj
  }
}
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    login: (state, action) =>{
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoggedIn = true;
      state.user = action.payload;
    }
  }
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
