/* eslint-disable */
import api from "../../../utils/api";

const sendCode = async (email) => {
  const response = await api.post(
    `api/Account/forgetPassword?email=${email}`
  );
  return response.data;
};

const verifyCode = async (data) => {
    const response = await api.post(
        `api/Account/verifyForgotPasswordCode`, data
    );
    return response.data;
  };

  const resendCode = async (email) => {
    const response = await api.post(
        `api/Account/resendCode?email=${email}`
      );
      return response.data;
  };

const setForgotPassword = async (data) => {
  const response = await api.post(`api/Account/setForgotPassword`, data);
  return response.data;
};

const forgotPaswword = {
    sendCode,
    verifyCode,
  resendCode,
  setForgotPassword,
};

export default forgotPaswword;
