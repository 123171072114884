/* eslint-disable */
import { Modal, Col, Row, Input, Button, Select, Form, Typography } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
//import { getAllProjectManager } from "../../../../app/features/Projects/project.slice";
import { getByTypeDropdown } from "../../../../app/features/Lookups/lookup.slice";
const { Text } = Typography;
function SearchForm(props) {
  const {
    visible,
    handleCancel,
    setIsModalOpen,
    form,
    setSearchProjectStatusIdValue,
    setSearchValue,
    setShowProject,
    showProject,
    setSearchProjectTypeIdValue,
    setSearchProjectManagerIdValue,
  } = props;
  const { Search } = Input;
  const dispatch = useDispatch();

  const { projectTypes, projectStatus } = useSelector(
    (state) => state.lookUps
  );

  const projectStatusType = [
    { value: 'newProject', label: 'New Projects' },
    { value: 'completedProject', label: 'Completed Projects' },
    { value: 'archiveProject', label: 'Archive Projects' }
  ]

  const { projectManagers } = useSelector((state) => state.projects);

  const onFinish = (data) => {
    // console.log(data, 'filter project data')
    setShowProject(data.project)
    setSearchValue(data.search);
    setSearchProjectStatusIdValue(data.projectStatusType);
    setSearchProjectTypeIdValue(data.projectType);
    setSearchProjectManagerIdValue(data.projectManager);
    setIsModalOpen(false);
  };


  useEffect(() => {
    const finalData = {
      type: "projectTypes",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "projectStatus",
    };
    dispatch(getByTypeDropdown(finalData));
    //dispatch(getAllProjectManager());
  }, [dispatch]);

  return (
    <Modal
      // search-modals
      className="project"
      open={visible}
      handleCancel={handleCancel}
      onCancel={handleCancel}
      width={676}
      centered
      style={
        {
          // top: 80,
          // left: 115,
        }
      }
      footer={false}
    >
      <h3
        style={{
          color: "#01215D",
          paddingBottom: "16px",
          fontWeight: 500,
          margin: 0,
        }}
      >
        Filter
      </h3>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        className="add-new-org-class"
        form={form}
        layout="vertical"
      >
        {/* <Row>
          <Col span={24}>
            <label htmlFor="search"> Search Project</label>
            <Form.Item
              name="search"
              style={{ marginTop: 4 }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                size="large"
                suffix={
                  <FiSearch
                    size={20}
                    style={{ color: "rgba(0, 0, 0, 0.45)" }}
                  />
                }
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row className="row d-flex search-form-filter-row" gutter={16}>
          {/* <Col xs={24} sm={12} lg={8} className="mb-2">
            <label htmlFor="projectManager"> Project Manager</label>
            <Form.Item
              name="projectManager"
              style={{ marginTop: 4 }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Project Manager"
                style={{ marginTop: 4 }}
                allowClear
                className="w-100"
              >
                {projectManagers?.map((item) => {
                  return (
                    <Select.Option key={item.userId} value={item.userId}>
                      {item.projectManager}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col> */}

          {/* <Col xs={24} sm={12} lg={8} className="mb-2">
            <label htmlFor="projectType">Project Type</label>
            <Form.Item
              name="projectType"
              style={{ marginTop: 4 }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select placeholder="Project type" allowClear className="w-100">
                {projectTypes?.map((item: I18n) => (
                  <Select.Option key={item.translations.en} value={item.id}>
                    {item.translations.en}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col xs={24} sm={12} lg={8} className="mb-2">
            <label htmlFor="projectStatusType"> Project Status</label>
            <Form.Item
              name="projectStatusType"

              style={{ marginTop: 4 }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select placeholder="Project Status" allowClear className="w-100">
                {projectStatus?.map((item: I18n) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.translations.en}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={12} lg={12} className="mb-2">
            <label htmlFor="project"> Projects Type</label>
            <Form.Item
              name="project"

              style={{ marginTop: 4 }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select placeholder="Project" defaultValue={showProject} className="w-100">
                {projectStatusType?.map((item: I18n) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            style={{ gap: "16px" }}
            className="align-items-center d-flex justify-content-end mt-2"
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
            // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  onSearch: PropTypes.func,
  setSearchProjectManagerIdValue: PropTypes.func,
  setSearchProjectTypeIdValue: PropTypes.func,
  setSearchValue: PropTypes.func,
  setSearchProjectStatusIdValue: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  form: PropTypes.object,
};
