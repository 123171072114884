import { useState } from "react";
import { Dropdown, Menu, Tag } from "antd";
import { useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import PropTypes from "prop-types";
import { BsEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
import { MdArchive } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../utils/path";
// import { getAllArchivedByUserProjects } from "../../../../../app/features/userDetail/userDetail.slice";
// import { archiveProject } from "../../../../../app/features/Projects/project.slice";

export default function ArchiveProjects(props) {
  const { handleRowClick } = props;
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const param = useParams();

  const { archivedProjectsByUser, isLoading } = useSelector((state) => state.userDetail);
  const [archieveProjectFilter, setArchieveProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  // function notifyToaster(data, condition) {
  //   notification.config({
  //     maxCount: 1,
  //   });
  //   if (condition === "success") {
  //     notification.success({
  //       message: data,
  //     });
  //   } else {
  //     notification.error({
  //       message: data,
  //     });
  //   }
  // }
  // const moveToNextArchived = () => {
  //   const finalData = {
  //     userId: param.userId
  //   }
  //   dispatch(getAllArchivedByUserProjects(finalData))
  // };
  // const archiveProjectFunction = (row) => {
  //   const finalData = {
  //     projectId: row.projectId,
  //     isArchived: false,
  //   };
  //   dispatch(archiveProject({ finalData, moveToNextArchived, notifyToaster }));
  // };

  // const profileDropdown = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <div
  //             className="d-flex justify-content-left align-items-center table-dropdown"
  //             role="button"
  //             tabIndex="0"
  //             onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
  //             onKeyDown={() =>
  //               navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)
  //             }
  //           >
  //             <BsEyeFill />
  //             <h5 className="mb-0 ml-3">View Project</h5>
  //           </div>
  //         ),
  //       },
  //       // {
  //       //   key: "2",
  //       //   label: (
  //       //     <div
  //       //       className="d-flex justify-content-left align-items-center table-dropdown"
  //       //       role="button"
  //       //       tabIndex="0"
  //       //       onClick={() => setAddUpdateNewProjectModal(true)}
  //       //       onKeyDown={() => setAddUpdateNewProjectModal(true)}
  //       //     >
  //       //       <MdModeEdit />
  //       //       <h5 className="mb-0 ml-3">Edit project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       {
  //         key: "3",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <MdUnarchive />
  //             <h5 className="mb-0 ml-3">Unarchive Project</h5>
  //           </div>
  //         ),
  //       },
  //       // {
  //       //   key: "4",
  //       //   label: (
  //       //     <div className="d-flex justify-content-left align-items-center table-dropdown">
  //       //       <MdShare />
  //       //       <h5 className="mb-0 ml-3">Share Project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       {
  //         key: "5",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <FaShareSquare />
  //             <h5 className="mb-0 ml-3">Export Project</h5>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const initialColState = [
    {
      id: 1,
      title: "Project Refrence",
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => <div >
        <MdArchive className="completed-icon" fill="#00215B" size={30} />
        <span>{text}</span>
      </div>,
    },
    {
      id: 2,
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 12,
      title: "Project Manager",
      dataIndex: "projectManager",
      key: "projectManager",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 3,
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 4,
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 5,
      title: "Project Status",
      dataIndex: "projectStatus",
      key: "projectStatus",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 6,
      title: "Portfolio's",
      dataIndex: "totalPorfolio",
      render: (text) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="portfolio-tag"
            role="button"
            tabIndex="0"
            onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
            onKeyDown={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text !== null ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 7,
      title: "Assets",
      dataIndex: "totalAssets",
      width: "10%",
      render: (text) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="assets-tag"
            role="button"
            tabIndex="0"
            onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
            onKeyDown={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text !== null ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 8,
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(`/user-management/${param.userId}/user-details/${row.projectId}/project-detail`)
                        }
                        onKeyDown={() =>
                          navigate(`/user-management/${param.userId}/user-details/${row.projectId}/project-detail`)
                        }
                      >
                        <BsEyeFill />
                        <h5 className="mb-0 ml-3">View Project</h5>
                      </div>
                    ),
                  },
                  // {
                  //   key: "3",
                  //   label: (
                  //     <div
                  //       className="d-flex justify-content-left align-items-center table-dropdown"
                  //       role="button"
                  //       tabIndex="0"
                  //       onClick={() => archiveProjectFunction(row)}
                  //       onKeyDown={() => archiveProjectFunction(row)}
                  //     >
                  //       <MdUnarchive />
                  //       <h5 className="mb-0 ml-3">UnArchive Project</h5>
                  //     </div>
                  //   ),
                  // },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   const finalData = {
  //     userId: param.userId
  //   }
  //   dispatch(getAllArchivedByUserProjects(finalData))
  //   // sethideTableColumns(basicDatahideTableColumns);
  // }, [showArchiveProject]);

  const pageNumbers =
  archieveProjectFilter.pagination.pageNo *
    archieveProjectFilter.pagination.size -
  archieveProjectFilter.pagination.size +
  1;

const nextPageNumber =
  archieveProjectFilter.pagination.pageNo *
    archieveProjectFilter.pagination.size >
    archivedProjectsByUser?.projectList?.[0]?.totalRecords
    ? archivedProjectsByUser?.projectList?.[0]?.totalRecords
    : archieveProjectFilter.pagination.pageNo *
      archieveProjectFilter.pagination.size;


  return (
    <div className="user-detail-wrapper">
      <div className="pt-3">
        <DataTable
          onRow={handleRowClick}
          columns={initialColState}
          dataSource={archivedProjectsByUser?.projectList}
         pagination={{
            current: archieveProjectFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: archivedProjectsByUser?.projectList?.[0]?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setArchieveProjectFilter({
                ...archieveProjectFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          scroll={{
            x: 800,
            y: 'calc(100vh - 325px)'
          }}
          loading={isLoading}
        />
      </div>
      {archivedProjectsByUser?.projectList?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of {" "}
            {archivedProjectsByUser?.projectList?.[0]?.totalRecords} entries
          </h5>
        </div>
      )}
    </div>
  );
}
ArchiveProjects.propTypes = {
  handleRowClick: PropTypes.node.isRequired,
  // sethideTableColumns: PropTypes.node.isRequired,
  // showArchiveProject: PropTypes.node.isRequired,
};