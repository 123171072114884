import { Button, Col, Input, notification, Row } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAssignedTaskDetail, saveComment } from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import IMAGES from "../../../../../assets/images";

export default function Comments({ selectedTaskComments, user, active, isLoaded  }) {
  const dispatch = useDispatch();
  const [sendComment, setSendComment] = useState("");

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setSendComment("");
    const finalData = {
      assignTaskId: active,
    };
    dispatch(getAssignedTaskDetail(finalData));
  };

  const sendUserComment = () => {
    const finalData = {
      toUserId: user,
      comment: sendComment,
      assignTaskId: active,
    };
    dispatch(saveComment({ finalData, moveToNext, notifyToaster }));
  };

  return (
    <div className="taskchat-wrapper">
            <div className="taskchat-chatcard-inner custom-scroll px-2 mb-3">
                {
                    selectedTaskComments?.map((item) => (
                        <Row className="d-flex align-items-center mt-3" gutter={8}>
                            <Col xs={4}>
                                <img className="chat-avatar" src={item.img ? item.img : IMAGES.MEN_AVATAR} alt='profile' />
                            </Col>
                            <Col xs={20} flex="auto" className="align-items-start">
                                <div className="m-0 text-black d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0 text-black">{item.userName}</h5>
                                    <p className="m-0">{item?.createdDate?.split("T")[0]}</p>
                                    </div>
                                <p className="m-0 text-black">{item.comment}</p>
                            </Col>
                        </Row>
                    ))
                }
            </div>
      <Input.Group size="small">
        <Input
          className="rounded-0"
          suffix={
            <Button
              size="small"
              className="m-0 p-2 bg-blue text-white rounded-sm"
              onClick={() => sendUserComment()}
              disabled={isLoaded}
            >
              Send
            </Button>
          }
          value={sendComment}
          placeholder="Add your comments"
          onChange={(e) => setSendComment(e.target.value)}
        />
      </Input.Group>
    </div>
  );
}

Comments.propTypes = {
  selectedTaskComments: PropTypes.node.isRequired,
  user: PropTypes.node.isRequired,
  active: PropTypes.node.isRequired,
  isLoaded: PropTypes.node.isRequired,
};
