/* eslint-disable */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Menu,
  Select,
} from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";

const { Option } = Select;

function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) {
  const inputNode = inputType === "number" ? <Input type={inputType} /> : <Select placeholder="Select" allowClear>
  <Option value="1">Yes</Option>
  <Option value="2">No</Option>
</Select>;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
function CurrentRent({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [toggle, setToggle] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const [dataSource, setDataSource] = useState([
    {
      key: 1,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "26.26",
      perMonth: "2,888.21",
      perYear: "34,658.52",
      vat: "Yes",
    },
    {
      key: 2,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "28.17",
      perMonth: "8,992.33",
      perYear: "107,907.96",
      vat: "No",
    },
    {
      key: 3,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "84.61",
      perMonth: "84.61",
      perYear: "1,015.32",
      vat: "Yes",
    },
    {
      key: 4,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "80.85",
      perMonth: "5,798.54",
      perYear: "69,582.48",
      vat: "Yes",
    },
    {
      key: 5,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "161.37",
      perMonth: "14,016.31",
      perYear: "168,195.72",
      vat: "Yes",
    },
    {
      key: 6,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "3.23",
      perMonth: "223.18",
      perYear: "2,678.16",
      vat: "No",
    },
    {
      key: 7,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "100.84",
      perMonth: "100.84",
      perYear: "1,210.08",
      vat: "No",
    },
    {
      key: 8,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "19.46",
      perMonth: "4,173.00",
      perYear: "50,076.00",
      vat: "Yes",
    },
    {
      key: 9,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "19.46",
      perMonth: "4,173.00",
      perYear: "50,076.00",
      vat: "No",
    },
    {
      key: 10,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "24.54",
      perMonth: "2,563.42",
      perYear: "30,761.04",
      vat: "Yes",
    },
    {
      key: 11,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "130.00",
      perMonth: "22,453.60",
      perYear: "269,443.20",
      vat: "Yes",
    },
    {
      key: 12,
      no: "1",
      pid: "01",
      status: "LET",
      perSqM: "8.71",
      perMonth: "629.77",
      perYear: "7,557.24",
      vat: "No",
    },
  ]);

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const currentRentcolumns = [
    {
      id: 1,
      title: "PID",
      dataIndex: "pid",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "status",
      hidden: false,
    },
    {
      id: 3,
      title: "Per Sq M",
      dataIndex: "perSqM",
      hidden: toggle.includes("perSqM"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "perSqM",
        title: "Per Sq M",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Per Month",
      dataIndex: "perMonth",
      hidden: toggle.includes("perMonth"),
      editable: true,
    },
    {
      id: 5,
      title: "Per Year",
      dataIndex: "perYear",
      hidden: toggle.includes("perYear"),
      editable: true,
    },

    {
      id: 6,
      title: "VAT",
      dataIndex: "vat",
      hidden: toggle.includes("vat"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "vat",
        title: "VAT",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record.key)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
          <div
            className="drawer-action-icons d-flex align-items-center cursor-pointer"
            role="presentation"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <MdModeEdit size={20} />
          </div>
          </div>
        );
      },
    },
  ];

  const handleReset = () => {
    setToggle([]);
  };
  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if(checked){
      setToggle((prev) => [...prev, value]);
    }
    else{
      setToggle((prev) => prev.filter((item) => item !== value));
    }
  };

  const basicDatahideTableColumns = (
    <Menu
      items={[
        {
          key: 1,
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        // {
        //   key: 2,
        //   label: (
        //     <Checkbox
        //       value="status"
        //       name="Status/Type"
        //       id={2}
        //       // checked={checkList.includes("valDate")}
        //       // onChange={handleChange}
        //     >
        //       Status/Type
        //     </Checkbox>
        //   ),
        // },
        {
          key: 3,
          label: (
            <Checkbox
              value="perSqM"
              name="Per Sq M"
              id={3}
              // checked={checkList.includes("projectNo")}
              onChange={handleChange}
            >
              Per Sq M
            </Checkbox>
          ),
        },
        {
          key: 4,
          label: (
            <Checkbox
              value="perMonth"
              name="Per Month"
              id={4}
              // checked={checkList.includes("projectName")}
              onChange={handleChange}
            >
              Per Month
            </Checkbox>
          ),
        },
        {
          key: 5,
          label: (
            <Checkbox
              value="perYear"
              name="Per Year"
              id={5}
              // checked={checkList.includes("nameOfClient1")}
              onChange={handleChange}
            >
              Per Year
            </Checkbox>
          ),
        },
        {
          key: 6,
          label: (
            <Checkbox
              value="vat"
              name="VAT"
              id={6}
              // checked={checkList.includes("nameOfClient2")}
              onChange={handleChange}
            >
              VAT
            </Checkbox>
          ),
        },
        {
          key: 7,
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn" onClick={handleReset}>Reset</Button>
            </div>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    sethideTableColumns(basicDatahideTableColumns);
  }, [activeKey]);
  return (
    <>
      <Form form={form} component={false}>
        <DataTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          columns={currentRentcolumns.filter((item) => item.hidden === false)}
            dataSource={[...dataSource]}
          pagination="true"
          className="antd-table-striped"
          rowSelection={rowSelection}
        />
      </Form>
      <div className="table-count">
        <h5 className="table-count-content">Showing 1 to 9 of 120 entries</h5>
      </div>
      {/* <Row gutter={24}>
        <Col xs={24}>
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
              <Button
                className="btn-action d-flex justify-content-center align-items-center text-danger"
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
              >
                <div className="active-action d-flex align-items-center">
                  <MdDelete />
                  <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                </div>
              </Button>

              {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddUpdateNewRentRoll(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdModeEdit className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                    </div>
                  </Button>
                </>
              )}
            </div>
            <Button className="btn-cancel py-2">
              <h5 className="mb-0">Cancel</h5>
            </Button> 
          </div>
        </Col>
      </Row> */}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </>
  );
}

export default CurrentRent;

EditableCell.propTypes = {
  editing: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  inputType: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
CurrentRent.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};
