import React from 'react'

const ImportIcon = ({ color = 'currentColor' }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 4.5C22 3.94772 21.5523 3.5 21 3.5H3C2.44772 3.5 2 3.94772 2 4.5V20.5C2 21.0523 2.44772 21.5 3 21.5H21C21.5523 21.5 22 21.0523 22 20.5V4.5ZM4 15.5H7.41604C8.1876 17.2659 9.94968 18.5 12 18.5C14.0503 18.5 15.8124 17.2659 16.584 15.5H20V19.5H4V15.5ZM4 5.5H20V13.5H15C15 15.1569 13.6569 16.5 12 16.5C10.3431 16.5 9 15.1569 9 13.5H4V5.5ZM16 9.5H13V6.5H11V9.5H8L12 14L16 9.5Z"
                fill={color} />
        </svg>
        // fill-opacity="0.45"
    )
}

export default ImportIcon