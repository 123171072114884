/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * I18nControllerApi - axios parameter creator
 * @export
 */
export const I18nControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} i18nItemKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItem: (i18nItemKey, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'i18nItemKey' is not null or undefined
            assertParamExists('getI18nItem', 'i18nItemKey', i18nItemKey);
            const localVarPath = `/api/i18n/{i18nItemKey}`
                .replace(`{${"i18nItemKey"}}`, encodeURIComponent(String(i18nItemKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItems: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} catalogKey
         * @param {string} contextKey
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItemsForContext: (catalogKey, contextKey, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'catalogKey' is not null or undefined
            assertParamExists('getI18nItemsForContext', 'catalogKey', catalogKey);
            // verify required parameter 'contextKey' is not null or undefined
            assertParamExists('getI18nItemsForContext', 'contextKey', contextKey);
            const localVarPath = `/api/i18n/{catalogKey}/{contextKey}`
                .replace(`{${"catalogKey"}}`, encodeURIComponent(String(catalogKey)))
                .replace(`{${"contextKey"}}`, encodeURIComponent(String(contextKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} i18nItemKey
         * @param {I18n} i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateI18nItem: (i18nItemKey, i18n, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'i18nItemKey' is not null or undefined
            assertParamExists('updateI18nItem', 'i18nItemKey', i18nItemKey);
            // verify required parameter 'i18n' is not null or undefined
            assertParamExists('updateI18nItem', 'i18n', i18n);
            const localVarPath = `/api/i18n/{i18nItemKey}`
                .replace(`{${"i18nItemKey"}}`, encodeURIComponent(String(i18nItemKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(i18n, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * I18nControllerApi - functional programming interface
 * @export
 */
export const I18nControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = I18nControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} i18nItemKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItem(i18nItemKey, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getI18nItem(i18nItemKey, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItems(page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getI18nItems(page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} catalogKey
         * @param {string} contextKey
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} i18nItemKey
         * @param {I18n} i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateI18nItem(i18nItemKey, i18n, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateI18nItem(i18nItemKey, i18n, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * I18nControllerApi - factory interface
 * @export
 */
export const I18nControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = I18nControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} i18nItemKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItem(i18nItemKey, options) {
            return localVarFp.getI18nItem(i18nItemKey, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItems(page, size, sort, options) {
            return localVarFp.getI18nItems(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} catalogKey
         * @param {string} contextKey
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options) {
            return localVarFp.getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} i18nItemKey
         * @param {I18n} i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateI18nItem(i18nItemKey, i18n, options) {
            return localVarFp.updateI18nItem(i18nItemKey, i18n, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * I18nControllerApi - object-oriented interface
 * @export
 * @class I18nControllerApi
 * @extends {BaseAPI}
 */
export class I18nControllerApi extends BaseAPI {
    /**
     *
     * @param {string} i18nItemKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nControllerApi
     */
    getI18nItem(i18nItemKey, options) {
        return I18nControllerApiFp(this.configuration).getI18nItem(i18nItemKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nControllerApi
     */
    getI18nItems(page, size, sort, options) {
        return I18nControllerApiFp(this.configuration).getI18nItems(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} catalogKey
     * @param {string} contextKey
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nControllerApi
     */
    getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options) {
        return I18nControllerApiFp(this.configuration).getI18nItemsForContext(catalogKey, contextKey, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} i18nItemKey
     * @param {I18n} i18n
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nControllerApi
     */
    updateI18nItem(i18nItemKey, i18n, options) {
        return I18nControllerApiFp(this.configuration).updateI18nItem(i18nItemKey, i18n, options).then((request) => request(this.axios, this.basePath));
    }
}
