/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userManagement from "./user.service";

const initialState = {
  users: null,
  user: null,
  createUser: null,
  createUserSuccess: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createUser = createAsyncThunk(
  "userManagement/createUser",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await userManagement.createUser(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      // response.isEdit = finalData.userId !== 0 ? true : false;
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllUserList = createAsyncThunk(
  "userManagement/getAllUserList",
  async (finalData, thunkAPI) => {
    try {
      return await userManagement.getAllUserList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleUser = createAsyncThunk(
  "userManagement/getSingleUser",
  async (finalData, thunkAPI) => {
    try {
      return await userManagement.getSingleUser(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteUsers = createAsyncThunk(
  "userManagement/deleteUsers",
  async ({ finalData, moveToNextDeleteUser, notifyToaster }, thunkAPI) => {
    try {
      const response = await userManagement.deleteUsers(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDeleteUser) {
          moveToNextDeleteUser();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        // const updateUserList = [...state.users];
        // if (action.payload.isEdit) {
        //   for (let i = 0; i < updateUserList.length; i++) {
        //     if (updateUserList[i].userId === action.payload.data.userId) {
        //       updateUserList[i] = action.payload.data;
        //       break;
        //     }
        //   }
        // } else {
        //   updateUserList.unshift(action.payload.data);
        // }
        state.isLoading = false;
        state.isSuccess = true;
        state.createUser = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllUserList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(getAllUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getSingleUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getSingleUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        const deleteMultipleUsers = state.users.filter(
          (item) => item.userId !== action.payload
        );
        state.isLoading = false;
        state.isSuccess = true;
        state.users = deleteMultipleUsers;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createUserSlice.actions;

export default createUserSlice.reducer;
