import api from "../../../utils/api";

const getDashboardData = async (data) => {
  const response = await api.get("/api/CorporateDashboard/getDashboardData", {
    params: data,
  });
  return response.data.data;
};

const getAssignedTaskDashboard = async (data) => {
  const response = await api.get(
    "/api/CorporateDashboard/getAssignedTaskList",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getAssignedTaskDetail = async (data) => {
  const response = await api.get(
    "/api/CorporateDashboard/getAssignedTaskDetail",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getCommentByUser = async (data) => {
  const response = await api.get(
    "/api/CorporateDashboard/getCommentsByAssignTaskId",
    {
      params: data,
    }
  );
  return response.data.data;
};

const saveComment = async (data) => {
  const response = await api.post("/api/CorporateDashboard/saveComment", data);
  return response.data;
};

const updateAssignedTaskStatus = async (data) => {
  const response = await api.post(
    "/api/CorporateDashboard/updateAssignedTaskStatus",
    data
  );
  return response.data;
};

const getTenantDetail = async (data) => {
  const response = await api.get("/api/CorporateDashboard/getTenantDetail", {
    params: data,
  });
  return response.data.data;
};
// Get Assest by Name
const getAssetsNearToExpireByName = async (data) => {
  const response = await api.get(`/api/Asset/getAssetsNearToExpireByName`, {
    params: data,
  });
  return response.data.data;
};

const getAssetByName = async (data) => {
  const response = await api.get(`/api/Asset/getAssetByName`, {
    params: data,
  });
  return response.data.data;
};

const SearchAssetsInByTenant = async (data) => {
  const response = await api.get(
    `/api/CorporateDashboard/SearchAssetsInByTenant`,
    {
      params: data,
    }
  );
  return response.data.data;
};

const AssetTerminalValueCalulation = async (data) => {
  const response = await api.get(
    "/api/AssetTerminalValueCalulation/getByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

const SaveAssetTerminalValueCalulation = async (data) => {
  const response = await api.post(
    "/api/AssetTerminalValueCalulation/save",
    data
  );
  return response.data;
};

const corporateDashboardData = {
  getDashboardData,
  getAssignedTaskDashboard,
  saveComment,
  getAssignedTaskDetail,
  getCommentByUser,
  updateAssignedTaskStatus,
  getTenantDetail,
  getAssetsNearToExpireByName,
  getAssetByName,
  SearchAssetsInByTenant,
  AssetTerminalValueCalulation,
  SaveAssetTerminalValueCalulation,
};

export default corporateDashboardData;
