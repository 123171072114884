import { Modal, Col, Row, Input, Cascader, Button, Form, Select } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";

function SearchForm(props) {
  const [form] = Form.useForm();
  const { visible, handleCancel, rentRollsByAsset, setRentRollsDataByAsset } = props;
  const options = [];
  const projectIdOptions = rentRollsByAsset?.map(item => ({ label: item?.id, value: item?.id }));

  const onFinish = (values) => {
    let filteredData = rentRollsByAsset;
    if (values?.search) {
      filteredData = filteredData.filter((item) =>
        item?.tenantName.toLowerCase().includes(values.search.toLowerCase())
      );
    }
    if (values.projectId) {
      filteredData = filteredData.filter(
        (item) => item.id === parseInt(values.projectId)
      );
    }
    setRentRollsDataByAsset(filteredData);
    handleCancel();
  }

  return (
    <Modal
      className="search-modals"
      open={visible}
      handleCancel={handleCancel}
      onCancel={handleCancel}
      centered
      width={600}
      footer={false}
    > <Form
      form={form}
      name="editAssetDetails"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
    >
        <Row>
          <Col xs={24} className="d-flex justify-content-between">
            <h3
              style={{
                color: "#01215D",
                paddingBottom: "16px",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Filter
            </h3>
            <Form.Item name="search" >
              <Input
                size="large"
                style={{ width: "287px" }}
                prefix={
                  <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                }
                className="mr-2"
                placeholder="Search a Rent roll"
              />
            </Form.Item>
          </Col>
        </Row>
        <br />

        <Row className="row d-flex search-form-filter-row" gutter={10}>
          <Col xs={24} sm={12} className="mb-2">
            <Form.Item name="projectId" >
              <Select placeholder="Project" className="w-100" allowClear>
                {projectIdOptions?.map((item: I18n) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} className="mb-2">
            <Form.Item name="projectName" >
              <Input placeholder="Project Name" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} className="mb-2">
            <Form.Item name="clientName" >
              <Input placeholder="Client Name" className="w-100" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} className="mb-2">
            <Form.Item name="projectCountry" >
              <Select placeholder="Project" className="w-100" allowClear>
                {options?.map((item: I18n) => (
                  <Select.Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} className="">
            <Form.Item name="projectState" >
              <Select placeholder="Project" className="w-100" allowClear>
                {options?.map((item: I18n) => (
                  <Select.Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            style={{ gap: "16px" }}
            className="align-items-center d-flex justify-content-end mt-2"
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
            // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
