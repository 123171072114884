/* eslint-disable react/jsx-props-no-spreading */
import { Card, Input, Form, Table, InputNumber } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { AssetDto } from "reia-rest-client";
import moment from "moment";
import { getFormattedDecimalNumber } from "../../../../../../../../utils/digitHelper";
import {
  getDurationInMonthsOrDefault
} from "../../../../../../../../utils/dcfParamsHelper";
import {DCF_PARAM_INFLATION} from "../../../../../../../../utils/dcfDefaults";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <InputNumber controls={false} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

function VeInflationAndERVGrowth(props) {
  const dispatch = useDispatch();
  const assetDCFParams = useSelector((state) => state.calculation.assetDCFParams);
  const { inflation } = useSelector((state) => state.calculation.assetDCFParams);
  const { asset }: { asset: AssetDto } = useSelector((state) => state.assets);

  const getDurationInMonths = () => getDurationInMonthsOrDefault(assetDCFParams)
  
  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  const startYear: number = moment(asset?.analysisDate).year();
  const endYear: number = startYear + getDurationInMonths() / 12;
  let year, inflationsYear = [];
  let maxYear = endYear - startYear >9 ? startYear + 9 : endYear -1;

  for (year = startYear; year <= maxYear; year++) {
    inflationsYear.push(year)
  }
  
  const inflationColumns = inflationsYear.map((year, index) => {
    return {
      title: year,
      dataIndex: index,
      key: index,
      align: "center",
      editable: true,
      render: (text) => <div>{text ? getFormattedDecimalNumber(text) : <span style={{color: "#a9a9a9"}}>{getFormattedDecimalNumber(DCF_PARAM_INFLATION)}</span>}</div>,
    }
  })
  
  const inflationData = {};

  inflationsYear.forEach((year,index) => {
    inflationData[index] = null
  });
  
  if(inflation)
      inflation.forEach((value, index) => {
        if(value && typeof value === 'number')
          inflationData[index] = value;
      })

  const { striped } = props;

  const handleSave = (row) => {
    setAssetDCFParams({ inflation: Object.values(row) })
    setAssetDCFParams({ ervGrowth: Object.values(row) })
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = inflationColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="erv-table">
      <Card bordered={false}>
        <div>
          <h3 style={{ fontSize: '18px', fontWeight: 500 }} className="text-black">Inflation & ERV Growth</h3>
          {/* <hr className="border-top-0 border-2 mt-0" /> */}
        </div>
        <div>
          <Table
            components={components}
            rowClassName={(record, index) =>
              striped !== false && index % 2 === 0
                ? "ant-table-row-light"
                : "ant-table-row-level"
            }
            columns={columns}
            dataSource={[inflationData]}
            pagination={false}
            className="calculation-table"
            scroll={{
              x: 250,
            }}
          />
        </div>
      </Card >
    </div >
  );
}

export default VeInflationAndERVGrowth;
VeInflationAndERVGrowth.propTypes = {
  striped: PropTypes.node.isRequired,
};

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};
