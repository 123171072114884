/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import forgotPaswword from "./forgotPassword.service";

const initialState = {
  sendCode: [],
  verifyCode: [],
  resendCode: [],
  setForgotPassword: [],
  isError: false,
  isSuccess: false,
  updatePasswordisSuccess: false,
  checkEmailisSuccess: false,
  isLoading: false,
  message: "",
};

export const sendCode = createAsyncThunk(
  "forgotPaswword/sendCode",
  async ({ email,moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await forgotPaswword.sendCode(email);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if(moveToNext){
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyCode = createAsyncThunk(
    "forgotPaswword/verifyCode",
    async ({ finalData, moveToNextVerify, notifyToaster }, thunkAPI) => {
      try {
        const response = await forgotPaswword.verifyCode(finalData);
        if (response.succeeded === true) {
          if (notifyToaster) {
            notifyToaster(response.message, "success");
          }
          if (moveToNextVerify) {         
            moveToNextVerify();
          }
        } else {
          notifyToaster(response.message, "error");
        }
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const resendCode = createAsyncThunk(
    "forgotPaswword/resendCode",
    async ({ email, notifyToaster }, thunkAPI) => {
      try {
        const response = await forgotPaswword.resendCode(email);
        if (response.succeeded === true) {
          if (notifyToaster) {
            notifyToaster(response.message, "success");
          }
        } else {
          notifyToaster(response.message, "error");
        }
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
export const setForgotPassword = createAsyncThunk(
  "updatePassword/setForgotPassword",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await forgotPaswword.setForgotPassword(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const forgotPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    clearforgotPassword: (state) => {
      state.checkEmail = [];
      state.updatePassword = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkEmailisSuccess = true;
        state.sendCode = action.payload;
      })
      .addCase(sendCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verifyCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkEmailisSuccess = true;
        state.verifyCode = action.payload;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resendCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkEmailisSuccess = true;
        state.resendCode = action.payload;
      })
      .addCase(resendCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(setForgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setForgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatePasswordisSuccess = true;
        state.setForgotPassword = action.payload;
      })
      .addCase(setForgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearforgotPassword } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
