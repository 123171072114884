import { Card, Checkbox, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { getFormattedDecimalNumber } from "../../../../../../../../utils/digitHelper";
import { getPortfolioAssetValue, getPortfolioSumProduct } from "../../../../../../../../utils/portfolioHelper";

function VeCapitalizeRate() {
  const dispatch = useDispatch();
  const [lockExitCheck, setLockExitCheck] = useState(false);
  const [bpDiscountRate, setBpDiscountRate] = useState(50);
  const [spreadEC, setSpreadEC] = useState(50);
  const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess, calculationModified, dcfResultsModified, portfolioValueMismatched, autoSaveCalc, portfolioDCFParams } = useSelector((state) => state.portfolio);
  const { discountRate, exitCapRate } = portfolioDCFParams;

  const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);

  const setDiscountRate = (newValue) => {
    dispatch({ type: "portfolio/setPortfolioDCFParams", payload: { discountRate: newValue } })
    if (spreadEC < 0 || spreadEC > 0 && lockExitCheck) {
      dispatch({ type: "portfolio/setPortfolioDCFParams", payload: { exitCapRate: newValue + spreadEC / 100 } })
    }
  }

  const setExitCapRate = (newValue) => {
    dispatch({ type: "portfolio/setPortfolioDCFParams", payload: { exitCapRate: newValue } })
    // if (spreadEC < 0 || spreadEC > 0) {
    //   dispatch({ type: "portfolio/setPortfolioDCFParams", payload: { discountRate: newValue - spreadEC / 100 } })
    // }
  }
  return (
    <Card bordered={false} className="assets_card">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="text-black">Capitalization</h3>
        <Checkbox checked={lockExitCheck} onChange={(e) => setLockExitCheck(e.target.checked)}><span style={{ fontSize: '12px' }}>Lock Exit</span></Checkbox>
      </div>

      <Row className="pt-2 align-items-baseline" gutter={12}>
        <Col xs={24} lg={12} xl={6} >
          <h4 className="holding_text">Discount rate %</h4>
          <IncDecCounter
            incDecStep={bpDiscountRate / 100}
            value={discountRate}
            formatter={getFormattedDecimalNumber}
            setIncDecValue={setDiscountRate}
          />
          Average: {getFormattedDecimalNumber(100 * getPortfolioSumProduct(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified, "discountRate", "capitalNetValue", portfolioDCFParams))}
        </Col>
        <Col xs={24} lg={12} xl={6}>
          <h4 className="holding_text">Exit cap rate %</h4>
          <div className="d-flex align-items-center">
            <IncDecCounter
              incDecStep={bpDiscountRate / 100}
              value={exitCapRate}
              formatter={getFormattedDecimalNumber}
              disabled={lockExitCheck}
              setIncDecValue={setExitCapRate}
            />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={6} >
          <div className="d-flex align-items-center" style={{ marginBottom: "0.5rem", gap: '0px' }}>
            <h4 className="holding_text mb-0">Spread BP</h4>
          </div>
          <div className="d-flex align-items-center">
            <Input
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
              }}
              type="number"
              value={spreadEC}
              onChange={e => { setSpreadEC(e.target.value) }}
            />

          </div>
        </Col>
        <Col xs={24} lg={12} xl={6}>
          <h4 className="holding_text">Steps BP</h4>
          <div className="d-flex align-items-center ">
            <div>
              <Input
                style={{
                  border: "1px solid #B7B7B7",
                  padding: "12px",
                }}
                type="number"
                value={bpDiscountRate}
                disabled={!lockExitCheck}
                onChange={(e) => {
                  setBpDiscountRate(e.target.value);

                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
export default VeCapitalizeRate;
