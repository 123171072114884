/* eslint-disable */
import {apiClient} from "../../../index";
import {AssetDto, Search, SearchCriteria} from "reia-rest-client";

// Create an asset for projects
const createAsset = async (asset: AssetDto) => {
  const response = await apiClient.assetsApi().addAsset(asset);
  return response.data;
};

// Get All list of projects
const getAssetsByProject = async (data) => {
  const projectId: SearchCriteria = {filterKey: "project.id", value: data.assetId, operation: "eq"}
  const search: Search = {dataOption: "all", searchCriteriaList: [projectId]}
  const response = await apiClient.assetsApi().searchAssets(search, data.page-1, data.size);
  return response.data;
};

const updateAsset = async (asset: AssetDto) => {
  const response = await apiClient.assetsApi().updateAsset(asset.id,asset);
  return response.data;
};

// Delete projects
const deleteAssets = async (data) => {
  const response = await apiClient.assetsApi().deleteAssets(data);
  return response.data;
};

// Get Single projects
const getAssetById = async (data) => {
  const response = await apiClient.assetsApi().getAsset(data.assetId)
  return response.data;
};

const assets = {
  createAsset,
  updateAsset,
  getAssetsByProject,
  getAssetById,
  deleteAssets,
};

export default assets;
