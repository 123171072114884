import { CheckCircleFilled } from "@ant-design/icons";
import { Dropdown, Menu, Tag } from "antd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsFillEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
// import { MdArchive } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../../../components/Layout/Table/DataTable";
// import PATH from "../../../../../utils/path";
// import { getAllCompletedByUserProjects } from "../../../../../app/features/userDetail/userDetail.slice";

export default function CompleteProjects(props) {
  const { handleRowClick } = props;
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const param = useParams();

  const { completedProjectsByUser, isLoading } = useSelector(
    (state) => state.userDetail
  );
  const [completeProjectFilter, setCompleteProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  // profile Dropdown
  // const profileDropdown = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <div
  //             className="d-flex justify-content-left align-items-center table-dropdown"
  //             role="button"
  //             tabIndex="0"
  //             onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
  //             onKeyDown={() =>
  //               navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)
  //             }
  //           >
  //             <BsEyeFill />
  //             <h5 className="mb-0 ml-3">View Project</h5>
  //           </div>
  //         ),
  //       },
  //       // {
  //       //   key: "2",
  //       //   label: (
  //       //     <div
  //       //       className="d-flex justify-content-left align-items-center table-dropdown"
  //       //       role="button"
  //       //       tabIndex="0"
  //       //       onClick={() => setAddUpdateNewProjectModal(true)}
  //       //       onKeyDown={() => setAddUpdateNewProjectModal(true)}
  //       //     >
  //       //       <MdModeEdit />
  //       //       <h5 className="mb-0 ml-3">Edit project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       {
  //         key: "3",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <MdArchive />
  //             <h5 className="mb-0 ml-3">Archive Project</h5>
  //           </div>
  //         ),
  //       },
  //       // {
  //       //   key: "4",
  //       //   label: (
  //       //     <div className="d-flex justify-content-left align-items-center table-dropdown">
  //       //       <MdShare />
  //       //       <h5 className="mb-0 ml-3">Share Project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       {
  //         key: "5",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <FaShareSquare />
  //             <h5 className="mb-0 ml-3">Export Project</h5>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const initialColState = [
    {
      id: 1,
      title: "Project Refrence",
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => (
        <div>
          <CheckCircleFilled className="completed-icon" />
          <span>{text}</span>
        </div>
      ),
    },
    {
      id: 2,
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 12,
      title: "Project Manager",
      dataIndex: "projectManager",
      key: "projectManager",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 3,
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 4,
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 5,
      title: "Project Status",
      dataIndex: "projectStatus",
      key: "projectStatus",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 6,
      title: "Portfolio's",
      dataIndex: "totalPorfolio",
      render: (text, row) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="portfolio-tag"
            role="button"
            tabIndex="0"
            onClick={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
              )
            }
            onKeyDown={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
              )
            }
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text !== null ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 7,
      title: "Assets",
      dataIndex: "totalAssets",
      width: "10%",
      render: (text, row) => (
        <div className="asset-portfolio-tags">
          <Tag
            className="assets-tag"
            role="button"
            tabIndex="0"
            onClick={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
              )
            }
            onKeyDown={() =>
              navigate(
                `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
              )
            }
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0 text-black">{text !== null ? text : 0}</h5>
              <AiFillEye className="fill-eye" size={15} fill="#fff" />
            </div>
          </Tag>
        </div>
      ),
    },
    {
      id: 8,
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            `/user-management/${param.userId}/user-details/${row.projectId}/project-detail`
                          )
                        }
                      >
                        <BsFillEyeFill />
                        <h5 className="mb-0 ml-3">View project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   const finalData = {
  //     userId: param.userId,
  //   };
  //   dispatch(getAllCompletedByUserProjects(finalData));
  //   // sethideTableColumns(basicDatahideTableColumns);
  // }, [showCompletedProject]);

  const pageNumbers =
    completeProjectFilter.pagination.pageNo *
      completeProjectFilter.pagination.size -
    completeProjectFilter.pagination.size +
    1;

  const nextPageNumber =
    completeProjectFilter.pagination.pageNo *
      completeProjectFilter.pagination.size >
    completedProjectsByUser?.projectList?.[0]?.totalRecords
      ? completedProjectsByUser?.projectList?.[0]?.totalRecords
      : completeProjectFilter.pagination.pageNo *
        completeProjectFilter.pagination.size;

  return (
    <div>
      <div className="pt-3">
        <DataTable
          onRow={handleRowClick}
          columns={initialColState}
          dataSource={completedProjectsByUser?.projectList}
          pagination={{
            current: completeProjectFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: completedProjectsByUser?.projectList?.[0]?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setCompleteProjectFilter({
                ...completeProjectFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          scroll={{
            x: 800,
            y: "calc(100vh - 325px)",
          }}
          loading={isLoading}
        />
      </div>
      {completedProjectsByUser?.projectList?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of{" "}
            {completedProjectsByUser?.projectList?.[0]?.totalRecords} entries
          </h5>
        </div>
      )}
    </div>
  );
}
CompleteProjects.propTypes = {
  handleRowClick: PropTypes.node.isRequired,
  // sethideTableColumns: PropTypes.node.isRequired,
  // showCompletedProject: PropTypes.node.isRequired,
};
