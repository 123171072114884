import { Modal, Col, Row, Input, Button, DatePicker, Form } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
// import moment from "moment";

function SearchForm(props) {
  const {
    visible,
    handleCancel,
    setSearchValue,
    setContractStartValue,
    setContractEndValue,
    setIsModalOpen,
    form,
  } = props;
  const { Search } = Input;

  const onFinish = (data) => {
    setSearchValue(data.search);
    setContractStartValue(data.contractStartDate);
    setContractEndValue(data.contractEndDate);
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={visible}
      handleCancel={handleCancel}
      // width={250}
      className="search-modals tenants"
      style={{
        top: 80,
        // left: 115,
      }}
      footer={false}
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        className="add-new-org-class"
        form={form}
        layout="vertical"
      >
        <Row gutter={24} className="tenants-search">
          <Col span={24} >
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Search className="mr-2" placeholder="Search Tenant" />
            </Form.Item>
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
          </Col>
          <Col xs={24} md={12} className="mb-md-0">
            <Form.Item
              name="contractStartDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                picker="Contract Start Date"
                className="w-100 rounded-sm"
                placeholder="Contract Start Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} className="mb-2 mb-md-0">
            <Form.Item
              name="contractEndDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                picker="Contract End Date"
                className="w-100 rounded-sm"
                placeholder="Contract End Date"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            className="align-items-center d-flex justify-content-end mt-2"
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
              // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  setSearchValue: PropTypes.node.isRequired,
  setIsModalOpen: PropTypes.node.isRequired,
  setContractEndValue: PropTypes.node.isRequired,
  setContractStartValue: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
};
