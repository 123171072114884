import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import signUp from "./signup.service";

const initialState = {
  createAccounts: null,
  createAccountSuccess: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createAccount = createAsyncThunk(
  "signUp/createAccount",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await signUp.createAccount(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAccountSlice = createSlice({
  name: "createAccounts",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createAccounts = action.payload;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createAccountSlice.actions;

export default createAccountSlice.reducer;
