import { Col, Row } from 'antd'
import ReactApexChart from 'react-apexcharts'
import { getFormattedDecimalNumber } from '../../../../../../../utils/digitHelper'

const CashFlowCharts = () => {
    const totalAreaByUseChart = {
        series: [65, 35, 20, 10, 30],
        options: {
            chart: {
                type: 'donut',
                width: 190,
                height: 190,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 190,
                    },
                },
            }],
            colors: ['#3372C4', '#ED7D31', '#A5A5A5', '#FFC000', '#5B9BD5'],
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
        }
    }
    const currentIncomeByUseChart = {
        series: [65, 35, 20, 10, 30],
        options: {
            chart: {
                type: 'donut',
                width: 190,
                height: 190,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 190,
                    },
                },
            }],
            colors: ['#3372C4', '#ED7D31', '#A5A5A5', '#FFC000', '#5B9BD5'],
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
        }
    }
    const occupancyChart = {
        series: [
            {
                name: 'Secured Icone',
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: 'Current Rent',
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: 'Potential Rent',
                type: "column",
                data: [4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.75]
            },
            {
                name: 'Market Rent',
                type: "column",
                data: [5.00, 6.00, 5.25, 5.00, 6.00, 5.25, 5.00, 6.00, 5.25, 5.25]
            },
            {
                name: 'Occupancy',
                type: "line",
                data: [3.00, 6.00, 5.25, 5.00, 6.00, 100, 5.00, 6.00, 5.25, 5.25]
            }],
        options: {
            chart: {
                height: 350,
                width: '100%',
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false // Hide the menu icon
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#DAE3F3', '#333F50', '#FFC000', '#5B9BD5', '#ED7D31'],
            stroke: {
                width: [1, 1, 4]
            },
            xaxis: {
                categories: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7', 'Year 8', 'Year 9', 'Year 10',],
            },
            yaxis: [
                {
                    min: 0,
                    seriesName: 'Income',
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'Revenue',
                    opposite: true,
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                    },
                },
            ],
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "%";
                    }
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 0
            }
        },
    };
    const cashflowChart = {
        series: [{
            name: 'Free Cash Flow',
            data: [4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.75]
        },
        {
            name: 'Reletting Costs',
            data: [0, 5.60, 4.75, 4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.75]
        },
        {
            name: `Non Rec's`,
            data: [0, 5.60, 4.75, 4.50, 5.60, 4.75, 4.50, 5.60, 4.75, 4.75]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '100%',
                        },

                    },
                }
            }],
            colors: ['#333F50', '#ED7D31', '#FFC000'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 15,  // Set border radius for all bars
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        hideOverflowingLabels: true,
                        enabled: false, // Remove labels in the chart bars
                    },
                    columnWidth: '40%',
                },

            },
            dataLabels: {
                enabled: false // Ensure data labels are globally disabled
            },
            grid: {
                show: false
            },
            xaxis: {
                categories: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7', 'Year 8', 'Year 9', 'Year 10'],
            },
            yaxis: {
                show: true
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 0
            },
            fill: {
                opacity: 1
            }
        },
    };


    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} lg={19}>
                    <div className='custom-card'>
                        <h2 className='mb-0' style={{ fontSize: '24px', color: '#0A0A0A', fontWeight: 500 }}>Cash Flow Chart</h2>
                        <ReactApexChart options={cashflowChart.options} series={cashflowChart.series} type="bar" height={220} />
                    </div>
                </Col>
                <Col xs={24} lg={5}>
                    <div className='custom-card' style={{ paddingBottom: '30px' }}>
                        <div>
                            <h2 className='mb-0' style={{ fontSize: '24px', color: '#0A0A0A', fontWeight: 500 }}>Current Income by Use</h2>
                            <p className='mb-0' style={{ color: '#0A0A0A', fontWeight: 500 }}>€/p.a.</p>
                        </div>
                        <div className='position-relative'>
                            <div className='d-flex justify-content-center'>
                                <ReactApexChart options={currentIncomeByUseChart.options} series={currentIncomeByUseChart.series} type="donut" width={225} height={225} />
                            </div>
                            <div className='text-center position-absolute' style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <h3 className='mb-0' style={{ color: '#797979', fontSize: '9px' }}>Total</h3>
                                <h3 className='mb-0' style={{ color: '#0A0A0A', fontSize: '18px' }}>{getFormattedDecimalNumber(7.78)}%</h3>
                                <h3 className='mb-0' style={{ color: '#797979', fontSize: '9px' }}>sq m</h3>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={19} style={{ paddingTop: '20px' }}>
                    <div className='custom-card'>
                        <h2 className='mb-0' style={{ fontSize: '24px', color: '#0A0A0A', fontWeight: 500 }}>Income & Occupancy</h2>
                        <div className='income-ocuupancy-chart'>
                            <ReactApexChart options={occupancyChart.options} series={occupancyChart.series} type="line" height={215} />
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={5} style={{ paddingTop: '20px' }}>
                    <div className='custom-card'>
                        <div>
                            <h2 className='mb-0' style={{ fontSize: '24px', color: '#0A0A0A', fontWeight: 500 }}>Total Area by Use</h2>
                            <p className='mb-0' style={{ color: '#0A0A0A', fontWeight: 500 }}>12,680 sqm</p>
                        </div>
                        <div className='position-relative'>
                            <div className='d-flex justify-content-center'>
                                <ReactApexChart options={totalAreaByUseChart.options} series={totalAreaByUseChart.series} type="donut" width={225} height={225} />
                            </div>
                            <div className='text-center position-absolute' style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <h3 className='mb-0' style={{ color: '#797979', fontSize: '9px' }}>Total</h3>
                                <h3 className='mb-0' style={{ color: '#0A0A0A', fontSize: '18px' }}>{getFormattedDecimalNumber(7.78)}%</h3>
                                <h3 className='mb-0' style={{ color: '#797979', fontSize: '9px' }}>sq m</h3>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default CashFlowCharts