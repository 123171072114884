import { Button, Col, Form, InputNumber, Row } from "antd";
import PropTypes from "prop-types";

function MarketRentAndTrueMR(props) {
  const { next, prev, handleCancel } = props;

  const onFinish = (values) => {
    console.log('Success:', values);
    next();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Market Rent & True MR
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={8}>
            <Form.Item label="Per Sq M" name="rent per sq m" rules={[
              {
                required: true,
                message: 'Please input Per Sq M',
              },
            ]}>
              <InputNumber type="number" placeholder="Per Sq M" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Per Month" name="rent per month" rules={[
              {
                required: false,
                message: 'Please input Per Month',
              },
            ]}>
              <InputNumber type="number" disabled placeholder="Per Month" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Per Year">
              <InputNumber placeholder="Per Year" disabled className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col xs={8}>
            <Form.Item label="Per Sq M">
              <InputNumber placeholder="" className="w-100" />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button type="primary" className="btn-primary" htmlType="submit">
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default MarketRentAndTrueMR;

MarketRentAndTrueMR.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
