// import { EyeFilled } from "@ant-design/icons";
import {
  Badge,
  // Button,
  Col,
  // DatePicker,
  Divider,
  // Dropdown,
  Input,
  // Menu,
  Row,
} from "antd";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
// import { BsFillCalendarMinusFill } from "react-icons/bs";
import IMAGES from "../../../assets/images";
import SearchForm from "./components/searchForm";

const { Search } = Input;

export default function Index() {
  const [selectedActivity, setSelectedActivity] = useState(null);

  // const onChange = (date, dateString) => {
  //   // eslint-disable-next-line
  //   console.log(date, dateString);
  // };

  // const [open, setOpen] = useState(false);

  // const handleOpenChange = (flag) => {
  //   setOpen(flag);
  // };

  // const hideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Filter Dates</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "2",
  //         label: (
  //           <DatePicker
  //             onChange={onChange}
  //             placeholder="Date from"
  //             className="activity-date-picker rounded-sm w-100"
  //           />
  //         ),
  //       },
  //       {
  //         key: "3",
  //         label: (
  //           <DatePicker
  //             onChange={onChange}
  //             placeholder="Date to"
  //             className="activity-date-picker rounded-sm w-100"
  //           />
  //         ),
  //       },
  //       {
  //         key: "4",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={() => handleOpenChange(false)}>Submit</Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  const [isModalOpen, setIsModalOpen] = useState();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const [active, setActive] = useState(true);

  const data = [
    {
      id: 0,
      date: new Date(2022, 10, 11),
      image: IMAGES.CHILD_IMG,
      name: "Kathryn Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 3,
      activity: [
        {
          id: 0,
          number: 1,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 1,
          number: 2,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 2,
          number: 3,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 3,
          number: 4,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 4,
          number: 5,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 5,
          number: 6,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 6,
          number: 7,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 7,
          number: 8,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 1,
      date: new Date(2022, 10, 11),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 2,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Kathryn Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 3,
      activity: [
        {
          id: 0,
          number: 1,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 1,
          number: 2,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 3,
      date: new Date(2022, 10, 13),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 4,
      date: new Date(2022, 10, 10),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 5,
      date: new Date(2022, 10, 11),
      image: IMAGES.CHILD_IMG,
      name: "Kathryn Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 3,
      activity: [
        {
          id: 0,
          number: 1,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
        {
          id: 1,
          number: 2,
          date: "22/2/2022",
          time: "02:24 PM",
          title: "New task created",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 6,
      date: new Date(2022, 10, 13),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 7,
      date: new Date(2022, 10, 13),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
    {
      id: 8,
      date: new Date(2022, 10, 13),
      image: IMAGES.CHILD_IMG,
      name: "Alexandar Murphy",
      userID: "0248",
      status: "Project Manager",
      activityCount: 35,
      activity: [
        {
          id: 0,
          number: 1,
          date: "21/1/2022",
          time: "05:34 PM",
          title: "Assign a new task to William",
          description:
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form",
        },
      ],
    },
  ];

  // const [filteredUser, setFilteredUser] = useState(data);

  // const handleClick = (status) => {
  //   if (status === 'All') {
  //     setFilteredUser(data)
  //   } else {
  //     setFilteredUser(data.filter((obj) => obj.status === status))
  //   }
  //   setActive(false);
  // };

  // const sortedTestData = filteredUser.sort(
  //   (objA, objB) => Number(objA.date) - Number(objB.date)
  // );

  const activityByDate = data.reduce((group, time) => {
    const { date } = time;
    group[date] = group[date] ?? [];
    group[date].push(time);
    return group;
  }, {});

  return (
    <div className="activity-log-wrapper">
      <Row gutter={24}>
        <Col xl={18} lg={16} md={16} sm={24} xs={24}>
          <Row gutter={24} className="d-flex justify-content-end align-items-center button-search">
            {/* Tab buttons */}
            {/* <Col xl={5} lg={24} md={24}>
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => handleClick('All')}
                  >
                    <div
                      className="active-action"
                      style={{
                        backgroundColor: active === true ? "#f2f3f5" : " ",
                      }}
                    >
                      <h5 className="mb-0 ">All</h5>
                    </div>
                  </Button>
                  <Divider type="vertical" />

                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => handleClick('Project Manager')}
                  >
                    <div className="active-action">
                      <h5 className="mb-0">Project Manager</h5>
                    </div>
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => handleClick('Project Manager')}
                  >
                    <div className="active-action">
                      <h5 className="mb-0">Project Manager</h5>
                    </div>
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => handleClick('Project Manager')}
                  >
                    <div className="active-action">
                      <h5 className="mb-0">Project Manager</h5>
                    </div>
                  </Button>
                </div>
              </div>
            </Col> */}
            {/* Search Field */}
            <Col xl={7} lg={24} md={24} className="w-100 mt-2 mt-xs-2 mt-xl-0">
              <div className="d-flex searchbar-project">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                <Search
                  className="mr-2"
                  placeholder="Search a Activity"
                  onClick={() => setIsModalOpen(true)}
                />
                {/* <Dropdown
                  overlay={hideTableColumns}
                  trigger={["click"]}
                  placement="bottomRight"
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                  <span className="filter-icon-span cursor-pointer">
                    <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
                  </span>
                </Dropdown> */}
              </div>
            </Col>
          </Row>
          <div className="mt-3 pr-2 all-activity-wrapper custom-scroll">
            {Object.entries(activityByDate).map(([key, value]) => (
              <div>
                <Row gutter={24}>
                  <Col xs={24}>
                    <h3 className="text-black">
                      {new Date(key).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </h3>
                  </Col>
                </Row>
                {value.map((item) => (
                  <Row
                    key={item.id}
                    className={`w-100 bg-white row-border rounded-sm p-2 d-flex align-items-center mb-2 m-0 cursor-pointer row-active-hover ${selectedActivity?.id === item?.id && "active"}`}
                    gutter={24}
                    onClick={() => {
                      setSelectedActivity(item);
                    }}
                  >
                    <Col span={12} xs={12}>
                      <Row gutter={24}>
                        <Col flex="none">
                          <img
                            style={{ width: "50px" }}
                            className="rounded-circle"
                            src={item.image}
                            alt="child avatar"
                          />
                        </Col>
                        <Col flex="auto">
                          <Row>
                            <Col xs={12}>
                              <h5 className="mb-0 text-dark-gray">{item.name}</h5>
                              <p className="mb-0 text-dark-gray">User ID - {item.userID}</p>
                            </Col>
                            <Col xs={12}>
                              <Badge
                                count={item.status}
                                style={{
                                  color: "#676EB4",
                                  backgroundColor: "#F2F3F5",
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} xs={12}>
                      <Row gutter={24} className="d-flex align-items-center">
                        <Col xl={3} lg={3} md={3} sm={0} xs={0}>
                          <Divider type="vertical" />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                          <span className="bg-light-grey d-flex align-items-center justify-content-center activity-count font-weight-bold">
                            {item.activityCount}
                          </span>
                        </Col>
                        <Col xl={11} lg={11} md={11} sm={13} xs={13}>
                          <h4 className="text-light-grey mb-0 font-weight-light">
                            Activities Performed
                          </h4>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={8} xs={8} flex="auto" className="d-flex justify-content-end">
                          <IoIosArrowForward size={20} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            ))}
          </div>
        </Col>
        {selectedActivity === null ? (
          <Col xs={24} sm={24} md={8} lg={8} xl={6} className="selected-activity-log bg-white d-flex align-items-center justify-content-center p-5 rounded-sm  mt-2 mt-xs-2 mt-xl-0"
          >
            <div>
              <img
                src={IMAGES.NO_USER}
                alt="no user found"
                className="w-75 pb-2 ml-4"
              />
              <p className="text-center px-2 py-5" style={{ color: "#595A5C" }}>
                Please select the user to see all the activity logs
              </p>
            </div>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={8} lg={8} xl={6} className="selected-activity-log bg-white p-0 px-3 py-3 rounded-sm  mt-2 mt-xs-2 mt-sm-0">
            <Row>
              <Col xs={24}>
                <Row className="bg-light-grey p-3 font-size-sm" gutter={5}>
                  <Col>
                    <img
                      style={{ width: "45px" }}
                      className="rounded-circle"
                      src={selectedActivity.image}
                      alt="child avatar"
                    />
                  </Col>
                  <Col>
                    <h5 className="mb-0 font-weight-bold">
                      {selectedActivity.name}
                    </h5>
                    <p className="mb-0">{selectedActivity.userID}</p>
                  </Col>
                    {/* <Col>
                      <Badge
                        count={selectedActivity.status}
                        style={{ color: "#676EB4", backgroundColor: "#F2F3F5" }}
                      />
                    </Col> */}
                </Row>
              </Col>
              <Col xs={24} className="mt-3 selected-activities custom-scroll">
                {selectedActivity?.activity?.map((item) => (
                  <div>
                    <hr />
                    <Row>
                      <Col xs={20} className="font-size-sm mt-3">
                        <h4 className="font-weight-bold ">
                          Activity {item.number}
                        </h4>
                        <p>
                          {item.date} / {item.time}
                        </p>
                        <h5 className="text-blue">{item.title}</h5>
                        <p>{item.description}</p>
                      </Col>
                      {/* <Col
                        xs={4}
                        className="d-flex align-items-center justift-content-center cursor-pointer"
                      >
                        <EyeFilled className="bg-light-grey-eye p-2 rounded-sm font-size-md" />
                      </Col> */}
                    </Row>
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <SearchForm visible={isModalOpen} handleCancel={closeModal} />
    </div>
  );
}
