/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * CalculationDetailControllerApi - axios parameter creator
 * @export
 */
export const CalculationDetailControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} calculationDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetail: (calculationDetailId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'calculationDetailId' is not null or undefined
            assertParamExists('getCalculationDetail', 'calculationDetailId', calculationDetailId);
            const localVarPath = `/api/calculations/{calculationDetailId}`
                .replace(`{${"calculationDetailId"}}`, encodeURIComponent(String(calculationDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetailByAsset: (assetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getCalculationDetailByAsset', 'assetId', assetId);
            const localVarPath = `/api/calculations/byAsset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} indexType
         * @param {string} date
         * @param {{ [key: string]: object; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexValue: (indexType, date, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'indexType' is not null or undefined
            assertParamExists('getIndexValue', 'indexType', indexType);
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getIndexValue', 'date', date);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getIndexValue', 'requestBody', requestBody);
            const localVarPath = `/api/calculations/indices/{indexType}/{date}`
                .replace(`{${"indexType"}}`, encodeURIComponent(String(indexType)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} calculationDetailId
         * @param {CalculationDetailDto} calculationDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculationDetail: (calculationDetailId, calculationDetailDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'calculationDetailId' is not null or undefined
            assertParamExists('updateCalculationDetail', 'calculationDetailId', calculationDetailId);
            // verify required parameter 'calculationDetailDto' is not null or undefined
            assertParamExists('updateCalculationDetail', 'calculationDetailDto', calculationDetailDto);
            const localVarPath = `/api/calculations/{calculationDetailId}`
                .replace(`{${"calculationDetailId"}}`, encodeURIComponent(String(calculationDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(calculationDetailDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CalculationDetailControllerApi - functional programming interface
 * @export
 */
export const CalculationDetailControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = CalculationDetailControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} calculationDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetail(calculationDetailId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCalculationDetail(calculationDetailId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetailByAsset(assetId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCalculationDetailByAsset(assetId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} indexType
         * @param {string} date
         * @param {{ [key: string]: object; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexValue(indexType, date, requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getIndexValue(indexType, date, requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} calculationDetailId
         * @param {CalculationDetailDto} calculationDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculationDetail(calculationDetailId, calculationDetailDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateCalculationDetail(calculationDetailId, calculationDetailDto, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CalculationDetailControllerApi - factory interface
 * @export
 */
export const CalculationDetailControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CalculationDetailControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} calculationDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetail(calculationDetailId, options) {
            return localVarFp.getCalculationDetail(calculationDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalculationDetailByAsset(assetId, options) {
            return localVarFp.getCalculationDetailByAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} indexType
         * @param {string} date
         * @param {{ [key: string]: object; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexValue(indexType, date, requestBody, options) {
            return localVarFp.getIndexValue(indexType, date, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} calculationDetailId
         * @param {CalculationDetailDto} calculationDetailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalculationDetail(calculationDetailId, calculationDetailDto, options) {
            return localVarFp.updateCalculationDetail(calculationDetailId, calculationDetailDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CalculationDetailControllerApi - object-oriented interface
 * @export
 * @class CalculationDetailControllerApi
 * @extends {BaseAPI}
 */
export class CalculationDetailControllerApi extends BaseAPI {
    /**
     *
     * @param {number} calculationDetailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationDetailControllerApi
     */
    getCalculationDetail(calculationDetailId, options) {
        return CalculationDetailControllerApiFp(this.configuration).getCalculationDetail(calculationDetailId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationDetailControllerApi
     */
    getCalculationDetailByAsset(assetId, options) {
        return CalculationDetailControllerApiFp(this.configuration).getCalculationDetailByAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} indexType
     * @param {string} date
     * @param {{ [key: string]: object; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationDetailControllerApi
     */
    getIndexValue(indexType, date, requestBody, options) {
        return CalculationDetailControllerApiFp(this.configuration).getIndexValue(indexType, date, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} calculationDetailId
     * @param {CalculationDetailDto} calculationDetailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationDetailControllerApi
     */
    updateCalculationDetail(calculationDetailId, calculationDetailDto, options) {
        return CalculationDetailControllerApiFp(this.configuration).updateCalculationDetail(calculationDetailId, calculationDetailDto, options).then((request) => request(this.axios, this.basePath));
    }
}
