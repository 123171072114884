import { Modal, Col, Row, Input, Button, Select } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FiSearch } from "react-icons/fi";
import PATH from "../../../../utils/path";

const { Option } = Select;
function SearchForm(props) {
  const { visible, handleCancel } = props;
  const { Search } = Input;
  // const options1 = [
  //     {
  //         value: 'Active',
  //         label: 'Active'
  //     }
  // ];
  // const options2 = [
  //     {
  //         value: 'Active',
  //         label: 'Active'
  //     }
  // ];

  return (
    <Modal
      centered
      visible={visible}
      handleCancel={handleCancel}
      // width={1250}
      // style={{
      //     top: 80,
      //     left: 115,
      // }}
      footer={[
        <Link to={PATH} onClick={() => handleCancel()} className="btn-cancel">
          Cancel
        </Link>,
        <Button key="submit" type="default" className="btn-primary">
          Search
        </Button>,
      ]}
    >
      <Row>
        <Col span={24} xs={24}>
          <span className="searchbar-icon">
            <FiSearch size={20} />
          </span>
          <Search placeholder="Search user" />
        </Col>
      </Row>
      <br />

      <div className="row d-flex search-form-filter-row">
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <Select
            // defaultValue=""
            style={{
              width: "100%",
            }}
            placeholder="Select Role"
          >
            <Option value="">Select Role</Option>
            <Option value="corp">Corporate</Option>
            <Option value="ind">Individual</Option>
          </Select>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <Select
            // defaultValue=""
            mode="multiple"
            style={{
              width: "100%",
            }}
            placeholder="Select"
          >
            {/* <Option value="">Select</Option> */}
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
            <Option value="subs">Subscriber</Option>
            <Option value="mla">Most Least Assets</Option>
          </Select>
        </div>
      </div>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};
