import { Button, Col, Input, notification, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCommentByUser,
  saveComment,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import IMAGES from "../../../../../assets/images";

export default function TaskName({
  selectedTaskComments,
  user,
  active,
  isLoaded,
}) {
  const dispatch = useDispatch();
  const [sendComment, setSendComment] = useState("");

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    setSendComment("");
    const finalData = {
      assignTaskId: active,
    };
    dispatch(getCommentByUser(finalData));
  };

  const sendUserComment = () => {
    const finalData = {
      toUserId: user,
      comment: sendComment,
      assignTaskId: active,
    };
    dispatch(saveComment({ finalData, moveToNext, notifyToaster }));
  };
  return (
    <div className="taskchat-wrapper mt-4">
      <h3 className="mb-0 text-black">Comments</h3>
      {!active && (
        <div>
          <p className="text-center mt-4">No Comments</p>
        </div>
      )}

      <div className="taskchat-card-inner custom-scroll px-2 mb-3">
        {active && (
          <>
            {selectedTaskComments.length <= 0 && (
              <div>
                <span
                  style={{ fontSize: "14px" }}
                  className="text-center mt-4 d-block fs-4"
                >
                  No Comments
                </span>
              </div>
            )}
            <div>
              {selectedTaskComments?.map((item) => (
                <Row className="d-flex align-items-center mt-3" gutter={8}>
                  <Col>
                    <img
                      className="chat-avatar"
                      src={item.img ? item.img : IMAGES.MEN_AVATAR}
                      alt="profile"
                    />
                  </Col>
                  <Col flex="auto" className="align-items-start">
                    <div className="m-0 text-black d-flex justify-content-between align-items-center mb-1">
                      <h5 className="mb-0 text-black">{item.userName}</h5>{" "}
                      <div>
                        <p className="m-0">
                          {item?.createdDate?.split("T")[0]}
                        </p>
                        <p className="m-0 mt-1">
                          {moment(item?.createdDate).format("h:mm a")}
                        </p>
                      </div>
                    </div>
                    <p className="m-0 text-black">{item.comment}</p>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        )}
      </div>

      {active && (
        <Input.Group size="small">
          <Input
            className="rounded-0 text-black"
            suffix={
              <Button
                size="small"
                className="m-0 p-2 bg-blue text-white rounded-sm " 
                onClick={() => sendUserComment()}
                disabled={isLoaded}
              >
                Send
              </Button>
            }
            value={sendComment}
            placeholder="Add your comments"
            onChange={(e) => setSendComment(e.target.value)}
          />
        </Input.Group>
      )}
    </div>
  );
}

TaskName.propTypes = {
  selectedTaskComments: PropTypes.node.isRequired,
  user: PropTypes.node.isRequired,
  active: PropTypes.node.isRequired,
  isLoaded: PropTypes.node.isRequired,
};
