import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/auth.slice";
import createAccountReducer from "./features/signUp/signup.slice";
import createUserReducer from "./features/userManagement/user.slice";
import createProjectReducer from "./features/Projects/project.slice";
import lookUpsReducer from "./features/Lookups/lookup.slice";
import forgotPasswordReducer from "./features/ForgotPassword/forgotPassword.slice";
import projectDetailsReducer from "./features/projectDetails/projectDetail.slice";
import portfolioReducer from "./features/Portfolio/portfolio.slice";
import rentRollReducer from "./features/RentRoll/rentRoll.slice";
import corporateDashboardDataReducer from "./features/CorporateDashboard/CorporateDashboard.slice";
import userDetailReducer from "./features/userDetail/userDetail.slice";
import calculationReducer from "./features/calculation/calculationAsset.slice";
import cashFlowReducer from "./features/cashflow/cashFlow.slice";
import createAssetsReducer from "./features/assets/assets.slice";
import settingsReducer from "./features/settings/settings.slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    signUp: createAccountReducer,
    userManagement: createUserReducer,
    projects: createProjectReducer,
    assets: createAssetsReducer,
    lookUps: lookUpsReducer,
    forgotPassword: forgotPasswordReducer,
    projectDetails: projectDetailsReducer,
    portfolio: portfolioReducer,
    rentRoll: rentRollReducer,
    corporateDashboardData: corporateDashboardDataReducer,
    userDetail: userDetailReducer,
    calculation: calculationReducer,
    cashflow: cashFlowReducer,
    settings: settingsReducer
  },
});

export default store;
