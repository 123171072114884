import PropTypes from "prop-types";
// import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from "antd";
import { PageCalculationDetailDto, PortfolioDto } from "reia-rest-client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTotalDurationForPortfolio } from "../../../../../../utils/portfolioHelper";
import { DCFResult } from "reia-rest-client";
import { CalculationDetailDto } from "reia-rest-client";
import { getFormattedInt } from "../../../../../../utils/digitHelper";
import moment from "moment";
import { TenantCashFlow } from "reia-dcf-client";
import { DCFOutput } from "reia-dcf-client";
// import DataTable from '../../../../../../components/Theme/DataTable';

// const icon = ({ expanded, onExpand, record }) =>
//     expanded ? (
//         <CaretUpOutlined onClick={e => onExpand(record, e)} />
//     ) : (
//         <CaretDownOutlined onClick={e => onExpand(record, e)} />
//     )

export default function CashflowDataTable(props) {
    const { showTanents, active } = props;
    const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess }: {
        portfolio: PortfolioDto,
        calculationDetailsByPortfolio: PageCalculationDetailDto
    } = useSelector((state) => state.portfolio);

    const [cashflowArrays, setCashflowArrays] = useState({ dataSourceMonth: [], dataSourceYear: [] });
    const [cashflowArraysWithAssets, setCashflowArraysWithAssets] = useState({
        dataSourceMonth: [],
        dataSourceYear: []
    });
    const [tableColumns, setTableColmuns] = useState({ monthColumns: [], yearColumns: [] });
    const assetTitle = {
        title: <div style={{ fontWeight: 500 }}>Assets Cash Flow</div>,
    }
    const portfolioTitle = {
        title: <div style={{ fontWeight: 500 }}>Portfolio Cash Flow</div>,
    }
    const getCashflowsArray = (startDate: moment.Moment, columnsTitle: string, columnKey: string, monthlyDataArray: {}, durationInMonths: number, firstDate: moment.Moment, dataCashFlowSum: {}, dataCashFlowYearSum: {}, calcDetail) => {
        const dataCashFlow = {};
        const dataCashFlowYear = {};

        // Calculate per month according to the year
        //let leftYearMonths = 12 - startDate.month()
        // Calculation if we just add 12 months, ignoring the current calendaryear
        let leftYearMonths = 12
        let yearAmount = 0;
        let yearIndex = Math.ceil(startDate.diff(firstDate, 'years', true));
        let month;
        let startMonth = Math.ceil(startDate.diff(firstDate, 'months', true))
        dataCashFlow.title = columnsTitle;
        dataCashFlow.key = columnKey;
        dataCashFlowYear.title = columnsTitle;
        dataCashFlowYear.key = columnKey;
        for (month = 0; month < monthlyDataArray?.data?.length; month++) {
            const nextMonthAmount = parseInt(monthlyDataArray?.data[month])
            dataCashFlow[startMonth] = nextMonthAmount;
            dataCashFlowSum[startMonth] = dataCashFlowSum[startMonth] ? dataCashFlowSum[startMonth] + nextMonthAmount : nextMonthAmount
            startMonth++;
            yearAmount = yearAmount + nextMonthAmount;
            leftYearMonths--;
            if (leftYearMonths < 1) {
                dataCashFlowYear[yearIndex] = yearAmount;
                dataCashFlowYearSum[yearIndex] = dataCashFlowYearSum[yearIndex] ? dataCashFlowYearSum[yearIndex] + yearAmount : yearAmount
                yearAmount = 0;
                let openMonths = durationInMonths - month - 1;
                leftYearMonths = openMonths >= 12 ? 12 : openMonths;
                yearIndex++
            }
        }
        if (monthlyDataArray?.children) {
            dataCashFlow.children = [];
            dataCashFlowYear.children = [];
            let columnKey = calcDetail.assetId;
            monthlyDataArray?.children?.forEach(childItem => {
                const {
                    monthlyCashflow,
                    yearlyCashFlow
                } = getCashflowsArray(startDate, childItem?.title, childItem?.key + columnKey, childItem?.data, durationInMonths, firstDate, dataCashFlowSum, dataCashFlowYearSum, calcDetail);
                dataCashFlow.children.push(monthlyCashflow);
                dataCashFlowYear.children.push(yearlyCashFlow);
            })
        }

        return { monthlyCashflow: dataCashFlow, yearlyCashFlow: dataCashFlowYear }
    }


    useEffect(() => {
        const { firstDate, lastDate, durationInMonths } = getTotalDurationForPortfolio(calculationDetailsByPortfolio?.calculationDetailDtos)
        const dataSourceMonth = [];
        const dataSourceYear = [];
        const dataSourceMonthWithAssets = [];
        const dataSourceYearWithAssets = [];
        dataSourceMonthWithAssets.push(assetTitle)
        dataSourceYearWithAssets.push(assetTitle)

        const yearsColumn = [{
            title: "Yearly Cash Flow",
            dataIndex: "title",
            fixed: "left",
            width: "250px",
            align: "left",
            render: (text) => <div className="text-blue">{text}</div>,
        }];
        const monthColumns = [{
            title: "Monthly Cash Flow",
            dataIndex: "title",
            fixed: "left",
            width: "250px",
            align: "left",
            render: (text) => <div className="text-blue">{text}</div>,
        }];

        setTableColmuns({ monthColumns: monthColumns, yearColumns: yearsColumn })

        let month;
        for (month = 0; month < durationInMonths; month++) {
            var futureMonth = firstDate.clone().add(month, 'M');
            monthColumns.push({
                title: moment(futureMonth).format("MM/YYYY"),
                dataIndex: month,
                width: 130,
                render: text => text !== undefined ? <div style={{ color: text < 0 ? '#FC0606' : 'auto' }}>{getFormattedInt(text) + " €"}</div> : ""
            })
        }

        const firstYear = firstDate.year();
        // Last year according to the real month per year
        //const lastYear = moment(asset?.analysisDate).add(durationInMonths-1, 'M').year();

        //Last year if we just add 12 months, ignoring the current calendaryear
        const lastYear = lastDate.year();;
        let year = 0;
        let yearDataIndex = 0;
        const startMonth = firstDate.month();
        const mixedYear = startMonth > 0;
        for (year = firstYear; year <= lastYear; year++) {
            yearsColumn.push({
                title: mixedYear ? year + "/" + (year + 1) : year,
                dataIndex: yearDataIndex,
                width: 130,
                render: text => text !== undefined ? <div style={{ color: text < 0 ? '#FC0606' : 'auto' }}>{getFormattedInt(text) + " €"}</div> : ""
            });
            yearDataIndex++;
        }

        if (calculationDetailsByPortfolio) {
            const cashFlowTypes = [
                { key: "totalGrossRentalIncome", title: "Gross Rental Income - GRI" },
                // { key: "totalMArketRent", title: "Market Rent" },
                // { key: "totalPotentialRent", title: "Portential Rent" },
                { key: "totalNonRecs", title: "Non Recoverable Costs" },
                // { key: "totalMaintenanceCosts", title: "Maintanance Costs" },
                // { key: "totalManagementCosts", title: "Management Costs" },
                // { key: "totalOtherCosts", title: "Other Costs" },
                { key: "otherIncomeCosts_beforeNOI", title: "Other Income/Costs before NOI" },
                { key: "totalNetOperatingIncome", title: "Net Operating Income - NOI" },
                // { key: "totalTenantImprovementCosts", title: "Tenant Improvement Costs" },
                // { key: "totalAgentCosts", title: "Agent Costs" },
                // { key: "totalVacancyCosts", title: "Vacancy Costs" },
                { key: "totalRelettingCosts", title: "Reletting & Vacancy Costs" },
                { key: "otherIncomeCosts_afterNOI", title: "Other Income/Costs after NOI" },
                { key: "totalFreeCashflow", title: "Free Cash FLow - FCF" },
                { key: "discountedCashFlowArray", title: "Discounted Cash Flow - DCF" },
            ]
            cashFlowTypes.forEach(cashFlowType => {
                const dataCashFlow = {};
                const dataCashFlowYear = {};
                dataCashFlow.title = cashFlowType.title;
                dataCashFlow.key = cashFlowType.key;
                dataCashFlow.children = [];
                dataCashFlowYear.title = cashFlowType.title;
                dataCashFlowYear.key = cashFlowType.key;
                dataCashFlowYear.children = [];

                Object.values(calculationDetailsByPortfolio?.calculationDetailDtos)?.forEach((calcDetail: CalculationDetailDto) => {

                    const dcfOutput: DCFOutput = calcDetail.assetDCFResult.dcfResult;

                    let columnTitle = `${calcDetail.assetId} - ${calcDetail.assetName}`;
                    let columnKey = calcDetail.assetId;
                    if (dcfOutput.assetCashFlow[cashFlowType.key]) {
                        const dataItem = { data: dcfOutput.assetCashFlow[cashFlowType.key] }
                        if (cashFlowType.key === "totalGrossRentalIncome") {
                            dataItem.children = [];
                            dataItem?.children?.push({
                                data: { data: dcfOutput.assetCashFlow["totalMArketRent"] },
                                title: "Market Rent",
                                key: "totalMArketRent"
                            });
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalPotentialRent"] },
                                title: "Portential Rent",
                                key: "totalPotentialRent"
                            });
                        }
                        if (cashFlowType.key === "totalNonRecs") {
                            dataItem.children = [];
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalMaintenanceCosts"] },
                                key: "totalMaintenanceCosts",
                                title: 'Maintanance Costs'
                            });
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalManagementCosts"] },
                                key: "totalManagementCosts",
                                title: 'Management Costs'
                            });
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalOtherCosts"] },
                                key: "totalOtherCosts",
                                title: 'Other Costs'
                            });
                        }
                        if (cashFlowType.key === "totalRelettingCosts") {
                            dataItem.children = [];
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalTenantImprovementCosts"] },
                                key: "totalTenantImprovementCosts",
                                title: 'Tenant Improvement Costs'
                            });
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalAgentCosts"] },
                                key: "totalAgentCosts",
                                title: 'Agent Costs'
                            });
                            dataItem.children.push({
                                data: { data: dcfOutput.assetCashFlow["totalVacancyCosts"] },
                                key: "totalVacancyCosts",
                                title: 'Vacancy Costs'
                            });
                        }
                        const {
                            monthlyCashflow,
                            yearlyCashFlow
                        } = getCashflowsArray(moment(calcDetail.analysisDate), columnTitle, cashFlowType.key + columnKey, dataItem, durationInMonths, firstDate, dataCashFlow, dataCashFlowYear, calcDetail)
                        if (cashFlowType.key === "totalGrossRentalIncome") {
                            dataSourceMonthWithAssets.push(monthlyCashflow);
                            dataSourceYearWithAssets.push(yearlyCashFlow);
                        }

                        dataCashFlow.children.push(monthlyCashflow);
                        dataCashFlowYear.children.push(yearlyCashFlow);
                    }
                });

                if (cashFlowType.key === "totalGrossRentalIncome") {
                    dataSourceMonthWithAssets.push(portfolioTitle)
                    dataSourceYearWithAssets.push(portfolioTitle)
                }
                dataSourceMonthWithAssets.push(dataCashFlow);
                dataSourceYearWithAssets.push(dataCashFlowYear);
                dataSourceMonth.push(dataCashFlow)
                dataSourceYear.push(dataCashFlowYear)
            })

        }
        const markForRemovalIfSumZero = (data, targetTitle) => {
            // Filter the items based on the target title
            const filteredItems = data.filter(item => item.title === targetTitle);
            // Check if all numeric values in the filtered items are zero
            const allValuesZero = filteredItems.every(currentItem => {
                let sum = 0;
                let hasNumericValue = false;
                for (let key in currentItem) {
                    if (typeof currentItem[key] === 'number') {
                        hasNumericValue = true;
                        sum += currentItem[key];
                    }
                }
                return hasNumericValue && sum === 0;
            });

            // If all numeric values are zero, mark the items for removal
            if (allValuesZero) {
                return data.map(item => item.title === targetTitle ? { ...item, isRemoved: true } : item);
            }
            return data;
        };

        const changeTitleOtherCost = (data) => {
            let result = [...data];
            // Get unique titles from the data
            const uniqueTitles = [...new Set(data.map(item => item.title))];
            // Iterate over each unique title and mark items for removal if their sum is zero
            uniqueTitles.forEach(title => {
                result = markForRemovalIfSumZero(result, title);
            });

            return result.filter(item => !item?.isRemoved);
        };

        setCashflowArrays({ dataSourceMonth: changeTitleOtherCost(dataSourceMonth), dataSourceYear: changeTitleOtherCost(dataSourceYear) });
        setCashflowArraysWithAssets({
            dataSourceMonth: changeTitleOtherCost(dataSourceMonthWithAssets),
            dataSourceYear: changeTitleOtherCost(dataSourceYearWithAssets)
        });

    }, [calculationDetailsByPortfolio]);

    const highlighted = [
        "Net Operating Income - NOI",
        "Free Cash FLow - FCF",
        "Discounted Cash Flow - DCF",
        "Total Financing Cash Flow",
        "Leverage Cash Flow",
    ];

    return (
        <Table key={showTanents ? "assetPortfolioTable" : "portfolioTable"}
            rowClassName={(record) =>
                highlighted.includes(record.title)
                    ? "highlighted-row table-row-light"
                    : "table-row-light"
            }
            className="antd-custom-card-table cashflow-table py-3"
            columns={active === "monthly" ? tableColumns.monthColumns : tableColumns.yearColumns}
            dataSource={active === "monthly" ? showTanents ? cashflowArraysWithAssets.dataSourceMonth : cashflowArrays.dataSourceMonth : showTanents ? cashflowArraysWithAssets.dataSourceYear : cashflowArrays.dataSourceYear}
            loading={isLoading}
            // columns={active === "monthly" ? monthColumn : yearColumn}
            // dataSource={dataSource}
            pagination={false}
            scroll={{
                x: 1300,
            }}
        // expandable={{
        //     expandIcon: icon,
        //     rowExpandable: (record) => record.title === 'Total Non Recoverable Costs',
        // }}
        />
    );
}

CashflowDataTable.propTypes = {
    showTanents: PropTypes.node.isRequired,
    active: PropTypes.node.isRequired,
};
