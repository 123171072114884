import React from 'react'

const SaveIcon = ({ color = 'currentColor' }) => {
    return (

        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 12.4998L12 16.4998M12 16.4998L16 12.4998M12 16.4998V7.29982C12 5.90911 12 5.21376 11.4495 4.43521C11.0837 3.91793 10.0306 3.2795 9.40278 3.19444C8.45789 3.06642 8.09907 3.2536 7.38143 3.62796C4.18333 5.29625 2 8.64306 2 12.4998C2 18.0227 6.47715 22.4998 12 22.4998C17.5228 22.4998 22 18.0227 22 12.4998C22 8.79841 19.989 5.56669 17 3.83764"
                stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default SaveIcon