import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cashflow from "./cashFlow.service";


const initialState = {
    cashFlowMonthly: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    statusMessage: "",
  };

  export const getCashFlowMonthly = createAsyncThunk(
    "cashflow/getCashFlowMonthly",
    async (finalData, thunkAPI) => {
      try {
        return await cashflow.getCashFlowMonthly(finalData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const cashFlowSlice = createSlice({
    name: "cashflow",
    initialState,
    reducers: {
      clearData: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.statusMessage = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCashFlowMonthly.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getCashFlowMonthly.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.cashFlowMonthly = action.payload;
        })
        .addCase(getCashFlowMonthly.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
    },
  });
  
  export const { clearData } = cashFlowSlice.actions;
  
  export default cashFlowSlice.reducer;