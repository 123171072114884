import { Modal, Col, Row, Input, Button } from "antd";
import PropTypes from "prop-types";
import { FiSearch } from "react-icons/fi";

function SearchForm(props) {
  const { visible, handleCancel } = props;
  const { Search } = Input;

  return (
    <Modal
      className="search-modals"
      visible={visible}
      handleCancel={handleCancel}
      width={600}
      style={{
        top: 80,
        // left: 115,
      }}
      footer={[
        <Button className="btn-cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="default" className="btn-primary">
          Search
        </Button>,
      ]}
    >
      <Row>
        <Col span={24} xs={24}>
          <span className="searchbar-icon">
            <FiSearch size={20} />
          </span>
          <Search placeholder="Search parameter" />
        </Col>
      </Row>
      <br />

      <Row className="row d-flex search-form-filter-row" gutter={10}>
        <Col xs={24} sm={12} lg={8} className="mb-2">
          <Input placeholder="Parameter Type" className="w-100" />
        </Col>
        <Col xs={24} sm={12} lg={8} className="mb-2">
          <Input placeholder="Created date" className="w-100" />
        </Col>
        <Col xs={24} sm={12} lg={8} className="mb-2">
          <Input placeholder="Value" className="w-100" />
        </Col>
      </Row>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};
