/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rentRoll from "./rentRoll.service.js";
import assetsDetails from "../projectDetails/projectDetail.service";
import assets from "../assets/assets.service";

const initialState = {
  rentRollsByAsset: null,
  allTenants:[],
  createRentRollPost: null,
  deleteRentRoll: null,
  RentRollById: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};


export const createRentRollPost = createAsyncThunk(
  "rentRoll/createRentRollPost",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.createRentRoll(finalData);
      if (response.id) {
        if (notifyToaster) {
          notifyToaster("New RentRoll with ID: "+response.id+" created!", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.detail, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRentRollsByAsset = createAsyncThunk(
    "rentRoll/getRentRollsByAsset",
    async ({finalData, notifyToaster}, thunkAPI) => {
      try {
        return await rentRoll.getRentRollsByAsset(finalData);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateRentRoll = createAsyncThunk(
    "rentRoll/updateRentRoll",
    async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
      try {
        const response = await rentRoll.updateRentRoll(finalData);
        if (response.id) {
          if (notifyToaster) {
            notifyToaster("RentRoll with "+response.id+" updated!", "success");
          }
          if (moveToNext) {
            moveToNext();
          }
        } else {
          notifyToaster("RentRoll update failed!", "error");
        }
        return response;
      } catch (error) {
        notifyToaster(error.message, "error");
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getRentRollByID = createAsyncThunk(
    "rentRoll/getRentRollByID",
    async ({finalData, notifyToaster}, thunkAPI) => {
      try {
        return await rentRoll.getRentRollById(finalData);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const deleteRentRolls = createAsyncThunk(
    "rentRoll/deleteRentRoll",
    async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
      try {
        const response = await rentRoll.deleteRentRolls(finalData);
        if (response.code === 200) {
          if (notifyToaster) {
            notifyToaster(response.message, "success");
          }
          if (moveToNextDelete) {
            moveToNextDelete();
          }
        } else {
          notifyToaster(response.message, "error");
        }
        return response;
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const createRentRollSlice = createSlice({
  name: "RentRoll",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRentRollPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRentRollPost.fulfilled, (state, action) => {
        // const createRentRollPost = [...state.rentRollList];
        // createRentRollPost.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.createRentRollPost = action.payload;
      })
      .addCase(createRentRollPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getRentRollsByAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRentRollsByAsset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollsByAsset = action.payload;
      })
      .addCase(getRentRollsByAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
  },
});

export const { clearData } = createRentRollSlice.actions;

export default createRentRollSlice.reducer;
