// /* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import portfolio from "./portfolio.service";
import {PortfolioDto} from "reia-rest-client";
import {DefaultMessage} from "reia-rest-client";
import calculation from "../calculation/calculationAsset.service";
import {DCFOutput} from "reia-dcf-client";
import {compareAssetValue, deepObjectAsStringCompare} from "../../../utils/dcfHelper";
import {comparePortfolioValue} from "../../../utils/portfolioHelper";

const initialState = {
  allPortfolio: [],
  portfolio: null,
  calculationDetailsByPortfolio: null,
  mergeAssets: null,
  assignAssets: null,
  createPortfolio: null,
  updatePortfolio: null,
  deletePortfolio: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
  isCalculationDetailLoading: false,
  calculationDetailsModified: {},
  dcfResultsModified: {},
  portfolioDCFParams: {
    discountRate: 0,
    exitCapRate: 0,
    durationInMonths: null,
    analysisDate: null
  },
  rentRollAssumptions: {},
  calculationModified: false,
  portfolioValueMismatched: false,
  autoSaveCalc: false,
  resetData: false,
};

export const createPortfolio = createAsyncThunk(
  "portfolio/createPortfolio",
  async ({ finalData, moveToNext2, notifyToaster }, thunkAPI) => {
    try {
      const response: PortfolioDto = await portfolio.createPortfolio(finalData);
      if (response.id != null) {
        if (notifyToaster) {
          notifyToaster("Portfolio with ID: "+response.id+" added!", "success");
        }
        if (moveToNext2) {
          moveToNext2();
        }
      } else {
        notifyToaster("Error adding Portfolio\n"+JSON.stringify(response), "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
          (error.response &&
              error.response.detail &&
              error.response.detail) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePortfolio = createAsyncThunk(
    "portfolio/updatePortfolio",
    async ({ finalData, moveToNext2, notifyToaster }, thunkAPI) => {
      try {
        const response: PortfolioDto = await portfolio.updatePortfolio(finalData);
        if (response.id != null) {
          if (notifyToaster) {
            notifyToaster("Portfolio with ID: "+response.id+" updated!", "success");
          }
          if (moveToNext2) {
            moveToNext2();
          }
        } else {
          notifyToaster("Error updating Portfolio\n"+JSON.stringify(response), "error");
        }
        return response;
      } catch (error) {
        notifyToaster(error.message, "error");
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getAllPortfolio = createAsyncThunk(
  "portfolio/getAllPortfolio",
  async (finalData, thunkAPI) => {
    try {
      return await portfolio.getAllPortfolio(finalData);
    } catch (error) {
      const message =
          (error.response &&
              error.response.detail &&
              error.response.detail) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPortfolio = createAsyncThunk(
    "portfolio/getPortfolio",
    async (finalData, thunkAPI) => {
      try {
        return await portfolio.getPortfolio(finalData);
      } catch (error) {
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCalculationDetailsByPortfolio = createAsyncThunk(
    "portfolio/getCalculationDetailsByPortfolio",
    async (finalData, thunkAPI) => {
      try {
        return await portfolio.getCalculationDetailsByPortfolio(finalData);
      } catch (error) {
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateCalculationDetailsByPortfolio = createAsyncThunk(
    "portfolio/updateCalculationDetailsByPortfolio",
    async (finalData, thunkAPI) => {
      try {
        return await portfolio.updateCalculationDetailsByPortfolio(finalData);
      } catch (error) {
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const mergeAssetToPortfolio = createAsyncThunk(
    "portfolio/mergeAssetToPortfolio",
    async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
      try {
        const response = await portfolio.addAssetsToPortfolio(finalData);
        if (response.code === 200) {
          if (notifyToaster) {
            notifyToaster(response.message, "success");
          }
          if (moveToNext) {
            moveToNext();
          }
        } else {
          notifyToaster(response.message, "error");
        }
        return response;
      } catch (error) {
        notifyToaster(error.message, "error");
        const message =
            (error.response &&
                error.response.detail &&
                error.response.detail) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const deletePortfolio = createAsyncThunk(
  "portfolio/deletePortfolio",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response: DefaultMessage = await portfolio.deletePortfolio(finalData);
      if (response.code === 200) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
          (error.response &&
              error.response.detail &&
              error.response.detail) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetsDCFResult = createAsyncThunk(
    "portfolio/getAssetsDCFResult",
    async ({finalData, notifyToaster}, thunkAPI) => {
      try {
        // console.log("DCF Inputs: ", finalData)
        const response = await calculation.getAssetsDCFResult(finalData);
        // console.log("DCF Outputs: ", response)
        if(Array.isArray(response))
        {
          const result = {};
          response.forEach((dcfResult: DCFOutput) => {
            result[dcfResult.assetCashFlow.assetId] = dcfResult; 
          })
          
          return result;  
        }
        
      } catch (error) {
        console.log(error)
        if(error.response.data)
        {
          if(error.response.data.title)
          {
            notifyToaster("DCF calculation: "+error.response.data.title+". Errors: "+JSON.stringify(error.response.data.errors), "error");
          }else{
            notifyToaster("DCF calculation: "+error.response.data, "error");
          }
        }
        else{
          notifyToaster(error.message, "error");
        }
        const message =
            (error.response &&
                error.response.data &&
                error.response.data) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const createPortfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState
    },
    resetData: (state) =>
    {
      state.rentRollAssumptions = {};
      state.calculationDetailsModified = {},
      state.dcfResultsModified = {},
      state.portfolioDCFParams = initialState.portfolioDCFParams,
      state.calculationModified= false
      state.portfolioValueMismatched = !comparePortfolioValue(state.calculationDetailsByPortfolio?.calculationDetailDtos, state.dcfResultsModified)
    },
    setPortfolioDCFParams: (state, action) => {
      state.portfolioDCFParams = {...state.portfolioDCFParams, ...action.payload}
      state.calculationModified = !deepObjectAsStringCompare(state.portfolioDCFParams, state.calculationDetailsByPortfolio)
    },
    setRentRollAssumption: (state, action) => {
      state.rentRollAssumptions = action.payload
      state.calculationModified = !Object.keys(state.rentRollAssumptions).length === 0
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPortfolio = action.payload;
      })
      .addCase(createPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
        .addCase(updatePortfolio.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updatePortfolio.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.updatePortfolio = action.payload;
        })
        .addCase(updatePortfolio.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
      .addCase(getAllPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPortfolio = action.payload;
      })
      .addCase(getAllPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
        .addCase(getPortfolio.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getPortfolio.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.portfolio = action.payload;
        })
        .addCase(getPortfolio.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
        .addCase(getCalculationDetailsByPortfolio.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getCalculationDetailsByPortfolio.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.calculationDetailsByPortfolio = action.payload;
          state.portfolioValueMismatched = !comparePortfolioValue(state.calculationDetailsByPortfolio?.calculationDetailDtos, state.dcfResultsModified)
        })
        .addCase(getCalculationDetailsByPortfolio.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
        .addCase(updateCalculationDetailsByPortfolio.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateCalculationDetailsByPortfolio.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.calculationDetailsByPortfolio = action.payload;
          state.rentRollAssumptions = {};
          state.calculationDetailsModified = {},
          state.dcfResultsModified = {},
          state.portfolioDCFParams = initialState.portfolioDCFParams,
          state.calculationModified= false
          state.portfolioValueMismatched = !comparePortfolioValue(state.calculationDetailsByPortfolio?.calculationDetailDtos, state.dcfResultsModified)
        })
        .addCase(updateCalculationDetailsByPortfolio.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.statusMessage = action.payload;
        })
        .addCase(mergeAssetToPortfolio.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(mergeAssetToPortfolio.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.mergeAssets = action.payload;
        })
        .addCase(mergeAssetToPortfolio.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.statusMessage = action.payload;
        })
      .addCase(deletePortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePortfolio = action.payload;
      })
      .addCase(deletePortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetsDCFResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetsDCFResult.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dcfResultsModified = action.payload;
        state.portfolioValueMismatched = !comparePortfolioValue(state.calculationDetailsByPortfolio?.calculationDetailDtos, state.dcfResultsModified)
      })
      .addCase(getAssetsDCFResult.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData,resetData } = createPortfolioSlice.actions;

export default createPortfolioSlice.reducer;
