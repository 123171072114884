/* eslint-disable no-unneeded-ternary */
import { Avatar, Card, Form, Typography } from "antd";
import { useState } from "react";
import PropTypes from "prop-types";
// import IMAGES from "../../../../assets/images";
import DataTable from "../../../../components/Layout/Table/DataTable";
import TenantsDetailAndProfile from "./TotalTenants/TenantsDetailAndProfile";

export default function TopFiveTenants({ dashboardData }) {
  const [tenantsDetails, setTenantsDetail] = useState(false);
  const [tenantsRecord, setTenantsRecord] = useState("");
  const [showRecordTenants, setShowRecordTenants] = useState(true);
  const [showProfileTenants, setShowProfileTenants] = useState(false);
  const [form] = Form.useForm();
  const { Text } = Typography;
  const closeTenantsDetailModal = () => {
    setTenantsDetail(false);
    setTenantsRecord("");
    setShowRecordTenants(true);
    setShowProfileTenants(false);
    form.resetFields();
  };
  const columns = [
    {
      title: "Avatar",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: "50px",
      render: (imageUrl, values) => (
        <Avatar
          src={imageUrl ? imageUrl : `https://ui-avatars.com/api/?name=${`${values.tenantName}`}&background=00215b&color=fff`}
          size={35}
          className="cursor-pointer avatar"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "tenantName",
      key: "tenantName",
      render: (text) => (
        <Text
          style={{
            width: 240,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text !== null ? text : "N/A"}
        </Text>
      ),
    },
    {
      title: "Revnue",
      dataIndex: "revenue",
      key: "revenue",
      render: (row) => (
        <>
          <h5 className="mb-0 pr-1 text-black">${row}</h5>
          <p className="mb-0 pr-1 font-weight-light">Revnue</p>
        </>
      ),
    },
  ];

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        setTenantsDetail(true);
        setTenantsRecord(path);
        // console.log(record, rowIndex, path); // to avoid es lint error
      }, // click row
    };
  };
  return (
    <div className="top-tanents-wrapper">
      <Card className="top-tenants-card">
        <h3 className="text-black mb-0 pt-1">Top 5 Tenants</h3>
        <hr />
        <DataTable
          columns={columns}
          dataSource={dashboardData?.topTenants}
          pagination={false}
          className="antd-table-striped"
          showHeader={false}
          scroll={{
            y: 250,
          }}
          onRow={navigateRow}
        />
        <TenantsDetailAndProfile
          tenantsRecord={tenantsRecord}
          visible={tenantsDetails}
          handleCancel={closeTenantsDetailModal}
          showRecordTenants={showRecordTenants}
          setShowRecordTenants={setShowRecordTenants}
          showProfileTenants={showProfileTenants}
          setShowProfileTenants={setShowProfileTenants}
          form={form}
        />
      </Card>
    </div>
  );
}

TopFiveTenants.propTypes = {
  dashboardData: PropTypes.node.isRequired,
};
