/* eslint-disable */
import { Card, Col, Dropdown, Menu, notification, Row, Typography } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BiFilter } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsArrowRight, BsDot } from "react-icons/bs";
import PropTypes from "prop-types";
import { HiUser } from "react-icons/hi";
import TaskName from "./TaskName";
import IMAGES from "../../../../../assets/images";
import PATH from "../../../../../utils/path";
import {
  getAssignedTaskDashboard,
  getCommentByUser,
  updateAssignedTaskStatus,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice.js";
import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";

const { Text } = Typography;

export default function RecentTasks({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const [active, setactive] = useState(null);
  const [user, setUser] = useState(null);
  // const getAssignedTask = useSelector((state) => state.corporateDashboardData);
  const { assignedTask, getComment, isLoading } = useSelector(
    (state) => state.corporateDashboardData
  );
  const { taskStatusType } = useSelector((state) => state.lookUps);

  const navigate = useNavigate();

  const [filteredTasks, setfilteredTasks] = useState();
  const [statusTask, setStatusTask] = useState();

  const handleCardClick = (task) => {
    // setselectedTask(task);
    setactive(task.assignTaskId);
    setUser(task.userId);
  };

  const getAllComment = () => {
    const finalData = {
      assignTaskId: active,
    };
    dispatch(getCommentByUser(finalData));
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  
  const moveToNext = () => {
    dispatch(getAssignedTaskDashboard());
  };

  const statusChange = (task) => {
    const finalData = {
      assignTaskId: active,
      taskStatusId: task.lookUpId
    };
    dispatch(updateAssignedTaskStatus({finalData, moveToNext, notifyToaster}));
  };
  useEffect(() => {
    const finalData = {
      type: "taskStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    if (active) {
      getAllComment();
    }
  }, [active]);
  
  const statusChangeActive = [{
    lookUpId: null,
    text: "All",
    value: "All"
  }, ...taskStatusType]

  const taskAssignedFilter = (
    <Menu
      defaultSelectedKeys={["1"]}
      selectable
      items={statusChangeActive?.map((item) => {
        return {
          key: item.lookUpId,
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => setfilteredTasks(item.lookUpId)}
            >
              {item.text}
            </div>
          ),
        };
      })}
    />
  );

  useEffect(() => {
    const finalData = {
      statusTypeId: filteredTasks ? filteredTasks : "",
    };
    dispatch(getAssignedTaskDashboard(finalData));
  }, [dispatch, filteredTasks]);
  return (
    <div className="bg-white rounded-sm recent-task w-100">
      <div className="p-2 rounded-sm">
        <div className="d-none d-xl-block">
          <span
            className=" drawer-close-btn cursor-pointer"
            role="button"
            tabIndex="0"
          >
            <MdOutlineKeyboardArrowRight
              size={25}
              fill="#fff"
              onClick={() => setIsOpen(!isOpen)}
            />
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0 d-flex align-items-center text-black">
            Task Assigned
            <Dropdown overlay={taskAssignedFilter} trigger={["click"]}>
              <BiFilter
                className="ml-3 p-1 rounded-sm cursor-pointer filter-hover"
                size={30}
              />
            </Dropdown>
          </h3>
          <div
            className="d-flex align-items-center text-blue cursor-pointer"
            role="button"
            tabIndex="0"
            onClick={() => navigate(PATH.TASK_ASSIGNED)}
            onKeyDown={() => navigate(PATH.TASK_ASSIGNED)}
          >
            <span className="mr-1">View all</span>
            <BsArrowRight size={18} />
          </div>
        </div>
        <hr />
        <div className="recent-task-scroll custom-scroll">
          {assignedTask?.map((item) => (
            <Card className="m-0 border-0" key={item.assignTaskId}>
              <div
                onClick={() => handleCardClick(item)}
                className={`assign-task cursor-pointer d-flex align-items-center justify-content-between p-2 ${
                  active === item.assignTaskId ? "active" : ""
                }`}
                role="presentation"
              >
                <div className="d-flex align-items-center">
                  <Col className="p-0">
                    <div
                      className={`${
                        (active === item.assignTaskId ? "active" : "",
                        item.isCommentExist === true ? "dot" : "")
                      } 

                       `}
                    />
                    <img
                      className="assign-task-avatar"
                      src={item.img ? item.img : IMAGES.MEN_AVATAR}
                      alt="avatar"
                    />
                  </Col>
                  <div className="mb-2 ml-2">
                    <div className="d-flex align-items-center task-title">
                      <h5 className="text-black mb-0">
                        <Text
                          style={{
                            width: 120,
                          }}
                          ellipsis={{ tooltip: item.taskName }}
                        >
                          {item.taskName}
                        </Text>
                      </h5>
                    </div>
                    <div className="d-flex align-items-center text-grey-shade m-0 task-name">
                      <HiUser className="mr-1" />
                      <Text
                        style={{
                          width: 90,
                        }}
                        ellipsis={{ tooltip: item.userName }}
                        className="font-weight-light"
                      >
                        {item.userName}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="task-status mr-2">
                  <div className="d-flex justify-content-end mt-2">
                    <p className="m-0 text-grey-shade d-flex align-items-center">
                      <BsDot />
                      {moment(item.assignedDate).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <Dropdown
                    overlay={
                      <Menu
                        selectable
                        defaultSelectedKeys={[item.statusType]}
                        items={taskStatusType?.map((item) => {
                          return {
                            key: item.lookUpId,
                            label: (
                              <div
                                role="presentation"
                                className="d-flex justify-content-left align-items-center table-dropdown"
                                onClick={() => statusChange(item)}
                              >
                                {item.text}
                              </div>
                            ),
                          };
                        })}
                      />
                    }
                    trigger={["click"]}
                  >
                    <p
                      className={`mt-1 ${
                        item.statusType === "Pending"
                          ? "pending-status" 
                          : item.statusType === "Completed" ? "complete-status" : "rejected-status"
                      }`}
                    >
                      {item.statusType}
                    </p>
                  </Dropdown>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <Row>
          <Col xs={24} className="mt-2">
            <TaskName
              selectedTaskComments={getComment}
              user={user}
              active={active}
              isLoaded={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

RecentTasks.propTypes = {
  setIsOpen: PropTypes.node.isRequired,
  isOpen: PropTypes.node.isRequired,
};
