/* tslint:disable */
/* eslint-disable */
/**
 * DCF API
 * The DCF API calculates discounted cashflows for assets
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * CashflowApi - axios parameter creator
 * @export
 */
export const CashflowApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This endpoint uses the existing calculation and checks whether a new calculation based on the asset data or possibly other rent rolls is necessary and returns the discounted cash flow for the asset accordingly.  All rent rolls have to be transferred if something has changed in the rent rolls. Or the field rent rolls is left empty if only the asset data has changed.
         * @summary Update existing DCF calculation for a Asset
         * @param {number} id
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetIdPost: (id, dCFInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dcfAssetIdPost', 'id', id);
            const localVarPath = `/dcf/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dCFInput, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for a single Asset
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetPost: (dCFInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/dcf/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dCFInput, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for multiple Asset
         * @param {Array<DCFInput>} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfPortfolioPost: (dCFInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/dcf/portfolio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(dCFInput, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CashflowApi - functional programming interface
 * @export
 */
export const CashflowApiFp = function (configuration) {
    const localVarAxiosParamCreator = CashflowApiAxiosParamCreator(configuration);
    return {
        /**
         * This endpoint uses the existing calculation and checks whether a new calculation based on the asset data or possibly other rent rolls is necessary and returns the discounted cash flow for the asset accordingly.  All rent rolls have to be transferred if something has changed in the rent rolls. Or the field rent rolls is left empty if only the asset data has changed.
         * @summary Update existing DCF calculation for a Asset
         * @param {number} id
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetIdPost(id, dCFInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.dcfAssetIdPost(id, dCFInput, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for a single Asset
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetPost(dCFInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.dcfAssetPost(dCFInput, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for multiple Asset
         * @param {Array<DCFInput>} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfPortfolioPost(dCFInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.dcfPortfolioPost(dCFInput, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CashflowApi - factory interface
 * @export
 */
export const CashflowApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CashflowApiFp(configuration);
    return {
        /**
         * This endpoint uses the existing calculation and checks whether a new calculation based on the asset data or possibly other rent rolls is necessary and returns the discounted cash flow for the asset accordingly.  All rent rolls have to be transferred if something has changed in the rent rolls. Or the field rent rolls is left empty if only the asset data has changed.
         * @summary Update existing DCF calculation for a Asset
         * @param {number} id
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetIdPost(id, dCFInput, options) {
            return localVarFp.dcfAssetIdPost(id, dCFInput, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for a single Asset
         * @param {DCFInput} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfAssetPost(dCFInput, options) {
            return localVarFp.dcfAssetPost(dCFInput, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
         * @summary Create new DCF calculation for multiple Asset
         * @param {Array<DCFInput>} [dCFInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dcfPortfolioPost(dCFInput, options) {
            return localVarFp.dcfPortfolioPost(dCFInput, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CashflowApi - object-oriented interface
 * @export
 * @class CashflowApi
 * @extends {BaseAPI}
 */
export class CashflowApi extends BaseAPI {
    /**
     * This endpoint uses the existing calculation and checks whether a new calculation based on the asset data or possibly other rent rolls is necessary and returns the discounted cash flow for the asset accordingly.  All rent rolls have to be transferred if something has changed in the rent rolls. Or the field rent rolls is left empty if only the asset data has changed.
     * @summary Update existing DCF calculation for a Asset
     * @param {number} id
     * @param {DCFInput} [dCFInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashflowApi
     */
    dcfAssetIdPost(id, dCFInput, options) {
        return CashflowApiFp(this.configuration).dcfAssetIdPost(id, dCFInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
     * @summary Create new DCF calculation for a single Asset
     * @param {DCFInput} [dCFInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashflowApi
     */
    dcfAssetPost(dCFInput, options) {
        return CashflowApiFp(this.configuration).dcfAssetPost(dCFInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint calculates the discounted cash flow for the asset based on the rent rolls and the asset data transferred in the body. The calculation is stored in the service and can be recalled and recalculated using the returned dcf_calc_id.
     * @summary Create new DCF calculation for multiple Asset
     * @param {Array<DCFInput>} [dCFInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashflowApi
     */
    dcfPortfolioPost(dCFInput, options) {
        return CashflowApiFp(this.configuration).dcfPortfolioPost(dCFInput, options).then((request) => request(this.axios, this.basePath));
    }
}
