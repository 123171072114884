/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import { Button, Col, Form, Input, notification, Row } from "antd";
// import { data } from "jquery";
import moment from "moment";
import PropTypes from "prop-types";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createRentRollPost,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { Loader1 } from "../../../../../../../components/Layout/Loader/FullScreenLoader";
import {RentRollDto} from "reia-rest-client";
import {defaultNotifyToaster, RENT_ROLLS_PAGE_SIZE} from "../../../../../../../utils/helpers";
import {useEffect} from "react";
import {getAssetByID} from "../../../../../../../app/features/assets/assets.slice";
import {getCalculationDetail} from "../../../../../../../app/features/calculation/calculationAsset.slice";

function RelettingCosts(props) {
  const { prev, handleCancel, setCurrent } = props;
  const {isLoading} = useSelector((state) => state.rentRoll);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userParams = useParams();
  // const onFinish = (values) => {
  //   console.log('Success:', values);
  //   handleCancel();
  // };
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const getFromLocalStorage = () => {
    const keys = [
      "basicForm",
      "currentRent",
      "generalInfo",
      "indexation",
      "leaseTerm",
      "marketRent",
      "nonRecoverable",
      "nonRecoverableCostTerm",
      "presetting",
      "renewalProbability",
      "vacanctCosts",
      "voidPeriod",
    ];
    const dataArray = {};
    keys.map((item) => {
      const basicFormData = localStorage.getItem(item);
      const formData = basicFormData ? JSON.parse(basicFormData) : null;
      dataArray[item] = formData;
      return formData;
    });

    return dataArray;
  };
  const moveToNext = () => {
    const keys = [
      "basicForm",
      "currentRent",
      "generalInfo",
      "indexation",
      "leaseTerm",
      "marketRent",
      "nonRecoverable",
      "nonRecoverableCostTerm",
      "presetting",
      "renewalProbability",
      "vacanctCosts",
      "voidPeriod",
    ];
    keys.map((item) => localStorage.removeItem(item));
    handleCancel();
    setCurrent(0);
    form.resetFields();
    const finalData = {
      assetId: +userParams.assetDetailId,
      page: 1,
      size: RENT_ROLLS_PAGE_SIZE
    };
    dispatch(getCalculationDetail({finalData,notifyToaster,autoSaveCalc:true}));
  };

  const localData = getFromLocalStorage();

  const onFinish = (data) => {
    const finalData: RentRollDto = {
      id: 0,
      assetId: parseInt(userParams.assetDetailId),
      rentRollStatusType: {id: localData.generalInfo.statusType},
      tenantName: localData.basicForm.tenantName,
      description1: localData.basicForm.description1,
      description2: localData.basicForm.description2,
      location: localData.basicForm.locationFloor,
      //useGroupType: {id: localData.basicForm.useGroup},
      useType: {id: localData.basicForm.use},
      rentalSpace: parseInt(localData.basicForm.areaUnit),
      currentRentPerSqm: parseInt(localData.currentRent.perSqM),
      currentRentPerMonth: localData.currentRent.month,
      currentRentPerYear: localData.currentRent.year,
      // vat: localData.currentRent.vat,
      currentRentVat: localData.currentRent.vat === "True" ? true : false,
      marketRentPerSqm: parseInt(localData.marketRent.perSqM),
      marketRentPerMonth: localData.marketRent.month,
      marketRentPerYear: localData.marketRent.year,
      leaseStartDate: moment(localData.leaseTerm.startdate),
      leaseEndDate: moment(localData.leaseTerm.end),
      leaseDurationInMonths: 0,
      leaseDurationOpenEndInMonths: 0,
      option1Date: moment(localData.leaseTerm.option1),
      option2Date: localData.leaseTerm.option2 ? moment(localData.leaseTerm.option2) : null,
      initialRenewalProbability: localData.renewalProbability.initialRenewalProbability !== "" && localData.renewalProbability.initialRenewalProbability || null,
      continuingRenewalProbability: localData.renewalProbability.continuingRenewalProbability !== "" && localData.renewalProbability.continuingRenewalProbability || null,
      indexationType: {id:localData.indexation.type},
      cpiType: {id:localData.indexation.index},
      indexationValue: localData.indexation.lastIndexValue,
      indexationLastDate: moment(localData.indexation.lastDate),
      indexationFirstDate: moment(localData.indexation.firstDate),
      indexationNextDate: moment(localData.indexation.nextDate),
      nextIndexValue: localData.indexation.nextIndexValue,
      indexationCycleInMonths: localData.indexation.cycle,
      indexationHurdle: localData.indexation.hurdle,
      indexationAdjustmentFactor: localData.indexation.factor,
      cluster: localData.presetting.cluster,
      subCluster: localData.presetting.subCluster,
      nrctMaintenance: localData.nonRecoverableCostTerm.maintenance,
      nrctManagement: localData.nonRecoverableCostTerm.management,
      nrctOtherCosts: localData.nonRecoverableCostTerm.otherCosts,
      nrcrMaintenance: localData.nonRecoverable.maintenance,
      nrcrManagement: localData.nonRecoverable.management,
      nrcrOtherCosts: localData.nonRecoverable.otherCosts,
      initialVoidPeriod: localData.voidPeriod.initial,
      continuingVoidPeriod: localData.voidPeriod.continuing,
      vacancyCosts: localData.vacanctCosts.VacancyCosts,
      relettingCostsInitialTIs: +data.initial,
      relettingCostsContinuingTIs: +data.continue,
      relettingCostsAgent: +data.agentCosta,
    };
    dispatch(createRentRollPost({ finalData, moveToNext, notifyToaster }));
  };

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Reletting Costs
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Initial TI's"
              name="initial"
              rules={[
                {
                  required: true,
                  message: "Please input Initial",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Eur / Sq M" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Cont. TI's"
              name="continue"
              rules={[
                {
                  required: true,
                  message: "Please input Cont.",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Eur / Sq M" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Agent Costs"
              name="agentCosta"
              rules={[
                {
                  required: true,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Months" type="number" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className={`btn-primary ${isLoading && "disabled"}`}  
                  htmlType="submit"
                  disabled={isLoading}
                >
                  <div className="d-flex justify-content-center align-items-center">
                  Done
                  {isLoading && <Loader1 />}
                </div>
                 
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default RelettingCosts;

RelettingCosts.propTypes = {
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
  setCurrent: PropTypes.node.isRequired,
};
