/* eslint-disable */
import PropTypes from "prop-types";
import { useEffect,  useState } from 'react';
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { Button, Checkbox, Form, Input, Menu } from 'antd';
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";

function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) {
  const inputNode = inputType === "number" ? <Input type={inputType} /> : <Input type={inputType} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
            {
              inputType: { inputType },
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

function Presettings({ activeKey, sethideTableColumns }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [toggle, setToggle] = useState([]);

  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const [dataSource, setDataSource] = useState([
    {
      key: 1,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 2,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 3,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 4,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 5,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 6,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 7,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 8,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 9,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 10,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 11,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
    {
      key: 12,
      no: "1",
      pid: "01",
      status: "LET",
      cluster: " ",
      subCluster: " ",
    },
  ]);

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const initialColState = [
    {
      id: 1,
      title: "PID",
      dataIndex: "pid",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "status",
      hidden: false,
    },
    {
      id: 3,
      title: "Cluster",
      dataIndex: "cluster",
      hidden: toggle.includes("cluster"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "cluster",
        title: "Cluster",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Sub-Cluster",
      dataIndex: "subCluster",
      hidden: toggle.includes("subCluster"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "subCluster",
        title: "Sub-Cluster",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record.key)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
          <div
            className="drawer-action-icons d-flex align-items-center cursor-pointer"
            role="presentation"
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <MdModeEdit size={20} />
          </div>
          </div>
        );
      },
    },
  ];

  const handleReset = () => {
    setToggle([]);
  };
  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if(checked){
      setToggle((prev) => [...prev, value]);
    }
    else{
      setToggle((prev) => prev.filter((item) => item !== value));
    }
  };

  const basicDatahideTableColumns = (
    <Menu
      items={[
        {
          key: 1,
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        // {
        //   key: 2,
        //   label: (
        //     <Checkbox
        //       value="status"
        //       name="Status/Type"
        //       id={2}
        //       // checked={checkList.includes("valDate")}
        //       onChange={handleChange}
        //     >
        //       Status/Type
        //     </Checkbox>
        //   ),
        // },
        {
          key: 3,
          label: (
            <Checkbox
              value="cluster"
              name="Cluster"
              id={3}
              // checked={checkList.includes("projectNo")}
              onChange={handleChange}
            >
              Cluster
            </Checkbox>
          ),
        },
        {
          key: 4,
          label: (
            <Checkbox
              value="subCluster"
              name="Sub-Cluster"
              id={4}
              // checked={checkList.includes("projectName")}
              onChange={handleChange}
            >
              Sub-Cluster
            </Checkbox>
          ),
        },
        {
          key: 5,
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn" onClick={handleReset}>
                Reset
              </Button>
            </div>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    sethideTableColumns(basicDatahideTableColumns)
  }, [activeKey]);
  return (
    <div>
       <Form form={form} component={false}>
        <DataTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          columns={initialColState.filter((item) => item.hidden === false)}
          dataSource={[...dataSource]}
        pagination="true"
        className="antd-table-striped"
        rowSelection={rowSelection}
      />
      </Form>
      <div className="table-count">
        <h5 className="table-count-content">Showing 1 to 9 of 120 entries</h5>
      </div>
      {/* <Row gutter={24}>
        <Col xs={24}>
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
              <Button
                className="btn-action d-flex justify-content-center align-items-center text-danger"
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
              >
                <div className="active-action d-flex align-items-center">
                  <MdDelete />
                  <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                </div>
              </Button>

              {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddUpdateNewRentRoll(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdModeEdit className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                    </div>
                  </Button>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row> */}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </div>
  );
}
export default Presettings;

Presettings.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};
EditableCell.propTypes = {
  editing: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  inputType: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};