import { Button, Col, Form, Input, Row, Select } from "antd";
import PropTypes from "prop-types";

function GeneralInfo(props) {

  const { next, handleCancel } = props;
  const onFinish = (values) => {
    console.log('Success:', values);
    next();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const { Option } = Select;
  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        General Info
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          {/* <Col xs={8}>
            <Form.Item
              label="No"
              name="no"
              rules={[
                {
                  required: true,
                  message: 'Please input your number!',
                },
              ]}>
              <Input placeholder="1" />
            </Form.Item>
          </Col> */}
          <Col xs={8}>
            <Form.Item label="PID" name="pID"
              rules={[
                {
                  required: false,
                  message: 'Please input product Id!',
                },
              ]}
            >
              <Input disabled placeholder="01" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Status Type" name="statusType" rules={[{ required: true, message: 'Please input Status Type!', }]}>
              <Select placeholder="Select" allowClear>
                <Option value="1">LET</Option>
                <Option value="2">VACANT</Option>
                <Option value="3">VACANT (S)</Option>
                <Option value="3">GUARANTEE</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button type="primary" className="btn-primary" htmlType="submit">
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default GeneralInfo;

GeneralInfo.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
