import { Button, Col, Form, Input, Row, Select } from "antd"
import PropTypes from "prop-types";

function BasicData(props) {
  const { Option } = Select;

  const { next, prev, handleCancel } = props;

  const onFinish = (values) => {
    console.log('Success:', values);
    next();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Basic Data
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={8}>
            <Form.Item label="Tenant Name" name="tenantName" rules={[
              {
                required: true,
                message: 'Please input your tanent name!',
              },
            ]}>
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Description#1" name="description1" rules={[
              {
                required: true,
                message: 'Please input description 1!',
              },
            ]}>
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Description#2" name="description2"
              rules={[
                {
                  required: true,
                  message: 'Please input description 2',
                },
              ]}>
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} >
          <Col xs={8}>
            <Form.Item label="Location/Floor" name="locationFloor"
              rules={[
                {
                  required: true,
                  message: 'Please input Location!',
                },
              ]}>
              <Input placeholder="Text" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Use Group" name="useGroup">
              <Select placeholder="Select" allowClear>
                <Option value="1">Select1</Option>
                <Option value="2">Select2</Option>
                <Option value="3">Select3</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Use" name="use">
              <Select placeholder="Select" allowClear>
                <Option value="1">Office</Option>
                <Option value="2">Surgery</Option>
                <Option value="3">Retail</Option>
                <Option value="4">Banking hall</Option>
                <Option value="5">Residential</Option>
                <Option value="6">Restaurant</Option>
                <Option value="7">Warehouse</Option>
                <Option value="8">Storage</Option>
                <Option value="9">Hotel</Option>
                <Option value="10">Other</Option>
                <Option value="11">&apos;--</Option>
                <Option value="12">Parking external</Option>
                <Option value="13">Parking internal</Option>
                <Option value="14">Antenna</Option>
                <Option value="15">Advertisements</Option>
                <Option value="16">Other (per unit)</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-form">
          <Col xs={8}>
            <Form.Item label="Area/Unit" name="areaUnit">
              <Input placeholder="Sqm/Unit" type="number"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button type="primary" className="btn-primary" htmlType="submit">
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default BasicData;


BasicData.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};