import { notification, Radio } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssignedTaskDashboard,
  // getAssignedTaskDashboard,
  getAssignedTaskDetail,
  updateAssignedTaskStatus,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";
// import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";
import IMAGES from "../../../../../assets/images";

export default function TaskDetails(props) {
  const { selectedTask } = props;
  const dispatch = useDispatch();
  const { taskStatusType } = useSelector((state) => state.lookUps);

  useEffect(() => {
    const finalData = {
      type: "taskStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    dispatch(getAssignedTaskDashboard());

    const finalData = {
      assignTaskId: selectedTask?.assignTaskId,
    };
    dispatch(getAssignedTaskDetail(finalData));
  };

  useEffect(() => {
    //
  }, [selectedTask]);

  const getStatusTypeId = (text) => {
    const statusType = taskStatusType.filter(
      (task) => task.text === (text === "Completed" ? "Completed" : "Rejected")
    );
    return typeof statusType === typeof [] ? statusType?.[0]?.lookUpId : null;
  };

  const statusChange = (e) => {
    const finalData = {
      assignTaskId: selectedTask?.assignTaskId,
      taskStatusId: getStatusTypeId(e.target.value),
    };
    dispatch(
      updateAssignedTaskStatus({ finalData, moveToNext, notifyToaster })
    );
  };
  return (
    <div className="detail-drawer bg-white rounded-sm text-center">
      <img
        className="chat-avatar mb-2"
        src={selectedTask.img ? selectedTask.img : IMAGES.MEN_AVATAR}
        alt="user"
      />
      <h3>{selectedTask.userName ? selectedTask.userName : "N/A"}</h3>
      <div className="d-flex justify-content-between align-items-center my-2">
        <h5 className="m-0">Task Assigned</h5>
        <p className="m-0">
          {selectedTask.taskName ? selectedTask.taskName : "N/A"}
        </p>
      </div>
      <hr />
      <div className="d-flex justify-content-between align-items-center my-2">
        <h5 className="m-0">Assign Date</h5>
        <p className="m-0">
          {selectedTask?.assignedDate
            ? selectedTask?.assignedDate?.split("T")[0]
            : "N/A"}
        </p>
      </div>
      <hr />
      <div className="d-flex justify-content-between align-items-center my-2">
        <h5 className="m-0">Task Status</h5>
        <p
          className="m-0"
          style={{
            color:
              (selectedTask.statusType === "Pending" && "#FFB458") ||
              (selectedTask.statusType === "Completed" && "#5CDB5C") ||
              (selectedTask.statusType === "Rejected" && "#fc0000"),
          }}
        >
          {selectedTask.statusType ? selectedTask.statusType : "N/A"}
        </p>
      </div>
      <hr />
      <div className="d-flex align-items-center">
        <Radio.Group
          onChange={(e) => statusChange(e)}
          value={selectedTask?.statusType}
        >
          <Radio checked value="Completed">
            Mark as complete
          </Radio>
          <Radio checked value="Rejected">
            Rejected
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
}

TaskDetails.propTypes = {
  selectedTask: PropTypes.node.isRequired,
};
