import { Card, Col, Dropdown, Menu, Row } from "antd";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { BiFilter } from "react-icons/bi";
import { getColorsTagClass } from "../../../../utils/helpers";
import SearchForm from "./searchForm";

function Users() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const usersGroupData = [
    {
      id: 0,
      date: new Date(2022, 9, 20),
      activity: [
        {
          id: 0,
          nameAbbr: "JB",
          name: "Jerome Bell",
          role: "Coorporate",
          tags: ["Most Least Assets", "Active"],
        },
        {
          id: 1,
          nameAbbr: "CW",
          name: "Cameron Williamson",
          role: "Individual",
          tags: ["Inactive"],
        },
        {
          id: 2,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Subscriber"],
        },
        {
          id: 3,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Most Least Assets"],
        },
      ],
    },
    {
      id: 1,
      date: new Date(2022, 9, 19),
      activity: [
        {
          id: 0,
          nameAbbr: "JB",
          name: "Jerome Bell",
          role: "Coorporate",
          tags: ["Most Least Assets", "Active"],
        },
        {
          id: 1,
          nameAbbr: "CW",
          name: "Cameron Williamson",
          role: "Individual",
          tags: ["Inactive"],
        },
        {
          id: 2,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Subscriber"],
        },
        {
          id: 3,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Most Least Assets"],
        },
        {
          id: 4,
          nameAbbr: "JB",
          name: "Jerome Bell",
          role: "Coorporate",
          tags: ["Most Least Assets", "Active"],
        },
        {
          id: 5,
          nameAbbr: "CW",
          name: "Cameron Williamson",
          role: "Individual",
          tags: ["Inactive"],
        },
        {
          id: 6,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Subscriber"],
        },
        {
          id: 7,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Most Least Assets"],
        },
      ],
    },
    {
      id: 2,
      date: new Date(2022, 9, 18),
      activity: [
        {
          id: 0,
          nameAbbr: "JB",
          name: "Jerome Bell",
          role: "Coorporate",
          tags: ["Most Least Assets", "Active"],
        },
        {
          id: 1,
          nameAbbr: "CW",
          name: "Cameron Williamson",
          role: "Individual",
          tags: ["Inactive"],
        },
        {
          id: 2,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Subscriber"],
        },
        {
          id: 3,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Most Least Assets"],
        },
        {
          id: 4,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Subscriber"],
        },
        {
          id: 5,
          nameAbbr: "BS",
          name: "Brooklyn Simmons",
          role: "Coorporate",
          tags: ["Most Least Assets"],
        },
      ],
    },
  ];

  const [filteredGroupData, setfilteredGroupData] = useState(usersGroupData);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const userFilter = (
    <Menu
      selectable
      defaultSelectedKeys={["0"]}
      items={[
        {
          key: "0",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => {
                setfilteredGroupData(usersGroupData);
              }}
            >
              All
            </div>
          ),
        },
        {
          key: "1",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => {
                setfilteredGroupData(
                  usersGroupData.map((obj) => ({
                    ...obj,
                    activity: obj.activity.filter((activity) =>
                      activity.tags.includes("Active")
                    ),
                  }))
                );
              }}
            >
              Active
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => {
                setfilteredGroupData(
                  usersGroupData.map((obj) => ({
                    ...obj,
                    activity: obj.activity.filter((activity) =>
                      activity.tags.includes("Inactive")
                    ),
                  }))
                );
              }}
            >
              Inactive
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => {
                setfilteredGroupData(
                  usersGroupData.map((obj) => ({
                    ...obj,
                    activity: obj.activity.filter((activity) =>
                      activity.tags.includes("Subscriber")
                    ),
                  }))
                );
              }}
            >
              Subscriber
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              role="presentation"
              className="d-flex justify-content-left align-items-center table-dropdown"
              onClick={() => {
                setfilteredGroupData(
                  usersGroupData.map((obj) => ({
                    ...obj,
                    activity: obj.activity.filter((activity) =>
                      activity.tags.includes("Most Least Assets")
                    ),
                  }))
                );
              }}
            >
              Most Least Assets
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <div className="report-user">
      <Card bordered={false}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-black">Users</h3>
          <div className="d-flex">
            <div className="d-flex justify-content-end align-items-center searchbar-project mr-2">
              <div
                className="d-flex my-auto users-top-icon cursor-pointer filter-hover"
                role="button"
                tabIndex="0"
                onClick={() => setIsModalOpen(true)}
                onKeyDown={() => setIsModalOpen(true)}
              >
                <FiSearch size={20} />
              </div>
            </div>
            <Dropdown overlay={userFilter} trigger={["click"]}>
              <div className="d-flex my-auto users-top-icon cursor-pointer filter-hover">
                <BiFilter size={21} />
              </div>
            </Dropdown>
          </div>
        </div>
        <hr />
        <div className="custom-scroll users-card-scroll">
          {filteredGroupData.map((item) => (
            <div key={item.id}>
              <Row gutter={24}>
                <Col span={12} xs={24}>
                  <h3 className="text-gray">
                    {new Date(item.date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </h3>
                </Col>

                {item.activity.map((activity) => (
                  <Col
                    span={12}
                    xs={24}
                    className="d-flex align-items-center justify-content-between mb-3"
                  >
                    <div className="d-flex align-items-center justify-content">
                      <div className="users-name-abbr">
                        <h3 className="m-0 text-blue">{activity.nameAbbr}</h3>
                      </div>

                      <div className="ml-2">
                        <h4 className="font-weight-normal m-0 text-blue">
                          {activity.name}
                        </h4>
                        <p className="mb-0">{activity.role}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mr-2">
                      {activity.tags.map((badge) => (
                        <div
                          className={`ml-2 pl-3 pr-3 ${getColorsTagClass(
                            badge
                          )}`}
                        >
                          {badge}
                        </div>
                      ))}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Card>
      <SearchForm visible={isModalOpen} handleCancel={closeModal} />
    </div>
  );
}

export default Users;
