import api from "../../../utils/api";

const getCashFlowMonthly = async (data) => {
    const response = await api.get("/api/CashFlowMonthly/getCashFlowMonthly", {
      params: data,
    });
    return response.data.data;
  };


  const cashflow = {
    getCashFlowMonthly,
  };
  
  export default cashflow;